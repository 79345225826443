import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Sprite } from './Sprite';
import { useDevice } from 'hooks';

const Container = styled.div`
	position: fixed;
	z-index: 1000000;
	right: 20px;
	bottom: 35px;
	width: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	color: #4F7EA7;
	cursor: pointer;
	opacity: 0;
	visibility: hidden;

	&.isShow {
		opacity: 1;
		visibility: visible;
	}
`;

const Icon = styled.div`
	width: 40px;
	height: 40px;
	flex-grow: 0;
	flex-shrink: 0;
	border-radius: 20px;
	border: 2px solid #FFFFFF;
	background-color: #4F7EA7;
	margin-bottom: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ToTop = () => {
	const { t } = useTranslation();
	const { isDesktop } = useDevice();
	const [ isShow, setShow ] = useState(false);

	const onScoll = () => setShow(window.pageYOffset >= 140);

	useEffect(() => {
		document.addEventListener('scroll', onScoll);
		return () => document.removeEventListener('scroll', onScoll);
	}, []);

	if (!isDesktop) return null;

	const onClick = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	return (
		<Container onClick={onClick} className={classnames({isShow})}>
			<Icon>
				<Sprite icon="arrow_top_white" />
			</Icon>
			{t('наверх')}
		</Container>
	);
};