/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Modal2 } from 'modals';
import { Counter } from 'forms';
import { Select } from 'forms2';
import { toMoney } from 'utils';
import Calendar from 'react-calendar';
import { parseDate, format, shortDayName, todayString, tomorrowString, today } from 'utils/dayjs';
import { Button } from 'components/Button';

const Container = styled.div``;

const ValueDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 659px) {
		flex-direction: column;
		align-items: flex-start;
	}

	b {
		font-weight: 500;
	}
`;

const Columns = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
	margin-top: 20px;

	@media (max-width: 659px) {
		flex-direction: column;
		align-items: stretch;
	}

	> div {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;

		&:last-child {
			flex-grow: 1;
			margin-left: 40px;

			@media (max-width: 659px) {
				margin-left: 0;
				margin-top: 20px;
			}

			flex-direction: column;
			align-items: flex-end;
			justify-content: space-between;

			h5 {
				width: 100%;
			}
		}

		h5 {
			margin-bottom: 10px;
			color: ${props => props.theme.darkBlue};
		}

		h4 {
			margin-bottom: 20px !important;
		}
	}
`;

const Dates = styled.div`
	border: 1px solid ${props => props.theme.lightGray};
	padding: 10px 10px 0 10px;
`;

const AllDates = styled.div`
	line-height: 40px;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	color: ${props => props.theme.darkBlue};
	text-align: center;
	cursor: pointer;
	font-weight: bold;

	&:hover {
		color: ${props => props.theme.lightBlue};
	}
`;

const Guests = styled.div`
	border-top: 1px solid ${props => props.theme.lightGray};
	border-bottom: 1px solid ${props => props.theme.lightGray};
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	.buttonAsLink {
		font-weight: bold;
	}
`;

const Title = styled.div`
	color: #333;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	text-align: center;
	font-weight: bold;
	margin-top: 10px;
`;

export const AddExtraMeal = ({
	children,
	canAddExtraMeal,
	extraMeals,
	setOrderInfo,
	currency,
	isMobile,
	totalGuests,
}) => {
	const [ isVisible, setVisible ] = useState(false);
	const [ meal, setMeal ] = useState(null);
	const [ startDate, setStartDate ] = useState(todayString);
	const [ endDate, setEndDate ] = useState(tomorrowString);
	const [ guests, setGuests ] = useState(1);
	const { t } = useTranslation();

	const onOpen = () => setVisible(true);
	const onClose = () => setVisible(false);

	const getPrice = items => {
		if (Array.isArray(items)) {
			const minPrice = items.reduce((acc, cur) => cur?.Price < acc ? cur?.Price : acc, items[0]?.Price ?? 0);
			const maxPrice = items.reduce((acc, cur) => cur?.Price > acc ? cur?.Price : acc, items[0]?.Price ?? 0);

			return `${toMoney(minPrice ?? 0)} - ${toMoney(maxPrice ?? 0)}`;
		} else {
			return toMoney(items?.Price ?? 0);
		}
	};

	const getMinDate = items => {
		if (Array.isArray(items)) {
			return items.reduce((acc, cur) => parseDate(cur.StartDate).isBefore(parseDate(acc)) ? cur.StartDate : acc, endDate);
		} else {
			return items.StartDate;
		}
	};

	const getMaxDate = items => {
		if (Array.isArray(items)) {
			return items.reduce((acc, cur) => parseDate(cur.EndDate).isAfter(parseDate(acc)) ? cur.EndDate : acc, startDate);
		} else {
			return items.EndDate;
		}
	};

	const options = extraMeals.map(i => ({
		Code: Array.isArray(i) ? i[0].Code : i.Code,
		Price: Array.isArray(i) ? i[0].Price : i.Price,
		items: Array.isArray(i) ? [...i] : i,
		value: Array.isArray(i) ? i[0].Code : i.Code,
		label: <ValueDiv>
			{Array.isArray(i) ? i[0].Name : i.Name}
			<b>{getPrice(i)} {currency} на гостя в сутки</b>
		</ValueDiv>,
		StartDate: getMinDate(i),
		EndDate: getMaxDate(i),
		KindCode: Array.isArray(i) ? i[0].KindCode : i.KindCode,
	}));

	useEffect(() => {
		if (options.length > 0) {
			const defaultMeal = options[0];

			const {
				StartDate,
				EndDate,
				KindCode
			} = defaultMeal;

			setGuests(totalGuests);
			setStartDate(KindCode === 2 ? parseDate(StartDate).add(1, 'day').format(format) : StartDate);
			setEndDate(EndDate);
			setMeal(defaultMeal);
		} else {
			setMeal(null);
		}
	}, [JSON.stringify(options.map(i => ({...i, label: ''})))]);

	const mealBind = {
		options,
		value: meal,
		onChange: newMeal => {
			parseDate(startDate).isBefore(parseDate(newMeal.StartDate)) && setStartDate(newMeal.StartDate);
			parseDate(endDate).isAfter(parseDate(newMeal.EndDate)) && setStartDate(newMeal.EndDate);
			setMeal(newMeal);
		},
		isBig: true,
	};

	const datesBind = {
		showDoubleView: !isMobile,
		minDate: parseDate(meal?.StartDate).toDate(),
		maxDate: parseDate(meal?.EndDate).toDate(),
		value: [parseDate(startDate).toDate(), parseDate(endDate).toDate()],
		onChange: dates => {
			setStartDate(parseDate(dates[0]).format(format));
			setEndDate(parseDate(dates[1]).format(format));
		},
		selectRange: true
	};

	const selectFullPeriod = () => {
		setStartDate(meal.StartDate);
		setEndDate(meal.EndDate);
	};

	const guestsBind = {
		value: guests,
		onChange: i => setGuests(i),
		min: 1,
		max: 100,
	};

	const setTotalGuests = () => setGuests(totalGuests);

	const nights = Math.abs(parseDate(startDate).diff(parseDate(endDate), 'day', true));

	const sum = useMemo(() => {
		if (!meal) return 0;

		if (Array.isArray(meal.items)) {
			return meal.items.reduce((acc, cur) => {
				if (parseDate(cur.StartDate).isAfter(parseDate(endDate)) || parseDate(cur.EndDate).isBefore(parseDate(startDate))) return acc;

				const date1 = parseDate(cur.StartDate).isAfter(parseDate(startDate)) ? cur.StartDate : startDate;
				const date2 = parseDate(cur.EndDate).isBefore(parseDate(endDate)) ? cur.EndDate : endDate;

				const nights = Math.abs(parseDate(date1).diff(parseDate(date2), 'day', true)) + 1;

				return acc + ((cur?.Price ?? 0) * nights * guests);
			}, 0);
		} else {
			return (meal?.Price ?? 0) * (nights + 1) * guests;
		}
	}, [meal?.Price, startDate, endDate, guests]);

	const addMeal = () => {
		setOrderInfo(prev => {
			const extraMeals = prev?.ExtraMeals ?? [];

			if (Array.isArray(meal?.items)) {
				meal.items.forEach(meal => {
					if (!parseDate(startDate).isAfter(parseDate(meal.EndDate)) && !parseDate(endDate).isBefore(parseDate(meal.StartDate))) {
						extraMeals.push({
							Code: meal.Code,
							Id: Math.max.apply(null, [...extraMeals.filter(i => i.Id < 0).map(i => i.Id), -100000]) + 1,
							StartDate: parseDate(meal.StartDate).isAfter(parseDate(startDate)) ? meal.StartDate : startDate,
							EndDate: parseDate(meal.EndDate).isBefore(parseDate(endDate)) ? meal.EndDate : endDate,
							KindCode: meal.KindCode,
							QuantityPerRoom: guests,
						});
					}
				});
			} else {
				extraMeals.push({
					Code: meal.Code,
					Id: Math.max.apply(null, [...extraMeals.filter(i => i.Id < 0).map(i => i.Id), -100000]) + 1,
					StartDate: startDate,
					EndDate: endDate,
					KindCode: meal.KindCode,
					QuantityPerRoom: guests,
				});
			}

			return {
				...prev,
				ExtraMeals: [
					...extraMeals,
				]
			};
		});

		onClose();
	};

	if (!canAddExtraMeal || extraMeals.length === 0 || !meal) {
		return null;
	}

	return (
		<>
			{React.cloneElement(children, { onClick: onOpen })}

			<Modal2 isVisible={isVisible} onClose={onClose}>
				<Container>
					<h1>{t('Добавить питание')}</h1>

					<Select {...mealBind} />

					<Columns>
						<div>
							<h5>{t('Период предоставления')}</h5>
							
							<Dates>
								<Calendar {...datesBind} />

								<Title>
									{startDate} {shortDayName(startDate)} - {endDate} {shortDayName(endDate)}
								</Title>

								<AllDates onClick={selectFullPeriod}>
									{t('Добавить на весь период проживания')}
								</AllDates>
							</Dates>
						</div>

						<div>
							<h5>{t('Количество')}</h5>
							
							<Guests>
								<Counter {...guestsBind} />
								<div className='buttonAsLink' onClick={setTotalGuests}>{t('Для всех гостей')}</div>
							</Guests>

							<div className='filler' />

							<h4>
								{t('ИТОГО')}: {toMoney(sum)} {currency}
							</h4>

							<Button gradient onClick={addMeal}>
								{t('Добавить')}
							</Button>
						</div>
					</Columns>
				</Container>
			</Modal2>
		</>
	);
};