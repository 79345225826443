import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore } from 'stores';
import { Sprite } from 'components';

export const Hotel = () => {
	const { t } = useTranslation();

	const {
		params: {
			filters: {
				hotel = ''
			}
		},
		setParams
	} = useHotelSearchStore();

	const onChange = e => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			hotel: e?.target?.value ?? e
		}
	}));

	const bind = {
		value: hotel,
		onChange
	};

	const isDirty = hotel.trim().length >= 2;
	const clear = () => onChange('');

	return (
		<div>
			<label>
				{t('Название отеля')}
				{isDirty && 
					<div onClick={clear}>
						<Sprite icon='small_cross' />
					</div>
				}
			</label>

			<input {...bind} />
		</div>
	);
};