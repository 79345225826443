import React from 'react';
import styled from 'styled-components';
import { Select } from 'forms2';
import { useTranslation } from 'react-i18next';
import { Sprite } from 'components';
import { Button } from 'components/Button';

const Container = styled.div`
	margin-top: 20px;
`;

const Title = styled.div`
	color: ${props => props.theme.darkBlue};
	font-size: 16px;
	line-height: 19px;
	margin-bottom: 10px;
	
	b {
		font-weight: 500;
	}
`;

const Item = styled.div`
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;

	@media (min-width: 660px) {
		> *:not(:nth-child(5)) {
			width: calc((100% - 60px) / 4) !important;
			margin-right: 10px;
		}
	}

	@media (max-width: 659px) {
		flex-direction: column;
		align-items: stretch;

		> * {
			width: 100% !important;

			&:not(:first-child) {
				margin-top: 10px;
			}
		}
	}
`;

const DelButton = styled.div`
	width: 20px;
	height: 20px;
	flex-grow: 0;
	flex-shrink: 0;
	cursor: pointer;
	color: ${props => props.theme.darkBlue};
`;

export const Persons = ({items = [], guests, categories = [], citizenships = [], onChange = () => null, onAdd = () => null, onDelete = () => null, readOnly = false}) => {
	const { t } = useTranslation();

	const handleChange = (key, item, field, value) => {
		if (readOnly) {
			return;
		}

		item = {
			...item,
			[field]: value
		};

		onChange(key, item);
	};

	const lastNameProps = (i, key) => ({
		name: `LastName_${key}`,
		defaultValue: i?.LastName ?? '',
		placeholder: t('Фамилия'),
		readOnly,
		onBlur: e => handleChange(key, i, 'LastName', e.target.value),
	});

	const firstNameProps = (i, key) => ({
		name: `FirstName_${key}`,
		defaultValue: i?.FirstName ?? '',
		placeholder: t('Имя'),
		readOnly,
		onBlur: e => handleChange(key, i, 'FirstName', e.target.value)
	});

	const categoryBind = (i, key) => ({
		options: categories,
		placeholder: t('Выберите...'),
		value: categories.find(c => c.Code === i?.Category?.Code) ?? null,
		onChange: option => handleChange(key, i, 'Category', option),
		isDisabled: readOnly,
		isBig: true,
		name: `category${key}`
	});

	const citizenshipBind = (i, key) => ({
		isBig: true,
		placeholder: t('Выберите...'),
		value: citizenships.find(c => c.Code === i?.Citizenship?.Code) ?? null,
		options: citizenships,
		name: `citizenship${key}`,
		onChange: options => handleChange(key, i, 'Citizenship', options),
		isDisabled: readOnly,
		isSearchable: true,
		isClearable: true,
		backspaceRemovesValue: false,
	});

	return (
		<Container>
			<Title>
				<b>{t('Гости')}:</b> {guests}
			</Title>

			<div>
				{items.map((i, key) => <Item key={key}>
					<Select {...categoryBind(i, key)} />
					<input type='text' {...lastNameProps(i, key)} />
					<input type='text' {...firstNameProps(i, key)} />
					<Select {...citizenshipBind(i, key)} />
					{items.length > 1 && <DelButton onClick={onDelete.bind(this, key)}>
						<Sprite icon='blue_cross' />
					</DelButton>}
				</Item>)}
			</div>

			{!readOnly && items.length < guests && <Button white small onClick={onAdd}>{t('Добавить гостя')}</Button>}
		</Container>
	);
};