import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toMoney } from 'utils';
import { useHotelSearchStore, useUserStore } from 'stores';
import { Button } from 'components';
import { PenaltyList } from '../';

const Container = styled.table`
	width: 100%;
	/* position: relative; */

	& + .offer {
		border-top: 1px solid ${props => props.theme.lightGray};
	}

	td {
		padding-bottom: 10px;
		padding-top: 10px;
		vertical-align: top;

		&.col1 {

		}

		&.col2 {
			padding-left: 20px;
			text-align: right;
			width: 250px;
		}

		&.col3 {
			padding-left: 20px;
			text-align: right;
			width: 365px;
		}

		&.col4 {
			padding-left: 20px;
			text-align: right;
			width: 210px;
		}

		*:not(.button):not(.components__button) {
			color: #333;
			font-family: ${props => props.theme.pt};
			font-size: 16px;
			font-weight: 400;
			line-height: normal;
		}

		b {
			font-weight: 700 !important;
		}

		img {
			vertical-align: sub;
			margin-right: 5px;
		}

		span + span, b + b {
			margin-left: 10px;
		}

		> div + div {
			margin-top: 5px;
		}
	}

	*:not(.button):not(.components__button) {
		&.orange { color: #FF6B00 !important; }
		&.blue { color: #004685 !important; }
		&.gray { color: #80A2C2 !important; }
		&.lightBlue { color: #00C0FF !important; }
		&.green { color: #5BB500 !important; }
	}

	span, b, div {
		white-space: nowrap !important;
	}

	.overflow {
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 350px;
		display: inline-block;
	}
`;

const Offer = ({ offer, CurrencyName, hotelCode, datesBind, onShowDetails }) => {
	const { t } = useTranslation();
	const { allowTabs } = useUserStore();

	const {
		params: {
			startDate = datesBind?.startDate,
			endDate = datesBind?.endDate,
		} = {},
		onShowDetails: handleShowDetails = onShowDetails,
	} = useHotelSearchStore() ?? {};

	const {
		Code,
		MealIncludedInPrice: {
			Code: MealIncludedInPrice,
			Name: MealIncludedInPriceName
		},
		WhereToPay: {
			Name: WhereToPayName
		},
		DiscountOffer: {
			Code: DiscountOffer = 2,
			Name: DiscountOfferName
		} = {},
		TotalPrice,
		Availability: {
			Code: Availability,
			Name: AvailabilityName,
			Allotment: {
				Rooms
			}
		},
		RateDescription = '',
		NumberOfGuests,
		NumberOfExtraBedAdult,
		NumberOfExtraBedChild,
		NumberOfExtraBedInfant,
		PenaltyRuleList: {
			PenaltyRule = []
		} = {}
	} = offer;

	const buttonBind = {
		orange: Availability === 4,
		gradient: Availability !== 4,
		to: `/order/${hotelCode}/${Code}/${startDate}/${endDate}/${NumberOfGuests}/${NumberOfExtraBedAdult}/${NumberOfExtraBedChild}/${NumberOfExtraBedInfant}`,
		target: Number(sessionStorage?.getItem('orderId') ?? 0) === 0 && allowTabs ? '_blank' : '_self',
	};

	return (
		<Container className='offer'>
			{/* <colgroup>
				<col />
				<col width='270' align='right' />
				<col width='425' />
				<col width='190' />
			</colgroup> */}

			<tbody>
				<tr>
					<td className='col1'>
						{Number(DiscountOffer) === 1 &&
							<div>
								<b className='orange overflow'>{DiscountOfferName}</b>
							</div>
						}

						{Number(DiscountOffer) !== 1 && !!RateDescription &&
							<div>
								<b className='blue overflow'>{RateDescription}</b>
							</div>
						}

						{PenaltyRule.length > 0 && <PenaltyList items={PenaltyRule} CurrencyName={CurrencyName} />}
					</td>
					
					<td className='col2'>
						<b>
							{MealIncludedInPrice > 1 ? MealIncludedInPriceName : t('Без питания')}
						</b>
					</td>

					<td className='col3'>
						<div onClick={handleShowDetails.bind(this, offer, hotelCode)} className='clickable'>
							<img src='/images/icon_warning.png' />

							<b className='blue'>
								{toMoney(TotalPrice)} {CurrencyName}
							</b>
						</div>

						<div>
							<span>
								{WhereToPayName}
							</span>
						</div>
					</td>

					<td className='col4'>
						<div>
							<span>
								{Availability === 4 && t('Под запрос')}
								{Availability !== 4 && Rooms !== '' && <>{t('В наличии')}: <b>{Rooms}</b></>}
								{Availability !== 4 && Rooms === '' && AvailabilityName.toLowerCase().indexOf('свободная продажа') === -1 && <>{t('В наличии')}: <b>{AvailabilityName}</b></>}
								{Availability !== 4 && Rooms === '' && AvailabilityName.toLowerCase().indexOf('свободная продажа') !== -1 && <>{t('В наличии')}: <b>{t('МНОГО')}</b></>}
							</span>
						</div>

						<Button {...buttonBind}>{t('Бронировать')}</Button>
					</td>
				</tr>
			</tbody>
		</Container>
	);
};

export default Offer;