import React from 'react';
import styled from 'styled-components';
import { toMoney } from 'utils';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
`;

const Comment = styled.div`
	padding-right: 10px;
	border-right: 1px solid ${props => props.theme.lightGray};
	font-family: ${props => props.theme.pt};
	color: #333;
	font-size: 16px;
	line-height: 21px;
	width: 50%;
	
	b {
		font-weight: bold;
		font-family: ${props => props.theme.pt};
	}
`;

const Pricing = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
	margin-left: 20px;
	width: calc(50% - 20px);
	
	> div {
		&:nth-of-type(n + 2) {
			margin-left: 10px;
		}
	}
`;

/*
const Points = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #00C0FF;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	padding-bottom: 5px;
`;
*/

const Count = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #5BB500;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;	
	flex-grow: 1;
	padding-bottom: 5px;
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 200px;
`;

const PriceDiv = styled.div`
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: ${props => props.theme.darkBlue};
	margin-bottom: 5px;
`;

const OrderButton = styled.div`
	width: 200px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 30px;
	background: linear-gradient(180deg, #22AFFE 0%, #0270CB 100%);
	border-radius: 5px;
	color: white;
	cursor: pointer;
	font-size: 14px;
	line-height: 17px;
	text-transform: uppercase;	
`;

export const Summary = ({ isDirect = true, pricing = {} }) => {
	const { t } = useTranslation();

	const {
		DeadlineTimeLoc,
		DeadlineTimeSys,
		DeadlineTimeUTC,
		PenaltySize,
		Price,
	} = pricing.HotelPricingDetail;

	const CurrencyName = pricing.Currency.Name;

	return (
		<Container>
			<Comment>
				<b>{t('БЕСПЛАТНАЯ')}</b> {t('отмена заявки возможна до {{DeadlineTimeLoc}} по местному времени отеля ({{DeadlineTimeSys}} по Московскому времени, {{DeadlineTimeUTC}} по UTС)', {DeadlineTimeLoc, DeadlineTimeSys, DeadlineTimeUTC})}.
				<br/>
				{t('Начиная с указанного времени за аннулирование или')} {isDirect ? t('изменение заявки, а также за незаезд') : t('незаезд')} {t('взимается штраф в размере')} <b>{toMoney(PenaltySize)} {CurrencyName}.</b>
			</Comment>

			<Pricing>
				{/* <Points>Баллы: +???</Points> */}

				<Count>{t('В наличии: много')}</Count>

				<Items>
					<PriceDiv>{toMoney(Price)} {CurrencyName}</PriceDiv>
					<OrderButton>{t('Бронировать')}</OrderButton>
				</Items>
			</Pricing>
		</Container>
	);
};
