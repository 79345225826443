/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-unreachable */
import { useEffect, useMemo, useRef, useState } from 'react';
import { toMoney } from 'utils';
// import { OrderInfoRequest } from 'requests/OrderInfoRequest';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInstance } from './useInstance';
import { useErrorStore } from 'stores';
import { getIds, inArray } from 'utils';
import { useCartStore } from 'stores';
import { useUserStore } from 'stores';
import md5 from 'md5';

export const useOrderInfo = id => {
	const { post } = useInstance();
	const { t } = useTranslation();

	const { allowTransfers, paymentBinds } = useUserStore();
	const { clear } = useCartStore();
	const { setUserError } = useErrorStore();
	const [ order, setOrder ] = useState(false);
	const [ selected, setSelected ] = useState([]);
	const [ penalty, setPenalty ] = useState(false);
	const [ editUrl, setEditUrl ] = useState('');
	const [ cancelPenalty, setCancelPenalty ] = useState(false);
	const [ editId, setEditId ] = useState(0);
	const confirmRef = useRef(null);
	const [ accommodations, setAccommodations ] = useState(() => JSON.parse(sessionStorage.getItem('accommodations')) ?? []);
	const [ transfers, setTransfers ] = useState(() => JSON.parse(sessionStorage.getItem('transfers')) ?? []);
	const navigate = useNavigate();

	const accommodationsIds = useMemo(() => getIds(order?.AccommodationList?.Accommodation), [JSON.stringify(order?.AccommodationList?.Accommodation)]);
	const transfersIds = useMemo(() => allowTransfers ? getIds(order?.TransportList?.Transport) : [], [JSON.stringify(order?.TransportList?.Transport)]);
	const copyAccommodationsIds = useMemo(() => getIds(accommodations), [JSON.stringify(accommodations)]);
	const copyTransfersIds = useMemo(() => allowTransfers ? getIds(transfers) : [], [JSON.stringify(transfers)]);

	const allIds = [ accommodationsIds, transfersIds, copyAccommodationsIds, copyTransfersIds ].flat();
	const allCopyIds = [ copyAccommodationsIds, copyTransfersIds ].flat();
	const idsCanCancel = useMemo(() => (parseInt(order?.ReadOnly?.Code ?? 1) === 1 ? [] : [
		getIds(order?.AccommodationList?.Accommodation.filter(i => parseInt(i?.AllowCancel?.Code) === 1)),
		getIds(order?.TransportList?.Transport.filter(i => parseInt(i?.AllowCancel?.Code) === 1)),
		copyAccommodationsIds,
		copyTransfersIds
	]).flat(), [JSON.stringify(order)]);

	const isGain = parseInt(order?.AccountDetails?.IsGain?.Code ?? 3) === 1;

	const {
		AddField1Provide,
		AddField1Name,
		AddField2Provide,
		AddField2Name,
		AddField3Provide,
		AddField3Name
	} = order;

	const addFields = [];
	if (AddField1Provide > 1) {
		addFields.push({
			field: 'AddField1',
			name: AddField1Name
		});
	}
	if (AddField2Provide > 1) {
		addFields.push({
			field: 'AddField2',
			name: AddField2Name
		});
	}
	if (AddField3Provide > 1) {
		addFields.push({
			field: 'AddField3',
			name: AddField3Name
		});
	}

	const fetchOrder = async () => {
		const request = {
			OrderInfoRequest: {
				Id: id
			}
		};
		const data = await post(request);

		if (!data) {
			clear();
			return false;
		}

		return data;
	};

	const fetch = async () => {
		const data = await fetchOrder();
		setOrder(data);
	};

	useEffect(() => {
		fetch();
		return () => setOrder(false);
	}, [id]);

	const checkPenalty = async id => {
		const acc = order?.AccommodationList?.Accommodation?.find(i => parseInt(i.Id) === parseInt(id));
		const trans = order?.TransportList?.Transport?.find(i => parseInt(i.Id) === parseInt(id));

		if (id > 0 && acc) {
			const request = {
				PenaltyCalcInfoRequest: {
					ActionInfo: {
						Parameters: {
							AccommodationId: id,
							PenaltyReason: 4
						}
					}
				}
			};
	
			const data = await post(request);
	
			return {
				id,
				penalty: data?.ToBuyer?.PenaltySize ?? 0,
				currency: data?.ToBuyer?.CurrencyNamePenaltySize ?? '',
				title: `${t('Размещение в')} ${acc?.ObjType?.NameWhere} «${acc?.Hotel?.Name}»`
			};	
		}

		if (id > 0 && trans) {
			const request = {
				PenaltyCalcInfoRequest: {
					ActionInfo: {
						Parameters: {
							TransferId: id,
							PenaltyReason: 4
						}
					}
				}
			};
	
			const data = await post(request);
	
			return {
				id,
				penalty: data?.ToBuyer?.PenaltySize ?? 0,
				currency: data?.ToBuyer?.CurrencyNamePenaltySize ?? '',
				title: `${t('Тренсфер')} ${trans?.Service?.Name}`
			};	
		}

		if (id < 0) {
			return {
				id
			};
		}
	};

	const cancel = async ids => {
		if (!Array.isArray(ids)) {
			ids = [ids];
		}

		const penalties = await Promise.all(
			ids.map(async id => await checkPenalty(id))
		);

		setCancelPenalty(penalties);
	};

	const copy = async () => {
		if (parseInt(order?.ReadOnly?.Code ?? 2) === 1) return;

		let accommodationsToCopy = order.AccommodationList.Accommodation.filter(i => inArray(i.Id, selected));
		let transfersToCopy = order.TransportList.Transport.filter(i => inArray(i.Id, selected));

		const minId = allCopyIds.reduce((acc, cur) => acc > cur ? acc : cur, -100000) + 1;

		if (accommodationsToCopy.length > 0) {
			accommodationsToCopy = accommodationsToCopy.map((i, key) => ({
				...i,
				TargetSegmentId: i.Id,
				Id: minId + key,
				AllowSetGain: {
					Code: order?.AccountDetails?.IsGain?.Code ?? 2
				},
				NoShowReported: {
					Code: 2
				},
				IsClosedFinancialPeriod: {
					Code: 2
				},
				Status: {
					Code: 11,
					Name: t('В работе')
				},
				ToBeCancelled: {
					Code: 2
				},
				ToBeChanged: {
					Code: 1
				},
				AllowCancel: {
					Code: 1
				},
				AllowModify: {
					Code: 1
				},
				Penalties: {
					Penalty: []
				},
				Penalty: 0,
				SupplierInfo: '',
				AmendmentRejected: {
					Code: 2
				},
				ExtraMeals: {
					ExtraMeal: (i?.ExtraMeals?.ExtraMeal ?? []).map(j => ({
						...j, Id: -99999 + key
					}))
				}
			}));

			setAccommodations(prev => {
				const allItems = [
					...prev,
					...accommodationsToCopy
				];

				sessionStorage.setItem('accommodations', JSON.stringify(allItems));

				return [...allItems];
			});
		}

		if (transfersToCopy.length > 0) {
			transfersToCopy = transfersToCopy.map((i, key) => ({
				...i,
				TargetSegmentId: i.Id,
				Id: minId + accommodationsToCopy.length + key,
				AllowSetGain: {
					Code: order?.AccountDetails?.IsGain?.Code ?? 2
				},
				NoShowReported: {
					Code: 2
				},
				IsClosedFinancialPeriod: {
					Code: 2
				},
				Status: {
					Code: 11,
					Name: t('В работе')
				},
				ToBeCancelled: {
					Code: 2
				},
				ToBeChanged: {
					Code: 1
				},
				AllowCancel: {
					Code: 1
				},
				AllowModify: {
					Code: 1
				},
				Penalties: {
					Penalty: []
				},
				Penalty: 0,
				SupplierInfo: '',
			}));

			setTransfers(prev => {
				const allItems = [
					...prev,
					...transfersToCopy
				];

				sessionStorage.setItem('transfers', JSON.stringify(allItems));

				return [...allItems];
			});
		}

		setSelected([]);
	};

	const select = id => {
		id = parseInt(id);
		setSelected(prev => {
			const index = prev.findIndex(i => i === id);
			if (index === -1) {
				prev.push(id);
			} else {
				prev.splice(index, 1);
			}

			return [...prev];
		});
	};

	const selectAll = (val, e) => {
		e.target.checked
			? setSelected(allIds)
			: setSelected([]);
	};

	const getPenalty = async (id, reason) => {
		const request = {
			PenaltyCalcInfoRequest: {
				ActionInfo: {
					Parameters: {
						AccommodationId: id,
						PenaltyReason: reason
					}
				}
			}
		};

		return await post(request);
	};

	const getPenaltyForCancel = async id => {
		const data = getPenalty(id, 4);

		const {
			ToBuyer: {
				PenaltySize,
				CurrencyNamePenaltySize
			}
		} = data;

		if (PenaltySize > 0) {
			setPenalty([
				t('Услуга находится в зоне действий штрафных санкций!'),
				`${t('За аннулирование услуги наступают штрафные санкции в размере')} ${toMoney(PenaltySize)} ${CurrencyNamePenaltySize}.`,
				t('Все равно продолжить?')
			]);
		}
	};

	const getPenaltyForChange = async id => {
		const data = await getPenalty(id, 3);

		const {
			ToBuyer: {
				PenaltySize,
				CurrencyNamePenaltySize
			}
		} = data;

		if (PenaltySize > 0) {
			setEditId(id);
			setEditUrl(`/order_edit/${id}`);
			setPenalty([
				t('Услуга находится в зоне действий штрафных санкций!'),
				`${t('За изменение услуги наступают штрафные санкции в размере')} ${toMoney(PenaltySize)} ${CurrencyNamePenaltySize}.`,
				' ',
				t('Все равно продолжить?')
			]);
		} else {
			navigate(`/order_edit/${id}`);
		}
	};

	const getPenaltyForSegment = async ({ type, reason, id }) => {
		const request = {
			PenaltyCalcInfoRequest: {
				ActionInfo: {
					Parameters: {
						[`${type.replace(/^\w/, (c) => c.toUpperCase())}Id`]: id,
						PenaltyReason: reason === 'edit' ? 3 : 4
					}
				}
			}
		};

		const data = await post(request);

		if (data) {
			const url = type === 'transfer'
				? `/transfers/booking/${id}`
				: `/order_edit/${id}`;

			const {
				ToBuyer: {
					PenaltySize,
					CurrencyNamePenaltySize
				}
			} = data;
	
			if (PenaltySize > 0) {
				const text = reason === 'edit'
					? t('За изменение услуги наступают штрафные санкции в размере')
					: t('За аннулирование услуги наступают штрафные санкции в размере');
	
				setEditId(id);
				setEditUrl(url);
				setPenalty([
					t('Услуга находится в зоне действий штрафных санкций!'),
					`${text} ${toMoney(PenaltySize)} ${CurrencyNamePenaltySize}.`,
					' ',
					t('Все равно продолжить?')
				]);
			} else {
				navigate(url);
			}	
		}
	};

	const edit = async id => {
		const item = (order?.AccommodationList?.Accommodation ?? [])?.find(i => i.Id === id);
		if (parseInt(item?.AllowModify?.Code ?? 1) === 2) {
			navigate(`/order_edit/${id}`);
		} else {
			await getPenaltyForChange(id);
		}
	};

	const editTransfer = async id => {
		if (id < 0) {
			navigate(`/transfers/booking/${id}`);
			return;
		}

		const item = (order?.TransportList?.Transport ?? []).find(i => parseInt(i.Id) === parseInt(id));
		if (parseInt(order?.ReadOnly?.Code ?? 2) === 1 || parseInt(item?.AllowModify?.Code ?? 1) === 2) {
			navigate(`/transfers/booking/${id}`);
		} else {
			await getPenaltyForSegment({
				type: 'transfer',
				reason: 'edit',
				id
			});
		}
	};

	const closeWarning = () => setPenalty(false);	

	const penalties = useMemo(() => {
		let penalties = [];

		(order?.AccommodationList?.Accommodation ?? [])
			?.forEach(a => {
				(a?.Penalties?.Penalty ?? [])
					.forEach(p => penalties.push({
						...p,
						forId: a.Id
					}));
			});
	
		(order?.PassiveList?.Passive ?? [])
			?.forEach(a => {
				(a?.Penalties?.Penalty ?? [])
					.forEach(p => penalties.push({
						...p,
						forId: a.Id
					}));
			});
	
		(order?.TransportList?.Transport ?? [])
			?.forEach(a => {
				(a?.Penalties?.Penalty ?? [])
					.forEach(p => penalties.push({
						...p,
						forId: a.Id
					}));
			});
			
		return penalties;
	}, [
		JSON.stringify(order?.AccommodationList?.Accommodation),
		JSON.stringify(order?.PassiveList?.Passive),
		JSON.stringify(order?.TransportList?.Transport)
	]);

	const accommodationsProps = {
		isReadOnly: parseInt(order?.ReadOnly?.Code ?? 2) === 1,
		selected,
		select,
		cancel,
		currency: order?.Currency?.Name ?? 'RUB',
		edit,
		editTransfer,
		items: order?.AccommodationList?.Accommodation ?? [],
		copyItems: Array.isArray(accommodations) ? accommodations : [],
		passives: order?.PassiveList?.Passive ?? [],
		transports: [...(order?.TransportList?.Transport ?? []), ...transfers],
		addFields,
		isGain,
		penalties,
		onChangeGain: async (id, newGain, onClose) => {
			const orderRequest = {
				OrderRequest: {
					...order,
					AccommodationList: {
						Accommodation: order.AccommodationList.Accommodation.map(a => ({
							...a,
							Penalties: {
								Penalty: a.Penalties.Penalty.map(p => ({
									...p,
									Gain: parseInt(p.Id) === parseInt(id) ? newGain : p.Gain
								}))
							}
						}))
					},
					TransportList: {
						Transport: order.TransportList.Transport.map(a => ({
							...a,
							Penalties: {
								Penalty: a.Penalties.Penalty.map(p => ({
									...p,
									Gain: parseInt(p.Id) === parseInt(id) ? newGain : p.Gain
								}))
							}
						}))
					},
					PassiveList: {
						Passive: order.PassiveList.Passive.map(a => ({
							...a,
							Penalties: {
								Penalty: a.Penalties.Penalty.map(p => ({
									...p,
									Gain: parseInt(p.Id) === parseInt(id) ? newGain : p.Gain
								}))
							}
						}))
					},
				}
			};

			const data = await post(orderRequest);

			if (data) {
				onClose();
				await fetch();
			}
		}
	};

	const transportProps = {
		items: order?.TransportList?.Transport ?? [],
	};

	const passiveProps = {
		items: order?.PassiveList?.Passive,
	};

	const isSelectedAll = selected.length > 0 && allIds.filter(i => !selected.includes(parseInt(i))).length === 0;

	const updateCustomer = async ({ Customer = {}, AccountDetails = {} }) => {
		const request = {
			OrderRequest: {
				...order,
				Customer,
				AccountDetails
			}
		};

		const data = await post(request);
		if (data) {
			const data = await fetchOrder();
			setOrder(data);
		}
	};

	const doCancel = async ids => {
		if (parseInt(order?.ReadOnly?.Code ?? 2) === 1) return;

		ids = ids.map(i => parseInt(i));

		const accToDelete = (order?.AccommodationList?.Accommodation ?? []).filter(i => inArray(i, ids) && parseInt(i?.AllowCancel?.Code ?? 2) === 1).length > 0;
		const copyAccToDelete = (accommodations ?? []).filter(i => ids.includes(parseInt(i.Id))).length > 0;
		const transToDelete = (order?.TransportList?.Transport ?? []).filter(i => inArray(i, ids) && parseInt(i?.AllowCancel?.Code ?? 2) === 1).length > 0;
		const copyTransToDelete = (transfers ?? []).filter(i => ids.includes(parseInt(i.Id))).length > 0;

		if (copyAccToDelete) {
			setAccommodations(prev => {
				prev = prev.filter(i => !ids.includes(parseInt(i.Id)));
				sessionStorage.setItem('accommodations', JSON.stringify(prev));
				return [...prev];
			});
		}

		if (copyTransToDelete) {
			setTransfers(prev => {
				prev = prev.filter(i => !ids.includes(parseInt(i.Id)));
				sessionStorage.setItem('transfers', JSON.stringify(prev));
				return [...prev];
			});
		}

		if (accToDelete || transToDelete) {
			const data = {...order};

			if (accToDelete) {
				data.AccommodationList.Accommodation = data.AccommodationList.Accommodation.map(i => {
					if (!ids.includes(parseInt(i.Id))) return {...i};

					return {
						...i,
						ToBeCancelled: {
							Code: 1
						},
						ToBeChanged: {
							Code: 1
						},
						Status: {
							Code: 71
						},
						AllowSetGain: {
							Code: 2
						}
					};
				});
			}

			if (transToDelete) {
				data.TransportList.Transport = data.TransportList.Transport.map(i => {
					if (!ids.includes(parseInt(i.Id))) return {...i};

					return {
						...i,
						ToBeCancelled: {
							Code: 1
						},
						ToBeChanged: {
							Code: 1
						},
						Status: {
							Code: 71
						},
						AllowSetGain: {
							Code: 2
						}
					};
				});
			}

			const request = {
				OrderRequest: {
					...data
				}
			};

			await post(request);
			setSelected([]);
			fetch();
		}
	};

	const modalCancelBind = {
		isVisible: cancelPenalty.length > 0,
		onClose: () => setCancelPenalty(false),
		penalties: cancelPenalty,
		confirmRef,
		onCancel: async ids => {
			if (!confirmRef.current || confirmRef.current.checked) {
				await doCancel(ids);
				setCancelPenalty(false);
			} else {
				setUserError(t('Необходимо согласиться с оплатой штрафа.'));
			}
		}
	};

	const isCanCancelSome = selected.filter(i => inArray(i, idsCanCancel)).length > 0;

	const AllowToPay = order?.AllowToPay;

	const payments = (AllowToPay?.Code === 1 && AllowToPay?.AmountToPay > 0)
		? paymentBinds.map(i => ({
			...i,
			product_price: AllowToPay?.AmountToPay,
			cs2: `${order?.Id}_${Number(order?.PaymentCount ?? 0) + 1}`,
			sign: md5(`${i.product_id}-${order?.Price ?? ''}-${i.sign}`),
			order
		}))
		: [];

	return {
		order,
		fetch,
		cancel,
		selected,
		select,
		selectAll,
		copy,
		getPenalty,
		getPenaltyForChange,
		getPenaltyForCancel,
		penalty,
		closeWarning,
		edit,
		editId,
		accommodationsProps,
		isSelectedAll,
		transportProps,
		passiveProps,
		updateCustomer,
		modalCancelBind,
		editUrl,
		idsCanCancel,
		isCanCancelSome,
		payments,
	};
};