import { useRequest } from 'hooks';
import { useRef } from 'react';

const useHotelPricing = ({
	AccommodationId = -99999,
	Id = 0,
	test = false,
	accommodation = {},
	setAccommodation = () => false
}) => {
	const accommodationRef = useRef(null);

	const {
		Hotel: {
			Code: Hotel = 0
		} = {},
		Product: {
			Code: ProductCode = 0
		} = {},
		ArrivalDate = '',
		DepartureDate = '',
		ArrivalTime = '',
		DepartureTime = '',
		NumberOfGuests = 0,
		NumberOfExtraBedsAdult = 0,
		NumberOfExtraBedsChild = 0,
		NumberOfExtraBedsInfant = 0,
		Meal: {
			Code: MealCode = 0
		} = {},
		ExtraMeals: {
			ExtraMeal = []
		} = {},
		NumberOfRooms = 1,
	} = accommodation ?? {};

	const {
		data,
		loading,
	} = useRequest(
		{
			HotelPricingRequest2: {
				ExtraMeals: {
					ExtraMeal
				},
				Hotel,
				ProductCode,
				ArrivalDate,
				DepartureDate,
				ArrivalTime,
				DepartureTime,
				Meal: MealCode,
				AccommodationId,
				Id,
				NumberOfRooms,
				NumberOfGuests,
				NumberOfExtraBedsAdult,
				NumberOfExtraBedsChild,
				NumberOfExtraBedsInfant
			}
		},
		{
			manual: !(Hotel > 0) || !(ProductCode > 0),
			refreshDeps: [
				Hotel,
				ProductCode,
				ArrivalDate,
				ArrivalTime,
				DepartureDate,
				DepartureTime,
				AccommodationId,
				Id,
				JSON.stringify(ExtraMeal),
				test,
				NumberOfGuests,
				NumberOfExtraBedsChild,
				NumberOfExtraBedsAdult,
				NumberOfExtraBedsInfant,
				MealCode,
				Number(NumberOfRooms),
			],
			onSuccess: data => {
				if (accommodation?.Persons?.Person === undefined) return;

				const needAllNames = Number(data?.HotelPricingDetail?.AllGuestNamesRequired?.Code ?? 2) === 1;
				const guests = (Number(NumberOfGuests) + Number(NumberOfExtraBedsAdult) + Number(NumberOfExtraBedsChild) + Number(NumberOfExtraBedsInfant)) * Number(NumberOfRooms);

				if (needAllNames && accommodation.Persons.Person.length > guests) {
					accommodationRef.current = {
						...accommodation,
						Persons: {
							Person: accommodation.Persons.Person.slice(0, guests),
						}
					};


					setAccommodation(prev => ({
						...prev,
						Persons: {
							Person: prev.Persons.Person.slice(0, guests),
						}
					}));
				}

				if (needAllNames && accommodation.Persons.Person.length < guests) {
					accommodationRef.current = {
						...accommodation,
						Persons: {
							Person: accommodation.Persons.Person.concat(new Array(guests - accommodation.Persons.Person.length).fill(null).map(() => ({
								LastName: '',
								FirstName: '',
								Citizenship: {
									Code: 1
								},
								Category: {
									Code: 99
								}		
							}))),
						}
					};


					setAccommodation(prev => ({
						...prev,
						Persons: {
							Person: prev.Persons.Person.concat(new Array(guests - prev.Persons.Person.length).fill(null).map(() => ({
								LastName: '',
								FirstName: '',
								Citizenship: {
									Code: 1
								},
								Category: {
									Code: 99
								}		
							}))),
						}
					}));
				}

				accommodationRef.current = { ...accommodation };
			},
			onError: () => {
				if (accommodation?.Persons?.Person === undefined) return;
				setAccommodation({ ...accommodationRef.current });
			},
		}
	);

	// console.log(accommodationRef.current);

	return {
		pricing: data,
		loading,
	};
};

export default useHotelPricing; 