import { useDevice } from 'hooks';

export const useExtraMealsProps = ({ pricing, setOrderInfo }) => {
	const { isMobile } = useDevice();

	const {
		NumberOfGuests = 1,
		NumberOfExtraBedsInfant = 0,
		NumberOfExtraBedsChild = 0,
		NumberOfExtraBedsAdult = 0,
		ExtraMeals: {
			ExtraMeal = []
		} = {},
		Currency: {
			Name: currency = 'RUB'
		} = {}
	} = pricing ?? {};

	const totalGuests = NumberOfGuests + NumberOfExtraBedsAdult + NumberOfExtraBedsChild + NumberOfExtraBedsInfant;

	const extraMeals = ExtraMeal.reduce((acc, cur) => {
		const index = acc.findIndex(i => Array.isArray(i) ? (i[0].Code === cur.Code && i[0].Name === cur.Name) : (i.Code === cur.Code && i.Name === cur.Name));
		
		if (index === -1) {
			return [...acc, cur];
		} else {
			if (Array.isArray(acc[index])) {
				const temp = [...acc];
				temp[index].push(cur);
				return temp;
			} else {
				const temp = [...acc];
				const items = [temp[index]];
				items.push(cur);
				temp.splice(index, 1, items);
				return [...temp];
			}
		}
	}, []);

	const extraMealsProps = {
		extraMeals,
		setOrderInfo,
		currency,
		isMobile,
		totalGuests,
	};

	return {
		extraMealsProps
	};
};