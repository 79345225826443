import React, { useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from 'hooks';
import { Checkbox } from 'forms';

const Container = styled.div`
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1;
	cursor: pointer;
	
	@media (max-width: 1279px) {
		z-index: 2000000;
		border: 1px solid ${props => props.theme.darkBlue};
		border-radius: 5px;
		top: 20px;
		right: 20px;
		margin-top: 0;
		width: 230px;
		height: 30px;
		background-color: white;

		span {
			padding: 0 10px;
			height: 28px;
		}
	}

	@media (max-width: 659px) {
		top: 50px;
		left: 20px;
		width: auto;
	}

	> div {
		position: relative;
		
		> span {
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: ${props => props.theme.darkBlue};
			text-transform: uppercase;
			background-color: white;
			height: 30px;
			padding: 0 40px;
			border-radius: 5px;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
			transition: color .2s ease-in-out;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			
			&:hover {
				color: ${props => props.theme.lightBlue};
			}
	
			&:after {
				content: '';
				display: flex;
				width: 12px;
				height: 7px;
				margin-left: 5px;
				background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23004685' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}
		}
		
		> div {
			position: absolute;
			top: 35px;
			right: 0;
			left: 0;
			z-index: 100;
			background-color: white;
			padding: 10px 10px 0 10px;
			opacity: 0;
			visibility: hidden;
			transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
			border-radius: 5px;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
			
			&.isShow {
				opacity: 1;
				visibility: visible;
			}
		}
	}
`;

const MapFilters = ({ filters, setFilters }) => {
	const { t } = useTranslation();
	const [ isOpen, setOpen ] = useState(false);
	const { ref } = useClickOutside(() => setOpen(false));

	const toggle = () => setOpen(prev => !prev);

	const onChange = (id, e) => setFilters(prev => {
		if (id === 'all') {
			return e.target.checked
				? [
					'3-9500001',
					'4-800001',
					'4-800104',
					'4-900001',
					'4-1000039',
					'4-1000040',
					'4-1000041',
					'2-1000042',
				]
				: [];
		}

		const temp = [...prev];

		const index = temp.findIndex(i => i === id);
		if (index === -1) {
			temp.push(id);
		} else {
			temp.splice(index, 1);
		}

		return [...temp];
	});

	return (
		<Container ref={ref}>
			<div>
				<span onClick={toggle}>{t('Объекты на карте')}</span>

				<div className={classnames({ isShow: isOpen })}>
					<Checkbox label={t('Все')} value='all' checked={filters.length === 8} onChange={onChange} />
					<Checkbox label={t('Отели')} value='3-9500001' checked={filters.includes('3-9500001')} onChange={onChange} />
					<Checkbox label={t('Аэропорты')} value='4-800001' checked={filters.includes('4-800001')} onChange={onChange} />
					<Checkbox label={t('Метро')} value='4-800104' checked={filters.includes('4-800104')} onChange={onChange} />
					<Checkbox label={t('Достопримечательности')} value='4-900001' checked={filters.includes('4-900001')} onChange={onChange} />
					<Checkbox label={t('Вокзалы')} value='4-1000039' checked={filters.includes('4-1000039')} onChange={onChange} />
					<Checkbox label={t('Морские порты')} value='4-1000040' checked={filters.includes('4-1000040')} onChange={onChange} />
					<Checkbox label={t('Речные порты')} value='4-1000041' checked={filters.includes('4-1000041')} onChange={onChange} />
					<Checkbox label={t('Центр города')} value='2-1000042' checked={filters.includes('2-1000042')} onChange={onChange} />
				</div>
			</div>
		</Container>
	);
};

export default MapFilters;