import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useGuestsProps = ({ orderInfo = {}, setOrderInfo, offer = {} }) => {
	const { t } = useTranslation();

	const labels = {
		Adult: t('Взрослых на доп. кроватях'),
		Child: t('Детей на доп. кроватях'),
		Infant: t('Младенцев на доп. кроватях')
	};	

	const {
		NumberOfGuests = 0,
		NumberOfExtraBedsAdult = 0,
		NumberOfExtraBedsChild = 0,
		NumberOfExtraBedsInfant = 0
	} = orderInfo;

	const {
		MaxNumberOfGuests = 0,
		MaxNumberOfExtraBeds = 0,
		MaxNumberOfExtraBedsAdult = 0,
		MaxNumberOfExtraBedsChild = 0,
		MaxNumberOfExtraBedsInfant = 0
	} = offer;

	const extraCount = NumberOfExtraBedsAdult + NumberOfExtraBedsChild + NumberOfExtraBedsInfant;

	const extraProps = name => ({
		value: NumberOfGuests < MaxNumberOfGuests ? 0 : (orderInfo?.[`NumberOfExtraBeds${name}`] ?? 0),
		onChange: value => setOrderInfo(prev => ({
			...prev,
			[`NumberOfExtraBeds${name}`]: parseInt(value)
		})),
		min: 0,
		max: offer?.[`MaxNumberOfExtraBeds${name}`] ?? 0,
		label: labels[name],
		isHidden: !offer?.[`MaxNumberOfExtraBeds${name}`] || (
			(orderInfo?.[`NumberOfExtraBeds${name}`] ?? 0) === 0 && extraCount >= MaxNumberOfExtraBeds
		) || NumberOfGuests < MaxNumberOfGuests
	});

	const mainBedsProps = useMemo(() => ({
		value: NumberOfGuests,
		onChange: value => setOrderInfo(prev => ({
			...prev,
			NumberOfGuests: parseInt(value),
			NumberOfExtraBedsAdult: parseInt(value) < MaxNumberOfGuests ? 0 : NumberOfExtraBedsAdult,
			NumberOfExtraBedsChild: parseInt(value) < MaxNumberOfGuests ? 0 : NumberOfExtraBedsChild,
			NumberOfExtraBedsInfant: parseInt(value) < MaxNumberOfGuests ? 0 : NumberOfExtraBedsInfant,
		})),
		min: 1,
		max:MaxNumberOfGuests,
		label: t('Гостей на основных кроватях')
	}), [NumberOfGuests, MaxNumberOfGuests]);

	const adultsBedsProps = useMemo(() => extraProps('Adult'), [NumberOfExtraBedsAdult, MaxNumberOfExtraBedsAdult, extraCount, NumberOfGuests]);

	const childsBedsProps = useMemo(() => extraProps('Child'), [NumberOfExtraBedsChild, MaxNumberOfExtraBedsChild, extraCount, NumberOfGuests]);

	const infantsBedsProps = useMemo(() => extraProps('Infant'), [NumberOfExtraBedsInfant, MaxNumberOfExtraBedsInfant, extraCount, NumberOfGuests]);

	const guestsProps = {
		readOnly: orderInfo?.Id > 0,
		mainBedsProps,
		adultsBedsProps,
		childsBedsProps,
		infantsBedsProps,
	};

	return {
		guestsProps
	};
};