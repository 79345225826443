/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const Container = styled.div`
	height: 40px;
	position: relative;

	.react-select__input {
		height: 100% !important;
	}

	&.small {
		height: 30px;
	}

	&.border {
		border: 1px solid ${props => props.theme.darkBlue};
	}
`;

const styles = {
	container: provided => ({
		...provided,
		// height: border ? 'calc(100% - 2px)' : '100%',
		height: '100%',
	}),
	control: provided => ({
		...provided,
		height: '100%',
		minHeight: '100%',
		border: 'none',
		boxShadow: 'none',
		borderRadius: 0,
	}),
	valueContainer: provided => ({
		...provided,
		// height: border ? 'calc(100% - 2px)' : '100%',
		height: '100%',
		maxHeight: '100%',
		padding: '0 10px',
		input: {
			height: '100% !important',
			maxHeight: '100% !important',
		}
	}),
	placeholder: provided => ({
		...provided,
		margin: 0,
		display: 'block',
		color: '#4F7EA7',
		fontSize: '16px',
		lineHeight: '21px',
		fontFamily: "'PT Sans', sans-serif !important",
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),
	input: provided => {
		return {
			...provided,
			// height: border ? 'calc(100% - 2px)' : '100%',
			height: '100% !important',
			maxHeight: '100% !important',
			overflow: 'hidden',
			padding: 0,
			margin: 0,
			boxShadow: 'none',
			input: {
				boxShadow: 'none !important',
				// maxHeight: border ? 'calc(100% - 2px)' : '100%',
				maxHeight: '100% !important',
				height: '100% !important',
				// height: 'auto !important',
				display: 'block',
				color: '#004685 !important',
				fontSize: '16px !important',
				lineHeight: '21px !important',
				fontFamily: "'PT Sans', sans-serif !important",
				transition: 'none !important',

				width: '100%',
				'&:focus': {
					gridArea: '1 / 2 / auto / span 3 !important',
					background: 'white !important',
				}
			}
			// border: '1px solid blue',
		};
	},
	singleValue: provided => ({
		...provided,
		color: '#004685 !important',
		padding: 0,
		margin: 0,
		fontSize: '16px !important',
		lineHeight: '21px !important',
		fontFamily: "'PT Sans', sans-serif !important",
	}),
	indicatorsContainer: provided => ({
		...provided,
		height: '100%',
		maxHeight: '100%'
	}),
	indicatorSeparator: provided => ({
		...provided,
		display: 'none',
	}),
	dropdownIndicator: provided => ({
		...provided,
		color: '#004685 !important',
	}),
	menu: provided => ({
		...provided,
		marginTop: 0,
		marginLeft: '-1px',
		width: 'calc(100% + 2px)',
		borderRadius: 0,
		zIndex: 999999,
		padding: 0,
		border: '1px solid #004685',
	}),
	menuList: provided => ({
		...provided,
		margin: 0,
		padding: 0,
	}),
	option: (provided, state) => ({
		...provided,
		minHeight: '40px',
		padding: '5px 10px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		color: state?.isSelected ? 'white' : '#004685',
		'&:not(:first-of-type)': {
			borderTop: '1px solid #D5E3F0',
		},
		'> div': {
			fontSize: '16px !important',
			lineHeight: '21px !important',
			fontFamily: "'PT Sans', sans-serif !important",
			color: state?.isSelected ? 'white' : '#004685',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			'svg': {
				marginRight: '10px',
			}
		}
	}),
};

export const SelectInput = ({ small = false, border = true, name='', ...props }) => {
	const { t } = useTranslation();

	return (
		<Container className={classnames('select_container', `select_container__${name}`, { small, border })}>
			<Select {...props} styles={styles} />
		</Container>
	);
};