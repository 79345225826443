import React from 'react';
import styled from 'styled-components';
import starEmpty from 'images/star_empty.svg';
import starFill from 'images/star_fill.svg';

const Container = styled.div`
	display: inline-block;

	svg, img {
		border: none !important;
	}
`;

const ImgCircle = styled.img`
	margin-right: 5px;
`;

const ImgCircleEmpty = () => <ImgCircle src={starEmpty} alt='' />;
const ImgCircleFill = () => <ImgCircle src={starFill} alt='' />;

export const ShowStars = ({ stars = 0 }) => {
	return (
		<Container>
			{stars <= 5 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
			{stars <= 4 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
			{stars <= 3 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
			{stars <= 2 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
			{stars <= 1 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
		</Container>
	);
};