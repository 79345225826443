import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useError = () => {
	const { t } = useTranslation();
	const [ error, setError ] = useState(false);

	const setFatalError = text => {
		const error = {
			Description: text,
			Type: {
				Code: 1,
				Name: t('Фатальная')
			},
			Pointer: ''
		};

		setError(error);
	};

	const setTimeError = text => {
		const error = {
			Description: text,
			Type: {
				Code: 2,
				Name: t('Временная')
			},
			Pointer: ''
		};

		setError(error);
	};

	const setUserError = text => {
		const error = {
			Description: text,
			Type: {
				Code: 3,
				Name: t('Пользовательская')
			},
			Pointer: ''
		};

		setError(error);
	};

	const clearErrors = () => setError(false);

	const isError = error !== false;

	return {
		error,
		isError,
		setError,
		setFatalError,
		setTimeError,
		setUserError,
		clearErrors,
	};
};

const ErrorContext = createContext(null);

const ErrorProvider = ({ children }) => {
	const errorStore = useError();

	return (
		<ErrorContext.Provider value={errorStore}>
			{children}
		</ErrorContext.Provider>
	);
};

const useErrorStore = () => {
	const context = useContext(ErrorContext);

	if (context === undefined) {
		throw new Error('useErrorStore must be used within a ErrorProvider');
	}

	return context;
};

export {
	ErrorProvider,
	useErrorStore,
};