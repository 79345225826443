import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import pictoMoveFilter from 'images/picto_move_filter.svg';
import pictoCloseFilter from 'images/picto_close_filter.svg';
import {Checkbox} from 'forms';
import { useHotelSearchStore } from 'stores';

const Container = styled.div`

	.title {
		margin-bottom: 0 !important;
	}

	.checkbox label {
		display: flex;
		flex-direction: row;
		margin: 0;
	}
`;

const MoveFilter = styled.div`
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-image: url(${pictoMoveFilter});
	cursor: pointer;
	flex: 0 0 20px;
	margin-right: 5px;

	& + div {
		margin-bottom: 0 !important;
	}
`;

const CloseFilter = styled.div`
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-image: url(${pictoCloseFilter});
	cursor: pointer;
	flex: 0 0 20px;
`;

export const ExtFreesale = ({ provided }) => {
	const { t } = useTranslation();
	const {
		params: {
			filters: {
				freesale = false
			}
		},
		setParams
	} = useHotelSearchStore();

	const onChange = (name, e) => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			freesale: e?.target?.checked ?? e
		}
	}));

	const onClear = () => onChange('', false);

	return (
		<Container ref={provided.innerRef} {...provided.draggableProps}>
			<div className="title">
				<MoveFilter {...provided.dragHandleProps} />
				<Checkbox label={t('В свободной продаже')} value='freesale1' checked={freesale} onChange={onChange} />
				<div className='filler' />
				{freesale && <CloseFilter onClick={onClear} />}
			</div>
		</Container>
	);
};