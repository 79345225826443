import React, { useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Sprite } from 'components';
import {
	ClearFilters,
	Exts,
} from './';

const Filters = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000001;
	visibility: hidden;

	> .overlay {
		background: rgba(3, 71, 129, 0.8);
		position: absolute;
		height: 100vh;
		z-index: 10 !important;
		opacity: 0;
		visibility: hidden;
		margin-left: 0;
		width: 100vw;
		transition: opacity ease-in-out .2s, visibility ease-in-out .2s, margin-left ease-in-out .2s, width ease-in-out .2s;
	}

	> .filters {
		z-index: 20 !important;
		background-color: white;
		width: 100vw;
		height: 100vh;
		overflow-y: scroll;
		padding: 10px;
		margin-left: -100vw;
		transition: margin-left ease-in-out .2s;
		z-index: 11 !important;
		position: relative;
	}

	&.isFilters {
		visibility: visible;

		> .overlay {
			/* margin-left: 300px; */
			/* width: calc(100vw - 300px); */
			width: 100vw;
			opacity: 1;
			visibility: visible;
		}

		> .filters {
			margin-left: 0;
		}
	}
`;

const CloseFilters = styled.div`
	width: 30px;
	margin-left: auto;
`;

const TabletMobileFilters = () => {
	const { t } = useTranslation();
	const [ isFilters, setFilters ] = useState(false);

	const toggleFilters = () => setFilters(prev => !prev);

	return (
		<>
			<Button white onClick={toggleFilters}>
				{t('Фильтры')}
			</Button>

			<Filters className={classnames({ isFilters })}>
				<div className='overlay' onClick={toggleFilters} />

				<div className='filters'>
					<CloseFilters onClick={toggleFilters}>
						<Sprite icon='close' />
					</CloseFilters>
					<Exts />
					<ClearFilters />
				</div>
			</Filters>
		</>
	);
};

export default TabletMobileFilters;