import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { nanoid } from 'nanoid';

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	height: 40px;
	/* z-index: 10; */

	&.withBorder {
		border: 1px solid ${props => props.theme.darkBlue};
	}
	
	&.disabled {
		opacity: .5;
	}
`;

const Items = styled.div`
	position: absolute;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	border: 1px solid ${props => props.theme.darkBlue};
	left: -1px;
	top: calc(100% - 2px);
	min-width: calc(100% + 2px);
	width: auto;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	background-color: white;
	max-height: calc(100% * 5);
	overflow-y: auto;
	z-index: 100;
	
	&.isOpen {
		opacity: 1;
		visibility: visible;
	}

	input { display: none; }
	
`;

const RightLabel = styled.div`
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	font-weight: bold;
	margin-right: 5px;
	cursor: inherit;
`;

const Item = styled.label`
	min-height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex: 0 0 40px;
	cursor: pointer;
	margin: 0 10px;
	
	* {
		color: ${props => props.theme.darkBlue};
		transition: color .2s ease-in-out;
	}

	&:hover * {
		color: ${props => props.theme.lightBlue} !important;
	}
	
	&:nth-of-type(n + 2) {
		border-top: 1px solid ${props => props.theme.lightGray};
	}

	label {
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
		cursor: inherit;
	}
`;

const Label = styled.label`
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: ${props => props.theme.darkBlue};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	cursor: default;
	height: 100%;
	padding: 0 10px;
	background-color: white;
	flex-grow: 1;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	/* max-width: 300px; */

	&:after {
		content: '';
		display: flex;
		width: 12px;
		height: 7px;
		margin-left: 5px;
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23004685' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	&.readOnly:after {
		display: none;
	}

	& + input {
		//visibility: hidden;
		//opacity: 0;
		height: 0 !important;
		width: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
		outline: none !important;
		border: none !important;
		background: transparent;
		position: absolute;
		
		&:focus + div {
			opacity: 1;
			visibility: visible;
		}
	}
`;

export const Select = ({ register = null, withBorder = false, watch = null, name = '', items = [], value = '', styleContainer = {}, rightLabel = false, onChange = () => null, readOnly = false, disabled = false }) => {
	const id = 'is_open_' + nanoid(10);

	const currentItem = register ?
		(watch(name) ? items.find(i => i.value.toString() === watch(name).toString()) : items[0]) :
		items.find(i => i?.value?.toString() === value?.toString()) ?? items[0];

	return (
		<Container style={styleContainer} className={classnames('select', `select_container__${name}`, {disabled, withBorder})}>
			<Label className={classnames({readOnly: readOnly || disabled})} htmlFor={id}>
				{currentItem?.label}{'\u00A0'}
				<div className='filler' />
				{rightLabel && <RightLabel>{rightLabel(currentItem)}</RightLabel>}
			</Label>
			<input type="text" id={id} />

			{!readOnly && !disabled && <Items>
				{items.map((i, key) => (
					<Item key={key}>
						{register ?
							<input type='radio' name={name} id={`${name}__${i.value}`} value={i.value} ref={register} /> :
							<input type='radio' name={name} id={`${name}__${i.value}`} value={i.value} onChange={onChange} />
						}
						<label htmlFor={`${name}__${i.value}`}>{i.label}</label>
						{rightLabel && <RightLabel>{rightLabel(i)}</RightLabel>}
					</Item>
				))}
			</Items>}
		</Container>
	);
};