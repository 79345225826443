import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Modal2 } from './';
import { useUserStore } from 'stores';
import {
	DatesInput,
	DestinationInput,
	GuestsInput,
} from 'features/HotelSearchComponents';
import { HotelSearchProvider, useHotelSearchStore } from 'stores';
import { Button } from 'components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;

	> .row {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
		position: relative;
		gap: 10px;

		> .dates, > .guests {
			width: calc((100% - 10px) / 2);
		}

		.dates_input_container {
			flex-grow: 1;

			.dates {
				border: none !important;
				flex-grow: 1;
				width: auto;
			}
		}

		.guests {
			border: 1px solid ${props => props.theme.darkBlue};
			box-sizing: border-box !important;

			input {
				border: none !important;
				height: 38px !important;
			}

			> div {
				top: 38px !important;
			}
		}
	}

	h1 {
		text-align: left;

		b {
			color: #00C0FF !important;
		}
	}

	.select_container {
		margin-bottom: 10px;
	}

	.single_date {
		border: 1px solid ${props => props.theme.darkBlue};
		width: 100%;

		& + .button {
			margin-top: 20px;
		}

		.calendar_container {
			left: -1px;
			right: auto;
		}
	}

	.row + .button {
		margin-top: 10px;
	}

	.transfer_destination {
		width: calc(50% - 5px);
		border: 1px solid ${props => props.theme.darkBlue};
	}

	.react-select__control {
		height: 100% !important;
	}

	@media (max-width: 1279px) {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
		height: calc(100%);

		h1 {
			font-size: 32px !important;
			line-height: 38px !important;
		}
	}
`;

const ButtonAddAccommodation = () => {
	const store = useHotelSearchStore();
	const { t } = useTranslation();

	const {
		params: {
			destinationValue,
			startDate,
			endDate,
		},
	} = store;

	const {
		isInner,
		buyer
	} = useUserStore();

	const disabled = (isInner && !buyer) || !destinationValue?.Code || !startDate || !endDate;

	return <Button gradient disabled={disabled} to='/search'>{t('Найти')}</Button>;
};

const Content = ({ id }) => {
	const { t } = useTranslation();

	return (
		<Container>
			<h1>{t('Заказ')} <b>{id}</b>. {t('Добавить услугу')}</h1>

			<HotelSearchProvider>
				{/* <Destination {...destination2Props} /> */}
				<DestinationInput label={false} />

				
				<div className='row'>
					{/* <Ranges {...datesProps} /> */}
					{/* <Guests {...guestsProps} /> */}
					<DatesInput label={false} />
					<GuestsInput label={false} />
				</div>

				<ButtonAddAccommodation />
			</HotelSearchProvider>
		</Container>
	);
};

export const ModalAddService = ({ children = <div />, onCancel = () => false, id = 0 }) => {
	const [ isVisible, setVisible ] = useState(false);

	const onOpen = useCallback(() => setVisible(true), []);
	const onClose = useCallback(() => {
		setVisible(false);
		onCancel();
	}, []);

	return (
		<>
			{React.cloneElement(children, { onClick: onOpen })}

			<Modal2 isVisible={isVisible} onClose={onClose} width={800} containerStyle={{overflow: 'visible'}}>
				<Content {...id} />
			</Modal2>
		</>
	);
};