import React from 'react';
import styled from 'styled-components';
import { Modal2 } from 'modals';
import starEmpty from 'images/star_empty.svg';
import starFill from 'images/star_fill.svg';
import noPhoto from 'images/no_photo.svg';
import cross from 'images/blue_cross.svg';
import { declines } from 'utils';
import { useTranslation } from 'react-i18next';

const ImgCircle = styled.img`
	margin-right: 5px;
`;

const ImgCircleEmpty = () => <ImgCircle src={starEmpty} alt='' />;
const ImgCircleFill = () => <ImgCircle src={starFill} alt='' />;

const MainTitle = styled.div`
	font-weight: 500;
	font-size: 34px;
	line-height: 40px;
	color: ${props => props.theme.darkBlue};
	margin-bottom: 10px;
`;

const Table = styled.table`
	display: table !important;
	table-layout: fixed !important;
	
	th, td {
		padding: 10px 0;
		border-top: 1px solid ${props => props.theme.lightGray};
		vertical-align: top;
		font-family: ${props => props.theme.pt};
	}
	
	th {
		text-align: left;
		width: 295px !important;
		font-weight: bold;
		font-size: 16px;
		line-height: 21px;
		color: ${props => props.theme.darkBlue};
	}
	
	td {
		width: 305px !important;
		padding-right: 20px;
		font-size: 16px;
		line-height: 21px;
		color: #333;		
		
		div {
			font-family: ${props => props.theme.pt};
		}
	}
	
	tr.name td {
		font-weight: 500;
		font-size: 24px;
		line-height: 28px;
		color: ${props => props.theme.darkBlue};
		position: relative;
		font-family: 'Rubik', sans-serif !important;
		
		div.remove {
			display: block;
			position: absolute;
			top: 10px;
			right: 20px;
			height: 14px;
			width: 14px;
			background: url(${cross}) center/cover no-repeat;
			cursor: pointer;
		}
	}
	
	tr.address td, tr.distance td {
		font-weight: bold;
	}
	
	tr.price td {
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;	
		font-family: 'Rubik', sans-serif !important;
		color: ${props => props.theme.darkBlue};
	}
	
	tr.amenities td div {
		margin-bottom: 10px;
	}
`;

const Photo = styled.a`
	width: 180px;
	height: 180px;
	position: relative;
	margin-bottom: 10px;
	line-height: 0;
	background-color: #4F7EA7;
	display: flex;
	flex: 0 0 180px;

	&.new:before {
		content: 'Новый';
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 30px;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		text-transform: uppercase;
		background-color: #5BB500;
		z-index: 10;			
	}
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		z-index: 9;
	}
	
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(3, 71, 129, 0.5);
		color: white;
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		height: 30px;
	}
	
	.noPhoto {
		width: 100%;
		height: 100%;
		background-image: url(${noPhoto});
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 20px 20px;
		z-index: 9;
	}
`;

export const ModalCompare = ({
	hotels,
	onClose,
	onRemove
}) => {
	const { t } = useTranslation();

	const roomsTitles = [t('номер'), t('номера'), t('номеров')];
	const guestsTitles = [t('гость'), t('гостя'), t('гостей')];
	const amenities = [
		{id: 15, title: t('Wi-Fi в бизнес центре')},
		{id: 66, title: t('Wi-Fi в публичных зонах')},
		{id: 18, title: t('Авиа-, ж/д- билеты')},
		{id: 100, title: t('Англоговорящий персонал')},
		{id: 20, title: t('Аптечный пункт')},
		{id: 17, title: t('Аренда автомобиля')},
		{id: 10, title: t('Бар')},
		{id: 27, title: t('Бассейн крытый')},
		{id: 26, title: t('Бассейн открытый')},
		{id: 34, title: t('Бильярд')},
		{id: 35, title: t('Боулинг')},
		{id: 16, title: t('Вызов такси')},
		{id: 37, title: t('Казино')},
		{id: 103, title: t('Камера хранения')},
		{id: 8, title: t('Лифт')},
		{id: 104, title: t('Офисные услуги (факс,ксерокс)')},
		{id: 3, title: t('Парковка неохраняемая')},
		{id: 2, title: t('Парковка охраняемая')},
		{id: 33, title: t('Пляж')},
		{id: 4, title: t('Поднос багажа')},
		{id: 7, title: t('Пункт обмена валюты')},
		{id: 42, title: t('Размещение с домашними животными')},
		{id: 9, title: t('Ресторан')},
		{id: 24, title: t('Салон красоты')},
		{id: 25, title: t('Сауна')},
		{id: 6, title: t('Сейф на стойке регистрации')},
		{id: 21, title: t('Сувенирный магазин')},
		{id: 31, title: t('Теннисный корт крытый')},
		{id: 30, title: t('Теннисный корт открытый')},
		{id: 101, title: t('Трансфер')},
		{id: 23, title: t('Тренажерный зал')},
		{id: 102, title: t('Удобства на этаже')},
		{id: 19, title: t('Услуги няни')},
		{id: 22, title: t('Услуги по предоставлению газет')},
		{id: 11, title: t('Услуги прачечной')},
		{id: 12, title: t('Услуги химчистки')},
	];

	const isDescriptionVisible = hotels.filter(i => Boolean(i.Description)).length > 0;
	const isAirportVisible = hotels.filter(i => Boolean(i.Airport)).length > 0;
	const isRailwayStationVisible = hotels.filter(i => Boolean(i.RailwayStation)).length > 0;
	const isRiverPortVisible = hotels.filter(i => Boolean(i.RiverPort)).length > 0;
	const isSeaPortVisible = hotels.filter(i => Boolean(i.SeaPort)).length > 0;
	const isMetroVisible = hotels.filter(i => Boolean(i.Underground)).length > 0;
	const amenitiesIds = [].concat.apply([], hotels.map(i => i.Amenities)).filter((v, i, a) => a.indexOf(v) === i);
	const ams = amenities.filter(i => amenitiesIds.includes(i.id));

	return (
		<Modal2 isVisible={hotels.length > 0} onClose={onClose} containerStyle={{overflow: 'auto', height: 'calc(100vh - 200px)'}}>
			<MainTitle>{t('Сравнение отелей')}</MainTitle>

			<Table style={{width: `${305 * hotels.length + 295}px`}}>
				<tbody>
					<tr className='name'>
						<th>{t('Отель')}</th>
						{hotels.map((h, key) =>
							<td key={key}>
								<Photo href={`/hotel/${h.Code}`} target='_blank'>
									{h?.Images?.Image[0]?.Url ?
										<img src={h.Images.Image[0].Url.replace('test-images', 'images')} alt=''/> :
										<div className='noPhoto'/>
									}
								</Photo>

								{h.Name}

								<div className='remove' onClick={onRemove.bind(this, h.Code)} />
							</td>
						)}
					</tr>
					<tr>
						<th>{t('Уровень')}</th>
						{hotels.map((h, key) =>
							<td key={key}>
								{h.Stars.Code <= 5 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
								{h.Stars.Code <= 4 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
								{h.Stars.Code <= 3 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
								{h.Stars.Code <= 2 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
								{h.Stars.Code <= 1 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
							</td>
						)}
					</tr>
					<tr className='address'>
						<th>{t('Адрес')}</th>
						{hotels.map((h, key) => <td key={key}>{h.TypeOfPlace.Name} {h.City.Name}, {h.Address}</td>)}
					</tr>
					<tr className='distance'>
						<th>{t('Расстояние от центра')}</th>
						{hotels.map((h, key) => <td key={key}>{h.CityCentre}</td>)}
					</tr>

					{isAirportVisible && <tr>
						<th>{t('Аэропорты')}</th>
						{hotels.map((h, key) => <td key={key}>{h?.Airport && h.Airport.split(', ').map((a, key2) => <div key={key2}>• {a}</div>)}</td>)}
					</tr>}

					{isRailwayStationVisible && <tr>
						<th>{t('Вокзалы')}</th>
						{hotels.map((h, key) => <td key={key}>{h?.RailwayStation && h.RailwayStation.split(', ').map((a, key2) => <div key={key2}>• {a}</div>)}</td>)}
					</tr>}

					{isRiverPortVisible && <tr>
						<th>{t('Речные порты')}</th>
						{hotels.map((h, key) => <td key={key}>{h?.RiverPort && h.RiverPort.split(', ').map((a, key2) => <div key={key2}>• {a}</div>)}</td>)}
					</tr>}

					{isSeaPortVisible && <tr>
						<th>{t('Морские порты')}</th>
						{hotels.map((h, key) => <td key={key}>{h?.SeaPort && h.SeaPort.split(', ').map((a, key2) => <div key={key2}>• {a}</div>)}</td>)}
					</tr>}

					{isMetroVisible && <tr>
						<th>{t('Метро')}</th>
						{hotels.map((h, key) => <td key={key}>{h?.Underground && h.Underground.split(', ').map((a, key2) => <div key={key2}>• {a}</div>)}</td>)}
					</tr>}

					{isDescriptionVisible && <tr>
						<th>{t('Достопримечательности')}</th>
						{hotels.map((h, key) => <td key={key}>{h?.Description && h.Description.split(', ').map((a, key2) => <div key={key2}>• {a}</div>)}</td>)}
					</tr>}

					<tr>
						<th>{t('Группа')}</th>
						{hotels.map((h, key) => <td key={key}>
							{h.GroupCriterionList.GroupCriterion.map((gc, key2) => <div key={key2}>
								{gc.GroupCriterionType.Code === 2 && `${gc.NumberOfRooms} ${declines(gc.NumberOfRooms, roomsTitles)}`}
								{gc.GroupCriterionType.Code === 3 && `${gc.NumberOfPersons} ${declines(gc.NumberOfPersons, guestsTitles)}`}
								{gc.GroupCriterionType.Code === 4 && `${gc.NumberOfRooms} ${declines(gc.NumberOfRooms, roomsTitles)} / ${gc.NumberOfPersons} ${declines(gc.NumberOfPersons, guestsTitles)}`}
								{gc.GroupCriterionType.Code === 5 && `${gc.NumberOfRooms} ${declines(gc.NumberOfRooms, roomsTitles)} ${t('и')} ${gc.NumberOfPersons} ${declines(gc.NumberOfPersons, guestsTitles)}`}
							</div>)}
						</td>)}
					</tr>

					<tr className='amenities'>
						<th>{t('Услуги')}</th>
						{hotels.map((h, key) => <td key={key}>
							{ams.map((a, key2) => <div key={key2}>{h.Amenities.includes(a.id) ? a.title : '—'}</div>)}
						</td>)}
					</tr>
				</tbody>
			</Table>
		</Modal2>
	);
};