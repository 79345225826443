export const useRatesProps = ({ rooms, hotelCode, setOrderInfo, setRate, currency }) => {
	const ratesProps = {
		items: rooms,
		onChange: value => setOrderInfo(prev => ({
			...prev,
			Product: value
		})),
		onShowDetails: rate => setRate(rate),
		hotelCode: hotelCode,
		currency
	};

	return {
		ratesProps
	};
};