import { useState } from 'react';

export const useDetails = ({ startDate, endDate }) => {
	const [ rate, setRate ] = useState(false); 

	const onShowDetails = async (rate, hotelCode, e) => {
		e.preventDefault();

		setRate({
			ArrivalDate: startDate,
			DepartureDate: endDate,
			Hotel: hotelCode,
			Id: 0,
			AccommodationId: -99999,
			ArrivalTime: '',
			DepartureTime: '',
			NumberOfGuests: rate.NumberOfGuests,
			NumberOfExtraBedsAdult: rate?.NumberOfExtraBedAdult ?? rate?.NumberOfExtraBedsAdult,
			NumberOfExtraBedsChild: rate?.NumberOfExtraBedChild ?? rate?.NumberOfExtraBedsAdult,
			NumberOfExtraBedsInfant: rate?.NumberOfExtraBedInfant ?? rate?.NumberOfExtraBedsAdult,
			ProductCode: rate?.Code,
		});
	};

	const modalDetailsProps = {
		rate,
		onClose: () => {
			setRate(false);
		}
	};

	return {
		onShowDetails,
		modalDetailsProps
	};
};