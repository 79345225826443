import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { HotelMinPrice, HotelPhoto, HotelTitle, HotelTransports } from '../';
import { useHotelSearchStore } from 'stores';
import { HotelCompare, Room } from './';
import { Button, Sprite } from 'components';
import { declines } from 'utils';
import { useEffectExceptOnMount } from 'hooks';

const Container = styled.table`
	width: 100%;
	margin-top: 20px;
	margin-bottom: 10px;

	td {
		/* border: 1px solid red; */
		vertical-align: top;
		position: relative;

		&.info:not(.compact) {
			padding-left: 10px;
		}

		&.price {
			text-align: right;

			&.compact {
				.hotel_compare, .possibleOffersAddHotel {
					display: inline-block;
					margin-left: 5px;
				}
			}
		}
	}
`;

const OnMapLink = styled.div`
	position: relative;
	margin-top: 10px;
	display: inline-block;
	vertical-align: sub;
	white-space: nowrap;
	width: 100px;
	cursor: pointer;

	img {
		margin-right: 5px;
	}

	span {
		color: ${props => props.theme.darkBlue};
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
		position: absolute;
	}
`;

const AddressDiv = styled.div`
	display: inline;
	width: calc(100% - 100px);
	color: #8D8D8D;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 10px;
`;

const Hotel = ({ hotel }) => {
	const { t } = useTranslation();
	const {
		lastDestination: {
			Code: DestinationCode,
			Name: DestinationName,
			TypeCode: DestinationTypeCode,
			TownType: {
				Name: TownTypeName = ''
			} = {},
			ObjType: {
				Name: ObjTypeName = ''
			} = {},
			HotelType: {
				Name: HotelTypeName = ''
			} = {},
		},
		params: {
			view: {
				value: view = 'standart'
			} = {}	
		} = {},
		setHotelOnMap,
		setMap,
	} = useHotelSearchStore();
	const [ isOpen, setOpen ] = useState(view === 'extended');

	useEffectExceptOnMount(() => {
		setOpen(view === 'extended');
	}, [view]);

	const {
		Code,
		Url = false,
		BigUrl = false,
		ThUrl = false,
		IsNewHotel = 2,
		ImagesCount = 0,
		Name = '',
		Stars: {
			Code: StarsCode = 0
		} = {},
		City: {
			Code: City,
			Name: CityName = ''
		} = {},
		TypeOfPlace: {
			Name: TypeOfPlaceName
		},
		DistanceToDestination = false,
		CityCentre,
		Address,
		HotelOfferList: {
			HotelOfferDetail: offers
		},
		Currency: {
			Name: CurrencyName,
		} = {},
		Airport = '',
		RailwayStation = '',
		Objects: {
			Object: subways = []
		} = {},
	} = hotel;

	const hotelPhotoBind = {
		Code,
		Url,
		BigUrl,
		ThUrl,
		IsNewHotel,
		ImagesCount,
	};

	const hotelTitleBind = {
		Code,
		Name,
		StarsCode,
	};

	const hotelTransportBind = {
		Airport,
		RailwayStation,
		subways,
	};

	const distances = [
		(DestinationTypeCode === 2 && Number(DestinationCode) !== Number(City)) || (DestinationTypeCode !== 2 && Number(Code) !== Number(DestinationCode)) ? `${DistanceToDestination} км ${Number(DestinationTypeCode)  === 2 ? `от центра ${TownTypeName?.toLowerCase()}` : `от ${Number(DestinationTypeCode) === 4 ? ObjTypeName?.toLowerCase() : HotelTypeName?.toLowerCase()}`} ${DestinationName?.split(',')?.[0]}` : false,
		CityCentre,
	].filter(i => Boolean(i));

	const offersCodes = useMemo(() => {
		return offers.map(i => Number(i.Code));
	}, [offers]);

	const rooms = useMemo(() => {
		const tempOffers = offers.slice(0, isOpen ? offers.length : 1);

		return tempOffers
			.reduce((acc, cur) => {
				if (acc.find(i => Number(i.RoomCode) === Number(cur.RoomCode))) {
					return [...acc];
				} else {
					return [
						...acc,
						{
							...cur,
							rates: tempOffers.filter(i => Number(i.RoomCode) === Number(cur.RoomCode))
						}
					];
				}
			}, []);
	}, [isOpen, offersCodes]);

	const onMap = () => {
		setHotelOnMap(hotel);
		setMap(true);
	};

	const toggle = () => setOpen(prev => !prev);

	return (
		<>
			<Container>
				<colgroup>
					{view !== 'compact' && <col className='col1' width='160' />}
					<col className='col2' />
					<col className='col3' width='250' />
				</colgroup>

				<tbody>
					<tr>
						{view !== 'compact' && <td>
							<HotelPhoto {...hotelPhotoBind} />
						</td>}

						<td className={`info ${view}`}>
							<HotelTitle {...hotelTitleBind} />

							<div>
								{distances.length > 0 && 
									<OnMapLink onClick={onMap}>
										<img src='/images/icon_marker.png' />
										<span>{t('На карте')}</span>
									</OnMapLink>
								}

								<AddressDiv>
									{distances.join(' • ')} {TypeOfPlaceName} {CityName}, {Address}
								</AddressDiv>
							</div>

							{view !== 'compact' && <HotelTransports {...hotelTransportBind} />}
						</td>

						<td className={`price ${view}`}>
							<HotelMinPrice offers={offers} CurrencyName={CurrencyName} />

							<HotelCompare Code={Code} />
						</td>
					</tr>
				</tbody>
			</Container>

			{rooms.map((room, key) => <Room key={key} room={room} hotelCode={Code} hotelName={Name} hotelStars={StarsCode} CurrencyName={CurrencyName} />)}

			{offers.length > 1 && 
				<Button white onClick={toggle}>
					{isOpen ? t('Скрыть') : `${t('Показать еще')} ${declines(offers.length - 1, [t('вариант'), t('варианта'), t('вариантов')], true)}`}
					<Sprite icon={isOpen ? 'arrow_up' : 'arrow_down'} />
				</Button>
			}
		</>
	);
};

export default Hotel;