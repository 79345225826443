/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Sprite } from './Sprite';
import { useUserStore } from 'stores';
import { ModalLogin } from 'modals';
import { useDevice } from 'hooks';
import { Lang } from './Lang';

const Container = styled.div`
	/* display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: white;
	z-index: 100; */

	/* height: 50px; */
	position: sticky;
	top: 0;
	width: 100vw;

	z-index: 100;
	background-color: white;
	padding: 0 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	user-select: none !important;
	white-space: nowrap !important;

	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		height: 10px;
		width: 100%;
		background: linear-gradient(180deg, rgba(0, 70, 133, 0.2) 0%, rgba(0, 70, 133, 0) 100%);
	}

	> div {
		&.first {
			flex: 0 0 30px;
			border-bottom: 1px solid ${props => props.theme.lightGray};
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 15px;

			a {
				font-weight: 400 !important;
				font-size: 14px !important;
				line-height: 17px !important;

				&.bold {
					font-weight: 500 !important;
				}
			}
		}

		&.last {
			flex: 0 0 70px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 15px;

			@media (max-width: 1279px) {
				flex: 0 0 60px;
			}

			> a {
				&.logo {
					font-weight: 500;
					font-size: 24px;
					line-height: 28px;
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 5px;

					@media (max-width: 1279px) {
						font-size: 14px;
						line-height: 17px;
					}
				}

				&:not(.logo) {
					font-weight: 400;
					font-size: 16px;
					line-height: 19px;
					text-transform: uppercase;
				}
			}
		}
	}
`;

const User = styled(Link)`
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: ${props => props.theme.darkBlue};
	text-transform: uppercase;
	/* text-decoration: underline !important; */
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	position: relative;

	> div {
		position: absolute;
		top: calc(100% + 5px);
		left: 0;
		background-color: white;
		padding: 10px;
		z-index: 100;
		border: 1px solid ${props => props.theme.darkBlue};
		display: flex;
		flex-direction: column;
		gap: 10px;
		opacity: 0;
		visibility: hidden;

		a {
			white-space: nowrap;
		}
	}

	&:hover {
		> div {
			opacity: 1;
			visibility: visible;
		}
	}
`;

const Logout = styled.div`
	cursor: pointer;
`;

const LoginButton = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	text-transform: uppercase;
	color: ${props => props.theme.darkBlue};

	&:hover {
		color: ${props => props.theme.lightBlue};
	}
`;

const MobileMenu = styled.div`
	height: 30px;
`;

const MenuContainer = styled.div`
	width: 320px;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: white;
	z-index: 999999;
	padding: 0 20px 20px 20px;
	margin-right: -100%;
	transition: margin-right .2s ease-in-out;

	&.isMenuOpen {
		margin-right: 0;
	}

	@media (max-width: 659px) {
		width: 100vw;
	}

	.row {
		height: 60px;
		border-bottom: 1px solid ${props => props.theme.lightGray};
		display: flex;
		flex-direction: row;
		align-items: center;
		/* justify-content: space-between; */
		justify-content: flex-start;
	}

	.menu {
		border-bottom: 1px solid ${props => props.theme.lightGray};
		padding: 15px 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		a {
			font-size: 16px !important;
			line-height: 19px !important;
			text-transform: uppercase;

			&:not(:first-child) {
				margin-top: 10px;
			}
		}
	}
`;

const Overlay = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999998;
	background-color: rgba(0, 70, 133, 0.8);
	opacity: 0;
	visibility: hidden;

	&.isMenuOpen {
		opacity: 1;
		visibility: visible;
	}
`;

const OldBrowser = styled.div`
	width: 100vw;
	min-height: 40px;
	height: 40px;
	background-color: #FF6B00;
	margin-bottom: -40px;
	user-select: none !important;
	color: white;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;

	a {
		display: inline !important;
		margin: 0 5px;
		color: inherit !important;
		font-size: inherit !important;
		line-height: inherit !important;
		text-decoration: underline !important;
	}
`;

const detectIEEdge = () => {
	var ua = window.navigator.userAgent;

	var msie = ua.indexOf('MSIE ');
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	}

	var trident = ua.indexOf('Trident/');
	if (trident > 0) {
		// IE 11 => return version number
		var rv = ua.indexOf('rv:');
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	}

	var edge = ua.indexOf('Edge/');
	if (edge > 0) {
		// Edge => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	}

	// other browser
	return false;
};

const CashbackLink = styled(Link)`
	padding: 3px;
	background: #0F754E;
	text-transform: uppercase;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	cursor: pointer;
	color: white !important;
	border-radius: 2px;
`;

export const HeaderNew = () => {
	const { t } = useTranslation();
	const { isDesktop } = useDevice();
	const [ isMenuOpen, setMenuOpen ] = useState(false);
	const loc = useLocation();

	const isIE = useMemo(() => {
		const isIE = detectIEEdge();
		return isIE > 0 && isIE <= 11;
	}, []);

	useEffect(() => {
		if (isIE) {
			document.getElementById('root').style.marginTop = '110px';
		}
	}, [isIE]);

	useEffect(() => {
		setMenuOpen(false);
	}, [loc?.pathname]);

	const {
		isLogged,
		logout,
		user,
	} = useUserStore();

	const {
		UserTypeCode = 2,
		ManagerName = '',
		UserName = '',
	} = user ?? {};

	let name = parseInt(UserTypeCode) === 3 ? ManagerName : UserName;

	const toggleMenuOpen = () => setMenuOpen(prev => !prev);

	return (
		<Container>
			{!isLogged && isDesktop && <div className='container first'>
				<a href='https://academservice.ru' target='_blank' rel="noreferrer" className='bold'>{t('ГРУППА КОМПАНИЙ «АКАДЕМСЕРВИС»')}</a>
				<div className='filler' />
				<a href='https://academonline.ru/add_hotel' target='_blank' rel="noreferrer">{t('ДОБАВИТЬ ОТЕЛЬ')}</a>
				<a href='https://academonline.ru/agent' target='_blank' rel="noreferrer">{t('СТАТЬ АГЕНТОМ')}</a>
			</div>}

			<div className='container last'>
				<Link to='/' className='logo'>
					<Sprite icon='logo' />
					{t('АКАДЕМ-ОНЛАЙН')}
				</Link>

				<div className='filler' />

				{/* <Lang /> */}

				{isDesktop &&
					<>
						{/* <CashbackLink to='/cashback'>Кешбэк</CashbackLink> */}

						<Lang />

						<NavLink to="/">{t('Поиск')}</NavLink>
						<NavLink to="/countries">{t('Отели')}</NavLink>
						{isLogged && <NavLink to="/orders">{t('Заказы')}</NavLink>}
						<NavLink to="/about">{t('О нас')}</NavLink>
						<NavLink to="/contacts">{t('Контакты')}</NavLink>

						{isLogged && 
							<User to='/profile'>
								<Sprite icon='user' />
								{name.substr(0, 37)}
								{name.length > 38 && '...'}

							</User>
						}

						{isLogged && <Logout onClick={logout}>
							<Sprite icon='logout' title={t('Выход')} />
						</Logout>}

						{!isLogged &&
							<ModalLogin initialIsOpen={loc?.pathname === '/login'}>
								<LoginButton id='button__login'>
									<Sprite icon='login' />
									{t('Войти')}
								</LoginButton>
							</ModalLogin>
						}
					</>
				}

				{!isDesktop &&
					<MobileMenu>
						<div onClick={toggleMenuOpen}>
							<Sprite icon='burger' />
						</div>

						<Overlay className={classnames({ isMenuOpen })} onClick={toggleMenuOpen} />

						<MenuContainer className={classnames({ isMenuOpen })}>
							<div className='row'>
								<Lang />
								<div className='filler' />
								<div onClick={toggleMenuOpen}>
									<Sprite icon='close' />
								</div>
							</div>

							<div className='row'>

								{isLogged && 
									<>
										<User to='/profile'>
											<Sprite icon='user' />
											{name.substr(0, 37)}
											{name.length > 38 && '...'}
										</User>

										<div className='filler' />

										<Logout onClick={logout}>
											<Sprite icon='logout' title={t('Выход')} />
										</Logout>
									</>
								}

								{!isLogged &&
									<ModalLogin initialIsOpen={loc?.pathname === '/login'}>
										<LoginButton id='button__login'>
											<Sprite icon='login' />
											{t('Войти')}
										</LoginButton>
									</ModalLogin>
								}
							</div>

							<div className='menu'>
								<NavLink to="/" onClick={toggleMenuOpen}>{t('Поиск')}</NavLink>
								<NavLink to="/countries" onClick={toggleMenuOpen}>{t('Отели')}</NavLink>
								{isLogged && <NavLink to="/orders" onClick={toggleMenuOpen}>{t('Заказы')}</NavLink>}
								<NavLink to="/about" onClick={toggleMenuOpen}>{t('О нас')}</NavLink>
								<NavLink to="/contacts" onClick={toggleMenuOpen}>{t('Контакты')}</NavLink>
							</div>

							<div className='menu'>
								{/* <CashbackLink to='/cashback' onClick={toggleMenuOpen}>Кешбэк</CashbackLink> */}
	
								<a href='https://academonline.ru/add_hotel' target='_blank' rel="noreferrer" onClick={toggleMenuOpen}>{t('ДОБАВИТЬ ОТЕЛЬ')}</a>
								<a href='https://academonline.ru/agent' target='_blank' rel="noreferrer" onClick={toggleMenuOpen}>{t('СТАТЬ АГЕНТОМ')}</a>
							</div>
						</MenuContainer>
					</MobileMenu>
				}

			</div>

			{isIE &&
				<OldBrowser>
					{t('Ваш браузер устарел! Пожалуйста, воспользуйтесь браузерами')} <a href="https://www.google.com/intl/ru_ru/chrome/" target="_blank" rel="noreferrer">Chrome</a> {t('или')} <a href="https://www.mozilla.org/ru/firefox/new/" target="_blank" rel="noreferrer">Firefox</a>
				</OldBrowser>
			}
		</Container>
	);
};