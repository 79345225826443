import React, { useState } from 'react';
import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';
import { Guests } from 'components';
import { useHotelSearchStore } from 'stores';

const Container = styled.div`
	.guests {
		border: 1px solid ${props => props.theme.darkBlue} !important;
		width: auto;

		input {
			border: none !important;
		}
	}
`;

export const GuestsInput = () => {
	// const { t } = useTranslation();
	const [ isOpen, setOpen ] = useState(false);
	const {
		params: {
			adults,
			childs,
			ages,
		},
		setParams
	} = useHotelSearchStore();

	const bind = {
		adults,
		childs,
		ages,
		isOpen,
		close: () => setOpen(false),
		toggle: () => setOpen(prev => !prev),
		decAdults: () => setParams(prev => ({
			...prev,
			adults: prev.adults > 1 ? prev.adults - 1 : prev.adults
		})),
		incAdults: () => setParams(prev => ({
			...prev,
			adults: prev.adults < 9 ? prev.adults + 1 : prev.adults
		})),
		decChilds: () => setParams(prev => ({
			...prev,
			childs: prev.childs > 0 ? prev.childs - 1 : prev.childs
		})),
		incChilds: () => setParams(prev => ({
			...prev,
			childs: prev.childs < 4 ? prev.childs + 1 : prev.childs
		})),
		setAge: (index, e) => setParams(prev => {
			const items = prev.ages;
			items[index] = parseInt(e.target.value);

			return {
				...prev,
				ages: [...items]
			};
		})
	};

	return (
		<Container>
			{/* {label && <label>{t('Гости')}</label>} */}
			<Guests {...bind} />
		</Container>
	);
};