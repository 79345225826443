import React, { useMemo } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Container = styled.div`
	display: flex;
	position: relative;
	z-index: ${props => props.$zIndex};

	&.test {
		border: 1px solid red;
	}

	&.row { flex-direction: row; }
	&.rowReverse { flex-direction: row-reverse; }
	&.column { flex-direction: column; }
	&.columnReverse { flex-direction: column-reverse; }

	&.grow { flex-grow: 1; }
	&.noGrow { flex-grow: 0; }
	&.shrink { flex-shrink: 1; }
	&.noShring { flex-shrink: 0; }

	&.wrap { flex-wrap: wrap; }
	&.noWrap { flex-wrap: nowrap; }
	&.wrapReverse { flex-wrap: wrap-reverse; }

	&.justifyStart { justify-content: flex-start; }
	&.justifyEnd { justify-content: flex-end; }
	&.justifyCenter { justify-content: center; }
	&.justifyBetween { justify-content: space-between; }
	&.justifyAround { justify-content: space-around; }
	/* .justifyEvenly { justify-content: space-evenly; } */
	/* .justifyStart { justify-content: start; } */
	/* .justifyEnd { justify-content: end; } */
	/* .justifyLeft { justify-content: left; } */
	/* .justifyRight { justify-content: right; } */

	&.selfAuto { align-self: auto; }
	&.selfStart { align-self: flex-start; }
	&.selfEnd { align-self: flex-end; }
	&.selfCenter { align-self: center; }
	&.selfBaseline { align-self: baseline; }
	&.selfStretch { align-self: stretch; }

	&.alignStretch { align-items: stretch; }
	&.alignStart { align-items: flex-start; }
	&.alignEnd { align-items: flex-end; }
	&.alignCenter { align-items: center; }
	&.alignBaseline { align-items: baseline; }

	&.contentStart { align-content: flex-start; }
	&.contentEnd { align-content: flex-end; }
	&.contentCenter { align-content: center; }
	&.contentBetween { align-content: space-between; }
	&.contentAround { align-content: space-around; }
	&.contentStretch { align-content: stretch; }

	&.gap40 { gap: 40px; }
	&.gap20 { gap: 20px; }
	&.gap10 { gap: 10px; }
	&.gap5 { gap: 5px; }

	&.rowGap40 { row-gap: 40px; }
	&.rowGap20 { row-gap: 20px; }
	&.rowGap10 { row-gap: 10px; }
	&.rowGap5 { row-gap: 5px; }

	&.columnGap40 { column-gap: 40px; }
	&.columnGap20 { column-gap: 20px; }
	&.columnGap10 { column-gap: 10px; }
	&.columnGap5 { column-gap: 5px; }

	&.padding40 { padding: 40px; }
	&.padding20 { padding: 20px; }
	&.padding10 { padding: 10px; }
	&.padding5 { padding: 5px; }

	&.margin40 { margin: 40px; }
	&.margin20 { margin: 20px; }
	&.margin10 { margin: 10px; }
	&.margin5 { margin: 5px; }

	&.container {
		width: calc(100vw - 80px);
		max-width: 1440px;

		@media (max-width: 1279px) {
			width: calc(100vw - 40px);
		}
	}

	&.full {
		width: 100vw;
	}

	&.content {
		margin-left: 40px;
		margin-right: 40px;
		max-width: 960px;
	}

	@media (max-width: 1279px) {
		.tabletColumn { flex-direction: column; }
	}

	@media (max-width: 1279px) {
		&.tablet__row { flex-direction: row; }
		&.tablet__rowReverse { flex-direction: row-reverse; }
		&.tablet__column { flex-direction: column; }
		&.tablet__columnReverse { flex-direction: column-reverse; }

		&.tablet__grow { flex-grow: 1; }
		&.tablet__noGrow { flex-grow: 0; }
		&.tablet__shrink { flex-shrink: 1; }
		&.tablet__noShring { flex-shrink: 0; }

		&.tablet__wrap { flex-wrap: wrap; }
		&.tablet__noWrap { flex-wrap: nowrap; }
		&.tablet__wrapReverse { flex-wrap: wrap-reverse; }

		&.tablet__justifyStart { justify-content: flex-start; }
		&.tablet__justifyEnd { justify-content: flex-end; }
		&.tablet__justifyCenter { justify-content: center; }
		&.tablet__justifyBetween { justify-content: space-between; }
		&.tablet__justifyAround { justify-content: space-around; }

		&.tablet__selfAuto { align-self: auto; }
		&.tablet__selfStart { align-self: flex-start; }
		&.tablet__selfEnd { align-self: flex-end; }
		&.tablet__selfCenter { align-self: center; }
		&.tablet__selfBaseline { align-self: baseline; }
		&.tablet__selfStretch { align-self: stretch; }

		&.tablet__alignStretch { align-items: stretch; }
		&.tablet__alignStart { align-items: flex-start; }
		&.tablet__alignEnd { align-items: flex-end; }
		&.tablet__alignCenter { align-items: center; }
		&.tablet__alignBaseline { align-items: baseline; }

		&.tablet__contentStart { align-content: flex-start; }
		&.tablet__contentEnd { align-content: flex-end; }
		&.tablet__contentCenter { align-content: center; }
		&.tablet__contentBetween { align-content: space-between; }
		&.tablet__contentAround { align-content: space-around; }
		&.tablet__contentStretch { align-content: stretch; }

		&.tablet__gap40 { gap: 40px; }
		&.tablet__gap20 { gap: 20px; }
		&.tablet__gap10 { gap: 10px; }
		&.tablet__gap5 { gap: 5px; }

		&.tablet__rowGap40 { row-gap: 40px; }
		&.tablet__rowGap20 { row-gap: 20px; }
		&.tablet__rowGap10 { row-gap: 10px; }
		&.tablet__rowGap5 { row-gap: 5px; }

		&.tablet__columnGap40 { column-gap: 40px; }
		&.tablet__columnGap20 { column-gap: 20px; }
		&.tablet__columnGap10 { column-gap: 10px; }
		&.tablet__columnGap5 { column-gap: 5px; }

		&.tablet__padding40 { padding: 40px; }
		&.tablet__padding20 { padding: 20px; }
		&.tablet__padding10 { padding: 10px; }
		&.tablet__padding5 { padding: 5px; }

		&.tablet__margin40 { margin: 40px; }
		&.tablet__margin20 { margin: 20px; }
		&.tablet__margin10 { margin: 10px; }
		&.tablet__margin5 { margin: 5px; }		
	}

	@media (max-width: 659px) {
		&.mobile__row { flex-direction: row; }
		&.mobile__rowReverse { flex-direction: row-reverse; }
		&.mobile__column { flex-direction: column; }
		&.mobile__columnReverse { flex-direction: column-reverse; }

		&.mobile__grow { flex-grow: 1; }
		&.mobile__noGrow { flex-grow: 0; }
		&.mobile__shrink { flex-shrink: 1; }
		&.mobile__noShring { flex-shrink: 0; }

		&.mobile__wrap { flex-wrap: wrap; }
		&.mobile__noWrap { flex-wrap: nowrap; }
		&.mobile__wrapReverse { flex-wrap: wrap-reverse; }

		&.mobile__justifyStart { justify-content: flex-start; }
		&.mobile__justifyEnd { justify-content: flex-end; }
		&.mobile__justifyCenter { justify-content: center; }
		&.mobile__justifyBetween { justify-content: space-between; }
		&.mobile__justifyAround { justify-content: space-around; }

		&.mobile__selfAuto { align-self: auto; }
		&.mobile__selfStart { align-self: flex-start; }
		&.mobile__selfEnd { align-self: flex-end; }
		&.mobile__selfCenter { align-self: center; }
		&.mobile__selfBaseline { align-self: baseline; }
		&.mobile__selfStretch { align-self: stretch; }

		&.mobile__alignStretch { align-items: stretch; }
		&.mobile__alignStart { align-items: flex-start; }
		&.mobile__alignEnd { align-items: flex-end; }
		&.mobile__alignCenter { align-items: center; }
		&.mobile__alignBaseline { align-items: baseline; }

		&.mobile__contentStart { align-content: flex-start; }
		&.mobile__contentEnd { align-content: flex-end; }
		&.mobile__contentCenter { align-content: center; }
		&.mobile__contentBetween { align-content: space-between; }
		&.mobile__contentAround { align-content: space-around; }
		&.mobile__contentStretch { align-content: stretch; }

		&.mobile__gap40 { gap: 40px; }
		&.mobile__gap20 { gap: 20px; }
		&.mobile__gap10 { gap: 10px; }
		&.mobile__gap5 { gap: 5px; }

		&.mobile__rowGap40 { row-gap: 40px; }
		&.mobile__rowGap20 { row-gap: 20px; }
		&.mobile__rowGap10 { row-gap: 10px; }
		&.mobile__rowGap5 { row-gap: 5px; }

		&.mobile__columnGap40 { column-gap: 40px; }
		&.mobile__columnGap20 { column-gap: 20px; }
		&.mobile__columnGap10 { column-gap: 10px; }
		&.mobile__columnGap5 { column-gap: 5px; }

		&.mobile__padding40 { padding: 40px; }
		&.mobile__padding20 { padding: 20px; }
		&.mobile__padding10 { padding: 10px; }
		&.mobile__padding5 { padding: 5px; }

		&.mobile__margin40 { margin: 40px; }
		&.mobile__margin20 { margin: 20px; }
		&.mobile__margin10 { margin: 10px; }
		&.mobile__margin5 { margin: 5px; }		
	}
`;

const Flex = ({
	className = '',
	children = null,
	tablet = {},
	mobile = {},
	zIndex = 'auto',
	...props
}) => {
	const classNames = useMemo(() => {
		const classNames = {};

		Object.keys(tablet).filter(i => tablet[i]).forEach(i => classNames[`tablet__${i}`] = true);

		return classNames;
	}, [JSON.stringify(tablet), JSON.stringify(mobile)]);

	return (
		<Container className={classnames(className, props, classNames)} $zIndex={zIndex}>
			{children}
		</Container>
	);
};

Flex.propTypes = {
	children: PropTypes.node,
	row: PropTypes.bool,
	column: PropTypes.bool,
	alignCenter: PropTypes.bool,
	gap5: PropTypes.bool,
	gap10: PropTypes.bool,
	gap20: PropTypes.bool,
	gap40: PropTypes.bool,
	padding5: PropTypes.bool,
	padding10: PropTypes.bool,
	padding20: PropTypes.bool,
	padding40: PropTypes.bool,
	container: PropTypes.bool,
	grow: PropTypes.bool,
	noGrow: PropTypes.bool,
	shrink: PropTypes.bool,
	noShrink: PropTypes.bool,
	justifyBetween: PropTypes.bool,
};

export { Flex };