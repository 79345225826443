import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { Paginator, RoomDescription } from 'components';
import { AddCompareMessageDesktop } from '..';
import { useHotelSearchStore } from 'stores';
import { MapContainer, ModalDetailsV2 } from 'features/Search';
import { Hotel, Toolbar } from './';
import Filters from './Filters/';
import { ModalCompare } from 'modals';

const Container = styled.div`
	margin: 20px;
	width: calc(100vw - 40px);
	position: relative;

	> h4 {
		margin: 20px 0 !important;
		color: #FF6B00 !important;
		font-size: 20px !important;
		font-weight: 500 !important;
		line-height: normal !important;
	}
`;

const Desktop = () => {
	const { t } = useTranslation();
	const {
		filteredCount,
		params: {
			limit: {
				value: limit = 25
			} = {},
		},
		isMap,
		filtered,
		altFiltered,
		roomDescriptionProps,
		setRoomDescriptionProps,
		modalDetailsProps,
		page,
		setPage,
		modalCompareProps,
	} = useHotelSearchStore();

	const paginatorBind = {
		total: filteredCount,
		limit,
		page,
		setPage
	};

	return (
		<>
			<Filters />
			<AddCompareMessageDesktop />

			<Container className='container'>
				<Toolbar />
	
				{isMap && Array.isArray(filtered) && <MapContainer />}

				{!isMap && Array.isArray(filtered) && <>
					{filtered.length === 0
						? <h4>{t('Отели, соответствующие Вашему запросу, не найдены!')}</h4>
						: filtered.map(hotel => <Hotel hotel={hotel} key={hotel.Code} />)
					}

					{altFiltered.length > 0 && <>
						<h4>{t('Следующие отели не полностью соответствуют Вашему запросу!')}</h4>
						{altFiltered.map(hotel => <Hotel hotel={hotel} key={hotel.Code} />)}
					</>}
				</>}

				<br />
				<Paginator {...paginatorBind} />
			</Container>

			<ModalCompare {...modalCompareProps} />
			<RoomDescription {...roomDescriptionProps} setRoomDescriptionProps={setRoomDescriptionProps} />
			<ModalDetailsV2 {...modalDetailsProps} />
		</>
	);
};

export default Desktop;