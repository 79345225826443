import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	position: absolute;
	/* top: calc(100% + 3px); */
	bottom: calc(100% + 5px);
	background: white;
	border: 1px solid ${props => props.theme.darkBlue};
	z-index: 999999;
	padding: 0 5px;
	font-family: ${props => props.theme.pt};
	font-size: 16px !important;
	line-height: 21px !important;
	color: #333 !important;
	font-weight: normal;
	/* white-space: nowrap; */
	width: 300px;

	> div {
		font-family: ${props => props.theme.pt};
		font-size: 16px !important;
		line-height: 21px !important;
		color: #333 !important;
		font-weight: normal;

		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	&:after {
		content: '';
		display: none;
		position: absolute;
		top: -19px; /* At the bottom of the tooltip */
		left: calc(45% + 1px);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 10px 19px 10px;
		border-color: transparent transparent white transparent;
		z-index: 11;
	}	
	
	&:before {
		content: '';
		display: none;
		position: absolute;
		top: -20px; /* At the bottom of the tooltip */
		left: 45%;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 11px 20px 11px;
		border-color: transparent transparent ${props => props.theme.darkBlue} transparent;
		z-index: 10;
	}	
	
	b {
		font-weight: bold;
		font-family: ${props => props.theme.pt};
		color: #333;
	}
	
	ul {
		margin: 10px 0;
		list-style-type: disc;
		list-style-position: outside;
		padding-left: 20px;
	
		li {
			font-family: ${props => props.theme.pt};
			color: #333;
			font-weight: normal;
		}
	}
	
	a {
		display: inline;
	}
`;


export const GroupCriterions = ({ items = null }) => {
	const { t } = useTranslation();

	// const roomTitles = [t('комната'), t('комнаты'), t('комнат')];
	// const guestTitles = [t('гость'), t('гостя'), t('гостей')];

	const makeItemText = item => {
		let textExtraBeds = '';
		let textGroupRules = '';

		if (item.ExtraBedsAdultIncluded.Code === 1) {
			if (item.ExtraBedsChildIncluded.Code === 1) {
				textExtraBeds = item.ExtraBedsInfantIncluded.Code === 1 ? t(', включая размещающихся на дополнительных местах') : t(', включая размещающихся на дополнительных местах, за исключением младенцев');
			} else {
				textExtraBeds = item.ExtraBedsInfantIncluded.Code === 1 ? t(', включая размещающихся на дополнительных местах, за исключением детей') : t(', включая взрослых на дополнительных местах');
			}
		} else {
			if (item.ExtraBedsChildIncluded.Code === 1) {
				textExtraBeds = item.ExtraBedsInfantIncluded.Code === 1 ? t(', включая размещающихся на дополнительных местах, за исключением взрослых') : t(', включая детей на дополнительных местах');
			} else {
				textExtraBeds = item.ExtraBedsInfantIncluded.Code === 1 ? t(', включая младенцев на дополнительных местах') : ' ';
			}
		}

		item.GroupCriterionType.Code === 2 && (textGroupRules = `${item.NumberOfRooms} ${t('и более номеров')}`);
		item.GroupCriterionType.Code === 3 && (textGroupRules = `${t('размещения')} ${item.NumberOfPersons} ${t('и более гостей')}${textExtraBeds}`);
		item.GroupCriterionType.Code === 4 && (textGroupRules = `${item.NumberOfRooms} ${t('и более номеров или размещение')} ${item.NumberOfPersons} ${t('и более гостей')}${textExtraBeds}`);
		item.GroupCriterionType.Code === 5 && (textGroupRules = `${item.NumberOfRooms} ${t('и более номеров и размещение')} ${item.NumberOfPersons} ${t('и более гостей')}${textExtraBeds}`);

		return `${t('В период')} ${item.DateFrom}-${item.DateTo} ${t('группой считается бронирование на одну и ту же дату')} ${textGroupRules}`;
	};

	if (!Array.isArray(items)) return null;

	if (items.length > 0) {
		return (
			<Container>
				<b>{t('Условия группового бронирования')}:</b>
				{items.map((i, key) => <div key={key}>{makeItemText(i)}</div>)}
			</Container>
		);
	} else {
		return (
			<Container>
				{t('Условия группового бронирования отсутствуют')}
			</Container>
		);
	}
};