import React from 'react';
import styled from 'styled-components';
import iconMinus from 'images/icon_minus.svg';
import iconPlus from 'images/icon_plus.svg';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore } from 'stores';
import { Sprite } from 'components';

const Button = styled.div`
	width: 20px;
	height: 20px;
	cursor: pointer;
	opacity: 1;
	transition: opacity .2s ease-in-out;
	flex-shrink: 0;
	/* border: 2px solid ${props => props.theme.darkBlue}; */
	/* border-radius: 2px; */

	&.disabled { opacity: .6; }
`;

const Inc = styled(Button)`
	background: url(${iconPlus}) center/cover no-repeat;
`;

const Dec = styled(Button)`
	background: url(${iconMinus}) center/cover no-repeat;
`;

const DistanceInputContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	color: ${props => props.theme.darkBlue};
`;

const DistanceInput = styled.input`
	border: 1px solid ${props => props.theme.darkBlue};
	outline: none;
	background-color: white;
	padding: 0 10px;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	height: 30px;
	color: ${props => props.theme.darkBlue};
	margin-right: -5px;
	text-align: center;
`;

export const Distance = () => {
	const { t } = useTranslation();
	const {
		params: {
			filters: {
				distance = 0
			}
		},
		setParams
	} = useHotelSearchStore();

	const onChange = distance => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			distance
		}
	}));

	const handleChange = e => {
		const value = e.target.value;

		value.trim() === '' && onChange(0);

		!isNaN(parseInt(value)) && parseInt(value) >= 0 && parseInt(value) <= 200 && onChange(parseInt(value));
	};

	const handleDec = () => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			distance: prev.filters.distance > 0 ? distance - 1 : distance
		}
	}));

	const handleInc = () => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			distance: prev.filters.distance < 200 ? distance + 1 : distance
		}
	}));

	const isDirty = distance > 0;
	const clear = () => onChange(0);

	return (
		<div>
			<label>
				{t('Радиус поиска')}

				{isDirty && 
					<div onClick={clear}>
						<Sprite icon='small_cross' />
					</div>
				}
			</label>

			<DistanceInputContainer>
				<Dec onClick={handleDec} />
				<DistanceInput value={distance.toString()} onChange={handleChange} /> {t('км')}
				<Inc onClick={handleInc} />
			</DistanceInputContainer>
		</div>
	);
};