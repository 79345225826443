export const usePricesProps = ({ offer, pricing }) => {
	const pricesProps = {
		currency: pricing?.Currency?.Name ?? 'RUB',
		offer,
		pricing,
	};

	return {
		pricesProps
	};
};