export const createPerson = () => {
	const request = {
		LastName: '',
		FirstName: '',
		Category: {
			Code: 0,
			Name: '',
		},
		Citizenship: {
			Code: 0,
			Name: ''
		}
	};

	return request;
};
