import React from 'react';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore, useUserStore } from 'stores';
import styled from 'styled-components';
import { Sprite } from 'components';

const Inputs = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	gap: 5px;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	color: #333;
`;

export const Price = () => {
	const { t } = useTranslation();
	const {
		params: {
			filters: {
				priceFrom = 0,
				priceTo = 0
			}
		},
		setParams
	} = useHotelSearchStore();
	const { user } = useUserStore();
	const CurrencyName = user?.CurrencyName ?? 'RUB';

	const setPriceFrom = e => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			priceFrom: e.floatValue
		}
	}));

	const setPriceTo = e => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			priceTo: e.floatValue
		}
	}));
	
	const isDirty = priceFrom > 0 || priceTo > 0;
	const clear = () => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			priceTo: 0,
			priceFrom: 0
		}
	}));

	return (
		<div>
			<label>
				{t('Стоимость {{CurrencyName}} (за все проживание)', { CurrencyName })}
				{isDirty && 
					<div onClick={clear}>
						<Sprite icon='small_cross' />
					</div>
				}
			</label>

			<Inputs>
				<NumericFormat 
					value={priceFrom} 
					onValueChange={setPriceFrom} 
					thousandSeparator=' '
				/>
				-
				<NumericFormat 
					value={priceTo}
					onValueChange={setPriceTo}
					thousandSeparator=' '
				/>
			</Inputs>
		</div>
	);
};
