import { todayString, tomorrowString } from 'utils/dayjs';

const user = JSON.parse(localStorage.getItem('user'));

export const defaultOrderInfo = {
	AdditionalInfo: '',
	ArrivalDate: todayString(),
	DepartureDate: tomorrowString(),
	ArrivalTime: '',
	DepartureTime: '',
	NumberOfGuests: 1,
	NumberOfExtraBedsAdult: 0,
	NumberOfExtraBedsChild: 0,
	NumberOfExtraBedsInfant: 0,
	Product: 0,
	Hotel: 0,
	Persons: [],
	Currency: 2,
	WhereToPay: 3,
	Meal: 0,
	Id: 0,
	AccommodationId: -99999,
	ExtraMeals: [],
	ReferenceNumber: '',
	ReferenceNumberName: user?.ReferenceNumberName ?? 'Ref No'
};
