import React from 'react';
import styled from 'styled-components';
import { BathLocation, BedType, RateNew, Square } from './';
import { Sprite } from 'components';

const RoomContainer = styled.div`
	//margin-top: 20px;

	.info {
		background-color: #D3EFFF;
		height: 60px;
		display: flex;
		flex-direction: row;
		align-items: center;
		cursor: pointer;
		padding-right: 10px;

		&:hover .title {
			color: ${props => props.theme.lightBlue};
		}

		> img {
			height: 60px;
			width: 60px;
			object-fit: cover;
			object-position: center;
			margin-right: 10px;
			flex-shrink: 0;
		}
	}
`;

const NoPhoto = styled.div`
	width: 60px;
	height: 60px;
	background-color: #A2D0EA;
	margin-right: 10px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Title = styled.div`
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	text-transform: uppercase;
	color: ${props => props.theme.darkBlue};
	margin-right: 20px;
	transition: color .2s ease-in-out;

	@media (max-width: 1279px) {
		font-size: 14px;
		line-height: 17px;
	}
	
	&:hover {
		color: ${props => props.theme.lightBlue};
	}
`;

const Bit = styled.div`
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: #333;
	margin-left: 10px;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	align-items: center;

	&.orange {
		color: ${props => props.theme.orange};
	}
	
	svg {
		margin-right: 5px;
	}	
`;

export const Room = ({
	hotelId = 0, 
	onAddOfferForClient, 
	hotelName = '', 
	hotelStars = {}, 
	room = {}, 
	rates = [], 
	onClickRoom = () => null, 
	onShowDetails = () => null, 
	orderUrl,
	filters
}) => {
	const {
		BedTypes: {
			Variant: BedTypes = []
		} = {},
		Bathlocation: bathLoc = false,
		Square: square = false
	} = room;

	return (
		<RoomContainer>
			<div className="info" onClick={onClickRoom.bind(this, room.Code, hotelId, room.RoomCode, hotelName, hotelStars)}>
				{room.Url ?
					<img src={room.Url.replace('test-images', 'images')} alt=''/> :
					<NoPhoto>
						<Sprite icon='no_photo' />
					</NoPhoto>
				}

				<Title>{room.RoomName}</Title>

				<div className='filler' />

				<BathLocation loc={bathLoc} Element={Bit} />

				<BedType BedTypes={BedTypes} Element={Bit} />

				<Square square={square} Element={Bit} />
			</div>

			{rates.map(rate => <RateNew filters={filters} rate={rate} key={rate.RateCode} hotelName={hotelName} hotelStars={hotelStars} onAddOfferForClient={onAddOfferForClient} hotelCode={hotelId} onShowDetails={onShowDetails} orderUrl={orderUrl} />)}
		</RoomContainer>
	);
};