/* eslint-disable no-unused-vars */

export const useCreateAccommodation = () => {
	const createAccommodation = async params => {
		const {
			Hotel,
			Product,
			ArrivalDate,
			DepartureDate,
			NumberOfGuests,
			NumberOfExtraBedAdult: NumberOfExtraBedsAdult,
			NumberOfExtraBedChild: NumberOfExtraBedsChild,
			NumberOfExtraBedInfant: NumberOfExtraBedsInfant,
			offer
		} = params;

		let meal = (offer?.Meals?.Meal ?? []).find(i => i?.IncludedInPrice?.Code === 1);
		if (!meal) meal = (offer?.Meals?.Meal ?? []).find(i => i.Code === 0);

		const item = {
			Id: -99999,
			ArrivalDate,
			ArrivalTime: '',
			DepartureDate,
			DepartureTime: '',
			NumberOfRooms: 1,
			NumberOfGuests,
			NumberOfExtraBedsAdult,
			NumberOfExtraBedsChild,
			NumberOfExtraBedsInfant,
			AdditionalInfo: '',
			ToBeCancelled: {
				Code: 2
			},
			ToBeChanged: {
				Code: 1
			},
			Hotel: {
				Code: Hotel
			},
			Product: {
				Code: Product,
				Allotment: {
					Code: 'void'
				}
			},
			Meal: {
				Code: meal?.Code ?? 0,
				Name: meal?.Name ?? '',
				IncludedInRate: meal?.IncludedInPrice?.Code ?? 1,
			},
			ExtraMeals: {
				ExtraMeal: []
			},
			CriticalEarlyCheckIn: {
				Code: 3
			},
			CriticalLateCheckOut: {
				Code: 3
			},
			IsLateCheckOut: {
				Code: 2
			},
			IsEarlyCheckIn: {
				Code: 2
			},
			Persons: {
				Person: [
					{
						LastName: '',
						FirstName: '',
						Category: 0,
						Citizenship: 0
					}
				]
			},
			Penalties: [],
			Gain: 0.00,
			Price: 0,
			TravelAgencyCommission: 0,
			VATIncludedInPrice: 0,
			PossiblePenaltySize: 0,
		};

		return item;
	};

	return {
		createAccommodation,
	};
};