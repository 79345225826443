import React from 'react';
import styled from 'styled-components';
import starEmpty from 'images/star_empty.svg';
import starFill from 'images/star_fill.svg';

const ImgCircle = styled.img`
	margin-right: 5px;
`;

const ImgCircleEmpty = () => <ImgCircle src={starEmpty} alt='' />;
const ImgCircleFill = () => <ImgCircle src={starFill} alt='' />;

const Container = styled.div`
	margin-top: 10px;
`;

const Title = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 5px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: ${props => props.theme.darkBlue};
	
	div {
		margin-left: 10px;
		white-space: nowrap;
	}
`;

const Address = styled.div`
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: #333;
`;

export const HotelInfo = ({ hotel = {} }) => {
	return (
		<Container>
			<Title>
				{hotel.Name}
				<div>
					{hotel.Stars.Code <= 5 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
					{hotel.Stars.Code <= 4 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
					{hotel.Stars.Code <= 3 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
					{hotel.Stars.Code <= 2 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
					{hotel.Stars.Code <= 1 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
				</div>
			</Title>

			<Address>
				{hotel.City.Name}, {hotel.Address}
			</Address>
		</Container>
	);
};