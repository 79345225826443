import React from 'react';
import { Sprite } from 'components';
import { useDevice } from 'hooks';

export const BathLocation = ({
	loc,
	Element
}) => {
	const { isDesktop } = useDevice();

	if ([3, 4].includes(parseInt(loc?.Code))) {
		return (
			<Element className='orange'>
				<Sprite icon='bath' />
				{isDesktop ? loc?.Name : '!'}
			</Element>
		);
	}

	return null;
};
