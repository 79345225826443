import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { HotelMinPrice, HotelPhoto, HotelTitle, HotelTransports } from '../';
import { useHotelSearchStore } from 'stores';
import { Room } from './';
import { Button, Sprite } from 'components';
import { declines } from 'utils';
import { useEffectExceptOnMount } from 'hooks';

const Container = styled.table`
	margin-top: 20px;
	width: 100%;

	td {
		/* border: 1px solid red; */
		vertical-align: top;
		position: relative;

		&.col1 {
			width: 120px;
		}

		&.col2 {
			&:not(.compact) {
				padding-left: 10px;
			}

			> .address {
				color: #8D8D8D;
				font-family: ${props => props.theme.pt};
				font-size: 16px;
				font-weight: 400;
				line-height: normal;
				
				& + div {
					margin-top: 5px;
				}
			}
		}

		&.col3 {
			vertical-align: bottom;
			white-space: nowrap;
			text-align: right;
			padding-bottom: 5px;

			> * {
				display: inline-block;
				white-space: nowrap;

				> * {
					display: inline-block;
					white-space: nowrap;
					margin-left: 10px;
				}
			}

			svg {
				vertical-align: sub;
			}
		}

		&.hotelTitle {
			padding-left: 10px;


			.transportsPrice {
				margin: 0 !important;
				padding: 0 !important;
				> * {
					display: inline-block;
				}

				> .hotelTransports {
					width: 50%;
					margin: 0 !important;
					vertical-align: middle;
				}

				> .price {
					width: 50%;
					text-align: right;

					> * {
						display: inline-block;
					}

					.possibleOffersAddHotel {
						margin-top: 0 !important;
						margin-left: 10px;
						vertical-align: middle;
					}
				}
			}
		}
	}
`;

const OnMapLink = styled.div`
	position: relative;
	white-space: nowrap;
	display: inline-block;
	width: 100px;
	vertical-align: top;

	img {
		margin-right: 5px;
	}

	span {
		color: ${props => props.theme.darkBlue};
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
		position: absolute;
	}
`;

const Hotel = ({ hotel }) => {
	const { t } = useTranslation();
	const {
		lastDestination: {
			Code: DestinationCode,
			Name: DestinationName,
			TypeCode: DestinationTypeCode,
			TownType: {
				Name: TownTypeName = ''
			} = {},
			ObjType: {
				Name: ObjTypeName = ''
			} = {},
			HotelType: {
				Name: HotelTypeName = ''
			} = {},
		},
		setHotelOnMap,
		setMap,
		params: {
			view: {
				value: view = 'standart'
			} = {}	
		} = {},
	} = useHotelSearchStore();
	const [ isOpen, setOpen ] = useState(view === 'extended');

	useEffectExceptOnMount(() => {
		setOpen(view === 'extended');
	}, [view]);

	const {
		Code,
		Url = false,
		BigUrl = false,
		ThUrl = false,
		IsNewHotel = 2,
		ImagesCount = 0,
		Name = '',
		Stars: {
			Code: StarsCode = 0
		} = {},
		City: {
			Code: City,
			Name: CityName = ''
		} = {},
		TypeOfPlace: {
			Name: TypeOfPlaceName
		},
		DistanceToDestination = false,
		CityCentre,
		Address,
		HotelOfferList: {
			HotelOfferDetail: offers
		},
		Currency: {
			Name: CurrencyName,
		} = {},
		Airport = '',
		RailwayStation = '',
		Objects: {
			Object: subways = []
		} = {},
	} = hotel;

	const hotelPhotoBind = {
		Code,
		Url,
		BigUrl,
		ThUrl,
		IsNewHotel,
		ImagesCount,
	};

	const hotelTitleBind = {
		Code,
		Name,
		StarsCode,
	};

	const hotelTransportBind = {
		Airport,
		RailwayStation,
		subways,
	};

	const distances = [
		(DestinationTypeCode === 2 && Number(DestinationCode) !== Number(City)) || (DestinationTypeCode !== 2 && Number(Code) !== Number(DestinationCode)) ? `${DistanceToDestination} км ${Number(DestinationTypeCode)  === 2 ? `от центра ${TownTypeName?.toLowerCase()}` : `от ${Number(DestinationTypeCode) === 4 ? ObjTypeName?.toLowerCase() : HotelTypeName?.toLowerCase()}`} ${DestinationName?.split(',')?.[0]}` : false,
		CityCentre,
	].filter(i => Boolean(i));

	const offersCodes = useMemo(() => {
		return offers.map(i => Number(i.Code));
	}, [offers]);

	const rooms = useMemo(() => {
		const tempOffers = offers.slice(0, isOpen ? offers.length : 1);

		return tempOffers
			.reduce((acc, cur) => {
				if (acc.find(i => Number(i.RoomCode) === Number(cur.RoomCode))) {
					return [...acc];
				} else {
					return [
						...acc,
						{
							...cur,
							rates: tempOffers.filter(i => Number(i.RoomCode) === Number(cur.RoomCode))
						}
					];
				}
			}, []);
	}, [isOpen, offersCodes]);

	const onMap = () => {
		setHotelOnMap(hotel);
		setMap(true);
	};

	const toggle = () => setOpen(prev => !prev);

	return (
		<>
			<Container>
				<tbody>
					<tr>
						{view !== 'compact' && <td className='col1'>
							<HotelPhoto {...hotelPhotoBind} />
						</td>}

						<td className={`col2 ${view}`}>
							<HotelTitle {...hotelTitleBind} />

							<div className='address'>
								{distances.length > 0 && 
									<OnMapLink onClick={onMap}>
										<img src='/images/icon_marker.png' />
										<span>{t('На карте')}</span>
									</OnMapLink>
								}

								{distances.join(' • ')} {TypeOfPlaceName} {CityName}, {Address}
							</div>

							{view !== 'compact' && <div className='transportsPrice'>
								{view !== 'compact' && <HotelTransports {...hotelTransportBind} />}
							</div>}
						</td>

						<td className='col3'>
							<div>
								<HotelMinPrice offers={offers} CurrencyName={CurrencyName} />
							</div>
						</td>
					</tr>
				</tbody>
			</Container>

			{rooms.map((room, key) => <Room key={key} room={room} hotelCode={Code} hotelName={Name} hotelStars={StarsCode} CurrencyName={CurrencyName} />)}

			{offers.length > 1 && 
				<Button white onClick={toggle}>
					{isOpen ? t('Скрыть') : `${t('Показать еще')} ${declines(offers.length - 1, [t('вариант'), t('варианта'), t('вариантов')], true)}`}
					<Sprite icon={isOpen ? 'arrow_up' : 'arrow_down'} />
				</Button>
			}
		</>
	);
};

export default Hotel;