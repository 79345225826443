import React from 'react';
import styled from 'styled-components';
import { Sprite } from 'components';
import { BathLocation, BedType, Square } from 'features/Search';
import { Rate } from './Rate';
import { useHotelSearchStore } from 'stores';

const NoPhoto = styled.div`
	width: 60px;
	height: 60px;
	background-color: #A2D0EA;
	margin-right: 10px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Bit = styled.div`
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: #333;
	margin-left: 10px;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	align-items: center;

	&.orange {
		color: ${props => props.theme.orange};
	}
	
	svg {
		margin-right: 5px;
	}	
`;

export const Room = ({ room, hotel, isOpen = false, isLast = false }) => {
	const {
		onClickRoom,
	} = useHotelSearchStore();

	const {
		Code,
		Url,
		ThUrl,
		RoomName,
		BedTypes: {
			Variant: BedTypes = []
		} = {},
		Bathlocation: bathLoc = false,
		Square: square = false,
		rates,
		RoomCode
	} = room;

	const image = ThUrl ?? Url ?? false;

	return (
		<>
			<tr onClick={onClickRoom.bind(this, Code, hotel.Code, RoomCode, hotel.Name, hotel.Stars)} className='clickable rommName'>
				<td className='roomPhoto'>
					{image ?
						<img src={room.Url.replace('test-images', 'images')} alt='' width='60' height='60' /> :
						<NoPhoto>
							<Sprite icon='no_photo' />
						</NoPhoto>
					}
				</td>

				<td colSpan={3} className='roomName'>
					{RoomName}
				</td>

				<td colSpan={2} className='roomParams'>
					<BathLocation loc={bathLoc} Element={Bit} />

					<BedType BedTypes={BedTypes} Element={Bit} />

					<Square square={square} Element={Bit} />
				</td>
			</tr>

			{rates.slice(0, isOpen ? rates.length : 1).map(i => <Rate rate={i} key={i.Code} hotel={hotel} isLast={isLast} />)}
		</>
	);
};