import { useEffect, useState } from 'react';
import { useInstance } from 'hooks';

export const useCitizenships = () => {
	const [ citizenships, setCitizenships ] = useState([]);
	const { post } = useInstance();

	const fetch = async () => {
		const request = {
			CitizenshipRequest: {
				ActionList: {
					Parameters: {
					}
				}
			}
		};

		const data = await post(request);
		setCitizenships(data.CitizenshipList.Citizenship);
	};

	useEffect(() => {
		fetch();
	}, []);

	return {
		citizenships
	};
};