import { parseDate } from './parseDate';

export const compareDates = (a, b) => {
	a === 'today' && (a = new Date());
	a === 'tomorrow' && (a = new Date().setDate(new Date().getDate() + 1));
	typeof a === 'string' && (a = parseDate(a));

	if (a instanceof Date) {
		a.setHours(0);
		a.setMinutes(0);
		a.setSeconds(0);
		a.setMilliseconds(0);
	} else {
		return false;
	}

	b === 'today' && (b = new Date());
	b === 'tomorrow' && (b = new Date().setDate(new Date().getDate() + 1));
	typeof b === 'string' && (b = parseDate(b));

	if (b instanceof Date) {
		b.setHours(0);
		b.setMinutes(0);
		b.setSeconds(0);
		b.setMilliseconds(0);
	} else {
		return false;
	}

	if (a > b) {
		return 1;
	}

	if (a < b) {
		return -1;
	}

	return 0;
};