import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { Sprite } from 'components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	align-items:center;
	justify-content: center;

	@media (max-width: 659px) {
		flex-wrap: wrap;
	}

	div {
		color: ${props => props.theme.darkBlue};
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
		font-weight: 400;

		&.disabled { color: #4F7EA7; }
		&.current { font-weight: bold; }
		&.comment, &.current { color: #333; }
		&.comment {
			@media (max-width: 659px) {
				margin-top: 10px;
			}
		}

		&:not(.current):not(.disabled):not(.comment) {
			cursor: pointer;
		}

		&:not(:last-child) {
			margin-right: 17px;
		}
	}
`;

export const Paginator2 = ({ totalPages, canPrev, canNext, items, page, from, to, onPrevPage, onNextPage, onSetPage }) => {
	const { t } = useTranslation();

	if (totalPages < 2) {
		return null;
	}

	return (
		<Container>
			<div className={classnames({disabled: !canPrev})} onClick={onPrevPage}>
				<Sprite icon='prev' />
			</div>

			{items.map(i => <div key={i} className={classnames({current: i === page})} onClick={onSetPage.bind(this, i)}>{i}</div>)}

			<div className={classnames({disabled: !canNext})} onClick={onNextPage}>
				<Sprite icon='next' />
			</div>

			<div className='comment'>
				{t('Показаны')} {from === to ? from : `${from}-${to}`}
			</div>
		</Container>
	);
};