import React, { useMemo } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { dayjs, format, parseDate, today } from 'utils/dayjs';

const Container = styled.div`
	width: 40px;
	height: 40px;
	flex-grow: 0;
	flex-shrink: 0;
	/* border: 1px solid ${props => props.theme.lightGray}; */
	border-collapse: collapse;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: ${props => 40 * props.$margin}px;
	font-family: ${props => props.theme.pt};
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: ${props => props.theme.darkBlue};
	cursor: pointer;
	user-select: none !important;

	&.now {
		background-color: #ffff76;
	}

	&.selected {
		background-color: #76baff !important;
		/* color: white; */
	}

	&.current {
		background-color: ${props => props.theme.lightBlue};
		color: white;
	}

	&.disabled {
		background-color: #f0f0f0;
		color: #4F7EA7;
		cursor: default !important;
	}
`;

export const Day = ({
	day,
	month,
	year,
	value,
	minDate,
	maxDate,
	onClick,
	onHover
}) => {
	const margin = useMemo(() => {
		if (day !== 1) return 0;

		const dayOfWeek = dayjs(new Date(year, month, day)).day();
		return dayOfWeek === 0 ? 6 : dayOfWeek - 1;
	}, [day, month, year]);
	const now = useMemo(() => dayjs(new Date(year, month, day)).format(format) === dayjs().format(format), [day, month, year]);
	const current = useMemo(() => {
		if (Array.isArray(value)) {
			return dayjs(new Date(year, month, day)).format(format) === value[0] || dayjs(new Date(year, month, day)).format(format) === value[1];
		}

		return dayjs(new Date(year, month, day)).format(format) === value;
	}, [day, month, year, value]);

	const selected = useMemo(() => {
		if (Array.isArray(value)) {
			const date1 = parseDate(value[0]);
			const date2 = parseDate(value[1]);
			const date = dayjs(new Date(year, month, day));

			if (date1 < date2) {
				return date.isAfter(date1) && date.isBefore(date2);
			}

			return date.isAfter(date2) && date.isBefore(date1);
		}

		return false;
	}, [day, month, year, value]);

	const disabled = useMemo(() => {
		const date = dayjs(new Date(year, month, day));
		const date1 = minDate === 'today' ? today() : parseDate(minDate);
		const date2 = maxDate === 'today' ? today() : parseDate(maxDate);

		if (minDate && maxDate) {
			return date.isBefore(date1) || date.isAfter(date2);
		}

		if (minDate) {
			return date.isBefore(date1);
		}

		if (maxDate) {
			return date.isAfter(date2);
		}

		return false;
	}, [day, month, year, minDate, maxDate]);

	const handleClick = () => {
		if (disabled) return;

		onClick(dayjs(new Date(year, month, day)).format(format));
	};

	const handleHover = () => {
		onHover(dayjs(new Date(year, month, day)).format(format));
	};

	return (
		<Container $margin={margin} className={classnames({ now, selected, current, disabled })} onClick={handleClick} onMouseEnter={handleHover}>
			{day}
		</Container>
	);
};