import { useCallback, useState } from 'react';

export const useIntInput = (initialValue = '') => {
	const [value, setValue] = useState(parseInt(initialValue));

	const onChange = useCallback(e => setValue(parseInt(e?.target?.value ?? e)), []);
	const onSet = useCallback(val => setValue(val), []);
	const onReset = () => setValue(parseInt(initialValue));

	const showReset = value !== parseInt(initialValue);

	return {
		value,
		onChange,
		onReset,
		showReset,
		onSet,
		bind: {
			value,
			onChange,
			onReset,
			showReset,
		}
	};
};