/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import cardMir from 'images/card_mir.png';
import cardVisa from 'images/card_visa.png';
import cardMC from 'images/card_mc.png';
import { NumericFormat } from 'react-number-format';
import { Combobox } from 'forms2';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;

	> * {
		width: calc(50% - 5px) !important;
		height: 40px;
		/* border: 1px solid blue; */
	}

	p {
		display: flex;
		align-items: center;
	}
`;

const Cards = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`;

const DatesCVC = styled.div`
	display: flex;
	gap: 10px;
	justify-content: space-between;
	align-items: center;

	> * {
		flex-shrink: 0;
	}

	label {
		font-weight: 700;
		font-size: 16px;
		line-height: 21px;
		color: ${props => props.theme.darkBlue};
		font-family: ${props => props.theme.pt};
	}

	> input {
		width: 70px !important;
	}

	.select_container__cardMonth, .select_container__cardYear {
		width: 70px !important;
	}
`;

const monthOptions = new Array(12).fill(null).map((i, key) => ({
	value: key < 9 ? `0${key + 1}` : `${key + 1}`,
	label: key < 9 ? `0${key + 1}` : `${key + 1}`,
}));

const currentYear = parseInt(new Date().getFullYear()) - 2000;

const yearOptions = new Array(20).fill(null).map((i, key) => ({
	value: currentYear + key,
	label: currentYear + key,
}));

export const Garant = ({ guarantee, setGuarantee }) => {
	const { t } = useTranslation();
	const [ monthInput, setMonthInput ] = useState('');
	const [ yearInput, setYearInput ] = useState('');

	const {
		Guarantee: {
			Code,
			CreditCard: {
				Number = '123456******1234',
				CVV = '',
				Holder = '',
				ExpiryDate = '/'
			} = {}
		} = {}
	} = guarantee;

	const [month, year] = ExpiryDate.split('/');

	const onChange = (field, value) => setGuarantee(prev => ({
		...prev,
		Guarantee: {
			...prev.Guarantee,
			CreditCard: {
				...prev.Guarantee.CreditCard,
				[field]: value
			}
		}
	}));

	const numberBind = {
		format: '#### #### #### ####',
		mask: '_',
		placeholder: t('Номер карты'),
		value: Number,
		onValueChange: values => onChange('Number', values.value)
	};

	const monthItem = month > 0 ? {
		value: month,
		label: month
	} : null;

	const monthBind = {
		name: 'cardMonth',
		value: monthItem,
		options: monthOptions,
		placeholder: t('ММ'),
		isSearchable: true,
		onChange: item => onChange('ExpiryDate', `${item.value}/${year}`),
		// isDisabled: !globalAllowModify,
		inputValue: monthInput,
		onInputChange: input => setMonthInput(input.replace(/[^0-9]/gi, '').slice(0, 2)),
		noOptionsMessage: () => null
	};

	const yearItem = year > 0 ? {
		value: year,
		label: year
	} : null;

	const yearBind = {
		name: 'cardYear',
		value: yearItem,
		options: yearOptions,
		placeholder: t('ГГ'),
		isSearchable: true,
		onChange: item => onChange('ExpiryDate', `${month}/${item.value}`),
		// isDisabled: !globalAllowModify,
		inputValue: yearInput,
		onInputChange: input => setYearInput(input.replace(/[^0-9]/gi, '').slice(0, 2)),
		noOptionsMessage: () => null
	};

	const cvvBind = {
		format: '###',
		mask: '_',
		placeholder: 'CVV',
		value: CVV,
		onValueChange: values => onChange('CVV', values.value),
	};

	const nameBind = {
		placeholder: t('Имя и фамилия держателя карты'),
		value: Holder,
		onChange: e => onChange('Holder', e.target.value.replace(/[^A-z`' ]/gi, '').toUpperCase()),
	};

	return (
		<>
			<br />

			<h5 className='orange'>{t('Оплата на стойке при размещении. Требуется гарантия кредитной картой')}</h5>

			<Container>
				<NumericFormat {...numberBind} />
				
				<Cards>
					<img src={cardMir} width='63' height='30' />
					<img src={cardVisa} width='63' height='30' />
					<img src={cardMC} width='63' height='30' />
				</Cards>

				<DatesCVC>
					<label>{t('Срок действия')}</label>
					<Combobox {...monthBind} />
					<Combobox {...yearBind} />

					<div className='filler' />

					<label>CVV/CVC</label>
					{/* <input {...cvcBind} /> */}
					<NumericFormat {...cvvBind} />
				</DatesCVC>

				<p>{t('Три цифры с оборотной стороны карты.')}</p>

				<input {...nameBind} />

				<p>{t('Латиницей, как указано на карте.')}</p>
			</Container>
		</>
	);
};