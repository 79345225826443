import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Modal } from 'modals';
import { Sprite } from 'components';
import { useUserStore } from 'stores';
import { useInstance } from 'hooks';

const MainTitle = styled.div`
	font-weight: 500;
	font-size: 34px;
	line-height: 40px;
	color: ${props => props.theme.darkBlue};
	margin-bottom: 10px;
`,

	Label = styled.label`
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;	
	color: ${props => props.theme.darkBlue};
	width: 100%;
	margin-bottom: 10px;
	display: block;
`,

	Input = styled.input`
	height: 40px;
	width: 100%;
	margin-bottom: 10px;
	outline: none;
	border: 1px solid ${props => props.theme.darkBlue};
	padding: 0 10px;
	font-size: 16px;
	line-height: 21px;
	font-family: ${props => props.theme.pt};
	color: ${props => props.theme.darkBlue};
	
	&::placeholder {
		color: #4F7EA7;
	}	
`,

	SaveButton = styled.div`
	cursor: pointer;
	width: 100%;
	margin-top: 10px;
	height: 40px;
	border-radius: 5px;
	background: linear-gradient(180deg, #22AFFE 0%, #0270CB 100%);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	line-height: 19px;
	box-shadow: 0 0 0 #333;
	transition: box-shadow .2s ease-in-out;
	
	&.disabled {
		background: ${props => props.theme.lightGray};
	}
	
	&:not(.disabled):hover {
		box-shadow: 5px 5px 15px #333;
	}
`,

	InlineDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	
	> div {
		flex-grow: 1;
		
		&:first-child {
			margin-right: 10px;
		}
	}
`,

	Error = styled.div`
	color: rgb(170, 31, 0);
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 10px;
`;

const Close = styled.div`
	color: ${props => props.theme.darkBlue};
	position: absolute;
	top: 10px;
	right: 10px;

	@media (min-width: 1280px) {
		display: none !important;
	}
`;

export const Profile = () => {
	const { t } = useTranslation();
	const { post } = useInstance();
	const {
		closeProfile,
		isProfileOpen,
		user
	} = useUserStore();

	const [userData, setUserData] = useState({
		login: user?.UserId ?? '',
		email: user?.Email ?? '',
		fio: user?.UserName ?? '',
		phone: user?.Phone ?? '',
		password: '',
		repassword: ''
	});

	useEffect(() => {
		setUserData({
			login: user?.UserId ?? '',
			email: user?.Email ?? '',
			fio: user?.UserName ?? '',
			phone: user?.Phone ?? '',
			password: '',
			repassword: ''
		});
	}, [user]);

	const handleChangeUserData = e => setUserData(prev => ({...prev, [e.target.name]: e.target.value}));

	const handleSave = async () => {
		const request = {
			UserRequest: {
				Data: {
					Action: 'Amend',
					UserId: userData.login,
					UserName: userData.fio,
					Password: userData.password.trim() >= 6 ? userData.password.trim() : (user?.Password ?? '').trim(),
					Email: userData.email,
					Phone: userData.phone
				}
			}
		};

		await post(request);
	};

	const errors = {
		login: userData.login.trim().length === 0 ? t('Введите логин!') : false,
		email: userData.email.trim().length === 0 ? t('Введите e-mail!') : (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userData.email.trim()) ? false : t('Некорректный адрес электронной почты!')),
		fio: userData.fio.trim().length === 0 ? t('Введите ФИО!') : (/^[A-zА-я\s-]+$/.test(userData.fio.trim()) ? false : t('Некорректные данные в поле "ФИО"!')),
		password: userData.password.trim().length > 0 && userData.password.trim().length < 6 ? t('Пароль короче 6 символов!') : false,
		repassword: userData.password.trim().length >= 6 && userData.password.trim() !== userData.repassword.trim() ? t('Пароли должны совпадать!') : false,
	};

	const errorsCount = Object.keys(errors).map(i => errors[i]).filter(i => i !== false).length;

	return (
		<Modal isVisible={isProfileOpen} onClose={closeProfile} width={560} fixed>
			<Close onClick={closeProfile}>
				<Sprite icon='blue_cross' />
			</Close>

			<MainTitle>{t('Редактировать информацию')}</MainTitle>

			<Label htmlFor='login'>{t('Логин')}</Label>
			<Input value={userData.login} onChange={handleChangeUserData} name='login' id='login' />
			{errors.login && <Error>{errors.login}</Error>}

			<Label htmlFor='email'>E-mail</Label>
			<Input value={userData.email} onChange={handleChangeUserData} name='email' id='email' />
			{errors.email && <Error>{errors.email}</Error>}

			<Label htmlFor='fio'>{t('ФИО')}</Label>
			<Input value={userData.fio} onChange={handleChangeUserData} name='fio' id='fio' />
			{errors.fio && <Error>{errors.fio}</Error>}

			<Label htmlFor='phone'>{t('Телефон')}</Label>
			<Input value={userData.phone} onChange={handleChangeUserData} name='phone' id='phone' />
			{errors.phone && <Error>{errors.phone}</Error>}

			<InlineDiv>
				<div>
					<Label htmlFor='password'>{t('Пароль (от 6 символов)')}</Label>
					<Input type='password' value={userData.password} onChange={handleChangeUserData} name='password' id='password' />
					{errors.password && <Error>{errors.password}</Error>}
				</div>

				<div>
					<Label htmlFor='repassword'>{t('Подтвердите пароль')}</Label>
					<Input type='password' value={userData.repassword} onChange={handleChangeUserData} name='repassword' id='repassword' />
					{errors.repassword && <Error>{errors.repassword}</Error>}
				</div>
			</InlineDiv>

			<SaveButton className={classnames({disabled: errorsCount > 0})} onClick={errorsCount === 0 ? handleSave : undefined}>{t('СОХРАНИТЬ')}</SaveButton>
		</Modal>
	);
};