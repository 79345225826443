import React, { useMemo } from 'react';
import { parseDate } from 'utils/dayjs';
import { useErrorStore } from 'stores';
import { useTranslation } from 'react-i18next';

export const useDatesProps = ({ orderInfo, setOrderInfo }) => {
	const { t } = useTranslation();
	const { setUserError } = useErrorStore();

	const readOnly = orderInfo?.Id > 0;
	const canChangeArrivalDate = orderInfo?.Id <= 0;
	const canChangeDepartureDate = orderInfo?.Id <= 0;

	const datesProps = useMemo(() => ({
		startDate: orderInfo?.ArrivalDate,
		endDate: orderInfo?.DepartureDate,
		readOnly,
		canChangeArrivalDate,
		canChangeDepartureDate,
		onChange: dates => {
			const errors = [];

			let extraMeals = orderInfo.ExtraMeals;

			const extraMealsChanged = extraMeals.filter(i => 
				parseDate(i.StartDate).isBefore(parseDate(dates[0])) ||
				parseDate(i.EndDate).isAfter(parseDate(dates[1]))
			).length;

			if (extraMealsChanged > 0) {
				extraMeals = extraMeals.map(i => {
					parseDate(i.StartDate).isBefore(parseDate(dates[0])) && (i.StartDate = dates[0]);
					parseDate(i.EndDate).isAfter(parseDate(dates[1])) && (i.EndDate = dates[1]);
					return {...i};
				});
			}

			const extraMealsCanceled = extraMeals.filter(i => parseDate(i.StartDate).isAfter(parseDate(i.EndDate))).length;

			extraMeals = extraMeals.filter(i => !parseDate(i.StartDate).isAfter(parseDate(i.EndDate)));

			extraMealsChanged > 0 && errors.push(<div key={0}>{t('Даты предоставления питания должны быть в периоде дат проживания и будут скорректированы автоматически')}.</div>);
			extraMealsCanceled > 0 && errors.push(<div key={1}>{t('Лишнее питание удалено')}.</div>);

			errors.length > 0 && setUserError(errors);

			setOrderInfo(prev => ({
				...prev,
				ArrivalDate: dates[0],
				DepartureDate: dates[1],
				ExtraMeals: extraMeals
			}));
		}
	}), [
		orderInfo?.Id,
		orderInfo?.ArrivalDate,
		orderInfo?.DepartureDate,
		JSON.stringify(orderInfo.ExtraMeals)
	]);

	return {
		datesProps
	};
};