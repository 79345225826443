import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ru from 'react-phone-number-input/locale/ru.json';
import { Link } from 'react-router-dom';
import { useErrorStore, useUserStore } from 'stores';
import { generatePassword } from 'utils';
import { useInstance } from 'hooks';
import PhoneInput from 'react-phone-number-input';
import { Checkbox } from 'forms';

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Register = ({ Container, Actions, setSection }) => {
	const { t } = useTranslation();
	const [ Email, setEmail ] = useState('');
	const [ UserName, setUserName ] = useState('');
	const [ Phone, setPhone ] = useState('');
	const [ personal, setPersonal ] = useState(false);
	const { setUserError } = useErrorStore();
	const { post } = useInstance();
	const { login } = useUserStore();

	const userNameBind = {
		value: UserName,
		type: 'text',
		onChange: e => setUserName(e.target.value),
		placeholder: t('Ваши ФИО'),
	};

	const intPhoneBind = {
		value: Phone,
		onChange: setPhone,
		defaultCountry: 'RU',
		labels: ru,
		withCountryCallingCode: true,
		smartCaret: true,
		international: true,
		countryCallingCodeEditable: false,
	};

	const emailBind = {
		value: Email,
		type: 'text',
		onChange: e => setEmail(e.target.value),
		placeholder: 'E-mail',
	};

	const personalBind = {
		checked: personal,
		onChange: () => setPersonal(prev => !prev),
		label: <>{t('Я даю согласие на')} <Link to='/personal_data_policy' target='_blank'><span>{t('обработку персональных данных')}</span></Link></>,
	};

	const onRegister = async e => {
		e.preventDefault();
		e.stopPropagation();

		if (UserName.trim() < 1) {
			setUserError('Введите Ваши ФИО');
			return;
		}

		if (!re.test(Email.trim())) {
			setUserError('Введите корректный E-mail');
			return;
		}

		if (!personal) {
			setUserError('Необходимо дать согласие на обработку персональных данных');
		}

		const Password = generatePassword();

		const request = {
			UserRequest: {
				Data: {
					Action: 'Create',
					UserId: Email.trim(),
					UserName: UserName.trim(),
					Password: Password.trim(),
					Email: Email.trim(),
					Phone: Phone.trim(),
				}
			}
		};

		const data = await post(request);
		if (data) {
			login({ BuyerId: 'RO1BAND', UserId: Email, Password });
		}
	};

	return (
		<Container onSubmit={onRegister}>
			<h4>{t('Регистрация для частных лиц')}</h4>

			<Actions>
				<div onClick={() => setSection('login')}>{t('Войти')}</div>
				{t('или')} {t('Зарегистрироваться')}
			</Actions>

			<input {...userNameBind} />
			<input {...emailBind} />
			<PhoneInput {...intPhoneBind} />

			<Checkbox {...personalBind} />

			<button type='submit' className='button grad height30'>{t('Зарегистрироваться')}</button>
		</Container>
	);
};

export default Register;