import React from 'react';
import styled from 'styled-components';
import { useMsgStore } from 'stores';
import { Modal2 } from './Modal2';
import iconUser from 'images/icon_error_user.svg';
import { Button } from 'components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;

	h5 {
		text-align: center;
	}
`;
	
const Icon = styled.img`
	height: 30px;
	width: 30px;
	object-fit: contain;
	object-position: center;
`;

const Content = () => {
	const {
		messages,
		clear,
	} = useMsgStore();

	return (
		<Container>
			<Icon src={iconUser} width='30' height='30' />

			{messages.map((i, key) => <h5 className='nomargin' key={key}>{i}</h5>)}

			<Button white onClick={clear}>OK</Button>
		</Container>
	);
};

export const ModalMsg = () => {
	const {
		messages,
		clear,
	} = useMsgStore();

	return (
		<Modal2 isVisible={messages.length > 0} onClose={clear} width={800} containerStyle={{overflow: 'visible'}}>
			{messages.length > 0 && <Content />}
		</Modal2>
	);
};