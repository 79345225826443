import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import pictoCalendar from 'images/picto_calendar.svg';
import { declines } from 'utils/declines';
import Calendar from 'react-calendar';
import { useDevice } from 'hooks';
import { useTranslation } from 'react-i18next';
import { dayjs, format, parseDate, today } from 'utils/dayjs';

const Container = styled.div`
	width: 345px;
	height: 40px;
	border: 1px solid ${props => props.theme.darkBlue};
	position: relative;

	@media (max-width: 659px) {
		height: auto;
	}
`;

const Icon = styled.div`
	display: block;
	width: 20px;
	height: 20px;
	background-image: url(${pictoCalendar});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 9px;
	right: 10px;
	cursor: pointer;
`;

const Label = styled.div`
	margin-left: 10px;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: ${props => props.theme.darkBlue};
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	overflow-x: hidden;
	flex-grow: 1;

	@media (max-width: 659px) {
		flex-direction: column;
		text-align: center;
	}
`;

const CalendarContainer = styled.div`
	position: absolute;
	top: 38px;
	left: -1px;
	border: 1px solid ${props => props.theme.darkBlue};
	/* width: calc(100% + 2px); */
	padding: 0 10px 10px 10px;
	background-color: white;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	display: flex;
	flex-direction: row;
	justify-content: center;
	
	&.isOpen {
		opacity: 1;
		visibility: visible;
	}
`;

export const Dates = ({ startDate = null, endDate = null, onChange = () => null, readOnly = false, canChangeArrivalDate, canChangeDepartureDate }) => {
	const { t } = useTranslation();
	const containerRef = useRef(null);
	const [isOpen, setOpen] = useState(false);
	const { isDesktop, isMobile } = useDevice();

	startDate = parseDate(startDate);
	endDate = parseDate(endDate);

	const listener = e => containerRef && containerRef.current && !containerRef.current.contains(e.target) && setOpen(false);

	useEffect(() => {
		document.body.addEventListener('click', listener, true);
		return () => document.body.removeEventListener('click', listener, true);
	}, []);

	let title = '';

	if (startDate && endDate) {
		const startDateString = `${startDate.format(format)} ${startDate.format('dd')}`;
		const endDateString = `${endDate.format(format)} ${endDate.format('dd')}`;

		const nights = Math.abs(startDate.diff(endDate, 'day', true));

		title = isDesktop
			? `${startDateString} - ${endDateString} • ${nights} ${declines(nights, [t('ночь'), t('ночи'), t('ночей')])}`
			: <>{startDateString} - {endDateString}<br/>{nights} {declines(nights, [t('ночь'), t('ночи'), t('ночей')])}</>;
	}

	const toggleOpen = () => !readOnly && setOpen(!isOpen);

	const bind = {
		value: [
			startDate.toDate(),
			endDate.toDate()
		],
		onChange: dates => {
			setOpen(false);

			const newStartDate = Array.isArray(dates) ? dayjs(dates[0]).format(format) : startDate.format(format);
			const newEndDate = Array.isArray(dates) ? dayjs(dates[1]).format(format) : dayjs(dates).format(format);
			
			onChange([
				newStartDate,
				newEndDate
			]);
		},
		minDate: today().toDate(),
		showDoubleView: !isMobile,
		selectRange: canChangeArrivalDate && canChangeDepartureDate,
	};

	return (
		<Container ref={containerRef}>
			<Label onClick={toggleOpen}>{title}</Label>
			{isDesktop && <Icon onClick={toggleOpen}/>}

			<CalendarContainer className={classnames({isOpen: isOpen})}>
				<Calendar {...bind} />
			</CalendarContainer>
		</Container>
	);
};