import React, { useEffect, lazy } from 'react';
import {
	Footer,
	GlobalStyles,
	Loader,
	Profile,
	Router,
	HeaderNew,
	ToTop,
} from 'components';
import { ModalError, ModalMsg } from 'modals';
import { ThemeProvider } from 'styled-components';
import { theme } from 'constants';
import dayjs from 'dayjs';
import i18n from 'i18next';
import { useMaintainance } from 'hooks';
const Maintainance = lazy(() => import('pages/Maintainance'));

const App = () => {
	const { isWorks, worksDate, worksTimeFrom, worksTimeTo } = useMaintainance();

	useEffect(() => {
		dayjs.locale(i18n.language);
	}, [i18n.language]);

	const clickListener = e => {
		const selection = window.getSelection();
		if (selection?.type === 'Range' || selection.toString().length > 0) {
			e.stopPropagation();
			return false;
		}
	};

	useEffect(() => {
		window.addEventListener('click', clickListener, true);
		return () => window.removeEventListener('click', clickListener, true);
	}, []);

	if (isWorks === false) return null;

	if (isWorks === 1) return <Maintainance worksDate={worksDate} worksTimeFrom={worksTimeFrom} worksTimeTo={worksTimeTo} />;

	return (
		<ThemeProvider theme={theme}>
			<HeaderNew />

			{/* {!isLogged && isDesktop && <div style={{ height: '30px', flex: '0 0 30px' }} />} */}
			
			<Router />

			<Footer />
			
			<Loader />
			<Profile />

			<ModalError />
			<ModalMsg />
			<GlobalStyles />
			<ToTop />
		</ThemeProvider>
	);
};

export default App;
