import { useEffect, useMemo, useState } from 'react';
import { useInstance } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores';

const limit = 10;

export const useServiceList = () => {
	const { t } = useTranslation();
	const { user } = useUserStore();
	const { post } = useInstance();
	const [ items, setItems ] = useState(false);
	const [ total, setTotal ] = useState(0);
	const [ page, setPage ] = useState(1);
	const [ isInit, setInit ] = useState(false);
	const [ sort, setSort ] = useState('Order');
	const { getBlob } = useInstance();

	const request = useMemo(() => ({
		ServiceListRequest: {
			ActionList: {
				Parameters: {
					ArrivalDateFrom: '',
					ArrivalDateTo: '',
					DepartureDateFrom: '',
					DepartureDateTo: '',
					RegistrationDateFrom: '',
					RegistrationDateTo: '',
					Hotel: 0,
					LastName: '',
					LegalEntityCode: '',
					RegistrationUser: {
						User: {
							Code: user?.Id ?? 0,
						},
						Company: {
							Code: user?.AgentCode ?? 0,
							IsAgentCode: 1,
						}
					},
					Limit: {
						Start: limit * (page - 1) + 1,
						End: limit * page
					},
					Sort: sort
				}
			}
		}
	}), [
		page,
		sort,
	]);

	const fetchXls = async () => {
		const request = {
			ServiceListRequest: {
				ActionExcelList: {
					Parameters: {
						ArrivalDateFrom: '',
						ArrivalDateTo: '',
						DepartureDateFrom: '',
						DepartureDateTo: '',
						RegistrationDateFrom: '',
						RegistrationDateTo: '',
						Hotel: 0,
						LastName: '',
						LegalEntityCode: '',
						RegistrationUser: {
							User: {
								Code: user?.Id ?? 0,
							},
							Company: {
								Code: user?.AgentCode ?? 0,
								IsAgentCode: 1,
							}
						},
						Sort: sort
					}
				}
			}	
		};

		getBlob(request, 'ServicesList.xlsx', {error504: t('Отчёт не сформирован. Слишком много строк. Попробуйте уменьшить период.')});
	};

	const sortBind = {
		items: [
			{ value: 'Order', label: t('Номер заказа') },
			{ value: 'ArrivalDate', label: t('Дата заезда') },
		],
		name: 'sortBy',
		styleContainer: {
			height: '30px', 
		},
		value: sort,
		withBorder: true,
		onChange: e => {
			setSort(e.target.value);
			setPage(1);
		}
	};

	const fetch = async (resetPage = true) => {
		const data = await post(request);
		setItems(data?.Services?.Service ?? []);
		resetPage && setPage(1);
		setTotal(parseInt(data?.ActionList?.Parameters?.Limit?.Count ?? 0));
	};

	useEffect(() => {
		return () => {
			setItems([]);
		};
	}, []);

	useEffect(() => {
		isInit ? fetch() : setInit(true);
	}, [sort]);
	
	useEffect(() => {
		isInit ? fetch(false) : setInit(true);
	}, [page]);

	return {
		items,
		limit,
		total,
		fetch,
		fetchXls,
		page,
		setPage,
		sortBind,
	};
};