import React, { useState } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import { Sprite } from 'components';
import { useClickOutside } from 'hooks';
import { useUserStore } from 'stores';

const Container = styled.div`
	/* margin-right: 17px; */
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	height: 25px;
	z-index: 101;

	input {
		position: absolute;
		top: 0 !important;
		left: 0 !important;
		bottom: 0 !important;
		right: 0 !important;
		width: 100% !important;
		height: 100% !important;
		background: none !important;
		border: none !important;
		outline: none !important;
		box-shadow: none !important;
		padding: 0 !important;
		margin: 0 !important;
		color: transparent !important;
		cursor: pointer;


		&:focus + div {
			opacity: 1;
			visibility: visible;
		}
	}

	label {
		cursor: pointer;
		font-size: 16px;
		line-height: 19px;
		color: ${props => props.theme.darkBlue};

		&:hover {
			color: ${props => props.theme.lightBlue};
		}

		& + div {
			border: 1px solid ${props => props.theme.darkBlue};
			position: absolute;
			top: 100%;
			left: -1px;
			right: -1px;
			background: white;
			/* opacity: 0; */
			/* visibility: hidden; */
			font-size: 16px !important;
			line-height: 19px !important;
			color: ${props => props.theme.darkBlue};

			> div {
				padding: 5px 0;
				cursor: pointer;

				&:hover {
					color: ${props => props.theme.lightBlue};
				}
			}
		}
	}

	svg {
		margin: 0 3px;
	}
`;

export const Lang = () => {
	const [ isOpen, setOpen ] = useState(false);
	const { ref } = useClickOutside(() => setOpen(false));
	const { updateUserData } = useUserStore();
	const lang = i18n.language;

	const changeLanguage = lang => {
		i18n.changeLanguage(lang);
		setOpen(false);
		updateUserData();
		window.location.reload();
	};

	const onToggle = () => setOpen(prev => !prev);

	return (
		<Container ref={ref}>
			<label onClick={onToggle}>
				<Sprite icon={`${lang}flag`} />
				{lang === 'ru' ? 'РУС' : 'ENG'}
				<Sprite icon='arrow_down' />
			</label>
			
			{isOpen && <div>
				<div onClick={changeLanguage.bind(this, 'ru')}>
					<Sprite icon='ruflag' />
					РУС
				</div>

				<div onClick={changeLanguage.bind(this, 'en')}>
					<Sprite icon='enflag' />
					ENG
				</div>
			</div>}
		</Container>
	);
};