import { useEffect, useState } from 'react';
import { useInstance } from 'hooks';

export const useHotel = HotelCode => {
	const [ hotel, setHotel ] = useState(false);
	const { post } = useInstance();

	const fetch = async () => {
		const request = {
			HotelRequest: {
				ActionDescription: {
					Parameters: {
						HotelCode,
						UseObjects: 1,
						Padezh: 1,
						BestEasternMember: 1,
						PublishHotelPhone: 1
					}
				}
			}
		};

		const data = await post(request);
		setHotel(data?.Hotel?.[0] ?? false);
	};

	useEffect(() => {
		const func = async () => {
			HotelCode > 0 && fetch();
		};
		func();
	}, [HotelCode]);

	return {
		hotel
	};
};