import { useEffect, useState } from 'react';

export const useDevice = () => {
	const [ width, setWidth ] = useState(() => window.innerWidth);

	const listenWidth = () => setWidth(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', listenWidth);
		return () => window.removeEventListener('resize', listenWidth);
	}, []);

	const isDesktop = width >= 1280;
	const isNotDesktop = width < 1280;
	const isMobile = width < 660;
	const isNotMobile = width >= 660;
	const isTablet = width >= 660 && width < 1280;
	const isNotTablet = width < 660 || width >= 1280;

	return {
		isDesktop,
		isNotDesktop,
		isMobile,
		isNotMobile,
		isTablet,
		isNotTablet
	};
};