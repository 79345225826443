import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { parseDate, format, today, dayjs } from 'utils/dayjs';
import { Sprite } from 'components/Sprite';

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 400px;
`;

const Month = styled.div`
	color: ${props => props.theme.darkBlue};
	font-family: ${props => props.theme.pt};
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	padding: 10px 5px;
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	
	&:not(.disabled):hover {
		background-color: ${props => props.theme.lightGray};
	}

	&:not(.disabled) {
		cursor: pointer;
	}

	&.disabled {
		opacity: .3;
	}
`;

const Title = styled.div`
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	font-family: ${props => props.theme.pt};
	color: #333;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;

	.selectMonth {
		font-family: inherit;
		line-height: inherit;
		font-size: inherit;
		font-weight: inherit;
		color: ${props => props.theme.darkBlue};
		cursor: pointer;
	}
`;

const Button = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	cursor: pointer;
	color: ${props => props.theme.darkBlue};

	&.disabled {
		cursor: default !important;
		color: ${props => props.theme.lightGray};
		opacity: .2;
	}
`;

const PrevMonth = styled(Button)`
	left: 0;
`;

const NextMonth = styled(Button)`
	right: 0;
`;

const MonthSelect = ({ minDate, maxDate, current, onChange, onClickYear }) => {
	const [ currentDate, setCurrentDate ] = useState(parseDate(current));
	
	const months = dayjs.months();

	const min = useMemo(() => {
		if (minDate === 'today') return today();

		if (/^\d{2}\.\d{2}\.\d{4}$/.test(minDate)) return parseDate(minDate);

		return false;
	}, [minDate]);

	const max = useMemo(() => {
		if (maxDate === 'today') return today();

		if (/^\d{2}\.\d{2}\.\d{4}$/.test(maxDate)) return parseDate(maxDate);

		return false;
	}, [maxDate]);

	const items = months.map((i, key) => ({
		label: i,
		disabled: (min && dayjs({ year: currentDate.get('year'), month: key, day: 1 }).isBefore(min, 'month')) || (max && dayjs({ year: currentDate.get('year'), month: key, day: 1 }).isAfter(max, 'month'))
	}));

	const onClick = month => {
		const newMonths = [
			dayjs({ year: currentDate.get('year'), month, day: 1 }).format(format),
			dayjs({ year: currentDate.get('year'), month: month + 1, day: 1 }).format(format),
		];

		onChange(newMonths);
	};

	const disablePrevYear = useMemo(() => {
		if (min) return currentDate.get('year') <= min.get('year');

		return false;
	}, [currentDate.get('year'), min]);

	const disableNextYear = useMemo(() => {
		if (max) return currentDate.get('year') >= max.get('year');

		return false;
	}, [currentDate.get('year'), max]);

	const toPrevYear = () => setCurrentDate(prev => prev.subtract(1, 'year'));

	const toNextYear = () => setCurrentDate(prev => prev.add(1, 'year'));

	return (
		<Container>
			<Title>
				<PrevMonth onClick={disablePrevYear ? null : toPrevYear} className={classnames({ disabled: disablePrevYear })}>
					<Sprite icon='arrow_left' />
				</PrevMonth>

				<div className='selectMonth' onClick={onClickYear}>{currentDate.get('year')}</div>

				<NextMonth onClick={disableNextYear ? null : toNextYear} className={classnames({ disabled: disableNextYear })}>
					<Sprite icon='arrow_right' />
				</NextMonth>
			</Title>

			{items.map((i, key) => 
				<Month key={key} className={classnames({ disabled: i.disabled })} onClick={!i.disabled ? onClick.bind(this, key) : null}>
					{i.label}
				</Month>
			)}
		</Container>
	);
};

export default MonthSelect;