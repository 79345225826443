import { useEffect, useState } from 'react';
import { useInstance } from 'hooks';

export const useFetchHotel = id => {
	const [ hotel, setHotel ] = useState(null);
	const { post } = useInstance();

	const fetchHotel = async () => {
		const request = {
			HotelRequest: {
				ActionDescription: {
					Parameters: {
						HotelCode: id,
						UseObjects: 1,
						Padezh: 1,
						BestEasternMember: 1,
						PublishHotelPhone: 1
					}
				}
			}
		};

		const data = await post(request);
		setHotel(data?.Hotel?.[0] ?? null);
	};

	useEffect(() => {
		fetchHotel();
	}, [id]);

	if (hotel === null) {
		return {
			HotelCode: null,
			HotelName: '',
			Airport: '',
			RailwayStation: '',
			StarsCode: 0,
			PlaceName: '',
			CityName: '',
			Address: '',
			CityCentre: '',
			Description: '',
			Amenity: [],
			HotelStory: '',
			Position: {},
			SpecialRequirement: [],
			morePhotosCount: 0,
			images: [],
			photos: [],
			photosTitles: [],
			subways: [],
		};
	}

	const {
		Code: HotelCode,
		Name: HotelName,
		Airport,
		RailwayStation,
		Stars: {
			Code: StarsCode = 0
		},
		TypeOfPlace: {
			Name: PlaceName
		},
		City: {
			Name: CityName
		},
		Address,
		CityCentre = '',
		Images: {
			Image: photos = []
		},
		Description,
		Amenities: {
			Amenity
		},
		Story: HotelStory,
		Position,
		SpecialRequirementList: {
			SpecialRequirement = []
		},
		ObjType: {
			Name: ObjTypeName
		},
		Objects: {
			Object: HotelObjects
		}
	} = hotel;

	const morePhotosCount = photos.length > 6 ? photos.length - 6 : false;
	const images = photos.slice(1, 6);

	const photosTitles = photos.map(i => {
		const bits = [`${ObjTypeName} ${HotelName}`];
		i.Text && bits.push(`${i.Text}${i.CategoryName ? `. ${i.CategoryName}` : ''}`);
		i.Copyright && bits.push(i.Copyright);
		return bits;
	});
	const subways = HotelObjects.filter(i => i.ObjectType.Code === 800104);

	return {
		HotelCode,
		HotelName,
		Airport,
		RailwayStation,
		StarsCode,
		PlaceName,
		CityName,
		Address,
		CityCentre,
		Description,
		Amenity,
		HotelStory,
		Position,
		SpecialRequirement,
		morePhotosCount,
		images,
		photos,
		photosTitles,
		subways,
		hotel
	};
};