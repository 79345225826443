import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import classnames from 'classnames';
import {
	HotelSubways,
	HotelTitleWithStars,
	HotelTransport,
	Sprite,
} from 'components';
import { Room } from './Room';
import { declines } from 'utils/declines';
import arrowDown from 'images/arrow_down.svg';
import arrowDownWhite from 'images/arrow_down_white.svg';
import noPhoto from 'images/no_photo.svg';
// import { GroupCriterions } from './index';
import { useDevice } from 'hooks';

const HotelContainer = styled.div`
	margin-top: 20px;
	position: relative;
`;

const MoreButton = styled.div`
	width: 100%;
	border: 2px solid ${props => props.theme.darkBlue};
	border-radius: 5px;
	height: 30px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	font-size: 14px;
	line-height: 17px;
	text-transform: uppercase;
	color: ${props => props.theme.darkBlue};
	transition: color .2s ease-in-out, background-color .2s ease-in-out;
	background-color: white;
	cursor: pointer;
	
	&:after {
		content: '';
		display: flex;
		width: 10px;
		height: 6px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-image: url(${arrowDown});
		margin-left: 5px;
	}
	
	&.active:after {
		transform: rotate(180deg);
	}
	
	&:hover {
		color: white;
		background-color: ${props => props.theme.darkBlue};
		
		&:after {
			background-image: url(${arrowDownWhite});
		}
	}
`;

const CompareButton = styled.div`
	img.compare {
		cursor: pointer;
	}

	svg {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		object-fit: cover;
		object-position: center;
		margin-right: 5px;
		cursor: pointer;
	}

	&.isCompare * {
		fill: #00C0FF !important;
	}
`;

const Photo = styled.a`
	width: 180px;
	height: 180px;
	position: relative;
	margin-right: 20px;
	line-height: 0;
	background-color: #A2D0EA;
	display: flex;
	flex: 0 0 180px;

	@media (max-width: 1279px) {
		width: 120px;
		height: 120px;
		flex: 0 0 120px;
		margin-right: 10px;
	}

	&.new:before {
		content: 'Новый';
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 30px;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		text-transform: uppercase;
		background-color: #5BB500;
		z-index: 10;			
	}
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		z-index: 9;
	}
	
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(3, 71, 129, 0.5);
		color: white;
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		height: 30px;
	}
	
	.noPhoto {
		width: 100%;
		height: 100%;
		background-image: url(${noPhoto});
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 20px 20px;
		margin-right: 10px;
		z-index: 9;
	}
`;

const InfoContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
`;

const Info = styled.div`
	h6 {
		font-family: ${props => props.theme.pt};
		font-weight: bold !important;
		margin-bottom: 5px !important;
		line-height: 21px !important;
	}
`;

const Address = styled.div`
	font-weight: bold;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: #333;
	margin-bottom: 5px;
`;

const Buttons = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	&.mobilephone {
		margin-bottom: 10px;
	}

	* {
		font-weight: bold;
	}
	
	.buttonAsLink {
		&:nth-of-type(n + 2) {
			margin-left: 10px;
		}

		> div {
			opacity: 0;
			visibility: hidden;
			transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
		}
		
		&:hover > div {
			opacity: 1;
			visibility: visible;
		}
	}
`;

/*
const GroupInfo = styled.div`
	font-family: ${props => props.theme.pt};
	color: #004685;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 10px;
	position: relative;
	cursor: pointer;

	> div {
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	}

	&:hover > div {
		opacity: 1;
		visibility: visible;
	}
	
	svg {
		margin-right: 5px;
	}
`;
*/

const MobileTransport = styled.div`
	border: 1px solid ${props => props.theme.darkBlue};
	padding: 10px 30px 10px 10px;
	position: absolute;
	z-index: 100;
	background: white;
	/* width: calc(100% - 40px); */
	width: 100%;
	opacity: 0;
	visibility: hidden;

	&.isShow {
		opacity: 1;
		visibility: visible;
	}

	.close {
		color: ${props => props.theme.darkBlue};
		position: absolute;
		top: 10px;
		right: 10px;
	}
`;

export const Hotel = ({
	item = {}, 
	onAddOfferForClient, 
	// getGroupCriterion = () => null, 
	// isDirect = true, 
	toggleShowRooms = () => null, 
	showRooms = [], 
	onClickRoom = () => null, 
	onClickShowOnMap, 
	onShowDetails = () => null, 
	setCompareHotel = () => null, 
	isCompare,
	filtersUrl = '',
	filters = {},
	lastDestination = {}
}) => {
	const { t } = useTranslation();

	const [ isTransport, setTransport ] = useState(false);
	const { isNotDesktop } = useDevice();

	const onOpen = () => setTransport(true);
	const onClose = () => setTransport(false);

	const {
		Code: HotelCode = 0,
		Name: HotelName = '',
		Url: HotelImageUrl = false,
		Address: HotelAddress = '',
		CityCentre = '',
		Airport = '',
		RailwayStation = '',
		Stars: {
			Code: StarsCode = 0
		} = {},
		City: {
			Name: CityName = ''
		} = {},
		Position: {
			Latitude = 0,
			Longitude = 0
		} = {},
		// AvailabilityGroupCriterion = 2,
		// GroupCriterionList,
		HotelOfferList: {
			HotelOfferDetail = []
		} = {},
		Objects: {
			Object: subways = []
		} = {},
		ImagesCount,
		IsNewHotel = 2,
		DistanceToDestination = false,
	} = item;

	const offers = HotelOfferDetail.slice(0, showRooms.includes(HotelCode) ? HotelOfferDetail.length : 1);

	const orderUrl = offer => {
		const url = `/order/${HotelCode}/${offer.Code}/${filtersUrl}/${offer?.NumberOfGuests}/${offer?.NumberOfExtraBedAdult}/${offer?.NumberOfExtraBedChild}/${offer?.NumberOfExtraBedInfant}`;
		return url;
	};

	const variantTitles = [
		t('вариант'),
		t('варианта'),
		t('вариантов')
	];

	// console.log(lastDestination)

	const {
		Code: DestinationCode,
		Name: DestinationName,
		DestinationType: {
			Code: DestinationTypeCode
		} = {},
		TownType: {
			Name: TownTypeName = ''
		} = {},
		ObjType: {
			Name: ObjTypeName = ''
		} = {},
		HotelType: {
			Name: HotelTypeName = ''
		} = {}
	} = lastDestination ?? {};

	const distances = [
		parseInt(HotelCode) !== parseInt(DestinationCode) ? `${DistanceToDestination} км ${DestinationTypeCode  === 2 ? `${t('от центра')} ${TownTypeName?.toLowerCase()}` : `${t('от')} ${DestinationTypeCode === 4 ? ObjTypeName?.toLowerCase() : HotelTypeName?.toLowerCase()}`} ${DestinationName}` : false,
		CityCentre,
	].filter(i => Boolean(i));

	return (
		<HotelContainer>
			<HotelTitleWithStars code={HotelCode} name={HotelName} starsCode={StarsCode} className='desktop' >
				<CompareButton onClick={setCompareHotel.bind(this, HotelCode)}>
					<Sprite icon={isCompare ? 'ok' : 'compare'} />
				</CompareButton>
			</HotelTitleWithStars>

			<InfoContainer>
				<Photo href={`/hotel/${HotelCode}`} target='_blank' className={classnames({'new': IsNewHotel === 1})}>
					{HotelImageUrl ?
						<img src={HotelImageUrl && HotelImageUrl.replace('test-images', 'images')} alt=''/> :
						<div className='noPhoto'/>
					}
					{HotelImageUrl && ImagesCount > 1 && <span>{t('ещё')} {ImagesCount - 1} {t('фото')}</span>}
				</Photo>

				<Info>
					<HotelTitleWithStars code={HotelCode} name={HotelName} starsCode={StarsCode} className='mobile title' />

					{distances && 
						<h6>{distances.join(' • ')}</h6>
					}

					<Address className='notmobilephone'>{CityName}, {HotelAddress}</Address>

					<HotelTransport title={t('Аэропорты')} string={Airport} className='desktop' />
					<HotelTransport title={t('Вокзалы')} string={RailwayStation} className='desktop' />
					<HotelSubways objects={subways} className='desktop' />

					<Buttons className='notmobilephone'>
						{isNotDesktop && <div className='buttonAsLink' onClick={onOpen}>
							<Sprite icon='transport' />
							{t('Транспорт')}
						</div>}

						<div className="buttonAsLink" onClick={onClickShowOnMap.bind(this, {Code: HotelCode, lat: Latitude, lng: Longitude})}>
							<Sprite icon='map' />
							{t('На карте')}
						</div>

						{/* {isDirect && parseInt(AvailabilityGroupCriterion) === 1 && <GroupInfo className='buttonAsLink' onMouseEnter={getGroupCriterion.bind(this, HotelCode)}>
							<Sprite icon='group' />
							{t('Группа')}
							<GroupCriterions items={GroupCriterionList} />
						</GroupInfo>} */}

						<div className='filler' />
					</Buttons>
				</Info>
			</InfoContainer>

			<Address className='mobilephone'>{CityName}, {HotelAddress} • {CityCentre}</Address>

			<Buttons className='mobilephone'>
				<div className='buttonAsLink' onClick={onOpen}>
					<Sprite icon='transport' />
					{t('Транспорт')}
				</div>

				<div className="buttonAsLink" onClick={onClickShowOnMap.bind(this, {Code: HotelCode, lat: Latitude, lng: Longitude})}>
					<Sprite icon='map' />
					{t('На карте')}
				</div>

				{/* {isDirect && parseInt(AvailabilityGroupCriterion) === 1 && <GroupInfo className='buttonAsLink' onMouseEnter={getGroupCriterion.bind(this, HotelCode)}>
							<Sprite icon='group' />
							{t('Группа')}
							<GroupCriterions items={GroupCriterionList} />
						</GroupInfo>} */}

				<div className='filler' />
			</Buttons>

			<MobileTransport className={classnames('mobile', {isShow: isTransport})}>
				<div className='close' onClick={onClose}>
					<Sprite icon='blue_cross' />
				</div>

				<HotelTransport title={t('Аэропорты')} string={Airport} />
				<HotelTransport title={t('Вокзалы')} string={RailwayStation} />
				<HotelSubways objects={subways} />
			</MobileTransport>

			{offers
				.map(i => i.RoomCode)
				.filter((v, i, a) => a.indexOf(v) === i)
				.map(roomCode => {
					const room = offers.find(i => i.RoomCode === roomCode);
					const rates = offers.filter(i => i.RoomCode === roomCode);
					return (
						<Room
							orderUrl={orderUrl}
							hotelId={HotelCode}
							hotelName={HotelName}
							hotelStars={item.Stars}
							room={room}
							rates={rates}
							onClickRoom={onClickRoom}
							key={room.RoomCode}
							onShowDetails={onShowDetails}
							filters={filters}
							onAddOfferForClient={onAddOfferForClient}
						/>
					);

				})
			}

			{HotelOfferDetail.length > 1 && 
				<MoreButton
					onClick={toggleShowRooms.bind(this, HotelCode)}
					className={classnames({'active': showRooms.includes(HotelCode)})}
				>
					{showRooms.includes(HotelCode) ? t('скрыть') : `${t('ещё')} ${HotelOfferDetail.length - 1} ${declines(HotelOfferDetail.length - 1, variantTitles)}`}
				</MoreButton>
			}
		</HotelContainer>
	);
};