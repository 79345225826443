import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import * as poi from 'images/poi';
import { Sprite } from 'components';
import noPhoto from 'images/no_photo.svg';
import starEmpty from 'images/star_empty.svg';
import starFill from 'images/star_fill.svg';
import { toMoney } from 'utils';
import { useHotelSearchStore } from 'stores';
import { Link } from 'react-router-dom';

const ImgCircle = styled.img`
	margin-right: 5px;
	width: 20px;
	height: 20px;
`;

const ImgCircleEmpty = () => <ImgCircle src={starEmpty} alt='' />;
const ImgCircleFill = () => <ImgCircle src={starFill} alt='' />;

const Container = styled.div`
	width: 40px;
	height: 54px;
	background-repeat: no-repeat;
	position: relative;
	border: none !important;
	transform: translate(-50%, -100%);
	border: 3px solid red;

	&.type9500001 { background-image: url(${poi.hotel}); }
	&.type9500001.isVisible { background-image: url(${poi.hotelHighlight}); }
	&.type800001 { background-image: url(${poi.airport}); }
	&.type800104 { background-image: url(${poi.subway}); }
	&.type900001 { background-image: url(${poi.attraction}); }
	&.type1000039 { background-image: url(${poi.railstation}); }
	&.type1000040 { background-image: url(${poi.seaport}); }
	&.type1000041 { background-image: url(${poi.riverport}); }
	&.type1000042 { background-image: url(${poi.citycenter}); }
	
	> div {
		position: absolute;
		left: 40px;
		bottom: 40px;
		z-index: 10;
	}
	
	&:hover {
		z-index: 99999 !important;
	}

	&.isVisible {
		z-index: 9999 !important;
	}

	&:hover > div, & > div.isVisible {
		opacity: 1;
		visibility: visible;
	}
`;

const Bubble = styled.div`
	background: #FFFFFF;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	padding: 10px 20px 10px 10px;
	min-width: 340px !important;
	position: relative;
	cursor: default !important;

	& {
		.close {
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
			color: ${props => props.theme.darkBlue};
		}
	}
`;

const PhotoName = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
`;

const Photo = styled.a`
	width: 80px;
	height: 80px;
	position: relative;
	margin-right: 10px;
	line-height: 0;
	background-color: #4F7EA7;
	display: flex;
	flex: 0 0 80px;

	.new {
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 30px;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		text-transform: uppercase;
		background-color: #5BB500;
		z-index: 10;			
	}
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		z-index: 9;
	}
	
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(3, 71, 129, 0.5);
		color: white;
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		height: 30px;
	}
	
	.noPhoto {
		width: 100%;
		height: 100%;
		background-image: url(${noPhoto});
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 20px 20px;
		margin-right: 10px;
		z-index: 9;
	}
`;

const FromCenter = styled.div`
	font-family: ${props => props.theme.pt};
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: #8D8D8D;
	margin-bottom: 10px;
`;

const Title = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	
	a {
		display: block;
		margin-bottom: 5px;
		margin-right: 20px;
	}
`;

const Price = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: ${props => props.theme.darkBlue};
	margin-bottom: 10px;
`;

export const MapMarker = ({
	isVisible = false,
	handleClearHotel,
	onClick,
	Code = 0,
	IsNewHotel = 2,
	Url = '',
	Name = '',
	StarCode = 0,
	CityCentre = '',
	FromPrice = 0,
	type = 0,
	id = '',
}) => {
	const { t } = useTranslation();
	const {
		params: {
			startDate,
			endDate,
			adults,
			childs,
			ages,
		}
	} = useHotelSearchStore();

	const isHotel = /^3-/.test(id);

	return (
		<Container className={classnames(`type${type}`, { isVisible })} onClick={onClick}>
			{isVisible && <Bubble className='bubble'>
				<div className='close' onClick={handleClearHotel}>
					<Sprite icon='small_cross' />
				</div>

				{isHotel && <>
					<PhotoName>
						<Photo href={`/hotel/${Code}/${startDate}/${endDate}/${adults}/${childs}/${ages.join(',')}`} target='_blank'>
							{IsNewHotel === 1 && <div className='new'>{t('Новый')}</div>}
							{Url
								? <img src={Url.replace('test-images', 'images')} alt=''/>
								: <div className='noPhoto'/>
							}
							{/* {i.Url && <span>ещё {i.ImagesCount - 1} фото</span>} */}
						</Photo>

						<Title>
							<a href={`/hotel/${Code}/${startDate}/${endDate}/${adults}/${childs}/${ages.join(',')}`} target='_blank' rel="noreferrer">{Name}</a>
							<div>
								{StarCode <= 5 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
								{StarCode <= 4 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
								{StarCode <= 3 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
								{StarCode <= 2 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
								{StarCode <= 1 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
							</div>
						</Title>
					</PhotoName>

					<FromCenter>
						{CityCentre}
					</FromCenter>

					<Price>
						{t('от')} {toMoney(FromPrice)} RUB
					</Price>

					<Link className='button grad height30' to={`/hotel/${Code}/${startDate}/${endDate}/${adults}/${childs}/${ages.join(',')}`} target='_blank'>{t('Описание и цены')}</Link>
				</>}

				{!isHotel && <Title>{Name}</Title>}
			</Bubble>}
		</Container>
	);
};