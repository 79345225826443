import React from 'react';
import styled from 'styled-components';
import { default as RSelect } from 'react-select';

const Container = styled.div`
	height: 40px;

	.react-select-container {
		width: 100%;
		height: 100%;

		.react-select__control {
			border: 1px solid ${props => props.theme.darkBlue};
			border-radius: 0;
			box-shadow: none !important;
			min-height: auto;
			height: 100%;

			.react-select__value-container {
				height: 38px;
				position: relative;
				padding-top: 0;
				padding-bottom: 0;
				padding-right: 0;
				height: 100%;

				.react-select__input-container {
					margin: 0;
					padding: 0;

					input {
						box-shadow: none !important;
						color: ${props => props.theme.darkBlue} !important;
					}
				}

				.react-select__placeholder {
					color: #4F7EA7;
					font-size: 16px;
					line-height: 21px;
					font-family: ${props => props.theme.pt};
					margin: 0;
					padding: 0;
					display: flex;
					flex-direction: row;
					align-items: center;
					position: absolute;
					height: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					/*
					top: 0;
					left: 10px;
					right: 0;
					bottom: 0;
					transform: none;
					height: auto;
					*/
				}

				.react-select__input-container {
					margin: 0;
					padding: 0;
					height: 100%;

					.react-select__input {
						/* height: 34px; */
						margin: 0;
						max-height: 100% !important;
						height: auto !important;
						margin: 0;
						padding: 0;

						input {
							font-size: 16px;
							line-height: 21px;
							font-family: ${props => props.theme.pt};
							color: ${props => props.theme.darkBlue};
							height: 34px !important;
							box-sizing: border-box !important;
							outline: none;
							box-shadow: none !important;
						}
					}
				}

				.react-select__single-value {
					font-size: 16px;
					line-height: 21px;
					font-family: ${props => props.theme.pt};
					color: ${props => props.theme.darkBlue};
				}

				div[class^="css"] {
					margin: 0;
					padding: 0;
					position: relative;
					/* height: 34px; */
				}
			}

			.react-select__indicators {
				.react-select__indicator-separator {
					display: none;
				}

				.react-select__indicator {
					padding: 0 5px;
					color: ${props => props.theme.darkBlue};
				}
			}
		}

		.react-select__menu {
			border: 1px solid ${props => props.theme.darkBlue};
			border-radius: 0;
			margin: -1px 0 0 0;
			padding: 0;

			.react-select__menu-list {
				padding: 0;
			}

			.react-select__option {
				font-size: 16px;
				line-height: 21px;
				font-family: ${props => props.theme.pt};
				color: ${props => props.theme.darkBlue};

				&:nth-of-type(n + 2) {
					background-image: linear-gradient(${props => props.theme.lightGray}, ${props => props.theme.lightGray});
					background-repeat: no-repeat;
					background-size: calc(100% - 20px) 1px;
					background-position: left 10px top;
				}

				&.react-select__option--is-focused {
					background-color: ${props => props.theme.lightGray};
					background-image: none;

					& + .react-select__option {
						background-image: none;
					}
				}

				&.react-select__option--is-selected {
					color: white;
					background-color: ${props => props.theme.darkBlue} !important;
					background-image: none;

					& + .react-select__option {
						background-image: none;
					}
				}

			}
		}
	}
`;

export const Combobox = props => {
	const bind = {
		className: 'react-select-container',
		classNamePrefix: 'react-select',
		isSearchable: true,
		...props
	};

	return (
		<Container className={`select_container select_container__${props?.name ?? ''}`}>
			<RSelect {...bind} />
		</Container>
	);
};