import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { toMoney } from 'utils';
import { format, parseDate, today } from 'utils/dayjs';
import { Counter } from 'forms';
import { Sprite } from '../';
import Calendar from 'react-calendar';
import { useClickOutside } from 'hooks';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	border-top: 1px solid ${props => props.theme.lightGray};

	> div {
		flex-grow: 0;
		flex-shrink: 0;
		text-overflow: ellipsis;
		font-weight: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};

		&:not(:first-child) {
			margin-left: 10px;
		}
	}
`;

const NameDiv = styled.div`
	flex-grow: 1 !important;

	&.bold {
		font-weight: bold;
		color: ${props => props.theme.darkBlue};
	}
`;

const PriceDiv = styled.div`
	width: 150px;
`;

const DatesDiv = styled.div`
	width: 300px;
	position: relative;

	&.bold {
		font-weight: bold;
		color: ${props => props.theme.darkBlue};
		cursor: pointer;
	}
`;

const CountDiv = styled.div`
	width: 80px;
	text-align: center;
`;

const TotalDiv = styled.div`
	width: 150px;
	text-align: right;

	&.bold {
		font-weight: bold;
		color: ${props => props.theme.darkBlue};
	}
`;

const DelButton = styled.div`
	cursor: pointer;
	line-height: 0 !important;
	flex-grow: 0;
	flex-shrink: 0;
	color: ${props => props.theme.darkBlue};

	@media (max-width: 659px) {
		position: absolute;
		top: 10px;
		right: 0;
	}
`;

const CalendarDiv = styled.div`
	position: absolute;
	top: calc(100% + 5px);
	right: 0;
	z-index: 1999999;
	background: white;
	border: 1px solid ${props => props.theme.darkBlue};
	box-shadow: 2px 2px 2px rgba(33, 33, 33, .3);
	opacity: 0;
	visibility: hidden;

	&.isVisible {
		opacity: 1;
		visibility: visible;
	}
`;

export const Meal = ({
	Code,
	Id,
	Name,
	Total,
	IncludedInPrice: {
		Code: IncludedInPrice = false
	} = {},
	StartDate,
	EndDate,
	currency,
	QuantityPerRoom,
	readOnly,
	setOrderInfo,
	extraMeals,
}) => {
	const { t } = useTranslation();
	const [ isVisible, setVisible ] = useState(false);
	const { ref } = useClickOutside(() => setVisible(false));

	const extraMeal = useMemo(() => extraMeals?.find(i => i.Code === Code && !parseDate(StartDate).isAfter(parseDate(i.EndDate)) && !parseDate(EndDate).isBefore(parseDate(i.StartDate))), [extraMeals]);

	readOnly = readOnly || !parseDate(StartDate).isAfter(today()) || IncludedInPrice === 1 || (IncludedInPrice === 2 && !extraMeal);

	const onToggle = e => {
		if (!readOnly && ref.current === e.target) {
			setVisible(prev => !prev);
		}
	};

	const onChangeCount = e => {
		if (IncludedInPrice === false) {
			setOrderInfo(prev => {
				const items = prev.ExtraMeals;
				const index = items.findIndex(i => parseInt(i.Id) === parseInt(Id));

				if (index !== -1) {
					items[index].QuantityPerRoom = e;
				}

				return {
					...prev,
					ExtraMeals: [
						...items
					]
				};
			});
		}

		if (IncludedInPrice === 2) {
			if (!extraMeal) return;

			setOrderInfo(prev => {
				const data = {
					Code: extraMeal.Code,
					Id: Math.max.apply(null, [...extraMeals.filter(i => i.Id < 0).map(i => parseInt(i.Id)), -100000]) + 1,
					StartDate: StartDate,
					EndDate: EndDate,
					KindCode: extraMeal.KindCode,
					QuantityPerRoom: e,
				};

				const items = prev.ExtraMeals;
				items.splice(0, 0, data);

				return {
					...prev,
					Meal: 0,
					ExtraMeals: [
						...items
					]
				};
			});
		}
	};

	const onDel = () => {
		setOrderInfo(prev => {
			const items = prev.ExtraMeals;
			const index = items.findIndex(i => parseInt(i.Id) === parseInt(Id));			

			index !== -1 && IncludedInPrice === false && items.splice(index, 1);
			IncludedInPrice === 2 && (prev.Meal = 0);

			return {
				...prev,
				ExtraMeals: [
					...items,
				]
			};
		});
	};

	const counterBind = {
		value: parseInt(QuantityPerRoom),
		min: 1,
		onChange: onChangeCount
	};

	const calendarBind = {
		value: [parseDate(StartDate).toDate(), parseDate(EndDate).toDate()],
		onChange: dates => {
			if (IncludedInPrice === false) {
				setOrderInfo(prev => {
					const items = prev.ExtraMeals;
					const index = items.findIndex(i => parseInt(i.Id) === parseInt(Id));
	
					if (index !== -1) {
						items[index].StartDate = parseDate(dates[0]).format(format);
						items[index].EndDate = parseDate(dates[1]).format(format);
					}
	
					return {
						...prev,
						ExtraMeals: [
							...items
						]
					};
				});
			}
	
			if (IncludedInPrice === 2) {
				if (!extraMeal) return;
	
				setOrderInfo(prev => {
					const data = {
						Code: extraMeal.Code,
						Id: Math.max.apply(null, [...extraMeals.filter(i => i.Id < 0).map(i => parseInt(i.Id)), -100000]) + 1,
						StartDate: parseDate(dates[0]).format(format),
						EndDate: parseDate(dates[1]).format(format),
						KindCode: extraMeal.KindCode,
						QuantityPerRoom,
					};
	
					const items = prev.ExtraMeals;
					items.splice(0, 0, data);
	
					return {
						...prev,
						Meal: 0,
						ExtraMeals: [
							...items
						]
					};
				});
			}

			setVisible(false);
		},
		minDate: parseDate(extraMeal?.StartDate ?? new Date()).toDate(),
		maxDate: parseDate(extraMeal?.EndDate ?? new Date()).toDate(),
		defaultActiveStartDate: parseDate(extraMeal?.StartDate ?? new Date()).toDate(),
		selectRange: true,
		showDoubleView: true,
	};

	return (
		<Container>
			<NameDiv className={classnames({bold: IncludedInPrice !== 1})}>
				{Name}
			</NameDiv>
			
			<PriceDiv>
				{IncludedInPrice !== 1 && `${toMoney(extraMeal?.Price)} ${currency}`}
			</PriceDiv>

			<DatesDiv className={classnames({bold: IncludedInPrice !== 1})} onClick={onToggle} ref={ref}>
				{StartDate} - {EndDate}

				<CalendarDiv className={classnames({isVisible})}>
					<Calendar {...calendarBind} />
				</CalendarDiv>
			</DatesDiv>

			<CountDiv>
				{readOnly
					? QuantityPerRoom
					: <Counter {...counterBind} />
				}
			</CountDiv>

			<TotalDiv className={classnames({bold: IncludedInPrice !== 1})}>
				{IncludedInPrice === 1 ? t('Включено в тариф') : `${toMoney(Total)} ${currency}`}
			</TotalDiv>

			{!readOnly && 
				<DelButton onClick={onDel}>
					<Sprite icon='blue_cross' />
				</DelButton>
			}
		</Container>
	);
};