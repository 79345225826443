import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { dayjs, format } from 'utils/dayjs';
import { toMoney } from 'utils';

const PenaltyList = ({ items, CurrencyName }) => {
	const { t } = useTranslation();

	const isPenalty = useMemo(() => {
		return dayjs(items[0].DeadlineTimeLoc, `${format} hh:mm`).isSameOrBefore(dayjs());
		// return Number(IsCancellationPolicyCode) === 1 && dayjs(DeadlineTimeLoc, `${format} hh:mm`).isSameOrBefore(dayjs());
	}, [items]);

	if (Number(items?.[0]?.CalculationRuleCode) === 5) {
		return (
			<div className='green'>
				{t('Отмена бесплатно')}
			</div>
		);
	}

	if (isPenalty) {
		return (
			<div className='orange'>
				{t('Отмена платно за {{PenaltySize}} {{CurrencyName}}', { PenaltySize: toMoney(items[0].PenaltySize), CurrencyName })}
				{items.length > 1 && <>
					,<br />
					{t('с {{DeadlineTimeLoc}} за {{PenaltySize}} {{CurrencyName}}', { DeadlineTimeLoc: items[1].DeadlineTimeLoc, PenaltySize: toMoney(items[1].PenaltySize), CurrencyName })}
				</>}
			</div>
		);
	}

	return (
		<div className='green'>
			{t('Отмена бесплатно до {{DeadlineTimeLoc}}', { DeadlineTimeLoc: items[0].DeadlineTimeLoc })},
			<br />
			{t('далее — за {{PenaltySize}} {{CurrencyName}}', { PenaltySize: toMoney(items[0].PenaltySize), CurrencyName })} {items.length > 1 && '...'}
		</div>
	);
};

export default PenaltyList;