import React, { useRef } from 'react';
import pictoMoveFilter from 'images/picto_move_filter.svg';
import pictoCloseFilter from 'images/picto_close_filter.svg';
import styled from 'styled-components';
import iconMinus from 'images/icon_minus.svg';
import iconPlus from 'images/icon_plus.svg';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore } from 'stores';

const Inc = styled.div`
	width: 20px;
	height: 20px;
	background: url(${iconPlus}) center/cover no-repeat;
	cursor: pointer;
	opacity: 1;
	transition: opacity .2s ease-in-out;
	margin-left: 10px;
	flex-shrink: 0;

	&.disabled { opacity: .6; }
`;

const Dec = styled.div`
	width: 20px;
	height: 20px;
	background: url(${iconMinus}) center/cover no-repeat;
	cursor: pointer;
	opacity: 1;
	transition: opacity .2s ease-in-out;
	margin-right: 10px;
	flex-shrink: 0;

	&.disabled { opacity: .6; }
`;

const MoveFilter = styled.div`
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-image: url(${pictoMoveFilter});
	cursor: pointer;
	margin-right: 5px;
`;

const CloseFilter = styled.div`
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-image: url(${pictoCloseFilter});
	cursor: pointer;
`;

const DistanceInputContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
	color: ${props => props.theme.darkBlue};
`;

const DistanceInput = styled.input`
	border: 1px solid ${props => props.theme.darkBlue};
	outline: none;
	background-color: white;
	padding: 0 10px;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	height: 40px;
	color: ${props => props.theme.darkBlue};
	width: 60px !important;
	margin-right: 5px;
	text-align: center;
`;

export const ExtDistance = ({ provided }) => {
	const { t } = useTranslation();
	const ref = useRef();
	const {
		params: {
			filters: {
				distance = 0
			}
		},
		setParams
	} = useHotelSearchStore();

	const onChange = distance => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			distance
		}
	}));

	const handleChange = e => {
		const value = e.target.value;

		value.trim() === '' && onChange(0);

		!isNaN(parseInt(value)) && parseInt(value) >= 0 && parseInt(value) <= 200 && onChange(parseInt(value));
	};

	const handleDec = () => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			distance: prev.filters.distance > 0 ? distance - 1 : distance
		}
	}));

	const handleInc = () => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			distance: prev.filters.distance < 200 ? distance + 1 : distance
		}
	}));

	const onClear = () => onChange(0);

	return (
		<div ref={provided.innerRef} {...provided.draggableProps}>
			<div className="title">
				<MoveFilter {...provided.dragHandleProps} />
				{t('Радиус поиска, км')}
				<div className='filler' />
				{distance > 0 && <CloseFilter onClick={onClear} />}
			</div>

			<DistanceInputContainer>
				<Dec onClick={handleDec} />
				<DistanceInput value={distance.toString()} onChange={handleChange} onFocus={() => ref.current.select()} ref={ref} />
				<Inc onClick={handleInc} />
			</DistanceInputContainer>
		</div>
	);
};