import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	padding: 40px;
	color: #333;
	text-align: center;
	/* background-color: #aa1f00; */
	font-size: 16px;
	line-height: 21px;
	font-family: ${props => props.theme.pt};
	border: 5px solid #FF6B00;
	margin-bottom: 20px;

	@media (max-width: 1279px) {
		padding: 20px;

	}
`;

export const Covid19Warning = () => {
	const { t } = useTranslation();

	return (
		<Container className="container">
			{t('Внимание! В связи с мерами по предотвращению распространения COVID-19 просим Вас использовать маски и перчатки, а так же, соблюдать социальную дистанцию. Мы заботимся о Вашем здоровье!')}
		</Container>
	);
};
