import React, { createContext, useContext, useState } from 'react';

const useLoader = () => {
	const [ counter, setCounter ] = useState(0);
	const [ manual, setManual ] = useState(false);

	const addCounter = () => setCounter(prev => prev + 1);
	const subCounter = () => setCounter(prev => prev > 0 ? prev - 1 : 0);
	const startLoading = () => setManual(true);
	const stopLoading = () => setManual(false);

	const isLoading = manual || counter > 0;

	return {
		addCounter,
		subCounter,
		startLoading,
		stopLoading,
		isLoading
	};
};

const LoaderContext = createContext(null);

const LoaderProvider = ({ children }) => {
	const loaderStore = useLoader();

	return (
		<LoaderContext.Provider value={loaderStore}>
			{children}
		</LoaderContext.Provider>
	);
};

const useLoaderStore = () => {
	const context = useContext(LoaderContext);

	if (context === undefined) {
		throw new Error('useLoaderStore must be used within a LoaderProvider');
	}

	return context;
};

export {
	LoaderProvider,
	useLoaderStore,
};