/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useErrorStore, useMsgStore, useUserStore } from 'stores';
import { PaymentMsg, Register } from './Order';
import { useRegister } from 'hooks';
import { Button } from './Button';
import { ModalLoginPrivate } from 'modals';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 10px;
`;

export const SummaryAndSave = ({
	prices = null,
	cancel = null,
	special = null,
	orderId,
	segmentId,
	personsCount,
	saveOrder,
	type = 'hotel',
	errors = [],
	guarantee = {},
	allowModify = true
}) => {
	const { t } = useTranslation();
	const { isLogged, register } = useUserStore();
	const { registerBind } = useRegister();
	const { show } = useMsgStore();
	const [ isOpen, setOpen ] = useState(false);
	const { setUserError } = useErrorStore();

	const {
		Guarantee: {
			Code,
			CreditCard: {
				Number = '',
				CVV = '',
				Holder = '',
				ExpiryDate = '/'
			} = {}
		} = {}
	} = guarantee;

	// console.log({
	// 	Code,
	// 	Number: /^\d{16}$/.test(Number),
	// 	CVV: /^\d{3}$/.test(CVV),
	// 	Holder: Holder.trim().length >= 2,
	// 	ExpiryDate: /^\d{2}\/\d{2}$/.test(ExpiryDate)
	// })

	const onClickSave = async () => {
		if (personsCount === 0) {
			show(t('Должны быть введены данные хотя бы одного гостя.'));
			return;
		}

		if (errors.length > 0) {
			setUserError(errors.map((i, key) => <div key={key}>{i}</div>));
			return;
		}

		if (!isLogged && !registerBind.isValid) {
			show(`${t('Заполните')} ${registerBind.invalidFields.join(', ')}.`);
			show(t('Если Вы уже зарегистрированы, нажмите кнопку "Войти" в верхней строке сайта.'));
			return;
		}

		if (!isLogged && registerBind.isValid) {
			const data = await register({
				UserName: registerBind.userNameBind.value.trim(),
				Email: registerBind.emailBind.value.trim(),
				Phone: registerBind.phoneBind.value.trim(),
				onError: error => {
					if ([8265, 8270].includes(parseInt(error?.Code))) {
						setOpen(true);
					} else {
						setUserError(error.Description);
					}
				},
			});

			if (data) {
				saveOrder();
			}
			return;
		}

		if (isLogged) {
			saveOrder();
		}
	};

	const modalBind = {
		isOpen,
		setOpen,
		saveOrder: () => false,
		emailBind: registerBind.emailBind,
	};

	return (
		<Container>
			{!isLogged && <Register {...registerBind} />}

			<PaymentMsg />

			{prices}

			{allowModify && <Button onClick={onClickSave} gradient>
				{segmentId > 0 ? t('Изменить') : t('Бронировать')}
			</Button>}

			<Button to={orderId > 0 ? `/order/${orderId}` : (type === 'hotel' ? '/' : '/transfers')} white>
				{t('НАЗАД')}
			</Button>

			{cancel}

			{special}

			<ModalLoginPrivate {...modalBind} />			
		</Container>
	);
};