import React, {useEffect} from 'react';
import styled from 'styled-components';
import arrow from 'images/whiteArrowLeft.svg';
import classnames from 'classnames';
import { Modal } from 'modals';
import { useGalleryLogic } from 'hooks';

const PrevButton = styled.div`
	width: 18px;
	height: 30px;
	background: url(${arrow}) center/cover no-repeat;
	cursor: pointer;
	opacity: 1;
	visibility: visible;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	flex-shrink: 0;
	flex-grow: 0;
	user-select: none !important;
	
	&.hidden {
		opacity: 0;
		visibility: hidden;	
	}
`;

const NextButton = styled.div`
	width: 18px;
	height: 30px;
	background: url(${arrow}) center/cover no-repeat;
	cursor: pointer;
	opacity: 1;
	visibility: visible;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	flex-shrink: 0;
	flex-grow: 0;
	transform: rotate(180deg);
	user-select: none !important;
	
	&.hidden {
		opacity: 0;
		visibility: hidden;	
	}
`;

const SlidesContainer = styled.div`
	overflow: hidden;
	position: relative;
	margin: 0 20px;
	height: 100%;
	user-select: none !important;

	* {
		user-select: none !important;
	}
`;

const Slides = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 0;
	flex-shrink: 0;
	height: 100%;
	
	&.transition {
		transition: all .2s ease-in-out;
	}

	${props => new Array(props.$length).fill(true).map((i, index) => `&.index${index} { transform: translate(${-1 * 100 * index}%); }`)}
`;

const Slide = styled.div`
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	
	h6 {
		color: white !important;
	}
	
	img {
		/* max-height: calc(100% - 40px); */
		max-height: calc(100% - 50px) !important;
		height: 50vh;
		object-fit: cover;
		object-position: center;
		margin-bottom: 10px;
	}
`;

export const Gallery = ({ items = [], index = false, setIndex = () => null, titles = [] }) => {
	const { current, transition, onClickNext, onClickPrev, onEsc, onClose } = useGalleryLogic(items.length, index, setIndex);

	useEffect(() => {
		document.addEventListener('keyup', onEsc, false);
		return () => document.removeEventListener('keyup', onEsc, false);
	}, []);

	return (
		<Modal isVisible={index !== false} onClose={onClose} containerStyle={{background: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
			<PrevButton onClick={onClickPrev} className={classnames({ hidden: index === 0 })} />

			<SlidesContainer>
				<Slides $length={items.length} className={classnames(`index${current}`, {'transition': transition})}>
					{items.map((i, key) =>
						<Slide key={key}>
							<img src={i.replace('test-images', 'images')} alt='' />
							{titles?.[key] && <h6>{titles[key]}</h6>}
						</Slide>
					)}
				</Slides>
			</SlidesContainer>

			<NextButton onClick={onClickNext} className={classnames({ hidden: index === items.length - 1 })} />
		</Modal>
	);
};