import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Modal2 } from './Modal2';

const Container = styled.div`
	h5:not(:first-child) {
		margin-top: 20px;
	}

	li {
		margin-left: 30px;
	}
`;

export const ModalTerms = ({ children = <div /> }) => {
	const [ isVisible, setVisible ] = useState(false);

	const onOpen = useCallback(() => setVisible(true), []);
	const onClose = useCallback(() => setVisible(false), []);

	return (
		<>
			{React.cloneElement(children, { onClick: onOpen })}

			<Modal2 isVisible={isVisible} onClose={onClose} width={800} containerStyle={{overflow: 'visible'}}>
				<Container>
					<h3>ПРАВИЛА БРОНИРОВАНИЯ ГОСТИНИЧНЫХ И ИНЫХ УСЛУГ В ООО «АКАДЕМСЕРВИС»</h3>

					<h5>РАЗДЕЛ 1. ОПРЕДЕЛЕНИЯ</h5>

					<p>Для целей настоящих Правил бронирования гостиничных и иных услуг в ООО «АКАДЕМСЕРВИС» (зарегистрировано в едином федеральном реестре туроператоров, за регистрационным номером 000001, серии «РТО», имеет гарантию КБ «ЛОКО-Банк» (АО) (местонахождение и почтовый адрес: 125167, г. Москва, Ленинградский просп., д. 39, стр.80 к/с № 30101810945250000161, БИК 044525161, ИНН 7750003943, Генеральная лицензия на осуществление банковских операций №2707 от 14.09.2015 г.), №
					LB1411183251 от 14.11.2018 года, на сумму 10 000 000 (десять миллионов) рублей, сроком до 09.02.2020 года) используются следующие основные понятия равно применимые в единственном и множественном числе:</p>

					<p>1.1. <b>«Академсервис»</b> - Общество с ограниченной ответственностью «АКАДЕМСЕРВИС» - (Местонахождение: 143405, Россия, Московская обл., Красногорский р-н, г. Красногорск, Ильинское ш., д. 4, пом. I, ком. 9. ОГРН1025002864226, ИНН/КПП 5024053441/502401001, реестровый номер туроператора РТО 000001), действующее, в том числе, в лице сайта www.acase.ru.</p>
					<p>1.2. <b>Заказчик</b> - физическое или юридическое лицо, пользующееся или имеющее намерение воспользоваться Услугами Академсервиса, в том числе через сайт www.acase.ru. Заказчик может быть нерезидентом Российской Федерации и такие отношения будут регулироваться нормами законодательства Российской Федерации.</p>
					<p>1.3. <b>Клиент, сотрудник Заказчика</b> - конечный пользователь гостиничных и иных услуг, забронированных через Академсервис. В некоторых случаях Клиент и Заказчик могут совпадать в одном лице.</p>
					<p>1.4. <b>Стороны/Сторона</b> - Заказчик и/или Академсервис.</p>
					<p>1.5. <b>Договор</b> - письменное соглашение Заказчика и Академсервиса, направленное на установление, изменение или прекращение гражданских прав и обязанностей Сторон, в ходе оказания Услуг. </p>

					<p>Неотъемлемыми частями Договора являются: настоящие Правила бронирования гостиничных и иных услуг в ООО «АКАДЕМСЕРВИС» и, если Стороны заключают, то Соглашение об XML – соединении. Все указанные в настоящем пункте документы, являются обязывающими для Сторон и дополняют, уточняют друг друга, в части не противоречащей друг другу.</p>
					<p>1.6. <b>Услуги</b> - следующие услуги Академсервиса:</p>

					<ul>
						<li>бронирование мест в отелях;</li>
						<li>организация визовой поддержки;</li>
						<li>бронирование транспортных средств с водителем для перевозки клиентов;</li>
						<li>организация экскурсионного обслуживания и сопровождения гидов-переводчиков;</li>
						<li>организация питания;</li>
						<li>бронирование конференц-залов, заказ специального оборудования, услуг переводчиков;</li>
						<li>иные услуги, согласованные Сторонами.</li>
					</ul>
					Заказчик имеет право выбрать любую услугу, указанную выше.<br/><br/>

					<p>1.7. <b>Правила, Правила бронирования</b> - настоящие правила бронирования гостиничных и иных услуг в ООО «АКАДЕМСЕРВИС», размещенные в общем доступе на сайте www. acase.ru или предоставляемые Заказчику по запросу.</p>
					<p>1.8. <b>Система бронирования, Система</b> - компьютерная система бронирования Академсервиса, с помощью которой Заказчик может осуществлять бронирование.</p>
					<p>1.9. <b>Бронирование</b> - непосредственное резервирование гостиничных и иных услуг для их последующего использования Клиентом или сотрудником Заказчика.</p>
					<p>1.10. <b>Запрос на бронирование или Заявка</b> - запрос Заказчика на конкретное Бронирование и/или размещение заказа на получение гостиничных и/или иных услуг, направленный в адрес Академсервиса.</p>
					<p>1.11. <b>Подтверждение бронирования</b> - ответ Академсервиса на Запрос на бронирование в форме уведомления с указанием «ЗАБРОНИРОВАНО».</p>
					<p>1.12. <b>Заказ</b> -&nbsp;услуга или перечень услуг, которым присвоен уникальный идентификационный номер Системы бронирования Академсервиса. Идентификационные номера Запроса и Заказа совпадают.</p>
					<p>1.13. <b>Тариф</b> - цена на гостиничные и другие услуги, предлагаемая Заказчику и размещенная в Системе бронирования Академсервиса или предоставляемая по запросу.</p>
					<p>1.14. <b>Соглашение об XML – соединении</b> - Соглашение Стороно сотрудничестве через XML-соединение компьютерной системы бронирования Академсервиса с компьютерной системой Заказчика в сети Интернет.</p>
					<p>1.15. <b>Территория</b> - страна, где гостиничные и/или иные услуги, оказываются непосредственно. </p>
					<p>1.16. <b>Поставщик</b> - поставщик гостиничных и иных услуг для Клиента, предложения по которым размещаются на&nbsp;сайте www.acase.ru и/или предоставляются по Запросу Заказчика.</p>
					<p>1.17. <b>Пассажир</b> - конечный пользователь услуг по перевозке (трансфер, т.е. перевозка Пассажиров и багажа наземным транспортом).</p>



					<h5>РАЗДЕЛ 2. ОБЩИЕ ПОЛОЖЕНИЯ</h5>

					<p>2.1. Пользование Услугами Академсервиса означает согласие Заказчика со всеми правилами, условиями и примечаниями, размещенными на сайте Академсервиса (www.acase.ru), указанными в Договоре, если заключен Сторонами, и в Соглашении об XML-соединении, если заключен Сторонами.</p>
					<p>2.2. Если Заказчик не согласен с правилами и условиями оказания Услуг, он не будет осуществлять Бронирование, так как Бронирование рассматривается Академсервисом как акцепт Заказчиком оферты, предложенной Академсервисом (статья 434 Гражданского кодекса Российской Федерации). Совершение акцепта производится в добровольном порядке.</p>
					<p>2.3. Академсервис не является собственником отелей, перевозчиком, поэтому Подтверждение бронирования осуществляется на условиях конкретных Поставщиков услуг.</p>
					<p>2.4. Заказчик знает и соглашается, что Академсервис является посредником при Бронировании гостиничных и иных Услуг, и самостоятельно не формирует и не продвигает (не реализует) подобные продукты.</p>
					<p>2.5. При работе в Системе бронирования, Заказчик обязан использовать Руководство пользователя (непосредственно в самой Системе), положения Договора, Правила бронирования и XML-Соглашения. Несоблюдение данного условия может стать причиной неполучения Запроса на бронирование/изменение/аннуляцию и/или отказа в предоставлении Услуг Заказчику.</p>
					<p>2.6. При наличии несоответствия условий настоящих Правил, Договора и Соглашения об XML – соединении непосредственно Подтверждениям бронирования и иным приложениям к Договору, то в любом случае будут являться единственно верными условия Договора – для Заказчиков юридических лиц и настоящих Правил – для Заказчиков физических лиц. Все указанные в настоящем пункте документы, являются обязывающими для Сторон и дополняют, уточняют друг друга, в части не
					противоречащей друг другу.</p>
					<p>2.7. Академсервис оставляет за собой право изменять настоящие Правила без предварительного уведомления Заказчика. Новые Правила вступают в силу с момента их размещения на сайте Академсервиса www.acase.ru.</p>
					<p>2.8. Бронирование Услуг в Системе Академсервиса производится для индивидуальных Клиентов.Система не предназначена для бронирования туристических групп. Все Запросы групповые бронирования должны быть отправлены в Академсервис по электронной почте или факсу. При попытке Заказчика разделить группу на несколько бронирований, Академсервис оставляет за собой право аннулировать такие бронирования, причем штрафные санкции, которые могут возникнуть,
					оплачиваются Заказчиком.</p>
					<p>2.9. Основанием для отказа Академсервиса в принятии Запроса является его ненадлежащее направление, ненадлежащее оформление, то есть отсутствие какой-либо информации, необходимой для исполнения Заказа, или иные объективные обстоятельства, препятствующие исполнению данного Заказа.</p>
					<p>2.10. Все изменения Заказчика по переданным Запросам на бронирование (изменение количества лиц, изменение категории номера отеля и/или сроков, изменения фамилий заезжающих лиц и иные изменения) принимаются Академсервисомв работу как новый Запрос, аннулирующий предшествующий Запрос. Данное положение применимо и к Заказам. Если в следствие изменения Заказа возникают финансовые санкции со стороны отеля, то Заказчик обязан их оплатить.</p>
					<p>2.11. Запрос на бронирование должен быть сделан только для реальных Клиентов. Если окажется, что Бронирование сделано на фиктивные фамилии с целью зарезервировать места в отеле, Академсервис оставляет за собой право аннулировать такой Запрос/Заказ, причем расходы, которые могут возникнуть в связи с этим, возмещаются Заказчиком.</p>
					<p>2.12. В случае указания Заказчиком в Запросе в разделе «Дополнительная информация» сведений, противоречащих основным данным бронирования (другие даты заезда/выезда, иные категории номеров и т.д.), дополнительная информация игнорируется и Запрос направляется в обработку без учета этих сведений.</p>
					<p>2.13. Заказчик соглашается с тем, что описание отелей и набор предоставляемых ими услуг, основаны на информации, полученной Академсервисом от отеля, и может отличаться от реального и/или субъективного мнения Клиента.</p>
					<p>2.14. Отель имеет право при размещении взимать непосредственно с Клиента дополнительную плату или депозит за дополнительные услуги. Размер регистрационного сбора в отношении иностранных граждан-Клиентов отель устанавливает самостоятельно.</p>
					<p>2.15. Академсервис имеет право отменить любой Запрос/Заказ, в случае обнаружения ошибки, сделанной Академсервисом и/или отелем (иным Поставщиком), в отношении запрошенных/заказанных услуг, но без каких-либо денежных компенсаций Заказчику, кроме как денежных сумм, уплаченных в счет стоимости Заказа. Данное положение введено в интересах Заказчика, чтобы оградить Заказчика от риска возможных убытков, вследствие таких ошибок.</p>
					<p>2.16. По письменному заявлению Заказчика при досрочном прекращении пользования заказанными услугами Академсервис может возвратить Заказчику сумму, не превышающую стоимость проживания и питания в отеле за неиспользованное количество дней, считая со дня, следующего за днем, когда обслуживание было прекращено.</p>
					<p>2.17. Бронирование Заказчика в отеле действует до 24:00 часов дня заезда (с учетом местного времени). После этого времени бронирование аннулируется автоматически и Заказчик обязан оплатить через Академсервис все штрафные санкции за забронированное, но неиспользованное обслуживание. Чтобы сохранить Бронированиев отеле необходимо проинформировать Академсервис о позднем заезде при оформлении Запроса или письменно по факсу или электронной почте
					после получения Подтверждения бронирования, но до момента начала оказания услуг непосредственным Поставщиком услуг.</p>
					<p>2.18. Как правило, время заезда в большинстве отелей после 15:00 и время выезда 12:00. Данное время может отличаться в зависимости от отеля. Время размещения и выписки из отеля указываются в уведомлении о состоянии заказа.</p>
					<p>2.19. Заказчик имеет право указать в Запросе особые пожелания, например, «номер для некурящих», «примыкающие» или «смежные» номера, но Академсервис не гарантирует, что такие пожелания будут выполнены.</p>

					<h5>РАЗДЕЛ 3. ПРАВИЛА БРОНИРОВАНИЯ ГОСТИНИЧНЫХ УСЛУГ ДЛЯ ЮРИДИЧЕСКИХ ЛИЦ</h5>

					<p>3.1. Правила бронирования гостиничных услуг для юридических лиц, приведенные в настоящем разделе (в целом или отдельные пункты),</p>
					<p>3.1.1. в случае если они не упомянуты в тексте самого Договора, дополняют условия Договора, в части не противоречащей Договору, и стороны Договора обязаны руководствоваться настоящим разделом, как условиями самого Договора;</p>
					<p>3.1.2. в случае если положения настоящего раздела, противоречат условиям Договора, применяются условия Договора.</p>

					<p>3.2. Порядок бронирования услуг.</p>
					<p>3.2.1. Для пользования Услугами Академсервиса юридическое лицо (Заказчик) обязано предварительно заключить с Академсервисом письменный Договор и, при необходимости, XML-Соглашение. Условия Договора имеют наибольшую юридическую силу по отношению к настоящим Правилам. </p>
					<p>3.2.2. Заказчик направляет Академсервису Запрос на бронирование следующими способами: </p>
					<ul>
						<li>через Систему бронирования Академсервиса в режиме on-line;</li>
						<li>через XML интерфейс;</li>
						<li>путем бронирования на любых других сайтах в сети Интернет и/или компьютерных системах бронирования подобных услуг, подключенных к Системе бронирования Академсервиса;</li>
						<li>иным письменным способом, указанным Академсервисом Заказчику.</li>
					</ul>
					<b>Запросы на бронирование</b>, изменения или аннуляции, сделанные иным способом, чем это указано в настоящих Правилах бронирования, <b>не принимаются</b>.<br/><br/>

					<p>3.2.3. Заказчик подтверждает, что Запрос на бронирование всегда направляет уполномоченное лицо Заказчика, зарегистрированное в системе Академсервиса. Заказчик обязан ознакомить своих уполномоченных лиц с условиями Договора, XML-Соглашения (если подписан Сторонами) и настоящими Правилами перед отправкой Запроса.</p>
					<p>3.2.4. Запросы на бронирование направляются Заказчиком не позднее 2 (двух) рабочих дней до предполагаемой даты начала оказания услуг соответствующим поставщиком (до даты заезда). При внесении Заказчиком на расчетный счет Академсервиса денежной суммы обеспечения обязательств Заказчика по Договору (см. п.3.3.7. настоящих Правил), последний имеет право направлять Запросы на бронирование менее чем за 2 (два) рабочих дня до предполагаемой даты начала
					оказания услуг.</p>
					<p>3.2.5. Академсервис после получения Запроса, в срок до 24 (двадцати четырех) часов (исключая выходные и праздничные дни, установленные законодательством РФ), отвечает Заказчику в форме Уведомления о состоянии заказа, в котором указаны: уникальный идентификационный номер Запроса и существенные условия его выполнения (цена (Тариф), даты начала оказания услуг, правила аннуляции, размер и даты образования штрафных санкций и другие условия) или приведено
					пояснение о невозможности выполнения данного Запроса. Одновременно с Уведомлением Заказчику может быть отправлен счет на оплату. Если Заказчик производил изменения Запроса или Заказа, то единственно верным Уведомлением будет являться то, которое направленно Заказчику последним по времени. </p>
					<p>3.2.6. После получения подтверждения бронирования от прямого Поставщика услуг, Академсервис, если иное не сказано в Договоре, направляет Заказчику Уведомление с указанием «забронировано» (Подтверждение бронирования), а также номер брони, если таковой присваивается. </p>
					<p>3.2.7. Заказчик имеет право изменить или аннулировать ранее направленный Академсервису Запрос или Заказ, используя Систему бронирования, либо путем направления соответствующего письменного уведомления Академсервису. Все изменения или аннуляции Запроса/Заказа считаются принятыми Академсервисом только при условии получения Заказчиком письменного уведомления с указанием принятых изменений или аннуляции. Если Заказчик не получил такого уведомления, то он
					обязан дополнительно уточнить у Академсервиса получение таких изменений или аннуляций, в противном случае, Заказчик несет риск предъявления к нему требований о возмещении фактически понесенных затрат от Академсервиса, который выставит их в регрессном порядке от поставщика забронированных услуг.</p>
					<p>3.2.8. В исключительных случаях Академсервис может произвести замену подтвержденного отеля на отель того же или более высокого класса, в том же городе без изменения стоимости Заказа.</p>
					<p>3.2.9. В соответствии с законодательством Российской Федерации, Заказчик признает и соглашается с использованием Академсервисом персональных данных Клиента(ов), указанных в Запросе, полученных Академсервисом в процессе бронирования Услуг. Срок использования предоставленных персональных данных – бессрочно. Заказчик гарантирует и несет ответственность, что данные, указанные в Запросе, достоверны и используются с согласия лиц, указанных в Запросе.</p>
					<p>3.2.10. Если клиент Заказчика не воспользовался забронированными услугами по собственной инициативе или досрочно прекратил пользование заказанными услугами, Заказчик обязан направить письменное Заявление в Академсервис (форма Заявления размещена на официальном сайте acase.ru) с указанием причины, в течение 20 (двадцати) дней с даты фактического оказания услуг. Под датой фактического оказания услуг Стороны понимают последние сутки обслуживания клиента
					Заказчика(в случае неоказания услуг, предполагаемые последние сутки обслуживания клиента, указанные в Заказе/Запросе). Академсервис по письменному Заявлению Заказчика может возвратить сумму денежных средств за не оказанные услуги либо учесть излишне перечисленные денежные средства в счет будущих бронирований Заказчика. Неполучение Академсервисом Заявления от Заказчика в течение 20 (двадцати) календарных дней с даты фактического оказания услуг означает, что услуги оказаны
					полностью, в сроки и объеме согласно Заказу/Запросу Заказчика.</p>


					<p>3.3. Порядок оплаты услуг.</p>
					<p>3.3.1. Если иное не сказано в Договоре, то порядок расчетов между Сторонами регулируется условиями настоящих Правил.</p>
					<p>3.3.2. Заказчик обязан оплатить полученный от Академсервиса счет на оплату заказанных услуг, на условиях предоплаты в размере 100% (сто процентов) стоимости услуг входящих в Заказ, в течение 3 (трех) банковских дней от даты выставления счета, но не позднее 3 (трех) банковских дней до даты наступления штрафных санкций, если иное не установлено Договором.Датой оплаты считается дата зачисления денежных средств на расчетный счет Академсервиса.</p>
					<p>3.3.3. Счет на оплату может быть выставлен Заказчику по электронной почте, путем его загрузки через Систему бронирования в электронном виде или с помощью иных способов, которые определяет Академсервис.</p>
					<p>3.3.4. Академсервис может выставлять счет(а) одновременно с уведомлением о существенных условиях выполнения Заказа, при этом, если Заказчик производил изменения Запроса/Заказа, то единственно верным счетом будет являться счет, направленный Заказчику последними по времени.</p>
					<p>3.3.5. Оплата счета может производиться безналичным путем, по кредитовой/дебетовой карте, корпоративной кредитной карте или наличными рублями Российской Федерации в кассу Академсервиса. При оплате безналичным путем Заказчик обязан в платежном поручении указать номер Заказа либо, если оплачиваются несколько Заказов по одному договору, то номер такого договора. При наличной оплате уполномоченный представитель Заказчика обязан предъявить в кассу
					Академсервиса доверенность на осуществление такого платежа и номер Запроса/Заказа, присвоенный Академсервисом и указанный в Подтверждении бронирования, и использующийся как идентификатор Запроса/Заказа.</p>
					<p>3.3.6. Заказчик обязан предпринять все меры для того, чтобы убедиться, что денежные средства, оплаченные им Академсервису, были получены последним. Заказчик обязан своевременно проинформировать Академсервис о произведенной оплате счета.В случае если Заказчик не проинформирует Академсервис о произведенной оплате счета до истечения срока для его оплаты, Академсервис имеет право аннулировать Запрос.</p>
					<p>3.3.7. Если Договором предусмотрено, то Академсервис имеет право потребовать от Заказчика, а Заказчик обязан выполнить, перечисление депозитных (обеспечительных) денежных средств (далее по тексту - Депозит) в порядке, сумме и сроки, указанные в Договоре, в счет обеспечения денежных обязательств Заказчика по Договору. </p>

					<p><strong>РАЗДЕЛ 4. ПРАВИЛА БРОНИРОВАНИЯ ГОСТИНИЧНЫХ УСЛУГ ДЛЯ ФИЗИЧЕСКИХ ЛИЦ</strong></p>
					<p>4.1. Порядок бронирования услуг.</p>
					<p>4.4.1.1. Для пользования Системой бронирования Академсервиса физическое лицо (Заказчик), руководствуясь указаниями Системы, обязан пройти регистрацию в Системе.</p>
					<p>4.1.2. В соответствии с законодательством Российской Федерации, Заказчик признает и соглашается с использованием Академсервисом его персональных данных, полученных Академсервисом в процессе Бронирования услуг. Срок использования предоставленных персональных данных – бессрочно. Заказчик гарантирует и несет ответственность, что данные, указанные им, достоверны.</p>
					<p>4.1.3. Заказчик осуществляет Бронирование через Систему бронирования Академсервиса в режиме on-line. Заказчик имеет право направить в Академсервис Запрос на бронирование не посредством Системы бронирования в режиме on-line, а на электронную почту или факс Академсервиса.</p>
					<p>4.1.4. Академсервис отвечает Заказчику в форме письменного уведомления (электронное письмо), в котором указаны: уникальный идентификационный номер Запроса/Заказа и существенные условия выполнения Заказа/Запроса, даты начала оказания услуг, правила аннуляции, порядок оплаты фактически понесенных Академсервисом/Поставщиком услуг расходов (штрафные санкции) и другие условия) или приведено пояснение о невозможности выполнения данного Заказа/Запроса.
					Если Заказчик производил изменения Заказа/Запроса, то единственно верным уведомлением будет являться уведомление, направленное Заказчику последними по времени.</p>
					<p>4.1.5. После оплаты Заказчиком счета на оплату заказанных услуг, Академсервис направляет Заказчику уведомление с указанием «забронировано» (Подтверждение бронирования).</p>
					<p>4.1.6. Услуга считается забронированной после получения Заказчиком уведомления Академсервиса с прямым указанием состояния Заказа «забронировано». Данное уведомление является ваучером на заселение в отель, если это требуется соответствующим Поставщиком услуг.</p>
					<p>4.1.7. Заказчик имеет право, используя Систему бронирования, изменить или аннулировать ранее направленный Академсервису Запрос. Все изменения или аннуляции Запроса/Заказа считаются принятыми Академсервисом только при условии получения Заказчиком письменного уведомления с указанием времени и даты получения Академсервисом таких изменений или аннуляции. Если Заказчик не получил такого уведомления, то он обязан дополнительно уточнить у Академсервиса
					получение таких изменений или аннуляции, в противном случае, Заказчик несет риск возникновения штрафных санкций от Поставщика забронированных услуг.</p>
					<p>4.1.8. Если Заказчик не воспользовался забронированными услугами по собственной инициативе или досрочно прекратил пользование заказанными услугами он обязан направить письменное Заявление в Академсервис (форма Заявления размещена на официальном сайте acase.ru) с указанием причины, в течение 20 (двадцати) дней с даты фактического оказания услуг. Под датой фактического оказания услуг Стороны понимают последние сутки обслуживания Заказчика(в случае
					неоказания услуг, предполагаемые последние сутки обслуживания Заказчика, указанные в Заказе/Запросе). Академсервис по письменному Заявлению Заказчика может возвратить сумму денежных средств за не оказанные услуги либо учесть излишне перечисленные денежные средства в счет будущих бронирований Заказчика. Неполучение Академсервисом Заявления от Заказчика в течение 20 (двадцати) календарных дней с даты фактического оказания услуг означает, что услуги оказаны полностью, в
					сроки и объеме согласно Заказу/Запросу Заказчика.</p>
					<p>4.2. Порядок оплаты услуг.</p>
					<p>4.4.2.1. Для получения Подтверждения бронирования Заказчик обязан оплатить полученный от Академсервиса счет на оплату Услуг, на условиях предоплаты, в размере 100% (сто процентов) от стоимости услуг входящих в Заказ, в срок указанный в таком счете, но не позднее 3 (трех) банковских дней до даты наступления штрафных санкций.</p>
					<p>4.2.2. Запросы/Заказы, по которым не получена оплата, или оплата получена в неполном размере, будут автоматически аннулированы Академсервисом. При оплате счета Заказчик обязан указать идентификационный номер Запроса/Заказа, присвоенный Академсервисом (указан в уведомлении о состоянии заказа).</p>
					<p>4.2.3. Счет на оплату Запроса/Заказа может быть выставлен Заказчику по электронной почте, путем его загрузки через Систему бронирования в электронном виде или с помощью иных способов, которые определяет Академсервис.</p>
					<p>4.2.4. Академсервис выставляет счет(а) одновременно с уведомлением о состоянии заказа(запроса), при этом, если Заказчик производил изменения Запроса/Заказа, то единственно верным счетом будет являться счет, направленный Заказчику последним по времени.</p>
					<p>4.2.5. Способы оплаты, принимаемые Академсервисом: </p>
					<ul>
						<li><b>мгновенная оплата через Интернет банковской картой.</b></li>
					К оплате принимаются карты Visa, Master Card, JCB.
						<br/><br/>
						<img align="MIDDLE" src="https://images.acase.ru/sjas/images/ru/logo/Verified by VISA.png" alt="Verified by VISA" width="113" height="48" border="0" />
						<img align="top" src="https://images.acase.ru/sjas/images/ru/logo/MasterCard SecureCode.png" alt="MasterCard SecureCode" width="119" height="48" border="0" />
						<br/><br/>

					Оплата по банковским картам в сети Интернет производится путем переадресации на сайт системы электронных платежей <a href="http://www.chronopay.com/" target="_blank" rel="noreferrer">Chronopay</a>. В системе <a href="http://www.chronopay.com/" target="_blank" rel="noreferrer">Chronopay</a> безопасность платежей обеспечивается использованием SSL протокола для передачи конфиденциальной информации от клиента на сервер системы <a href="http://www.chronopay.com/" target="_blank" rel="noreferrer">Chronopay</a> для дальнейшей
					обработки. Дальнейшая передача информации осуществляется по закрытым банковским сетям высшей степени защиты. Сбор и обработка полученных конфиденциальных данных клиента (реквизиты карты, регистрационные данные и т.д.) производится в процессинговом центре, а не на сайте Академсервиса (www.acase.ru). Таким образом, Академсервис не может получить персональные и банковские данные клиента, включая информацию о его покупках, сделанных в других магазинах. При совершении платежа
					через систему <a href="http://www.chronopay.com/" target="_blank" rel="noreferrer">Chronopay</a> владелец карточки автоматически получит от <a href="http://www.chronopay.com/" target="_blank" rel="noreferrer">Chronopay</a> по электронной почте уведомление о списании денежной суммы со счета банковской карты.
						<li><b>оплата банковским переводом.</b></li>
					Оплата банковским переводом может быть осуществлена из отделения любого банка. Банковские реквизиты Академсервиса указаны в счете. Датой оплаты считается дата зачисления денежных средств на расчетный счет Академсервиса. Ссылка на номер заказа в платежном поручении обязательна. <br/>
					Банк может взимать комиссию за осуществление перевода средств за счет плательщика, но Академсервис данные средства не возмещает.
						<li><b>оплата наличными в офисе «Академсервиса».</b></li>
					Оплата наличными может быть осуществлена в офисе Академсервиса по адресу: Россия, 115563, г.Москва, ул.Шипиловская, д.28а (с понедельника по пятницу с 09:30 до 18:00). К оплате принимаются только рубли Российской Федерации.
					</ul>
					<p>4.2.6. При совершении платежа с помощью банковской карты, Заказчик обязан использовать банковскую карту, выпущенную на имя Заказчика, Клиент - Клиента. Одновременно с этим Академсервис вправе потребовать предоставления Заказчиком оригиналов документов, удостоверяющих личность. Платеж не принимается, а Заказ аннулируется, при нарушении Заказчиком условий платежа, установленных законодательством Российской Федерации.</p>
					<p>4.2.7. При внесении платежа в кассу Академсервиса Заказчик обязан указать: идентификационный номер Заказа/Запроса, Ф.И.О. (фамилия, имя, отчество) Заказчика, номер мобильного телефона, адрес электронной почты, использующиеся, как идентификаторы Заказчика и Заказа. </p>


					<h5>РАЗДЕЛ 5. ТАРИФЫ И СБОРЫ</h5>

					<p>5.5.1. Стоимость гостиничных и иных услуг определяется в соответствии с ценами, указанными на сайте Академсервиса www.acase.ru на момент Бронирования. Стоимость Услуг, не упомянутых на сайте, предоставляется по Запросу.</p>
					<p>5.2. Стоимость услуг отелей, указанная на стойке приема и размещения, может отличаться от указанной в счете на оплату от Академсервис, но Заказчик самостоятельно на момент Бронирования определяет место бронирования услуги.</p>
					<p>5.3. В Системе бронирования, в зависимости от отеля, могут присутствовать цены на будние и выходные дни, периоды выставок, специальных мероприятий, праздников, спортивных и иных мероприятий и промо-тарифы. Специальные мероприятия, например, религиозные праздники, Новый год и основные спортивные события, могут повлечь более высокие цены в некоторых городах. Они не обязательно являются периодами выставок, но в течение этих периодов, Тарифы
					Академсервиса могут быть близки или даже выше опубликованных цен на отели. При бронировании Заказчик должен обратить внимание на применимый Тариф.</p>
					<p>В некоторых отелях применяются различные тарифы в зависимости от гражданства Клиента. Заказчик несет ответственность за правильность выбора Тарифа в соответствии с гражданством Клиента.</p>
					<p>5.4. Некоторые отели имеют опубликованные плавающие тарифы, которые меняются каждый день. Академсервис не будет платить возмещение или принимать недоплату по причине, того, что тарифы Академсервиса были близки или выше опубликованных тарифов на отели.</p>
					<p>5.5. Система доплат Академсервиса за нестандартный въезд/заезд и/или трансфер, может отличаться от установленной отелем системы доплат и указанной на стойке размещения. Такое отличие обусловлено особенностью договора между Академсервисом и отелем.</p>
					<p>5.6. Большинство отелей требуют кредитную карту во время регистрации для покрытия непредвиденных расходов. При отсутствии кредитной карты, отель может потребовать депозит наличными денежными средствами. Размер депозита отель устанавливает самостоятельно. Все дополнительные оплаты должны быть осуществлены непосредственно в отеле перед отъездом. </p>


					<h5>РАЗДЕЛ 6. ТРАНСФЕР</h5>

					<p>6.1. Стоимость трансфера (перевозка Пассажиров и багажа наземным транспортом) складывается из тарифов перевозчиков, а также налогов, предусмотренных законодательством Российской Федерации.</p>
					<p>6.2. Время подачи трансфера рассчитывается в соответствии с обычными местными условиями движения. Время подачи может меняться в период государственных праздников и иных мероприятий, проходящих в городе. В случае неожиданных аварий/ пробок на дорогах или других обстоятельств, возникающих в ходе трансфера, которые являются результатом пропущенного рейса, поезда или других связующих транспортных средств, Академсервис не будет нести ответственность за
					любые дополнительные расходы, которые Клиенты могут впоследствии понести.</p>
					<p>6.3. Время ожидания Клиента:</p>
					<p>6.3.1. в Москве и Санкт-Петербурге:</p>
					<ul>
						<li>в аэропортах - 2 часа от указанного в Запросе/Заказе времени;</li>
						<li>на вокзалах – 30 минут (легковые автомобили), 1 час (микроавтобусы) от указанного в Запросе/Заказе времени;</li>
						<li>в отелях или по адресу – 30 минут (легковые автомобили), 1 час (микроавтобусы) от указанного в Запросе/Заказе времени. Клиенты обязаны ждать в определенном месте и в определенное время, указанные на ваучере, или в ином документе, выдаваемом Заказчиком Клиенту.</li>
					</ul>
					<p>6.3.2. В регионах:</p>
					<ul>
						<li>в аэропорту на внутренних рейсах – 45 минут от времени прибытия самолета;</li>
						<li>в аэропорту на международных рейсах – 45 минут от времени прибытия самолета;</li>
						<li>на ж/д вокзале – 20 минут от времени прибытия поезда;</li>
						<li>в отелях или по адресу – 20 минут от указанного в Запросе/Заказе времени.</li>
					</ul>
					<p>Клиенты обязаны ждать в определенном месте и в определенное время, указанные на ваучере, или в ином документе, выдаваемом Заказчиком Клиенту.</p>
					<p>Если у Клиента может возникнуть необходимость увеличить время ожидания, то при оформлении Запроса/Заказа необходимо подтвердить оплату за фактическое время ожидания.</p>

					<p>6.4. Если Клиент не явился в определенное в ваучере или в ином документе, выдаваемом Заказчиком Клиенту, место в течение времени, указанного в п. 6.3. настоящих Правил бронирования, то транспортное средство уезжает из места подачи трансфера, а Заказчик обязан оплатить Академсервису фактически понесенные расходы в размере 100% (сто процентов) стоимости услуг.</p>
					<p>6.5. Если Клиенты путешествуют с младенцами или маленькими детьми, они ответственны за привоз соответствующего детского сидения и за его установку. Несоблюдение этого требования может привести к отказу поставщика в предоставлении услуги без возмещения стоимости услуг.</p>
					<p>6.6. При наличии у Клиента крупногабаритного багажа, Заказчик обязан об этом указать в Запросе/Заказе, передаваемом Академсервису. Несоблюдение этого требования может привести к отказу поставщика в предоставлении услуги Клиенту без возмещения стоимости услуг, при этом Заказчик обязан оплатить все штрафы поставщика, выставленные Академсервису.</p>
					<p>6.7. Заказчик несет ответственность за то, что контактная информация Клиентов, например, личный номер мобильного телефона, предоставляемые в&nbsp; Академсервис, являются точными.</p>
					<p>6.8. Для осуществления трансферов после прохождения паспортного и таможенного контроля Клиентов, заказавших данную услугу, встретят с указанной в заказе табличкой. В случае, если Клиент не видит табличку, ему следует позвонить по телефону (номера и схемы расположения стоек в Шереметьево, Домодедово, Внуково и Жуковском указаны на сайте в разделе &laquo;Контакты&raquo;). В случае заказа ВИП - зала в аэропорту по прилету и трансфера из аэропорта, представитель Академсервиса встречает Клиента в ВИП-зале аэропорта.</p>
					<p>6.9. В отелях водитель ожидает Клиентов с оговоренной в Запросе/Заказе табличкой на ресепшн, если в Запросе/Заказе не указано иное место ожидания. Если по правилам отеля запрещено ожидать с табличкой у ресепшен, то водитель ожидает Клиентов у выхода в фойе отеля или у выхода со стороны улицы.</p>
					<p>6.10. На ж/д вокзалах водитель встречает Клиентов с указанной в Запросе/Заказе табличкой у вагона. Если в Запросе/Заказе не указан номера вагона, водитель встречает у головы состава поезда, при условии возможности пройти на перрон. Если нет возможности пройти на перрон водитель ожидает у выхода из закрытой зоны вокзала.</p>
					<p>6.11. Если в Запросе/Заказе не указано другое место ожидания, при подаче трансфера по адресу, водитель ожидает у подъезда в машине, с табличкой на лобовом стекле, указанной в Запросе/Заказе.</p>
					<p>6.12. Если Клиент не смог в назначенное время найти свой трансфер, он обязан незамедлительно позвонить по телефону, указанному в ваучере/уведомлении о подтверждении трансфера дежурному Академсервиса.</p>
					<p>6.13. Во время совершения трансфера не допускается несогласованный заезд на дополнительный адрес.</p>
					<p>Если у Клиента может возникнуть необходимость заезда на дополнительный адрес, то при оформлении Запроса/Заказа необходимо указать данный факт с подтверждением дополнительной оплаты.</p>
					<p>6.14. Оформление Запросов/Заказов на организованную перевозку группы детей (8 и более человек).</p>
					<p>При заказе трансфера для организованной перевозки группы детей необходимо указывать в комментариях к Запросу/Заказу - кто оформляет документы для осуществления перевозки (Клиент или Академсервис).</p>
					<p>6.14.1 В случае, когда документы на оформление организованной перевозки оформляет Клиент, в комментариях необходимо указать дату и время предоставления Академсервисом данных о трансфере и водителе).</p>
					<p>6.14.2 В случае, когда документы на оформление организованной перевозки группы детей оформляет Академсервис, Клиенту необходимо предоставить следующую информацию:</p>
					<p>6.14.2.1 При Заказе 1, 2-х автобусов для направления уведомления в органы ГИБДД (направляется минимум за 4 рабочих дня до даты начала перевозки) сообщить Академсервису:</p>
					<ul>
						<li>дату, время и место подачи автотранспорта;</li>
						<li>маршрут перевозки;</li>
						<li>количество детей;</li>
						<li>количество сопровождающих;</li>
						<li>примерное время окончания перевозки;</li>
					</ul>
					<p>Список пассажиров (минимум за 3 рабочих дня), где указывается:</p>
					<ul>
						<li>Ф.И.О.(полностью);</li>
						<li>число, месяц, год рождения;</li>
						<li>номер телефона одного из родителей;</li>
						<li>список сопровождающих Ф.И.О. (полностью) и номера их телефонов;</li>
					</ul>
					<p>План рассадки детей в автобусе (можно заполнить при посадке в автобус). </p>
					<p>Схема движения-маршрут (минимум за 3 рабочих дня).</p>
					<p>6.14.2.2 При Заказе 3-х автобусов и более для направления заявки на сопровождение в органы ГИБДД (направляется минимум за 12 рабочих дней до даты начала перевозки) сообщить Академсервису:</p>
					<ul>
						<li>дату, время и место подачи автотранспорта;</li>
						<li>маршрут перевозки;</li>
						<li>количество детей;</li>
						<li>количество сопровождающих;</li>
						<li>примерное время окончания перевозки;</li>
					</ul>
					<p>Список пассажиров (минимум за 3 рабочих дня)</p>
					<ul>
						<li>Ф.И.О.(полностью);</li>
						<li>число, месяц, год рождения;</li>
						<li>номер телефона одного из родителей;</li>
						<li>список сопровождающих Ф.И.О. (полностью) и номера их телефонов;</li>
					</ul>
					<p>План рассадки детей в автобусе (можно заполнить при посадке в автобус). </p>

					<h5>РАЗДЕЛ 7. ВИЗОВАЯ ПОДДЕРЖКА</h5>

					<p>7.1. При условии бронирования отеля через Академсервис по запросу Заказчика/Клиента Академсервисом может предоставляться визовая поддержка.</p>
					<p>7.2. Академсервис оставляет за собой право отказать в предоставлении визовой поддержки при наличии обстоятельств, очевидно свидетельствующих о том, что визовая поддержка запрашивается исключительно в целях предоставления Клиенту въезда в страну.</p>
					<p>7.3. Если Академсервис понес расходы, связанные с обеспечением визовой поддержки Заказчику/Клиенту по его запросу, то последний обязан полностью возместить Академсервису такие расходы по письменному требованию Академсервиса.</p>
					<p>В любом случае при аннулировании Заказчиком/Клиентом забронированного номера, а также, если Клиент не воспользовался забронированным номером, бронирование которого сопровождалось визовой поддержкой со стороны Академсервиса по запросу Заказчика/Клиента, последний обязан выплатить в пользу Академсервиса сумму денежных расходов по исполнению визовой поддержки в размере 3&nbsp;000 (трех тысяч) рублей.</p>

					<h5>РАЗДЕЛ 8. ОТВЕТСТВЕННОСТЬ</h5>

					<p>8.1. Дополнительно к ответственности за неисполнение или ненадлежащее исполнение своих обязательств в рамках оказания Услуг, предусмотренной Договором и/или настоящими Правилами, Стороны несут ответственность в соответствии с законодательством Российской Федерации.</p>
					<p>8.2. Заказчик несет ответственность за соблюдение настоящих Правил бронирования, в том числе его Клиентами, а также за нанесение Академсервису материального ущерба и ущерба деловой репутации.</p>
					<p>8.3. Лицо, оформившее и направившее Запрос, представляет интересы всех лиц внесенных в Запрос и несет ответственность перед Академсервисом за правильность сообщенных в Запросе данных, за выполнение всеми указанными в Запросе лицами обязательств, включая обязательства по оплате заказанных услуг и оплате Академсервису его Услуг и фактически понесенных расходов в случаях, предусмотренных настоящими Правилами и Договором. Академсервис не несет
					ответственности за убытки, понесенные Заказчиком или третьими лицами в результате неправильности или неполноты сведений, предоставленных Заказчиком при Бронировании.</p>
					<p>8.4. Внесение изменений или аннулирование Запроса/Заказа после штрафной даты указанной в Подтверждении бронирования, а также неприбытие Клиента Заказчика или его Сотрудника к месту начала оказания Услуг влекут за собой оплату Заказчиком штрафных санкций от поставщика услуг, в размере указанном в Подтверждении бронирования.</p>
					<p>8.5. Если иное не сказано в Уведомлении о состоянии заказа, аннулирование Заказа Заказчиком менее чем за 25 (двадцать пять) часов до момента начала оказания услуг поставщиком (с учетом расчетного часа в отелях и местного времени), а также неприбытие клиентов к месту начала оказания заказанных услуг, влекут за собой выплату Заказчиком в пользу Академсервиса суммы денежных расходов последнего по исполнению Запроса Заказчика, в размере стоимости
					проживания, питания, транспортного обслуживания и других подтвержденных услуг, согласно Заказа, приходящихся на первые сутки обслуживания клиентов (если иное не сказано в уведомлении о состоянии заказа).</p>
					<p>8.6. Если иное не сказано в Уведомлении о состоянии заказа, аннулирование или изменение Заказчиком Заявки, сделанной менее чем за 25 (двадцать пять) часов до заезда клиентов, независимо от наличия Подтверждения бронирования данной Заявки на момент аннуляции, влечет за собой выплату Заказчиком в пользу Академсервиса суммы денежных расходов последнего по исполнению Запроса Заказчика, в размере стоимости проживания, питания, транспортного обслуживания и
					других заказанных Услуг, приходящихся на первые сутки обслуживания (если иное не сказано в уведомлении о состоянии заказа).</p>
					<p>8.7. Заказчик несет полную ответственность за ущерб, причиненный конечному поставщику Услуг лицом(ами), указанным(и) в Запросе/Заказе и пользующимся забронированными Услугами, согласно законодательству Российской Федерации.</p>
					<p>8.8. Академсервис не несёт ответственности за несоответствие исполненного Заказа субъективной оценке Клиента, а также за недостатки или недочеты в работе авиакомпаний, железных дорог, отелей и иных объектов размещения, транспортных, и иных компаний, непосредственно оказывающих соответствующие услуги. Академсервис не несет ответственности за информацию об услугах, полученную Клиентом/Сотрудником Заказчика напрямую от поставщика услуг (отеля,
					авиа-ж/д, автомобильной компании и т.д.).</p>
					<p>8.9. Академсервис не несет ответственность перед Заказчиком и/или его Клиентом за любые запросы/изменения/аннуляции, сделанные Заказчиком или его представителем непосредственно у поставщика Услуг. Все аннуляции и изменения по Заказам, сделанным в Академсервисе, должны быть также сделаны через Академсервис.</p>
					<p>8.10. Заказчик несет ответственность за правильность оформления Запроса на бронирование, выбора тарифа в соответствии с гражданством клиента и принимает на себя все негативные последствия неточного, неполного заполнения Запроса.</p>
					<p>8.11. Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение своих обязательств если докажут, что это было вызвано возникновением обстоятельств непреодолимой силы (войны, стихийных бедствий, пожара, тайфуна, урагана, массовых заболеваний, изменение визового режима, решений органов государственной власти и управления, а также других обстоятельств, не зависящих от воли сторон и не поддающихся их контролю). При наступлении
					таких обстоятельств каждая из сторон обязана известить об этом другую сторону в максимально короткий срок.</p>

					<h5>РАЗДЕЛ 9. ПОРЯДОК РАССМОТРЕНИЯ ПРЕТЕНЗИЙ И РАЗРЕШЕНИЯ СПОРОВ</h5>

					<p>9.1. В случае возникновения претензий со стороны Заказчика в процессе оказания забронированных Услуг, Заказчик (представитель Заказчика, конечный пользователь заказанных услуг) должен письменно оформить претензию и заверить её у представителя отеля или иного соответствующего поставщика услуг, качество которых не удовлетворяет, о невозможности удовлетворения претензии на месте. Если причину вызвавшую претензию, на месте устранить не удалось, то
					Заказчик должен обратиться в письменной форме к Академсервису с приложением претензии клиента и документов, подтверждающих некачественное оказание услуг, в течение 20 (двадцати) дней с даты прекращения пользования заказанными услугами.</p>
					<p>9.2. Если Заказчик не передал претензию в Академсервис, в течение срока, указанного в п.9.1. настоящих Правил, то такая претензия не подлежит рассмотрению Академсервисом.</p>
					<p>9.3. В течение 10 (десяти) рабочих дней Академсервис либо возмещает убытки, либо предоставляет письменный обоснованный отказ. В любом случае, размер убытков, подлежащих возмещению Академсервисом, не может превышать 100% (сто процентов) стоимости Услуг указанных в Заказе.</p>
					<p>9.4. При неудовлетворении своих претензий в досудебном порядке, Сторона вправе обратится за разрешением спора в суд по месту нахождения Истца.</p>

					<h5>РАЗДЕЛ 10. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h5>

					<p>10.1. Любая информация, указанная на сайте Академсервиса www.acase.ru, в том числе данные, специальные предложения, графика, фотографии, рисунки, логотипы, значки, изображения, программное обеспечение, аудио- и видеоматериалы, товарные знаки, слоганы и прочие материалы, авторские и другие права интеллектуальной собственности, охраняемые законом принадлежат Академсервису, его аффилированным лицам, партнерам, представителям и другим, связанным с ними,
					сторонам, действующим от имени Академсервиса. Академсервис не гарантирует качество информации, полученной от третьих лиц.</p>
					<p>10.2. Академсервис размещает на сайте ссылки на другие сайты, но Академсервис не несет ответственность за содержимое этих сайтов. Наличие на сайте Академсервиса ссылки, обеспечивающей переход на другой сайт, не означает и не подразумевает, что Академсервис одобряет или рекомендует содержимое сайта, не принадлежащего Академсервису.</p>
				</Container>
			</Modal2>
		</>
	);
};