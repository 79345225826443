import React, { useRef } from 'react';
import pictoMoveFilter from 'images/picto_move_filter.svg';
import pictoCloseFilter from 'images/picto_close_filter.svg';
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore } from 'stores';

const MoveFilter = styled.div`
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-image: url(${pictoMoveFilter});
	cursor: pointer;
	margin-right: 5px;
`;

const CloseFilter = styled.div`
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-image: url(${pictoCloseFilter});
	cursor: pointer;
`;

export const ExtPrice = ({ provided }) => {
	const { t } = useTranslation();
	const minPriceRef = useRef();
	const maxPriceRef = useRef();
	const {
		params: {
			filters: {
				priceFrom = 0,
				priceTo = 0
			}
		},
		setParams
	} = useHotelSearchStore();

	const setPriceFrom = e => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			priceFrom: parseFloat(e.floatValue)
		}
	}));

	const setPriceTo = e => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			priceTo: e.floatValue
		}
	}));
	
	const onClear = () => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			priceFrom: 0,
			priceTo: 0
		}
	}));

	return (
		<div ref={provided.innerRef} {...provided.draggableProps}>
			<div className="title">
				<MoveFilter {...provided.dragHandleProps} />
				{t('Стоимость')} RUB
				<div className='filler' />
				{(priceFrom > 0 || priceTo > 0) && <CloseFilter onClick={onClear} />}
			</div>

			<div className="fromTo">
				<NumericFormat 
					value={priceFrom} 
					onValueChange={setPriceFrom} 
					thousandSeparator=' '
					allowLeadingZeros={false}
					onFocus={() => minPriceRef.current.select()}
					allowNegative={false}
					getInputRef={minPriceRef}
				/>
				-
				<NumericFormat 
					value={priceTo}
					onValueChange={setPriceTo}
					thousandSeparator=' '
					allowLeadingZeros={false}
					getInputRef={maxPriceRef}
					onFocus={() => maxPriceRef.current.select()}
				/>
			</div>
		</div>
	);
};