import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Modal2 } from './Modal2';
import { Button, Sprite } from 'components';
import { useMsgStore, useUserStore } from 'stores';
import { useInstance } from 'hooks';
import { useTranslation } from 'react-i18next';

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	.checkbox {
		margin-bottom: 0 !important;
	}

	p {
		color: ${props => props.theme.orange} !important;
	}

	form {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
`;

const Switch = styled.div`
	color: #333;
	font-family: ${props => props.theme.pt};
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	gap: 5px;
	display: inline;

	div {
		display: inline;
		font-size: inherit;
		font-family: inherit;
		line-height: inherit;
		font-weight: 700;
		color: ${props => props.theme.darkBlue};
		cursor: pointer;
	}
`;

const PasswordContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	border: 1px solid ${props => props.theme.darkBlue};

	input {
		border: none !important;
		height: 38px !important;
		box-shadow: none !important;
	}

	div {
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
`;

const Info = styled.div`
	color: #333 !important;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	font-family: ${props => props.theme.pt};
`;

// const ForgetPassword = styled.div`
// 	margin-bottom: 10px;
// 	font-family: ${props => props.theme.pt};
// 	font-weight: 700;
// 	font-size: 16px;
// 	line-height: 21px;
// 	color: ${props => props.theme.darkBlue};
// 	cursor: pointer;
// `;

export const ModalLoginPrivate = ({
	isOpen,
	setOpen,
	emailBind,
	// saveOrder
}) => {
	const { t } = useTranslation();
	const { login } = useUserStore();
	const { post } = useInstance();
	const { show } = useMsgStore();
	
	const [ section, setSection ] = useState('login');

	// const [ UserId, setUserId ] = useState(emailBind.value);
	const [ Password, setPassword ] = useState('');
	const [ visiblePass, setVisiblePass ] = useState(false);

	useEffect(() => {
		setPassword('');
		setVisiblePass(false);
	}, [section]);

	useEffect(() => {
		isOpen && setSection('login');
	}, [isOpen]);

	const onClose = () => setOpen(false);
	const switchLogin = () => setSection('login');
	const switchForget = () => setSection('forget');
	const toggleVisiblePass = () => setVisiblePass(prev => !prev);

	const userBind = {
		value: emailBind?.value ?? '',
		onChange: emailBind?.onChange ?? (() => false),
		placeholder: 'E-mail'
	};

	const passwordBind = {
		value: Password,
		onChange: e => setPassword(e.target.value),
		onBlur: e => setPassword(e.target.value.trim()),
		placeholder: t('Пароль'),
		type: visiblePass ? 'text' : 'password',
	};

	const allowLogin = useMemo(() => {
		if (emailBind.value.trim().length < 2) return false;
		if (Password.trim().length < 1) return false;

		return true;
	}, [section, emailBind.value, Password]);

	const allowForget = useMemo(() => {
		if (!emailBind.value.trim().toLowerCase().match(re)) return false;
		return true;
	}, [emailBind.value]);

	const onLogin = async e => {
		e.preventDefault();

		if (allowLogin) {
			const data = await login({ UserId: emailBind.value, Password });
			if (data) {
				// saveOrder();
				setOpen(false);
			}
		}
	};

	const onForget = async e => {
		e.preventDefault();

		if (!allowForget) return;

		const request = {
			UserRequest: {
				Data: {
					Action: 'SendPassword',
					Email: emailBind.value.trim(),
				}
			}
		};

		const data = await post(request);
		if (data) {
			show(t('Пароль отправлен Вам на электронную почту.'));
			setSection('login');
		}
	};

	return (
		<Modal2 isVisible={isOpen} onClose={onClose} width={620}>
			{isOpen &&
				<Container>
					<h1 className='margin10'>{t('Личный кабинет')}</h1>

					<Switch>
						{t('E-mail уже использован')}.<br />
						{section === 'login' && <>{t('Войдите, используя Ваши e-mail и пароль или воспользуйтесь функцией')} <div onClick={switchForget}>{t('восстановления пароля')}</div>.</>}
						{section === 'forget' && <><div onClick={switchLogin}>{t('Войдите')}</div>, {t('используя Ваши e-mail и пароль или воспользуйтесь функцией восстановления пароля')}.</>}
					</Switch>

					{section === 'forget' &&
						<form onSubmit={onForget}>
							<Info>{t('Пожалуйста, укажите адрес электронной почты, мы отправим на него ссылку для восстановления пароля')}:</Info>
							<input {...userBind} autoComplete="new-password" />
							<Button type='submit' gradient={allowForget} disabled={!allowForget}>
								{t('Отправить')}
							</Button>
						</form>
					}

					{section === 'login' &&
						<form onSubmit={onLogin}>
							<input {...userBind} autoComplete="new-password" />
							<PasswordContainer>
								<input {...passwordBind} autoComplete="new-password" />
								<div onClick={toggleVisiblePass}>
									<Sprite icon={visiblePass === false ? 'hidden_pass' : 'visible_pass'} />
								</div>
							</PasswordContainer>

							<Button type='submit' gradient={allowLogin} disabled={!allowLogin} onClick={() => false}>
								{t('Войти')}
							</Button>
						</form>
					}
				</Container>
			}
		</Modal2>
	);
};