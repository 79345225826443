import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import noPhoto from 'images/no_photo.svg';
import starEmpty from 'images/star_empty.svg';
import starFill from 'images/star_fill.svg';
import GoogleMapReact from 'google-map-react';
import { toMoney } from 'utils';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore } from 'stores';
import i18n from 'i18next';
import { mapKey } from 'constants';
import { useEffectExceptOnMount, useRequest } from 'hooks';
import { MapMarker } from './MapMarker';
import MapFilters from './MapFilters';

const ImgCircle = styled.img`
	margin-right: 5px;
	width: 20px;
	height: 20px;
`;

const ImgCircleEmpty = () => <ImgCircle src={starEmpty} alt='' />;
const ImgCircleFill = () => <ImgCircle src={starFill} alt='' />;

const Container = styled.div`
	height: calc(100vh - 250px);
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
	/* position: sticky; */
	top: 0;
	margin-top: 10px;
	
	@media (max-width: 1279px) {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		height: auto;
		z-index: 1999999;
	}
`;

const HotelsList = styled.div`
	overflow-y: auto !important;
	overflow-x: hidden;
	width: 300px;
	padding-right: 10px;
	margin-right: 10px;

	.button {
		height: 30px;
	}

	@media (max-width: 1279px) {
		display: none;
	}
`;

const Hotel = styled.div`
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
`;

const PhotoName = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
`;

const Photo = styled.a`
	width: 120px;
	height: 120px;
	position: relative;
	margin-right: 10px;
	line-height: 0;
	background-color: #4F7EA7;
	display: flex;
	flex: 0 0 120px;

	.new {
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 30px;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		text-transform: uppercase;
		background-color: #5BB500;
		z-index: 10;			
	}
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		z-index: 9;
	}
	
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(3, 71, 129, 0.5);
		color: white;
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		height: 30px;
	}
	
	.noPhoto {
		width: 100%;
		height: 100%;
		background-image: url(${noPhoto});
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 20px 20px;
		margin-right: 10px;
		z-index: 9;
	}
`;

const Name = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	
	a {
		display: block;
		margin-bottom: 10px;
		margin-right: 20px;
	}
`;

const FromCenter = styled.div`
	font-family: ${props => props.theme.pt};
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: #8D8D8D;
	margin-bottom: 10px;
`;

const FromPrice = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: ${props => props.theme.darkBlue};
	margin-bottom: 10px;
`;

const MapDiv = styled.div`
	border: 1px solid ${props => props.theme.darkBlue};
	flex-grow: 1;
	position: relative;
`;

const MobileCloseButton = styled.div`
	display: none;
	position: fixed;
	width: 230px;

	@media (min-width: 1280px) {
		display: none !important;
	}

	@media (max-width: 1279px) {
		display: flex;
		z-index: 2000000;
		top: 20px;
		left: 20px;
	}

	@media (max-width: 659px) {
		top: 10px;
		left: 20px;
		right: 20px;
		width: auto;
	}
`;

const stars = [
	'',
	'https://images.acase.ru/signs_images/Level 5 grey.svg',
	'https://images.acase.ru/signs_images/Level 4 grey.svg',
	'https://images.acase.ru/signs_images/Level 3 grey.svg',
	'https://images.acase.ru/signs_images/Level 2 grey.svg',
	'https://images.acase.ru/signs_images/Level 1 grey.svg',
];

const getIconUri = (destinationTypeCode, objectTypeCode) => {
	// const icon = destinationTypeCode + "-" + objectTypeCode + (destinationTypeCode === 3 && objectTypeCode === 9500001 ? (code === this.hotel.Code ? '-p' : '-b') : '');
	const icon = destinationTypeCode + "-" + objectTypeCode + (destinationTypeCode === 3 && objectTypeCode === 9500001 ? '-b' : '');
	return "https://images.acase.ru/gmapIcons/" + icon + ".png";
};

export const MapContainer = () => {
	const { t } = useTranslation();
	const mapContainerRef = useRef(null);
	const mapRef = useRef(null);
	const [ filters, setFilters ] = useState(['3-9500001']);
	const itemRef = useRef();
	const locale = i18n?.language ?? 'ru';
	const [ coords, setCoords ] = useState();

	const {
		params: {
			startDate,
			endDate,
			adults,
			childs,
			ages,
		}
	} = useHotelSearchStore();

	const { data } = useRequest(
		{
			MapRequest: {
				Language: "ru",
				ActionDestinationSearch: {
					Parameters: {
						Zoom: coords?.zoom,
						Bounds: {
							NorthEast: {
								Lat: coords?.bounds?.ne?.lat,
								Lng: coords?.bounds?.ne?.lng
							},
							SouthWest: {
								Lat: coords?.bounds?.sw?.lat,
								Lng: coords?.bounds?.sw?.lng
							}
						},
						Context: '004'
					}
				}
			}
		},
		{
			manual: !(coords?.bounds?.ne?.lat > 0),
			parser: data => {
				let markers = [];

				data.DestinationGroupList.DestinationGroup.forEach(g => {
					g.DestinationList.Destination.forEach(i => {
						const hotel = g.DestinationTypeCode === 3 && g.ObjectTypeCode === 9500001 ? hotels.find(h => h.Code === i.Code) : null;

						if (hotel) {
							markers.push({
								...i,
								id: `${g.DestinationTypeCode}-${g.ObjectTypeCode}`,
								icon: getIconUri(g.DestinationTypeCode, g.ObjectTypeCode),
								stars: g.DestinationTypeCode === 3 && g.ObjectTypeCode === 9500001 ? stars[i.StarCode] : false,
								type: g.ObjectTypeCode,
								Url: hotel?.Url ?? false,
								Name: hotel?.Name ?? false,
								Stars: hotel?.Stars?.Code ?? false,
								CityCentre: hotel?.CityCentre ?? false,
								FromPrice: hotel ? Math.min.apply(null, [].concat.apply([], hotel.HotelOfferList.HotelOfferDetail.map(i => parseInt(i.TotalPrice)))) : false,
							});
						}

						if (g.DestinationTypeCode !== 3 && g.ObjectTypeCode !== 9500001) {
							markers.push({
								...i,
								id: `${g.DestinationTypeCode}-${g.ObjectTypeCode}`,
								icon: getIconUri(g.DestinationTypeCode, g.ObjectTypeCode),
								type: g.ObjectTypeCode,
								Name: i.Title,
							});
						}
					});
				});

				return [...markers];
			},
			refreshDeps: [
				coords?.zoom ?? 0,
				coords?.bounds?.ne?.lat ?? 0,
				coords?.bounds?.ne?.lng ?? 0,
				coords?.bounds?.sw?.lat ?? 0,
				coords?.bounds?.sw?.lng ?? 0,
			],
			defaultData: [],
		}
	);

	const {
		setMap,
		lastDestination: destination,
		hotelOnMap: hotel,
		all: hotels,
		setHotelOnMap,
	} = useHotelSearchStore();
	const [ center, setCenter ] = useState({
		lat: hotel?.lat ?? hotel?.Position?.Latitude ?? destination.Position.Latitude,
		lng: hotel?.lng ?? hotel?.Position?.Longitude ?? destination.Position.Longitude
	});

	useEffectExceptOnMount(() => {
		if ((hotel?.lng ?? hotel?.Position?.Longitude) > 0 && (hotel?.lat ?? hotel?.Position?.Latitude) > 0) {
			setCenter({
				lat: hotel?.lat ?? hotel?.Position?.Latitude,
				lng: hotel?.lng ?? hotel?.Position?.Longitude
			});
		}
	}, [hotel?.lat ?? hotel?.Position?.Latitude, hotel?.lng ?? hotel?.Position?.Longitude]);

	useEffect(() => {
		return () => setHotelOnMap(null);
	}, []);

	useEffect(() => {
		if (hotel?.Code > 0 && itemRef.current) {
			const container = document.getElementById('map_hotels_list');
			const item = itemRef.current;

			if (container && item) {
				container.scroll({
					top: item.offsetTop - container.offsetTop,
					behavior: "smooth",
				});
			}
		}
	}, [itemRef.current]);

	const setHotel = hotel => setHotelOnMap(hotel);

	const onClose = () => setMap(false);

	const handleShowHotel = item => setHotel({
		Code: item.Code,
		lat: item.Position.Latitude,
		lng: item.Position.Longitude
	});

	const handleClearHotel = e => {
		e.preventDefault();
		e.stopPropagation();
		setHotel(null);
	};

	const mapBind = {
		bootstrapURLKeys: {
			key: mapKey,
			language: locale,
		},
		// ref: mapRef,
		ref: mapRef,
		zoom: destination?.Position?.OptimalGmapZoom > 0 ? destination.Position.OptimalGmapZoom : 15,
		center,
		onGoogleApiLoaded: () => {
			document.getElementById('results').scrollIntoView({ behavior: 'smooth', block: 'center' });
		},
		onChange: coords => setCoords(coords),
		yesIWantToUseGoogleMapApiInternals: true,
		options: () => ({
			clickableIcons: false,
			fullscreenControl: false,
			styles: [
				{
					featureType: 'poi',
					elementType: 'labels',
					stylers: [{ visibility: 'on' }],
				},
			],
		}),
	};

	const markers = data.filter(i => filters.includes(i.id));

	return (
		<>
			<Container id='results'>
				<MobileCloseButton className='button white height30' onClick={onClose}>
					{t('Отели списком')}
				</MobileCloseButton>

				<HotelsList id="map_hotels_list">
					{hotels.map((item, key) =>
						<Hotel key={key} data-hotel={item.Code} ref={Number(item.Code) === Number(hotel?.Code) ? itemRef : null}>
							<PhotoName>
								<Photo href={`/hotel/${item.Code}/${startDate}/${endDate}/${adults}/${childs}/${ages.join(',')}`} target='_blank'>
									{item.IsNewHotel === 1 && <div className='new'>{t('Новый')}</div>}
									{item.Url ?
										<img src={item.Url && item.Url.replace('test-images', 'images')} alt=''/> :
										<div className='noPhoto'/>
									}
									{item.Url && item.ImagesCount > 1 && <span>{t('ещё')} {item.ImagesCount - 1} {t('фото')}</span>}
								</Photo>

								<Name>
									<a href={`/hotel/${item.Code}/${startDate}/${endDate}/${adults}/${childs}/${ages.join(',')}`} target='_blank' rel="noreferrer">{item.Name}</a>

									<div>
										{item.Stars.Code <= 5 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
										{item.Stars.Code <= 4 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
										{item.Stars.Code <= 3 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
										{item.Stars.Code <= 2 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
										{item.Stars.Code <= 1 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
									</div>
								</Name>
							</PhotoName>

							<FromCenter>
								{item.CityCentre}
							</FromCenter>

							<FromPrice>
								{t('от')} {toMoney((Math.min.apply(null, [].concat.apply([], item.HotelOfferList.HotelOfferDetail.map(i => parseInt(i.TotalPrice))))))} RUB
							</FromPrice>

							<Button white small onClick={handleShowHotel.bind(this, item)}>{t('На карте')}</Button>
						</Hotel>
					)}
				</HotelsList>

				{destination && <MapDiv ref={mapContainerRef}>
					<MapFilters filters={filters} setFilters={setFilters} />

					<GoogleMapReact {...mapBind}>
						{markers.map(i => {
							return <MapMarker
								key={i.Code}
								{...i}
								isVisible={Number(i.Code) === Number(hotel?.Code)}
								onClick={() => setHotel({Code: i.Code, lat: i.Lat, lng: i.Lng})}
								handleClearHotel={handleClearHotel}
								lat={i.Lat}
								lng={i.Lng}
								$iconUrl={i.icon}
							/>;
						})}
					</GoogleMapReact>
				</MapDiv>}
			</Container>

			<div className='filler' />
		</>
	);
};