import React, { useMemo } from 'react';
import styled from 'styled-components';
import { parseDate, dayjs, today } from 'utils/dayjs';
import { Day } from './Day';
import { Sprite } from 'components/Sprite';
import classnames from 'classnames';

const Container = styled.div`
	/* margin: 0 10px !important; */
	margin-bottom: 10px;
`;

const Days = styled.div`
	width: 280px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	position: relative;
`;

const Title = styled.div`
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	font-family: ${props => props.theme.pt};
	color: #333;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.selectMonth {
		font-family: inherit;
		line-height: inherit;
		font-size: inherit;
		font-weight: inherit;
		color: ${props => props.theme.darkBlue};
		cursor: pointer;
	}
`;

const WeekDays = styled.div`
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: stretch;

	> div {
		font-family: ${props => props.theme.pt};
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: #333;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const Button = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	cursor: pointer;
	color: ${props => props.theme.darkBlue};

	&.disabled {
		cursor: default !important;
		color: ${props => props.theme.lightGray};
		opacity: .2;
	}
`;

const PrevMonth = styled(Button)`
	left: 0;
`;

const NextMonth = styled(Button)`
	right: 0;
`;

const Month = ({
	stringDate,
	value,
	minDate,
	maxDate,
	toPrevMonth = false,
	toNextMonth = false,
	onClick,
	onHover,
	selectMonth
}) => {

	const isDisablePrevButton = useMemo(() => {
		if (!minDate || !stringDate) return false;

		const date1 = parseDate(stringDate).subtract(1, 'month').endOf('month');
		const date2 = minDate === 'today' ? today() : parseDate(minDate);

		return date1.isBefore(date2, 'day');
	}, [stringDate, minDate]);

	const isDisableNextButton = useMemo(() => {
		if (!maxDate || !stringDate) return false;

		const date1 = parseDate(stringDate).add(1, 'month').startOf('month');
		const date2 = maxDate === 'today' ? today() : parseDate(maxDate);

		return date1.isAfter(date2, 'day');
	}, [stringDate, maxDate]);

	const {
		date,
		days,
		year,
		monthName,
		month
	} = useMemo(() => {
		const date = parseDate(stringDate);
		const daysInMonth = date.daysInMonth();
		const days = new Array(daysInMonth).fill(null).map((i, key) => key + 1);
		const year = date.year();
		const month = date.month();
		const monthName = date.format('MMMM').replace(/^./, (c) => c.toUpperCase());

		return {
			date,
			days,
			year,
			month,
			monthName
		};
	}, [stringDate]);

	const bind = {
		date,
		month,
		year,
		value,
		minDate,
		maxDate,
		onClick,
		onHover
	};

	return (
		<Container>
			<Title>
				{toPrevMonth &&
					<PrevMonth onClick={isDisablePrevButton ? null : toPrevMonth} className={classnames({ disabled: isDisablePrevButton })}>
						<Sprite icon='arrow_left' />
					</PrevMonth>
				}

				<div className='selectMonth' onClick={selectMonth}>{monthName} {year}</div>

				{toNextMonth &&
					<NextMonth onClick={isDisableNextButton ? null : toNextMonth} className={classnames({ disabled: isDisableNextButton })}>
						<Sprite icon='arrow_right' />
					</NextMonth>
				}
			</Title>

			<WeekDays>
				<div>{dayjs().day(1).format('dd')}</div>
				<div>{dayjs().day(2).format('dd')}</div>
				<div>{dayjs().day(3).format('dd')}</div>
				<div>{dayjs().day(4).format('dd')}</div>
				<div>{dayjs().day(5).format('dd')}</div>
				<div>{dayjs().day(6).format('dd')}</div>
				<div>{dayjs().day(0).format('dd')}</div>
			</WeekDays>

			<Days>
				{days.map(i => <Day key={i} day={i} {...bind} />)}
			</Days>
		</Container>
	);
};

export default Month;