import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { Sprite } from 'components';

const Container = styled.div`
	display: flex;
	position: relative;
	flex-grow: ${props => props.$fill ? 1 : 0};
	
	textarea {
		width: 100%;
		min-height: 40px !important;
		height: auto !important;
		border: none;
		outline: none;
		color: ${props => props.theme.darkBlue};
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		overflow: visible;
	}
	
	input {
		text-overflow: ellipsis;
		border: none;
		width: 100%;
	}
`;

const Items = styled.div`
	background-color: white;
	border: 1px solid ${props => props.theme.darkBlue};
	position: absolute;
	top: 40px;
	left: -1px;
	width: ${props => props.$small ? '560px' : 'calc(100% + 2px)'};
	z-index: 999999;
	max-height: 202px;
	height: auto;
	overflow-y: auto;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	
	&.active {
		opacity: 1;
		visibility: visible;
	}
`;

const Item = styled.div`
	min-height: 40px;
	z-index: 999999;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: ${props => props.theme.darkBlue};
	${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	transition: color .2s ease-in-out, background-color .2s ease-in-out;
	background-color: transparent;
	cursor: pointer;
	padding: 0 10px;
	position: relative;
	justify-content: flex-start;
	text-align: left;
	
	svg {
		margin-right: 10px;
	}

	&:not(:last-child):after {
		background-color: ${props => props.theme.lightGray};
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 10px;
		right: 10px;
		height: 1px;
	}
	
	&:hover, &.active {
		background-color: #0270CB;
		color: white;

		&:after { display: none !important; }
	}
`;

export const Combobox = ({
	fill = false, 
	placeholder = '', 
	items = [], 
	needle = '', 
	onChangeNeedle = () => null, 
	onChange = () => null, 
	value = null, 
	small = false
}) => {
	const resultsDiv = useRef();
	const inputRef = useRef();
	const [ index, setIndex ] = useState(0);
	const [ isFocused, setFocused ] = useState(false);

	const handleKeyupNeedle = e => {
		if (e.keyCode === 38) {
			setIndex(index > 0 ? index - 1 : items.length - 1);
		}

		if (e.keyCode === 40) {
			setIndex(index < items.length - 1 ? index + 1 : 0);
		}

		if (e.keyCode === 13) {
			if (resultsDiv.current.children[index] !== undefined) {
				resultsDiv.current.children[index].click();
				inputRef.current.blur();
			}
		}
	};

	const handleMouseEnter = key => setIndex(key);

	useEffect(() => {
		if (resultsDiv.current && resultsDiv.current.children[index] !== undefined) {
			resultsDiv.current.children[index].scrollIntoView({block: 'nearest', behavior: 'smooth'});

			const scrollTop = resultsDiv.current.scrollTop;
			const parentHeight = resultsDiv.current.getBoundingClientRect().height;
			const childTop = resultsDiv.current.children[index].offsetTop;
			const childHeight = resultsDiv.current.children[index].getBoundingClientRect().height;

			if (scrollTop + parentHeight < childTop + childHeight) {
				resultsDiv.current.children[index].scrollIntoView({block: 'nearest', behavior: 'smooth'});
			}
		}
	}, [index]);

	const inputProps = {
		type: 'text',
		placeholder,
		value: value?.Code && !isFocused ? (value?.Name?.replace(/(<([^>]+)>)/gi, '') ?? '') : needle,
		onChange: onChangeNeedle,
		onKeyUp: handleKeyupNeedle,
		ref: inputRef,
		onFocus: () => setFocused(true),
		onBlur: () => setFocused(false),
		autoComplete: 'new-password'
	};

	return (
		<Container $fill={fill} className='combobox'>
			<input {...inputProps} />

			{items.length > 0 && <Items ref={resultsDiv} $small={small} className={classnames('combobox__items', {'active': isFocused})}>
				{items.map((i, key) => 
					<Item key={key} onMouseEnter={handleMouseEnter.bind(this, key)} onClick={onChange.bind(this, i)} className={classnames({'active': key === index}, `type${i.TypeCode}`)}>
						{i.TypeCode === 2 && <Sprite icon='city' />}
						{i.TypeCode === 3 && <Sprite icon='hotel' />}
						{i.TypeCode === 4 && <Sprite icon='airport' />}
						{i.Name}
					</Item>
				)}
			</Items>}
		</Container>
	);
};