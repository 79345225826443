import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore } from 'stores';
import { declines } from 'utils/declines';

const Container = styled.h3`
	span {
		color: #00C0FF;
	}
`;

export const LastDestination = () => {
	const { t } = useTranslation();
	const {
		lastDestination,
		filteredCount
	} = useHotelSearchStore();

	const foundedTitles = [t('найден'), t('найдено'), t('найдено')];
	const hotelTitles = [t('отель'), t('отеля'), t('отелей')];

	return (
		<Container>
			{lastDestination?.Name?.replace(/(<([^>]+)>)/gi, '')}: {declines(filteredCount, foundedTitles)} <span>{filteredCount}</span> {declines(filteredCount, hotelTitles)}
		</Container>
	);
};