import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useDevice } from 'hooks';
import { Select2 } from 'forms';
import { colors } from 'constants';

const Container = styled.div`
	height: 40px;
	border-bottom: 1px solid ${props => props.theme.lightGray};
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	position: relative;

	@media (max-width: 659px) {
		flex-direction: column-reverse;
		height: 80px;

		.select2, .select {
			width: 100%;
		}
	}
`;

const Item = styled.div`
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	cursor: pointer;
	font-size: 16px;
	line-height: 19px;
	color: ${props => props.theme.darkBlue};
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;

	&.active {
		border-top: 1px solid ${props => props.theme.lightGray};
		border-left: 1px solid ${props => props.theme.lightGray};
		border-right: 1px solid ${props => props.theme.lightGray};
		border-bottom: 1px solid white;
		color: ${props => props.theme.lightBlue};
	}
`;

const Sort = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;
	font-family: ${props => props.theme.pt};
	color: #333;
	font-size: 16px;
	line-height: 21px;

	@media (max-width: 659px) {
		flex-direction: column;

		.select {
			margin-left: 0 !important;
			margin-right: 0 !important;
			margin-bottom: 10px !important;
			width: 100% !important;
		}
	}
`;

export const Tabs = ({
	items = [],
	item,
	onChange = () => false,
	children
}) => {
	const { isDesktop } = useDevice();

	return isDesktop
		? (
			<>
				<Sort>
					{children}
				</Sort>

				<Container>
					{items.map((i, key) => 
						<Item key={key} className={classnames({active: item === i.value})} onClick={onChange.bind(this, i.value)}>
							{i.label}
						</Item>
					)}
				</Container>
			</>
		)
		: (
			<>
				<Sort>
					{children}
				</Sort>
				<Container>
					<Select2
						items={items.map(i => ({ Code: i.value, Name: i.label }))}
						value={item}
						onChange={onChange}
						style={{
							borderBottom: '1px solid white',
							borderTop: `1px solid ${colors.lightGray}`,
							borderLeft: `1px solid ${colors.lightGray}`,
							borderRight: `1px solid ${colors.lightGray}`,
							borderTopLeftRadius: '5px',
							borderTopRightRadius: '5px',
						}}
					/>
				</Container>
			</>
		);
};