import React from 'react';
import { useTranslation } from 'react-i18next';
import { Sprite } from 'components';
import { useDevice } from 'hooks';

export const Square = ({
	square,
	Element
}) => {
	const { isDesktop } = useDevice();
	const { t } = useTranslation();

	if (!isDesktop || !square) return null;

	return (
		<Element>
			<Sprite icon='area' />
			{/* {t('Площадь')}&nbsp; */}
			{square} {t('кв. м.')}
		</Element>
	);
};
