import React, { useState } from 'react';
import styled from 'styled-components';
import { IconHidePass, IconShowPass } from 'icons';

const Container = styled.div`
	border: 1px solid ${props => props.theme.darkBlue};
	border-radius: 2px;
	padding: 0 10px 0 0;
	display: flex;
	gap: 10px;
	align-items: center;
	height: 30px;

	input {
		height: 100% !important;
		border: none !important;
		outline: none !important;
		box-shadow: none !important;
	}
`;

const Button = styled.div`
	width: 18px;
	height: 18px;
	cursor: pointer;
`;

const InputPassword = props => {
	const [ isVisible, setVisible ] = useState(false);

	const toggle = () => setVisible(prev => !prev);

	return (
		<Container>
			<input type={isVisible ? 'text' : 'password'} {...props} />

			<Button onClick={toggle}>
				{isVisible ? <IconShowPass /> : <IconHidePass />}
			</Button>

		</Container>
	);
};

export default InputPassword;