import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Sprite } from 'components';
import { useHotelSearchStore } from 'stores';

const Container = styled.div`
	margin-top: 10px;
	margin-right: 5px;
	cursor: pointer;
`;

const HotelCompare = ({ Code }) => {
	const {
		toggleCompare,
		compareHotels,
	} = useHotelSearchStore();

	const isCompare = useMemo(() => {
		return compareHotels.includes(Code);
	}, [Code, compareHotels]);

	return (
		<Container onClick={toggleCompare.bind(this, Code)} className='hotel_compare'>
			<Sprite icon={isCompare ? 'ok' : 'compare'} />
		</Container>
	);
};

export default HotelCompare;