import React, { useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import {Room} from './';
import {declines} from 'utils/declines';
import arrowDown from 'images/arrow_down.svg';
import arrowDownWhite from 'images/arrow_down_white.svg';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from 'hooks';

const Container = styled.div`
	border-color: ${props => props.theme.darkBlue};
	border-style: solid;
	border-width: 0;
	padding: 0;
	transition: padding .2s ease-in-out, border-width .2s ease-in-out;
	
	&.isOpen {
		padding: 10px;
		border-width: 1px;
	}
`;

const Items = styled.div`
	max-height: 0;
	overflow-y: hidden;
	padding-right: 10px;
	
	&.isOpen {
		max-height: 485px;
		overflow-y: auto;
	}
`;

const MoreButton = styled.div`
	border: 2px solid ${props => props.theme.darkBlue};
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	background-color: white;
	color: ${props => props.theme.darkBlue};
	font-size: 14px;
	line-height: 17px;
	text-transform: uppercase;
	transition: color .2s ease-in-out, background-color .2s ease-in-out, margin-top .2s ease-in-out;
	cursor: pointer;
	margin-top: 0;
	
	&:after {
		content: '';
		display: flex;
		width: 10px;
		height: 6px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-image: url(${arrowDown});
		margin-left: 5px;
	}
	
	&.isOpen {
		margin-top: 10px;
		
		&:after {
			transform: rotate(180deg);
		}
	}
	
	&:hover {
		color: white;
		background-color: ${props => props.theme.darkBlue};

		&:after {
			background-image: url(${arrowDownWhite});
		}
	}
`;

export const Rates = ({ items = [], onChange = () => null, onShowDetails, hotelCode, currency }) => {
	const { t } = useTranslation();
	const [isOpen, setOpen] = useState(false);
	const { ref } = useClickOutside(() => setOpen(false));

	const countsTitles = [t('вариант'), t('варианта'), t('вариантов')];

	const counts = items.reduce((a, b) => a + (b?.Rates ?? []).length, 0);

	const handleChangeOpen = () => setOpen(!isOpen);

	const handleChange = (code) => {
		onChange(code);
		setOpen(false);
	};

	return (
		<Container className={classnames({isOpen: isOpen})} ref={ref}>
			<Items className={classnames({isOpen: isOpen})}>
				{items.map((i, key) => <Room key={key} item={i} onChange={handleChange} onShowDetails={onShowDetails} hotelCode={hotelCode} currency={currency} />)}
			</Items>

			{isOpen &&
				<MoreButton className={classnames({isOpen: isOpen})} onClick={handleChangeOpen}>
					{t('Скрыть')}
				</MoreButton>
			}

			{!isOpen &&
				<MoreButton className={classnames('more', {isOpen: isOpen})} onClick={handleChangeOpen}>
					{t('Показать еще')} {counts} {declines(counts, countsTitles)}
				</MoreButton>
			}
		</Container>
	);
};