/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
// import { HotelPricingRequest2 } from 'requests/HotelPricingRequest2';
import { useInstance } from 'hooks';

const user = JSON.parse(localStorage.getItem('user'));

export const usePricing = (orderInfo, products, setOrderInfo, accommodationId, order) => {
	const [ pricing, setPricing ] = useState(false);
	const { post } = useInstance();

	const requestPricingProps = useMemo(() => {
		if (orderInfo.Id < 1 || orderInfo.AccommodationId < 1) {
			return { 
				Hotel: orderInfo.Hotel, 
				ArrivalDate: orderInfo.ArrivalDate, 
				ArrivalTime: orderInfo.ArrivalTime, 
				DepartureDate: orderInfo.DepartureDate, 
				DepartureTime: orderInfo.DepartureTime, 
				ProductCode: orderInfo.Product,
				NumberOfGuests: orderInfo.NumberOfGuests,
				NumberOfExtraBedsAdult: orderInfo.NumberOfExtraBedsAdult,
				NumberOfExtraBedsChild: orderInfo.NumberOfExtraBedsChild,
				NumberOfExtraBedsInfant: orderInfo.NumberOfExtraBedsInfant,
				Meal: orderInfo.Meal,
				ExtraMeals: orderInfo.ExtraMeals,
			};
		}

		return { 
			Id: orderInfo.Id, 
			AccommodationId: orderInfo.AccommodationId, 
			Hotel: orderInfo.Hotel, 
			ArrivalDate: orderInfo.ArrivalDate, 
			ArrivalTime: orderInfo.ArrivalTime, 
			DepartureDate: orderInfo.DepartureDate, 
			DepartureTime: orderInfo.DepartureTime, 
			ProductCode: orderInfo.Product,
			NumberOfGuests: orderInfo.NumberOfGuests,
			NumberOfExtraBedsAdult: orderInfo.NumberOfExtraBedsAdult,
			NumberOfExtraBedsChild: orderInfo.NumberOfExtraBedsChild,
			NumberOfExtraBedsInfant: orderInfo.NumberOfExtraBedsInfant,
			Meal: orderInfo.Meal,
			ExtraMeals: orderInfo.ExtraMeals,
		};
	}, [
		orderInfo.Id, 
		orderInfo.AccommodationId, 
		orderInfo.Hotel, 
		orderInfo.ArrivalDate, 
		orderInfo.ArrivalTime, 
		orderInfo.DepartureDate, 
		orderInfo.DepartureTime, 
		orderInfo.Product,
		orderInfo.NumberOfGuests,
		orderInfo.NumberOfExtraBedsAdult,
		orderInfo.NumberOfExtraBedsChild,
		orderInfo.NumberOfExtraBedsInfant,
		orderInfo.Meal,
		orderInfo.ExtraMeals
	]);
	

	const fetch = async () => {
		const productGuests = products?.find(i => parseInt(i.Code) === parseInt(orderInfo.Product))?.MaxNumberOfGuests ?? 0;

		if (products.length && productGuests > 0 && orderInfo.NumberOfGuests > productGuests) {
			return;
		}

		// const request = HotelPricingRequest2(requestPricingProps);
		const request = {
			HotelPricingRequest2: {
				ExtraMeals: {
					ExtraMeal: requestPricingProps?.ExtraMeals ?? []
				},
				// CurrencyCode: CurrencyCode,
				Hotel: requestPricingProps?.Hotel ?? 0,
				ProductCode: requestPricingProps?.ProductCode ?? 0,
				// Currency: Currency,
				WhereToPay: requestPricingProps?.WhereToPay ?? 3,
				ArrivalDate: requestPricingProps?.ArrivalDate ?? '',
				DepartureDate: requestPricingProps?.DepartureDate ?? '',
				ArrivalTime: requestPricingProps?.ArrivalTime ?? '',
				DepartureTime: requestPricingProps?.DepartureTime ?? '',
				Meal: requestPricingProps?.Meal ?? 0,
				AccommodationId: requestPricingProps?.AccommodationId ?? 0,
				Id: requestPricingProps?.Id ?? 0,
				NumberOfGuests: requestPricingProps?.NumberOfGuests ?? 1,
				NumberOfExtraBedsAdult: requestPricingProps?.NumberOfExtraBedsAdult ?? 1,
				NumberOfExtraBedsChild: requestPricingProps?.NumberOfExtraBedsChild ?? 0,
				NumberOfExtraBedsInfant: requestPricingProps?.NumberOfExtraBedsInfant ?? 0,
			}
		};

		if (order?.Id > 0) {
			request.HotelPricingRequest2.ProfitCentreCode = order?.ProfitCentreCode;
			request.HotelPricingRequest2.SUserCode = order?.SUserCode;
			request.HotelPricingRequest2.MarketCode = order?.Market?.Code;
			request.HotelPricingRequest2.AgentCode = order?.AgentCode;
			request.HotelPricingRequest2.BuyerCode = order?.ContractId;
			request.HotelPricingRequest2.UkCode = order?.LegalEntity?.Code;
		}

		const data = await post(request);
		setPricing(data);

		if (accommodationId === 0 && data?.HotelPricingDetail?.Meals) {
			const Meal = data?.HotelPricingDetail?.Meals?.Meal?.find(i => i.IncludedInPrice.Code === 1)?.Code;
			if (Meal && parseInt(orderInfo.Meal) !== Meal) {
				setOrderInfo(prev => ({
					...prev,
					Meal
				}));
			}
		}
	};

	useEffect(() => {
		orderInfo.Hotel > 0 && orderInfo.Product > 0 && fetch();
	}, [JSON.stringify(requestPricingProps), orderInfo.Product]);

	return {
		pricing
	};
};