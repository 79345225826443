import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'forms';
import { toMoney } from 'utils';
import { useDevice, useHotelPricing } from 'hooks';
import { dayjs, getNights, parseDate } from 'utils/dayjs';
import { Portal } from 'components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-right: 10px;
	/* overflow-y: auto; */
	/* height: 100%; */

	h3 {
		margin: 0 !important;

		& + p {
			margin-top: -5px;
		}
	}

	p + p {
		margin-top: -5px;
	}
`;

const Payment = styled.div`
	color: #333;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	font-family: ${props => props.theme.pt};
	margin-bottom: 10px;
`;

const Filters = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0 20px;
	flex-wrap: wrap;
	position: relative;

	@media (max-width: 659px) {
		> * {
			min-width: 100px;
		}
	}
`;

const Data = styled.table`
	width: 100%;
	display: table;
	table-layout: auto;
	position: relative;
	border-top: 1px solid #D5E3F0;
	margin-bottom: 10px;

	td, th {
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		color: #333;
		text-align: left;
		padding: 10px 0;
		border-bottom: 1px solid #D5E3F0;
		/* white-space: nowrap; */

		&:not(:first-child) {
			text-align: right;
		}

		div {
			font-size: inherit;
			font-weight: inherit;
			line-height: inherit;
			font-family: inherit;
			color: inherit;
			/* white-space: nowrap; */
			text-align: inherit;
		}
	}

	th {
		font-weight: 700;
	}
`;

const isPenalty = datetime => {
	return dayjs.utc(datetime, 'DD.MM.YYYY HH:mm').isSameOrBefore(dayjs());
};

const ModalDetailsV2 = ({ rate, onClose }) => {
	const { t } = useTranslation();
	const { isDesktop, isMobile } = useDevice();
	const [ columns, setColumns ] = useState(['extraMeals']);
	const { pricing } = useHotelPricing({
		accommodation: {
			...rate,
			Hotel:
			{
				Code: rate?.Hotel
			},
			Product: {
				Code: rate?.ProductCode
			},
		}
	});

	const {
		Name,
		TypeOfPlace: {
			Name: TypeOfPlaceName
		} = {},
		City: {
			Name: CityName
		} = {},
		Country: {
			Name: CountryName
		} = {},
		Currency: {
			Name: CurrencyName
		} = {},
		HotelPricingDetail: {
			RoomName,
			DiscountOffer: {
				Code: DiscountOffer,
				Name: DiscountOfferName
			} = {},
			Meals: {
				Meal: meals = []
			} = {},
			WhereToPayList: {
				WhereToPay = []
			} = {},
			PriceDescription: {
				Days: {
					Day = []
				} = {},
			} = {},
			PenaltyRuleList: {
				PenaltyRule = [],
			} = {},
			RateDescription = '',
		} = {},
		ExtraMeals: {
			ExtraMeal = []
		} = {}
	} = pricing ?? {};

	const penaltiesText = useMemo(() => {
		if (PenaltyRule.length > 1) {
			const bits = PenaltyRule.map(i => {
				if (isPenalty(i.DeadlineTimeUTC)) {
					return t('Платно — {{Value}}% от полной стоимости в размере {{PenaltySize}} {{CurrencyName}}', { Value: i?.Value ?? 0, PenaltySize: toMoney(i.PenaltySize), CurrencyName });
				}

				return t('с {{DeadlineTimeLoc}} — {{Value}}% от полной стоимости в размере {{PenaltySize}} {{CurrencyName}}', { DeadlineTimeLoc: i.DeadlineTimeLoc, Value: i?.Value ?? 0, PenaltySize: toMoney(i.PenaltySize), CurrencyName });
			});

			return bits.join('; ') + '.';
		}

		if (PenaltyRule.length === 1) {
			if (isPenalty(PenaltyRule[0].DeadlineTimeUTC)) {
				return t('Платно — {{Value}}% от полной стоимости в размере {{PenaltySize}} {{CurrencyName}}.', { Value: PenaltyRule[0]?.Value ?? 0, PenaltySize: toMoney(PenaltyRule[0].PenaltySize), CurrencyName });
			}

			return t('Бесплатно до {{DeadlineTimeLoc}}, далее — за {{PenaltySize}} {{CurrencyName}}.', { DeadlineTimeLoc: PenaltyRule[0].DeadlineTimeLoc, PenaltySize: toMoney(PenaltyRule[0].PenaltySize), CurrencyName });
		}

		return '';
	}, [PenaltyRule, CurrencyName]);

	if (!pricing || !rate) return null;

	const extraMeals = Day.reduce((acc, cur) => ({
		...acc,
		[cur.Date]: ExtraMeal.filter(i => parseDate(cur.Date).isSameOrAfter(parseDate(i.StartDate)) && parseDate(cur.Date).isSameOrBefore(parseDate(i.EndDate))).map(i => ({
			...i,
			Name: isMobile ? i.Name.replace(/\s*"[^"]+"$/, '').trim() : i.Name,
		}))
	}), {});

	const totalExtraMeals = ExtraMeal.map(i => ({
		Name: isMobile ? i.Name.replace(/\s*"[^"]+"$/, '').trim() : i.Name,
		TotalPrice: getNights(i.StartDate, i.EndDate) * parseFloat(i.Price)
	}));

	const meal = meals.find(i => i?.IncludedInPrice?.Code === 1);

	const onChange = name => setColumns(prev => {
		const columns = [...prev];
		const index = columns.findIndex(i => i === name);
		if (index === -1) columns.push(name);
		else columns.splice(index, 1);

		return [...columns];
	});

	const totalPrice = Day.reduce((acc, cur) => acc + parseFloat(cur?.Price ?? 0.00) , 0.00);

	return (
		<Portal isOpen={!!rate} onClose={onClose} width='1240' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
			<Container>
				<h2>
					{Name}, {TypeOfPlaceName} {CityName}, {CountryName}
				</h2>

				<h3>
					{t('Номер')} {RoomName}{DiscountOffer === 1 && `, ${DiscountOfferName}`}, {meal ? `${t('включено питание {{mealName}}', { mealName: meal.Name })}` : t('питание не включено')}
				</h3>

				<Payment>
					{WhereToPay?.[0]?.Name}
				</Payment>

				<Filters>
					<Checkbox name='extraMeals' checked={columns.includes('extraMeals')} onChange={onChange} value='extraMeals' label={t('Дополнительное питание')} />
				</Filters>

				{isDesktop &&
					<Data>
						<thead>
							<tr>
								<th>{t('Дата')}</th>
								{columns.includes('extraMeals') && <th>{t('Дополнительное питание')}</th>}
								<th>{t('Проживание')}</th>
							</tr>
						</thead>
		
						<tbody>
							{Day.map((i, key) => 
								<tr key={key}>
									<th>{i.Date}</th>
									{columns.includes('extraMeals') && <td>
										{(extraMeals?.[i.Date] ?? []).map((i, key) => <div key={key}>{i.Name} + {toMoney(i.Price)} {CurrencyName}</div>)}
									</td>}
									<td>{toMoney(i?.Price ?? 0)} {CurrencyName}</td>
								</tr>
							)}
						</tbody>

						<thead>
							<tr>
								<th>{t('Итого')}:</th>
								{columns.includes('extraMeals') && <th>
									{totalExtraMeals.map((i, key) => <div key={key}>{i.Name} + {toMoney(i.TotalPrice)} {i.CurrencyName}</div>)}
								</th>}
								<th>{toMoney(totalPrice)} {CurrencyName}</th>
							</tr>
						</thead>
					</Data>
				}

				{!isDesktop &&
					<Data>
						<tbody>
							{Day.map((i, key) => 
								<React.Fragment key={key}>
									<tr>
										<th>{t('Дата')}</th>
										<th>{i.Date}</th>
									</tr>

									{columns.includes('extraMeals') &&
										<tr>
											<th>{isMobile ? t('Доп. питание') : t('Дополнительное питание')}</th>
											<td>
												{(extraMeals?.[i.Date] ?? []).map((i, key) => <div key={key}>{i.Name} + {toMoney(i.Price)} {CurrencyName}</div>)}
											</td>
										</tr>
									}

									<tr>
										<th>{t('Проживание')}</th>
										<td>{toMoney(i.Price)} {CurrencyName}</td>
									</tr>
								</React.Fragment>
							)}

							<tr>
								<th>{t('Итого')}:</th>
								<td />
							</tr>
							
							{columns.includes('extraMeals') &&
								<tr>
									<th>{isMobile ? t('Доп. питание') : t('Дополнительное питание')}</th>
									<th	>
										{totalExtraMeals.map((i, key) => <div key={key}>{i.Name} + {toMoney(i.TotalPrice)} {i.CurrencyName}</div>)}
									</th>
								</tr>
							}

							<tr>
								<th>{t('Проживание')}</th>
								<th>{toMoney(totalPrice)} {CurrencyName}</th>
							</tr>
						</tbody>
					</Data>
				}

				{PenaltyRule.length > 0 &&
					<>
						<h3>{t('Условия изменения и аннуляции (по местному времени отеля)')}</h3>

						<p>{penaltiesText}</p>
					</>
				}

				{!!RateDescription &&
					<>
						<h3>{t('Описание тарифа')}</h3>

						<p>
							{RateDescription}
						</p>
					</>
				}
			</Container>
		</Portal>
	);
};

export default ModalDetailsV2;