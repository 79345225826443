import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Modal } from 'modals';
import { Button } from 'components';

const H5 = styled.h5`
	color: ${props => props.theme.darkBlue};
	margin-bottom: 10px;
`;

const useNotFoundPopup = () => {
	const [isOpen, setOpen] = useState(false);
	// const [ text, setText ] = useState(current);
	const [ text, setText ] = useState('');
	const { t } = useTranslation();

	const onOpen = useCallback(() => setOpen(true), []);
	const onClose = useCallback(() => setOpen(false), []);

	const onSend = () => {
		const user = JSON.parse(localStorage.getItem('user'));

		const {
			UserName,
			Email
		} = user;

		const data = {
			UserName,
			Email,
			text
		};

		axios.post('/mail404.php', data)
			.then(data => data.data)
			.then(() => {
				onClose();
				setText('');
			})
			.catch(error => console.log(error));
	};

	const NotFoundPopup = (
		<Modal isVisible={isOpen} onClose={onClose} width={560}>
			<h1>{t('Не нашли нужный отель?')}</h1>
			<H5>{t('Пожалуйста, напишите нам о том, что Вы искали, и мы постараемся добавить нужный Вам отель в нашу систему')}.</H5>
			<input
				className='textInput'
				style={{marginBottom: '20px'}}
				value={text}
				onChange={e => setText(e.target.value)} placeholder={t('Введите название отеля или выберите его из предложенных')}
			/>
			<Button gradient onClick={onSend}>{t('Отправить')}</Button>
		</Modal>
	);

	return [onOpen, NotFoundPopup];
};

export default useNotFoundPopup;