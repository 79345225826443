import { useEffect, useState } from 'react';

const readLocalStorage = (key, initialValue) => {
	if (typeof window === 'undefined') {
		return initialValue;
	}

	const item = window.localStorage.getItem(key);
	if (item === null) return initialValue;

	try {
		return item ? JSON.parse(item) : initialValue;
	} catch (error) {
		return item;
	}
};

const useLocalStorage = (key, initialValue = null, sync = false) => {
	const [ value, setValue ] = useState(() => readLocalStorage(key, initialValue));

	const handle = e => {
		if (e?.key === null || e?.key === key) setValue(readLocalStorage(key, initialValue));
	};

	useEffect(() => {
		sync && window.addEventListener('storage', handle);
		return () => sync && window.removeEventListener('storage', handle);
	}, []);

	const handleSetValue = newValue => {
		if (newValue === initialValue) {
			localStorage.removeItem(key);
		} else {
			localStorage.setItem(key, JSON.stringify(newValue));
		}

		setValue(newValue);
	};

	const handleClear = () => {
		localStorage.removeItem(key);
		setValue(initialValue);
	};

	return [value, handleSetValue, handleClear];
};

export default useLocalStorage;