import React from 'react';
import styled from 'styled-components';
import starEmpty from 'images/star_empty.svg';
import starFill from 'images/star_fill.svg';

const ImgCircle = styled.img`
	margin-right: 5px;
`;

const ImgCircleEmpty = () => <ImgCircle src={starEmpty} alt='' />;
const ImgCircleFill = () => <ImgCircle src={starFill} alt='' />;

export const HotelStars = ({ starsCode = false }) => {
	if (isNaN(parseInt(starsCode))) {
		return null;
	}

	return (
		<div>
			{starsCode <= 5 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
			{starsCode <= 4 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
			{starsCode <= 3 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
			{starsCode <= 2 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
			{starsCode <= 1 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
		</div>
	);
};