import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;

	@media (max-width: 1279px) {
		flex-direction: column;
		align-items: stretch;
	}

	& > div:not(:first-child) {
		@media (min-width: 1280px) {
			margin-left: 10px;
		}
	}

	& > div {
		@media (max-width: 1279px) {
			margin-bottom: 20px;
		}
	}
`;

const AddFieldContainer = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: stretch;

	label {
		margin-bottom: 10px;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: ${props => props.theme.darkBlue};
	}
`;

const Req = styled.b`
	margin-left: 5px;
	color: #FF6B00;
`;

const AddField = ({
	label,
	value,
	onChange,
	readOnly,
	required
}) => {
	return (
		<AddFieldContainer>
			<label>
				{label}
				{required && <Req>*</Req>}
			</label>
			<input value={value} onChange={onChange} className='textInput' readOnly={readOnly} />
		</AddFieldContainer>
	);
};

export const AddFields = ({
	addField1Props,
	addField2Props,
	AllowAddFieldModify,
	count
}) => {
	if (count === 0) {
		return null;
	}

	return (
		<Container>
			{addField1Props && <AddField {...addField1Props} readOnly={!AllowAddFieldModify} />}
			{addField2Props && <AddField {...addField2Props} readOnly={!AllowAddFieldModify} />}
		</Container>
	);
};