import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { IconWhiteCross } from 'icons';
import { useClickOutside } from 'hooks';
import { Sprite } from 'components';

const Overlay = styled.div`
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(3, 71, 129, 0.8);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out !important;
	margin: 0 !important;
	padding: 0 !important;
	
	&.isVisible {
		opacity: 1;
		visibility: visible;
	}
`;

const Container = styled.div`
	padding: 20px;
	background: white;
	width: ${props => props.$width}px;
	margin: 100px 0;
	overflow-y: auto;
	max-height: 80vh;
	overflow-y: auto !important;

	@media (max-width: 1279px) {
		width: calc(100vw - 40px) !important;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: auto !important;
		margin: 0;
		padding: 40px 20px;
		max-height: 100vh;
	}

	h1 {
		margin-bottom: 20px;

		@media (max-width: 1279px) {
			font-size: 32px !important;
			line-height: 38px !important;
		}
	}
`;

const CloseButtonDesktop = styled.div`
	cursor: pointer;
	position: absolute;
	top: 20px;
	right: 20px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	@media (min-width: 1280px) {
		top: 40px;
		right: 40px;
	}

	@media (max-width: 1279px) {
		display: none;
	}

	&.fixed {
		@media (max-width: 1279px) {
			display: none;
		}
	}
`;

const CloseButtonMobile = styled.div`
	color: ${props => props.theme.darkBlue};
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1000;

	@media (min-width: 1280px) {
		display: none !important;
	}
`;

export const Modal2 = ({
	children = null, 
	isVisible = false, 
	onClose = () => null, 
	width = 1240, 
	containerStyle = {}
}) => {
	const { mainRef, ref } = useClickOutside(onClose);

	return (
		<Overlay className={classnames({isVisible})} ref={mainRef}>
			<CloseButtonDesktop onClick={onClose}>
				<IconWhiteCross />
			</CloseButtonDesktop>

			<CloseButtonMobile onClick={onClose}>
				<Sprite icon='blue_cross' />
			</CloseButtonMobile>

			<Container $width={width} className={classnames('modal_container')} style={containerStyle} ref={ref}>
				{isVisible && children}
			</Container>
		</Overlay>
	);
};
