/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Sprite } from 'components';
// import { ModalMessage } from 'modals';
import { Lang } from './Lang';
import { useDevice } from 'hooks';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { useUserStore } from 'stores';
import { ModalLogin } from 'modals';

const Container = styled.div`
	height: 70px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background-color: white;
	padding: 0 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	user-select: none !important;

	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		height: 10px;
		width: 100%;
		background: linear-gradient(180deg, rgba(0, 70, 133, 0.2) 0%, rgba(0, 70, 133, 0) 100%);
	}

	@media (max-width: 1279px) {
		height: 60px;
		padding: 0 20px;
	}
`;

const Logo = styled(Link)`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 24px;
	line-height: 28px;
	font-weight: 500;

	svg {
		margin-right: 5px;
	}

	span {
		margin-left: 5px;
		color: ${props => props.theme.lightBlue};
	}

	@media (max-width: 1279px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const Menu = styled.div`
	margin-left: 20px;

	a {
		font-size: 16px !important;
		line-height: 19px !important;
		text-transform: uppercase;

		&:not(:first-child) {
			margin-left: 20px;
		}
	}
`;

const User = styled(Link)`
	font-size: 16px !important;
	line-height: 19px !important;
	text-decoration: underline !important;

	@media (min-width: 1280px) {
		margin-left: 20px;
	}
`;

/*
const Message = styled.div`
	cursor: pointer;
	margin-left: 20px;
	height: 30px;
`;
*/

const Logout = styled.div`
	cursor: pointer;
	margin-left: 20px;
`;

const MobileMenu = styled.div`
	height: 30px;
`;

const MenuContainer = styled.div`
	width: 320px;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: white;
	z-index: 999999;
	padding: 0 20px 20px 20px;
	margin-right: -100%;
	transition: margin-right .2s ease-in-out;

	&.isMenuOpen {
		margin-right: 0;
	}

	@media (max-width: 659px) {
		width: 100vw;
	}

	.row {
		height: 60px;
		border-bottom: 1px solid ${props => props.theme.lightGray};
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.menu {
		border-bottom: 1px solid ${props => props.theme.lightGray};
		padding: 15px 0;
		display: flex;
		flex-direction: column;

		a {
			font-size: 16px !important;
			line-height: 19px !important;
			text-transform: uppercase;

			&:not(:first-child) {
				margin-top: 10px;
			}
		}
	}
`;

const LoginButton = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	font-size: 16px !important;
    line-height: 19px !important;
    text-transform: uppercase;
	color: ${props => props.theme.darkBlue};

	&:hover {
		color: ${props => props.theme.lightBlue};
	}
`;

const Overlay = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999998;
	background-color: rgba(0, 70, 133, 0.8);
	opacity: 0;
	visibility: hidden;

	&.isMenuOpen {
		opacity: 1;
		visibility: visible;
	}
`;

export const Header = () => {
	const { t } = useTranslation();
	// const [ isSendOpen, setSendOpen ] = useState(false);
	const { isDesktop } = useDevice();
	const [ isMenuOpen, setMenuOpen ] = useState(false);
	const loc = useLocation();
	const {
		user = {},
		logout,
		isLogged,
	} = useUserStore();

	useEffect(() => {
		if (!isDesktop) setMenuOpen(false);
	}, [loc]);

	// const onSendOpen = () => setSendOpen(true);
	// const onSendClose = () => setSendOpen(false);

	const {
		UserTypeCode = 2,
		ManagerName = '',
		UserName = '',
		OrderAccess,
		UserPrivileges: UserPrivileges,
		BillingControlAccess,
		TypeContractCode,
	} = user ?? {};

	let name = parseInt(UserTypeCode) === 3 ? ManagerName : UserName;

	const isOrderAccess = parseInt(OrderAccess) === 1;

	const toggleMenuOpen = () => setMenuOpen(prev => !prev);

	return (
		<Container id="header">
			<Logo to="/">
				<Sprite icon='logo' />
				<Trans i18nKey='full_title'>АКАДЕМ-ОНЛАЙН <span>B2B</span></Trans>
			</Logo>

			<div className='filler' />

			{isDesktop &&
				<>
					<Menu>
						<NavLink to="/">{t('Поиск')}</NavLink>
						{/* {isOrderAccess && <NavLink to="/orders/quick">{t('Заказы')}</NavLink>} */}
						<NavLink to="/countries">{t('Отели')}</NavLink>
						<NavLink to="/about">{t('Помощь')}</NavLink>
						<NavLink to="/contacts">{t('Контакты')}</NavLink>
					</Menu>

					<User to='/personal'>
						{name.substr(0, 37)}
						{name.length > 38 && '...'}
					</User>

					{/* <Message onClick={onSendOpen}>
						<Sprite icon='message' />
					</Message> */}

					{isLogged && <Logout onClick={logout}>
						<Sprite icon='logout' title={t('Выход')} />
					</Logout>}

					{!isLogged &&
						<ModalLogin>
							<LoginButton id='button__login'>
								<Sprite icon='login' />
								Войти
							</LoginButton>
						</ModalLogin>
					}
				</>
			}

			{!isDesktop &&
				<MobileMenu>
					<div onClick={toggleMenuOpen}>
						<Sprite icon='burger' />
					</div>

					<Overlay className={classnames({ isMenuOpen })} onClick={toggleMenuOpen} />

					<MenuContainer className={classnames({ isMenuOpen })}>
						<div className='row'>
							<Lang />
							<div className='filler' />
							<div onClick={toggleMenuOpen}>
								<Sprite icon='close' />
							</div>
						</div>

						<div className='row'>
							<User to='/personal'>
								{name.substr(0, 37)}
								{name.length > 38 && '...'}
							</User>

							<div className='filler' />

							{/* <Message onClick={onSendOpen}>
								<Sprite icon='message' />
							</Message> */}

							{isLogged && <Logout onClick={logout}>
								<Sprite icon='logout' title={t('Выход')} />
							</Logout>}
						</div>

						<div className='menu'>
							<NavLink to="/">{t('Поиск')}</NavLink>
							{isOrderAccess && <NavLink to="/findorders">{t('Заказы')}</NavLink>}
							<NavLink to="/countries">{t('Отели')}</NavLink>
							<NavLink to="/about">{t('Помощь')}</NavLink>
							<NavLink to="/contacts">{t('Контакты')}</NavLink>
						</div>
					</MenuContainer>
				</MobileMenu>
			}

			{/* <ModalMessage isVisible={isSendOpen} onClose={onSendClose} /> */}
		</Container>
	);
};