export const inArray = (needle, array) => {
	console.log({
		needle,
		array
	});
	if (!Array.isArray(array) || array.length === 0) return false;

	if (!isNaN(parseInt(needle))) needle = parseInt(needle);

	if (needle?.Id !== undefined) needle = parseInt(needle.Id);

	if (!isNaN(parseInt(array?.[0]))) array = array.map(i => parseInt(i));

	if (array?.[0]?.Id) array = array.map(i => parseInt(i.Id));

	return array.includes(needle);
};