import React, { useMemo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
	width: 40px;
	height: 40px;

	img {
		width: 40px;
		height: 40px;
		object-fit: cover;
		line-height: 0;
	}
`;

const NoPhoto = styled.div`
	width: 40px;
	height: 40px;
	background-color: #A2D0EA;
	position: relative;

	img {
		position: absolute;
		top: 50%;
		left: 50%;

		transform: translateX(-50%) translateY(-50%);
	}
`;

const RoomPhoto = ({
	Url,
	BigUrl,
	ThUrl,
}) => {
	const url = useMemo(() => {
		if (ThUrl) return ThUrl.replace('test-images', 'images');
		if (Url) return Url.replace('test-images', 'images');
		if (BigUrl) return BigUrl.replace('test-images', 'images');

		return false;
	}, []);

	if (!url) {
		return (
			<NoPhoto>
				<img src='/images/no_photo.png' />
			</NoPhoto>
		);
	}

	return (
		<Container>
			<img src={url} />
		</Container>
	);
};

export default RoomPhoto;