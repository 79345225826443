export const toMoney = (number) => {
	const formatter = new Intl.NumberFormat('ru-RU', {
		style: 'decimal',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	try {
		return formatter.format(number);
	} catch (e) {
		return formatter.format(0);
	}
};
