import { useMemo } from 'react';
import { format, parseDate, todayString, tomorrowString } from 'utils/dayjs';

const today = todayString();
const tomorrow = tomorrowString();

export const useMealsProps = ({ pricing, orderInfo, setOrderInfo }) => {
	const {
		StartDate = today,
		EndDate = tomorrow,
		NumberOfGuests = 1,
		NumberOfExtraBedsInfant = 0,
		NumberOfExtraBedsChild = 0,
		NumberOfExtraBedsAdult = 0,
		Currency: {
			Name: currency = 'RUB'
		} = {},
		ExtraMeals: {
			ExtraMeal: availableExtraMeals = []
		} = {},
		HotelPricingDetail: {
			MealIncludedInExtraBedAdult = 2,
			MealIncludedInExtraBedChild = 2,
			MealIncludedInExtraBedInfant = 2,
			Meals: {
				Meal = []
			} = {},
			ExtraMeals: {
				ExtraMeal = []
			} = {},
			PriceDescription: {
				Days: {
					Day = []
				} = {}	
			} = {}
		} = {}
	} = pricing ?? {};

	const guests = useMemo(() => NumberOfGuests
		+ (MealIncludedInExtraBedAdult === 1 ? NumberOfExtraBedsAdult : 0)
		+ (MealIncludedInExtraBedChild === 1 ? NumberOfExtraBedsChild : 0)
		+ (MealIncludedInExtraBedInfant === 1 ? NumberOfExtraBedsInfant : 0)
	, [pricing]);

	const price = useMemo(() => Day.map(i => parseInt(i?.AdditionalMeal ?? 0)).reduce((acc, cur) => acc + cur, 0), [pricing]);
	
	const [startDate, endDate] = useMemo(() => {
		const dates = Day.filter(i => i?.AdditionalMeal > 0)
			.map(i => i.Date)
			.sort((a, b) => parseDate(a).isBefore(parseDate(b)) ? -1 : 1);
		
		const startDate = dates?.[0]
			? parseDate(dates?.[0]).add(1, 'day').format(format)
			: parseDate(StartDate).add(1, 'day').format(format);
		
		const endDate = dates?.[dates?.length - 1]
			? parseDate(dates?.[dates?.length - 1]).add(1, 'day').format(format)
			: EndDate;
		
		return [
			startDate,
			endDate
		];
	}, [pricing]);

	const meal = useMemo(() => {
		if (!pricing) {
			return false;
		}

		const meal = parseInt(orderInfo?.Meal) === 0
			? Meal.find(i => i.IncludedInPrice.Code === 1)
			: Meal.find(i => parseInt(i.Code) === parseInt(orderInfo.Meal));

		if (meal) {
			meal.Total = price;
			meal.StartDate = startDate;
			meal.EndDate = endDate;
			meal.QuantityPerRoom = guests;
		}

		return meal;
	}, [pricing, startDate, endDate, price, guests]);

	const extraMeals = useMemo(() => {
		return ExtraMeal.map(i => ({
			...i,
			Price: parseFloat(availableExtraMeals.find(j => j.Code === i.Code)?.Price)
		}));
	}, [orderInfo, pricing]);

	const mealsProps = {
		meal,
		guests,
		extraMeals,
		Day,
		setOrderInfo,
		currency,
		availableExtraMeals,
	};

	return {
		mealsProps,
	};
};