import React from 'react';
import styled from 'styled-components';
import {
	DatesInput,
	DestinationInput,
	GuestsInput,
} from '../';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	gap: 5px;
	height: 40px;

	.guests {
		height: 40px;
		flex-shrink: 0;
		width: 220px !important;
	}

	.select_container__destination {
		flex-grow: 1;

		react-select-container: {
			flex-grow: 1;
		}
	}

	.dates {
		height: 40px;
		border: none !important;
		flex-shrink: 0;
	}
`;

export const MainFilters = () => {
	return (
		<Container>
			<DestinationInput />
			<DatesInput />
			<GuestsInput />
		</Container>
	);
};