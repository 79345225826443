import { useCallback, useEffect, useRef } from 'react';

export const useClickOutside = (callback = () => null) => {
	const mainRef = useRef(document);
	const ref = useRef(null);

	const handleClick = useCallback((e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	}, []);

	useEffect(() => {
		mainRef.current && mainRef.current.addEventListener('touchstart', handleClick, { capture: true });
		mainRef.current && mainRef.current.addEventListener('mousedown', handleClick, { capture: true });

		return () => {
			mainRef.current && mainRef.current.removeEventListener('touchstart', handleClick, { capture: true });
			mainRef.current && mainRef.current.removeEventListener('mousedown', handleClick, { capture: true });
		};
	}, [mainRef.current]);

	return {
		mainRef,
		ref,
	};
};
