import { useState } from 'react';
import { useInstance } from 'hooks';

export const useRoom = () => {
	const { post } = useInstance();
	const [ room, setRoom ] = useState(false);

	const fetch = async (ProductCode, HotelCode, roomCode, hotelName, hotelStars) => {
		const request = {
			RoomRequest: {
				ActionGetRoomDesc: {
					Parameters: {
						Header: {
							ProductCode
						}
					}
				}
			}
		};

		const request2 = {
			HotelImageRequest: {
				ActionInfoTypes: {
					Parameters: {
						HotelCode: HotelCode
					}
				}
			}		
		};

		const request3 = {
			HotelImageRequest: {
				ActionList: {
					Parameters: {
						HotelCode: HotelCode
					}
				}
			}
		};

		const data = await post(request);

		if (data) {
			const roomResponse = {...data};
			roomResponse.HotelName = hotelName;
			roomResponse.HotelStars = hotelStars;
			roomResponse.Images = [];

			const data2 = await post(request2);
			if (data2?.Hotel?.RoomTypes?.RoomType) {
				const roomType = data2.Hotel.RoomTypes.RoomType.find(i => i.Code === roomCode);

				if (roomType !== undefined) {
					const data3 = await post(request3);

					if (data3?.Hotel?.Images?.Image) {
						roomResponse.Images = data3.Hotel.Images.Image
							.filter(i => i.ImageTypeCode === 6 && i.RoomTypeCode === roomType.Code && Number(i.IsApprove) === 1)
							.map(i =>
								i.IsOldArchive === 1 ?
									`http://images.acase.ru/hotels_images/${i.OldFile}` :
									`http://images.acase.ru/acase_images/${HotelCode}/${i.PhotoId}_P.jpg`
							);
					}
				}	
			}

			setRoom(roomResponse);
		}
	};

	const onClose = () => setRoom(false);

	const roomDescriptionProps = {
		room,
		onClose
	};

	return {
		fetch,
		roomDescriptionProps
	};
};