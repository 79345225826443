import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useHotelSearchStore, useUserStore } from 'stores';

const Container = styled(Link)`
	display: inline;
	color: ${props => props.theme.darkBlue};
	font-size: 16px;
	font-weight: 500;
	line-height: normal;

	@media (min-width: 1280px) {
		font-size: 20px;
	}

	div {
		margin-top: 5px;

		@media (min-width: 1280px) {
			display: inline-block;
		}

		img {
			margin-right: 5px;

			@media (min-width: 1280px) {
				margin-right: 0;
				margin-left: 5px;
			}
		}
	}
`;

const HotelTitle = ({
	Code,
	Name,
	StarsCode,
}) => {
	const { allowTabs } = useUserStore();
	const {
		params: {
			startDate,
			endDate,
			adults = 1,
			childs = 0,
			ages = [],
		} = {},
	} = useHotelSearchStore();

	return (
		<Container to={`/hotel/${Code}/${startDate}/${endDate}/${adults}/${childs}/${ages.join(',')}`} target={allowTabs ? '_blank' : '_self'} rel="noreferrer">
			{Name}
			<div>
				<img src={`/images/star_${StarsCode <= 5 ? 'fill' : 'empty'}.png`} />
				<img src={`/images/star_${StarsCode <= 4 ? 'fill' : 'empty'}.png`} />
				<img src={`/images/star_${StarsCode <= 3 ? 'fill' : 'empty'}.png`} />
				<img src={`/images/star_${StarsCode <= 2 ? 'fill' : 'empty'}.png`} />
				<img src={`/images/star_${StarsCode <= 1 ? 'fill' : 'empty'}.png`} />
			</div>
		</Container>
	);
};

export default HotelTitle;