import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	background-color: #00C0FF;
	padding: 20px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
`;

export const PaymentMsg = () => {
	const { t } = useTranslation();

	return (
		<Container>
			{t('Пожалуйста, оплатите Ваш заказ в течение 20 минут после бронирования, иначе он будет автоматически аннулирован.')}
		</Container>
	);
};