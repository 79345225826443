import React from 'react';
import styled from 'styled-components';
import pictoSquare from 'images/picto_square.svg';
import { Rate } from './';
import noPhoto from 'images/no_photo.svg';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
`;

const NoPhoto = styled.div`
	width: 40px;
	height: 40px;
	//background: url(${noPhoto}) #4F7EA7 20px center no-repeat;
	background-color: #4F7EA7;
	background-image: url(${noPhoto});
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 20px 20px;
	margin-right: 10px;
	flex-shrink: 0;
`;

const Info = styled.div`
	min-height: 40px;
	background: #D3EFFF;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const Cover = styled.img`
	height: 40px;
	width: 40px;
	object-fit: cover;
	object-position: center;
	margin-right: 10px;
`;

const Title = styled.div`
	color: ${props => props.theme.darkBlue};
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	text-transform: uppercase;

	@media (max-width: 1279px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const Bit = styled.div`
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: #333;
	margin-right: 10px;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	align-items: center;
	
	img {
		width: 20px;
		height: 20px;
		margin-right: 5px;
		flex: 0 0 20px;
		object-fit: cover;
		object-position: center;
	}	
`;

export const Room = ({item = {}, isSelected = false, onChange = () => null, onShowDetails, hotelCode, currency}) => {
	const { t } = useTranslation();

	return (
		<Container>
			<Info>
				{(item?.ThUrl || item?.Url) ?
					<Cover src={(item.ThUrl ?? item.Url).replace('test-images', 'images')}/> :
					<NoPhoto/>
				}
				<Title>{item.RoomName}</Title>
				<div className='filler' />

				{item?.Sqaure && <Bit>
					<img src={pictoSquare} alt=''/>
					{t('Площадь')} {item?.Sqaure} {t('кв. м.')}
				</Bit>}
			</Info>

			{isSelected ?
				<Rate 
					item={item} 
					isSelected={isSelected} 
					onChange={onChange} 
					onShowDetails={onShowDetails} 
					hotelCode={hotelCode} 
					currency={currency}
				/> :
				(item.Rates ?? [item]).map((i, key) => 
					<Rate 
						key={key} 
						item={i} 
						isSelected={isSelected} 
						onChange={onChange} 
						onShowDetails={onShowDetails} 
						hotelCode={hotelCode}
						currency={currency}
					/>
				)
			}
		</Container>
	);
};