import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDevice } from 'hooks';
import { Sprite } from 'components';
import { Modal } from 'modals';
import { toMoney } from 'utils';

const Title = styled.div`
	font-weight: 500;
	font-size: 34px;
	line-height: 40px;
	color: ${props => props.theme.darkBlue};
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	/* white-space: nowrap; */

	a {
		flex-grow: 0 !important;
		width: auto;
	}
`;

const HotelName = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;	
	color: ${props => props.theme.darkBlue};
`;

const PriceTable = styled.table`
	width: 100%;
	margin-bottom: 20px;
	
	th, td {
		&:not(:last-child) {
			width: ${props => props.$withRack ? 400 : 600}px;
			text-align: left;
		}
		
		&:last-child {
			text-align: right;
			width: 200px;
		}
	}
	
	th {
		font-family: ${props => props.theme.pt};
		color: ${props => props.theme.darkBlue};
		font-weight: bold;
		font-size: 16px;
		line-height: 21px;
		padding-bottom: 10px;
		border-bottom: 1px solid ${props => props.theme.lightGray};
	}
	
	td {
		font-family: ${props => props.theme.pt};
		line-height: 40px;
		font-size: 16px;
		color: #333;
		border-bottom: 1px solid ${props => props.theme.lightGray};
	}
	
	tr.total td {
		font-weight: bold;
		font-size: 20px;
		line-height: 40px;
		
		&.title, &.price {
			color: ${props => props.theme.darkBlue};
			font-family: ${props => props.theme.rubik};
			font-weight: 500 !important;
			font-size: 20px !important;
		}

		&.rack {
			color: #4F7EA7;
		}
		
		&.commission {
			color: #00C0FF;
		}
	}
`;

const Bit = styled.div`
	font-size: 16px;
	line-height: 21px;
	color: #333;
	font-family: ${props => props.theme.pt};
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 10px;
	
	svg {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		object-fit: cover;
		object-position: center;
		margin-right: 5px;
	}
	
	img {
		margin-right: 5px;
	}
	
	* { fill: #333 !important; }
`;

const SmallTitle = styled.div`
	margin-top: 20px;
	margin-bottom: 5px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: ${props => props.theme.darkBlue};
`;

const Text = styled.div`
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: #333;	

	> svg {
		vertical-align: bottom;
	}
`;

const Close = styled.div`
	color: ${props => props.theme.darkBlue};
	position: absolute;
	top: 10px;
	right: 10px;

	@media (min-width: 1280px) {
		display: none !important;
	}
`;

const PriceTableMobile = styled.table`
	width: 100%;
	margin-bottom: 20px;

	tr {
		height: 40px;
		border-bottom: 1px solid ${props => props.theme.lightGray};

		th, td {
			vertical-align: middle;
			font-size: 16px;
			line-height: 21px;
			color: #333;

			&.com {
				color: #00C0FF;
			}

			&.title, &.price {
				color: ${props => props.theme.darkBlue};
				font-family: ${props => props.theme.rubik};
				font-weight: 500 !important;
				/* font-size: 20px !important; */
			}
		}

		td {
			text-align: right;
		}

		th {
			text-align: left;
			color: ${props => props.theme.darkBlue};

			&.date { font-weight: 500 !important; }
		}
	}
`;

export const ModalDetails = ({ 
	rate,
	details,
	onClose = () => null
}) => {
	const { t } = useTranslation();
	const { isDesktop } = useDevice();

	if (!rate || !details) {
		return null;
	}

	const isVisible = rate && details;

	const {
		PenaltySize,
		RateName = '',
		RoomName = ''
	} = rate ?? {};

	const {
		Currency: {
			Name: Currency
		} = {},
		Name: Hotel,
		TypeOfPlace: {
			Name: TypeOfPlace
		} = {},
		City: {
			Name: City
		} = {},
		Country: {
			Name: Country,
			IsDirect = 1
		} = {},
		HotelPricingDetail: {
			Price,
			RackPrice,
			RateDescription,
			PriceDescription: {
				Days: {
					Day = []
				} = {}
			} = {},
			DiscountOffer: {
				Code: DiscountOffer = 2
			} = {},
			PenaltyRuleList: {
				PenaltyRule = rate?.details?.HotelPricingDetail?.PenaltyRuleList?.PenaltyRule ?? []
			} = {},
			DeadlineTimeLoc = rate?.details?.HotelPricingDetail?.DeadlineTimeLoc,
			DeadlineTimeSys = rate?.HotelPricingDetail?.DeadlineTimeSys,
			DeadlineTimeUTC = rate?.HotelPricingDetail?.DeadlineTimeUTC,
		} = {},
	} = details ?? {};

	const includedMeal = details?.HotelPricingDetail?.Meals?.Meal?.find(i => i?.IncludedInPrice?.Code === 1);

	let cancelText = '';
	if (parseInt(IsDirect) === 2) {
		cancelText = <Text>
			{t('БЕСПЛАТНАЯ отмена заявки возможна до {{DeadlineTimeLoc}} по местному времени отеля ({{DeadlineTimeSys}} по Московскому времени, {{DeadlineTimeUTC}} по UTС).', { DeadlineTimeLoc, DeadlineTimeSys, DeadlineTimeUTC })}<br/>
			{t('Начиная с указанного времени за аннулирование или изменение заявки, а также за незаезд взимается штраф в размере')} {toMoney(PenaltySize)} {Currency}.
		</Text>;
	} else {
		if (PenaltyRule.length > 0) {
			cancelText = [];
			cancelText.push(<Text>{t('БЕСПЛАТНАЯ отмена заявки возможна до {{DeadlineTimeLoc}} по местному времени отеля ({{DeadlineTimeSys}} по Московскому времени, {{DeadlineTimeUTC}} по UTС).', { DeadlineTimeLoc, DeadlineTimeSys, DeadlineTimeUTC })}</Text>);
			cancelText.push(<Text>{t('Штрафные санкции наступают (по местному времени отеля):')}</Text>);
			PenaltyRule.forEach(i => {
				cancelText.push(<Text>{t('с')} {i?.DeadlineTimeLoc} {i?.ValueCurrencyCode === 1 ? `${i?.Value}${i?.CalculationRuleName}` : ''} {t('в размере')} {toMoney(i?.PenaltySize)} {Currency}</Text>);
			});
		} else {
			if (PenaltySize > 0) {
				cancelText = <Text>
					{t('БЕСПЛАТНАЯ отмена заявки возможна до {{DeadlineTimeLoc}} по местному времени отеля ({{DeadlineTimeSys}} по Московскому времени, {{DeadlineTimeUTC}} по UTС).', { DeadlineTimeLoc, DeadlineTimeSys, DeadlineTimeUTC })}<br/>
					{t('Начиная с указанного времени за аннулирование или изменение заявки, а также за незаезд взимается штраф в размере')} {toMoney(PenaltySize)} {Currency}.
				</Text>;
			} else {
				cancelText = <Text>{t('БЕСПЛАТНАЯ отмена заявки возможна до {{DeadlineTimeLoc}} по местному времени отеля ({{DeadlineTimeSys}} по Московскому времени, {{DeadlineTimeUTC}} по UTС).', { DeadlineTimeLoc, DeadlineTimeSys, DeadlineTimeUTC })}</Text>;
			}
		}
	}

	return (
		<Modal isVisible={isVisible} onClose={onClose} fixed>
			<Close onClick={onClose}>
				<Sprite icon='blue_cross' />
			</Close>

			<Title>
				{t('Детализация стоимости и условия бронирования')}
			</Title>
			<HotelName>{Hotel}, {TypeOfPlace} {City}, {Country}</HotelName>
			<HotelName>{t('Номер')} {RoomName}</HotelName>
			<HotelName>{t('Тариф')} {RateName}</HotelName>
			<br />

			{isDesktop 
				? <PriceTable $withRack={RackPrice !== undefined}>
					<thead>
						<tr>
							<th>{t('Дата')}</th>
							{RackPrice !== undefined && <th>{t('Стоимость в отеле')}</th>}
							<th>{t('Стоимость')}</th>
						</tr>
					</thead>
					<tbody>
						{Day.map((i, key) =>
							<tr key={key}>
								<td>{i.Date}</td>
								{RackPrice !== undefined && <td>{toMoney(i.RackPrice)} {Currency}</td>}
								<td>{toMoney(i.Price)} {Currency}</td>
							</tr>
						)}
						<tr className='total'>
							<td className='title'>{t('Итого')}:</td>
							{RackPrice !== undefined && <td className='rack'>{toMoney(RackPrice)} {Currency}</td>}
							<td className='price'>{toMoney(Price)} {Currency}</td>
						</tr>
					</tbody>
				</PriceTable>
				: <PriceTableMobile>
					<tbody>
						{Day.map((i, key) =>
							<React.Fragment key={key}>
								<tr>
									<th className='date'>{t('Дата')}</th>
									<td>{i.Date}</td>
								</tr>
								{RackPrice !== undefined && 
									<tr>
										<th>{t('Стоимость в отеле')}</th>
										<td>{toMoney(i.RackPrice)} {Currency}</td>
									</tr>
								}
								<tr>
									<th>{t('Стоимость')}</th>
									<td>{toMoney(i.Price)} {Currency}</td>
								</tr>
							</React.Fragment>
						)}

						<tr>
							<th className='date'>{t('ИТОГО')}</th>
							<td />
						</tr>

						{RackPrice !== undefined && <tr>
							<th>{t('Стоимость в отеле')}</th>
							<td>{toMoney(RackPrice)} {Currency}</td>
						</tr>}

						<tr>
							<th className='date'>{t('Стоимость')}</th>
							<td className='price'>{toMoney(Price)} {Currency}</td>
						</tr>
					</tbody>
				</PriceTableMobile>
			}

			<div className='flex row alignCenter'>
				<Bit onClick={e => e.preventDefault()}>
					<Sprite icon={`payment_${details?.HotelPricingDetail?.WhereToPayList?.WhereToPay?.[0]?.Code === 2 ? 'at_counter' : 'cashless'}`} />
					{details?.HotelPricingDetail?.WhereToPayList?.WhereToPay?.[0]?.Name}
				</Bit>
				
				<Bit>
					<Sprite icon={`${includedMeal === undefined ? 'without' : 'with'}_meal`} />
					{includedMeal === undefined ? t('Без питания') : includedMeal?.Name}
				</Bit>
			</div>

			{(RateDescription || DiscountOffer === 1) &&
				<>
					<SmallTitle>{t('Описание тарифа')}</SmallTitle>
					{RateDescription}
					{DiscountOffer === 1 &&
						<Text>
							<Sprite icon='orange_info' />&nbsp;
							{t('«Невозвратный тариф» предполагает самую выгодную цену при условии, что гость уплатит отелю стоимость его проживания,  даже если не заселится.')}
						</Text>
					}
				</>
			}

			<SmallTitle>{t('Штрафные санкции')}</SmallTitle>
			{Array.isArray(cancelText) ? cancelText.map((i, key) => <div key={key}>{i}</div>) : cancelText}
		</Modal>
	);
};