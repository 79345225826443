export const useRoomProps = ({ orderInfo, setOrderInfo, offer, setRate = () => false, currency, canChangeProduct }) => {
	
	const onChange = Product => {
		if (canChangeProduct) {
			setOrderInfo(prev => ({
				...prev,
				Product
			}));
		}
	};

	const roomProps = {
		item: offer,
		isSelected: true,
		onShowDetails: rate => setRate(rate),
		hotelCode: orderInfo.Hotel,
		currency,
		onChange,
	};

	return {
		roomProps
	};
};