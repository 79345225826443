import React from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Sprite } from './Sprite';

const styles = css`
	cursor: pointer;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	min-height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 5px;
	text-transform: uppercase;
	border-radius: 5px;
	outline: none !important;
	padding: 0 20px;
	user-select: none !important;
	transition: background-position .2s ease-in-out, background-color .2s ease-in-out, color .2s ease-in-out;
	color: white !important;
	background-color: white;
	width: ${props => props.$width};
	outline: none;
	border: none;
	position: relative;

	&.gradient {
		background-color: #0270CB;
		background-image: linear-gradient(180deg, #22AFFE 0%, #0270CB 100%);
		background-size: 100% calc(100% + 10px);
		background-position: 0 0;

		&:hover {
			background-position: 0 -10px;
		}
	}

	&.cashback {
		background: linear-gradient(180deg, #00C0FF 0%, #0F754E 100%);
		color: white;
	}

	&.blue {
		border: 2px solid ${props => props.theme.darkBlue};
		background-color: ${props => props.theme.darkBlue};

		&:hover {
			color: ${props => props.theme.darkBlue} !important;
			background-color: white;
		}
	}

	&.orange {
		background-image: linear-gradient(180deg, #FEB446 0%, #FF6B00 100%);
		background-size: 100% calc(100% + 10px);
		background-position: 0 0;

		&:hover {
			background-position: 0 -10px;
		}
	}

	&.white {
		color: ${props => props.theme.darkBlue} !important;
		border: 2px solid ${props => props.theme.darkBlue};

		&:hover {
			color: white !important;
			background-color: ${props => props.theme.darkBlue};
		}
	}

	&.red {
		border: 2px solid #A2001D;
		background-color: #A2001D;
	}

	&.disabled {
		border: none !important;
		color: white !important;
		background-color: #D1D1D1 !important;
		background-image: none !important;
	}

	&.small {
		min-height: 30px;
		font-size: 14px;
		line-height: 17px;
	}

	&:not(.iconInCenter) {
		svg {
			/* position: absolute;
			right: 10px; */
		}
	}
`;

const Container = styled.div`${styles}`;
const ContainerDiv = styled.div`${styles}`;
const ContainerLink = styled(Link)`${styles}`;
const ContainerButton = styled.button`${styles}`;

const Button = ({
	children = null,
	width = 'auto',
	className = '',
	icon = false,
	onClick = false,
	to = false,
	disabled,
	type = false,
	value = false,
	target = '_self',
	...props
}) => {
	if (!isNaN(width)) width = `${width}px`;
	
	if (type) {
		return (
			<ContainerButton className={classnames('components__button', className, props, { disabled })} $width={width} value={value} type={type}>
				{children}
				{icon && <Sprite icon={icon} />}
			</ContainerButton>
		);
	}

	if (disabled || (onClick === false && to === false)) {
		return (
			<Container className={classnames('components__button', className, props, { disabled })} $width={width}>
				{children}
				{icon && <Sprite icon={icon} />}
			</Container>
		);	
	}

	if (onClick !== false) {
		return (
			<ContainerDiv onClick={onClick} className={classnames('components__button', className, props, { disabled })} $width={width}>
				{children}
				{icon && <Sprite icon={icon} />}
			</ContainerDiv>
		);	
	}

	if (to !== false) {
		return (
			<ContainerLink to={to} target={target} className={classnames('components__button', className, props, { disabled })} $width={width}>
				{children}
				{icon && <Sprite icon={icon} />}
			</ContainerLink>
		);	
	}

	return null;
};

Button.propTypes = {
	children: PropTypes.node,
	gradient: PropTypes.bool,
	cashback: PropTypes.bool,
	blue: PropTypes.bool,
	orange: PropTypes.bool,
	white: PropTypes.bool,
	red: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	to: PropTypes.string,
	small: PropTypes.bool,
	icon: PropTypes.string,
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
};

export { Button };