import React, { createContext, useContext, useState } from 'react';

const useMsg = () => {
	const [ messages, setMessages ] = useState([]);

	const show = msg => msg.trim().length > 0 && setMessages(prev => {
		prev.push(msg);
		return [...prev];
	});

	const clear = () => setMessages([]);

	return {
		messages,
		show,
		clear,
	};
};

const MsgContext = createContext(null);

const MsgProvider = ({ children }) => {
	const msgStore = useMsg();

	return (
		<MsgContext.Provider value={msgStore}>
			{children}
		</MsgContext.Provider>
	);
};

const useMsgStore = () => {
	const context = useContext(MsgContext);

	if (context === undefined) {
		throw new Error('useMsgStore must be used within a MsgProvider');
	}

	return context;
};

export {
	MsgProvider,
	useMsgStore,
};