/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { Sprite } from 'components';
import { useClickOutside } from 'hooks';
import { Checkbox } from 'forms';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	height: 40px;
	border: 1px solid ${props => props.theme.darkBlue};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;
	position: relative;

	> span {
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
		color: ${props => props.theme.darkBlue};
		max-width: calc(100% - 20px - 12px - 10px);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		cursor: default;
	}

	&.isOpen ul {
		opacity: 1;
		visibility: visible;
	}
`;

const Items = styled.ul`
	background-color: white;
	position: absolute;
	z-index: 10;
	left: -1px;
	right: -1px;
	top: 100%;
	border: 1px solid ${props => props.theme.darkBlue};
	max-height: 200px;
	overflow: auto;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	padding-top: 10px;

	li {
		padding: 0 10px;
		margin-bottom: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
		color: #333;

		.checkbox { margin-bottom: 0; }
	}
`;

export const DropDownCheckList = ({
	items,
	allItemsLabel,
	value,
	onChange
}) => {
	const { t } = useTranslation();
	const [ isOpen, setOpen ] = useState(false);
	const { ref } = useClickOutside(() => setOpen(false));

	const onClick = e => (e.target.contains(ref?.current) || ['svg', 'span'].includes(e.target.tagName.toLowerCase())) && setOpen(prev => !prev);

	const onCheck = val => {
		if (val === 'all') {
			onChange(items.map(i => i.value));
		} else {
			onChange(prev => {
				const index = prev.findIndex(i => i === val);

				if (index === -1) {
					prev.push(val);
				} else {
					prev.splice(index, 1);
				}

				return [...prev];
			});
		}
	};

	return (
		<Container className={classnames('drop_down_check_list', { isOpen })} onClick={onClick} ref={ref}>
			<span>{value.length === items.length ? allItemsLabel : value.join('; ')}</span>
			<Sprite icon='arrow_down' />

			<Items>
				<li>
					<Checkbox
						checked={value.length === items.length}
						label={t('Все')}
						onChange={onCheck}
						value='all'
					/>
				</li>
				{items.map((i, key) => 
					<li key={key}>
						<Checkbox checked={value.includes(i.value)} label={i.label} onChange={onCheck} value={i.value} />
					</li>
				)}
			</Items>
		</Container>
	);
};