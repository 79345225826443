/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectExceptOnMount, useInstance } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useCreateOrder, createTransport } from 'utils';
import { useUserStore } from 'stores';
import { useRegister } from 'hooks';
import { useTranslation } from 'react-i18next';

const checkTime = (date, time) => {
	const dateTime = new Date(`${date} ${time}`.replace(/^(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2}).*$/, '$3-$2-$1 $4:$5'));
	return ((dateTime.getTime() - (new Date()).getTime()) / 1000 / 3600) >= 3;
};

const useTransferBooking = () => {
	const { t } = useTranslation();
	const { post } = useInstance();
	const navigate = useNavigate();
	const params = useParams();
	const { create: createOrder } = useCreateOrder();
	const { user, isLogged } = useUserStore();
	const { registerBind, re } = useRegister();

	const [ order, setOrder ] = useState(null);
	const [ transfer, setTransfer ] = useState(null);
	const [ service, setService ] = useState(null);

	// const { id } = useCartStore();

	const fetchOrder = async Id => {
		const request = {
			OrderInfoRequest: {
				Id
			}
		};

		const data = await post(request);

		if (!data) {
			navigate('/findorders');
			return false;
		}

		return data;
	};

	const fetchService = async Parameters => {
		const request = {
			TransferSearchRequest: {
				Action: {
					Name: 'ServiceInfo',
					Parameters
				}
			}
		};

		const data = await post(request);
		setService(data?.AvailableTransfersList?.Transfer?.[0] ?? false);
	};

	useEffect(() => {
		const func = async () => {
			const {
				ServiceDate,
				ServiceCode,
				transferId,
			} = params;

			const order = !transferId ? (await createOrder()).OrderRequest : (await fetchOrder(transferId));

			let transfer;

			transferId > 0 && (transfer = (order?.TransportList?.Transport ?? []).find(i => parseInt(i.Id) === parseInt(transferId)));
			transferId < 0 && (transfer = (JSON.parse(sessionStorage.getItem('transfers')) ?? []).find(i => parseInt(i.Id) === parseInt(transferId)));

			if (!transferId) {
				const Id = [
					order.AccommodationList.Accommodation.filter(i => i.Id < 0).map(i => i.Id),
					order.PassiveList.Passive.filter(i => i.Id < 0).map(i => i.Id),
					order.TransportList.Transport.filter(i => i.Id < 0).map(i => i.Id),
				].flat().reduce((acc, cur) => cur > acc ? cur : acc, -100000) + 1;

				transfer = createTransport({
					Id,
					ServiceCode,
					ServiceDate,
					IsGain: order?.AccountDetails?.IsGain?.Code ?? 3
				});
			}

			setTransfer(transfer);
			setOrder(order);
		};
		func();
	}, []);

	useEffectExceptOnMount(() => {
		const func = async () => {
			if (params?.transferId) {
				const order = (await createOrder()).OrderRequest;
				setOrder(order);
			}
		};
		func();
	}, [user?.Id, params?.transferId]);

	useEffect(() => {
		if (!transfer?.ServiceDate || !transfer?.Service?.Code || !order?.Currency || !order?.WhereToPay) return;

		fetchService({
			ArrivalDate: transfer?.ServiceDate,
			ServiceId: transfer?.Service?.Code,
			WhereToPay: order?.WhereToPay?.Code ?? 3,
			Currency: order?.Currency?.Code ?? 2
		});
	}, [
		transfer?.ServiceDate,
		transfer?.Service?.Code,
		order?.Currency,
		order?.WhereToPay
	]);

	const {
		Currency,
		WhereToPay,
		AccountDetails: {
			IsGain: {
				Code: IsGain = 3
			} = {}
		} = {}
	} = order ?? {};

	// const globalAllowModify = transfer?.Id <= 0 || (parseInt(order?.ReadOnly?.Code ?? 2) !== 1 && parseInt(transfer?.AllowModify?.Code ?? 1) === 1 && checkTime(transfer?.ServiceDate, transfer?.ServiceTime));
	const globalAllowModify = transfer?.Id <= 0 || (parseInt(order?.ReadOnly?.Code ?? 2) !== 1 && parseInt(transfer?.AllowModify?.Code ?? 1) === 1);

	const bind = {
		Currency,
		WhereToPay,
		IsGain,
		order,
		transfer,
		service,
		setTransfer,
		globalAllowModify,
	};

	const getPointLabel = point => {
		if (!point?.Code) return '';

		const {
			TransferPointType: {
				Code: TransferPointType,
				Name: TransferPointTypeName,
			} = {},
			TypeOfPlace: {
				Name: TypeOfPlaceName
			} = {},
			City: {
				Name: CityName
			} = {},
			AdmUnit1: {
				Code: AdmUnit1,
				Name: AdmUnit1Name,
			} = {},
			AdmUnit2: {
				Code: AdmUnit2,
				Name: AdmUnit2Name,
			} = {},
			Country: {
				Name: CountryName
			} = {},
			Hotel: {
				Name: HotelName
			} = {},
			ObjectType: {
				Name: ObjectTypeName
			} = {},
			Object: {
				Name: ObjectName
			} = {}
		} = point;

		if (parseInt(TransferPointType) === 1) {
			const place = [];

			parseInt(AdmUnit1) !== 1 && place.push(AdmUnit1Name);
			parseInt(AdmUnit1) !== 1 && parseInt(AdmUnit2) !== 1 && place.push(AdmUnit2Name);
			place.push(CountryName);

			return `${TypeOfPlaceName} ${CityName} (${place.join(', ')})`;
		} else if (parseInt(TransferPointType) === 2) {
			const place = [];

			place.push(`${TypeOfPlaceName} ${CityName}`);
			parseInt(AdmUnit1) !== 1 && place.push(AdmUnit1Name);
			parseInt(AdmUnit1) !== 1 && parseInt(AdmUnit2) !== 1 && place.push(AdmUnit2Name);
			place.push(CountryName);

			return `${TransferPointTypeName} ${HotelName} (${place.join(', ')})`;
		} else {
			const place = [];

			place.push(`${TypeOfPlaceName} ${CityName}`);
			parseInt(AdmUnit1) !== 1 && place.push(AdmUnit1Name);
			parseInt(AdmUnit1) !== 1 && parseInt(AdmUnit2) !== 1 && place.push(AdmUnit2Name);
			place.push(CountryName);

			return `${ObjectTypeName} ${ObjectName} (${place.join(', ')})`;
		}
	};

	const fromLabel = useMemo(() => getPointLabel(service?.StartPoint ?? transfer?.StartPoint), [JSON.stringify(service?.StartPoint ?? transfer?.StartPoint)]);
	const toLabel = useMemo(() => getPointLabel(service?.EndPoint ?? transfer?.EndPoint), [JSON.stringify(service?.EndPoint ?? transfer?.EndPoint)]);

	const save = async () => {
		const transferRequest = {
			...transfer,
			Price: service.TotalPrice,
			VATIncludedInPrice: service.VATIncludedInPrice,
			FullVATInPrice: service.FullVATInPrice,
			TravelAgencyCommission: service.TravelAgencyCommission,
			Penalty: service.PenaltySize,
			ServiceName: service.ServiceName,
			Transport: service.TransportType,
			StartPoint: service.StartPoint,
			EndPoint: service.EndPoint,
			FlightDate: transfer.FlightDate ? transfer.FlightDate : transfer.ServiceDate,
			NumberOfServices: 1,
			Availability: service.Availability,
			DeadlineDate: service.DeadlineDate,
			ToBeChanged: {
				Code: 1
			}
		};
		
		const orderRequest = {
			...order,
		};

		const items = [...(orderRequest?.TransportList?.Transport ?? [])];

		if (params.transferId < 0 || !params.transferId) {
			items.push(transferRequest);
	
			orderRequest.TransportList = {
				Transport: items
			};
			// orderRequest.RegistrationDate = todayString()
		} else if (params.transferId > 0) {
			const index = items.findIndex(i => parseInt(i.Id) === parseInt(transferRequest.Id));

			if (index !== -1) {
				items[index] = transferRequest;
			}

			orderRequest.TransportList = {
				Transport: items
			};
		}

		const data = await post({
			OrderRequest: orderRequest
		});

		if (data && data?.Success?.Id) {
			if (params.transferId < 0) {
				const items = JSON.parse(sessionStorage.getItem('transfers')) ?? [];
				const index = items.findIndex(i => parseInt(i.Id) === parseInt(transferRequest.Id));

				if (index !== -1) {
					items.splice(index, 1);
					sessionStorage.setItem('transfers', JSON.stringify(items));
				}
			}

			navigate(`/order/${data?.Success?.Id}`);
		}
	};

	const onSave = async () => {
		await save();
	};

	let errors = [
		((transfer?.ServiceTime ?? '') !== '' && !checkTime(transfer?.ServiceDate, transfer.ServiceTime)) ? t('До времени подачи должно оставаться не менее трех часов') : false,
		(parseInt(service?.StartPoint?.TransferPointType?.Code) === 1 && (transfer?.AddressFrom ?? '').length < 3) ? t('Адрес подачи автомобиля не указан') : false,
		(parseInt(service?.EndPoint?.TransferPointType?.Code) === 1 && (transfer?.AddressTo ?? '').length < 3) ? t('Адрес отправления автомобиля не указан') : false,
		(transfer?.ServiceTime ?? '') === '' ? t('Время подачи не указано') : false,
		(transfer?.FlightNumber ?? '').length < 3 ? t('Номер рейса не указан') : false,
		(transfer?.NameBoard ?? '').length < 3 ? t('Текст для таблички встречающего не указан') : false,
		(transfer?.PassengerPhone ?? '').length < 7 ? t('Мобильный телефон пассажира для связи не указан') : false,
	];

	errors = errors.filter(i => i !== false);

	return {
		errors,
		orderId: parseInt(order?.Id ?? 0),
		transferId: parseInt(transfer?.Id ?? -99999),
		fromLabel,
		toLabel,
		transfer,
		setTransfer,
		globalAllowModify,
		isCityStartPoint: parseInt(service?.StartPoint?.TransferPointType?.Code ?? transfer?.StartPoint?.TransferPointType?.Code) === 1,
		isCityEndPoint: parseInt(service?.EndPoint?.TransferPointType?.Code ?? transfer?.EndPoint?.TransferPointType?.Code) === 1,
		isLoaded: order?.Id !== undefined && transfer?.Id !== undefined && service !== null,
		service,
		capacity: parseInt(service?.Capacity ?? 1),
		Currency,
		WhereToPay,
		order,
		onSave,
		bind,
		registerBind,
	};
};

const TransferBookingContext = createContext(null);

const TransferBookingProvider = ({ children }) => {
	const transferBookingStore = useTransferBooking();

	return (
		<TransferBookingContext.Provider value={transferBookingStore}>
			{children}
		</TransferBookingContext.Provider>
	);
};

const useTransferBookingStore = () => {
	const context = useContext(TransferBookingContext);

	if (context === undefined) {
		throw new Error('useErrorStore must be used within a TransferBookingProvider');
	}

	return context;
};

export {
	TransferBookingProvider,
	useTransferBookingStore
};
