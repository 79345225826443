import React from 'react';
import styled from 'styled-components';
import { Modal2 } from 'modals';
import iconTime from 'images/icon_error_time.svg';
import iconFatal from 'images/icon_error_fatal.svg';
import { useTranslation } from 'react-i18next';
import { useErrorStore } from 'stores';
// import { Button } from 'components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 10px;

	> * {
		/* border: 1px solid red; */
	}

	> img {
		height: 30px;
		width: 30px;
		object-fit: contain;
		object-position: center;
		flex-shrink: 0;
		flex-grow: 0;
	}

	h2 {
		margin: 0 !important;
	}

	> p {
		font-weight: 400 !important;
		font-size: 18px !important;
		line-height: 23px !important;

		& + .button {
			margin-top: 10px;
		}

		& + p {
			margin-top: -5px;
		}
	}

	.button {
		width: 240px;
	}
`;

export const ModalError = () => {
	const { t } = useTranslation();

	const { error, isError, clearErrors } = useErrorStore();

	const {
		Code = false,
		Type: {
			Code: TypeCode,
			Name: TypeName,
		} = {},
		Description = '',
		Pointer = '',
	} = error ?? {};

	const handleClose = () => clearErrors();

	return (
		<Modal2 isVisible={isError} onClose={handleClose}>
			<Container>
				{TypeCode === 1 && <img src={iconFatal} alt={TypeName} />}
				{TypeCode === 2 && <img src={iconTime} alt={TypeName} />}
				{TypeCode === 3 && <img src={iconFatal} alt={TypeName} />}
				
				{Code > 0 &&
					<h2>
						{t('Ошибка')} {Code}
					</h2>
				}

				<h2>
					{Description}
				</h2>
				
				{!!Pointer &&
					<p dangerouslySetInnerHTML={{ __html: Pointer }} />
				}

				<p>
					{TypeCode === 1 && t('Ошибка сервиса. Мы уже работаем над ее устранением')}
					{TypeCode === 2 && t('Пожалуйста, повторите действие позднее')}
					{TypeCode === 3 && t('Проверьте корректность Ваших действий и попробуйте снова')}
				</p>

				<div className='button white' onClick={handleClose}>
					OK
				</div>
			</Container>
		</Modal2>
	);
};
