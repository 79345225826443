import { useEffect, useState } from 'react';
import { defaultOrderInfo, defaultPerson } from './';
import { useNavigate } from 'react-router-dom';
import { useInstance } from 'hooks';
import { useCartStore } from 'stores';

export const useOrderInfo = ({ 
	// orderId,
	AccommodationId: accommodationId,
	Hotel,
	Product,
	ArrivalDate,
	DepartureDate,
	NumberOfGuests,
	NumberOfExtraBedAdult,
	NumberOfExtraBedChild,
	NumberOfExtraBedInfant
}) => {
	const { id } = useCartStore();
	const [ accommodation, setAccommodation ] = useState(false);
	const [ order, setOrder ] = useState(false);
	const [ orderInfo, setOrderInfo ] = useState(defaultOrderInfo);
	const navigate = useNavigate();

	const { post } = useInstance();

	const fetchOrder = async Id => {
		const request = {
			OrderInfoRequest: {
				Id
			}
		};
		const data = await post(request);
		setOrder(data.Error ? false : data);
	};

	useEffect(() => {
		accommodationId > 0 && fetchOrder(accommodationId);
	}, [accommodationId]);

	useEffect(() => {
		if (accommodationId > 0) {
			const item = order?.AccommodationList?.Accommodation?.find(i => parseInt(i.Id) === parseInt(accommodationId)) ?? false;
			setAccommodation(item);
		}

		if (accommodationId < 0) {
			try {
				const items = JSON.parse(sessionStorage.getItem('accommodations'));
				const item = items.find(i => parseInt(i.Id) === parseInt(accommodationId));
				
				if (!item) {
					throw new Error();
				}

				setAccommodation(item);
			} catch (e) {
				navigate(id === 0 ? `/findorders` : `/order/${id}`);
			}
		}
	}, [order, accommodationId]);

	useEffect(() => {
		if (order && accommodation) {
			setOrderInfo({
				AdditionalInfo: accommodation?.AdditionalInfo,
				ArrivalDate: accommodation?.ArrivalDate,
				DepartureDate: accommodation?.DepartureDate,
				ArrivalTime: accommodation?.ArrivalTime,
				DepartureTime: accommodation?.DepartureTime,
				NumberOfGuests: accommodation?.NumberOfGuests ?? 1,
				NumberOfExtraBedsAdult: accommodation?.NumberOfExtraBedsAdult ?? 0,
				NumberOfExtraBedsChild: accommodation?.NumberOfExtraBedsChild ?? 0,
				NumberOfExtraBedsInfant: accommodation?.NumberOfExtraBedsInfant ?? 0,
				Product: accommodation?.Product?.Code ? parseInt(accommodation?.Product?.Code) : -99999,
				Hotel: accommodation?.Hotel?.Code,
				Persons: accommodation?.Persons?.Person ?? [],
				CriticalEarlyCheckIn: (accommodation?.CriticalEarlyCheckIn?.Code ?? 1) === 1,
				CriticalLateCheckOut: (accommodation?.CriticalLateCheckOut?.Code ?? 1) === 1,
				Id: order?.Id,
				AccommodationId: accommodation?.Id ?? -99999,
				Currency: order?.Currency?.Code ?? 2,
				WhereToPay: order?.WhereToPay?.Code ?? 3,
				Meal: accommodation?.Meal?.Code ?? 0,
				ExtraMeals: accommodation?.ExtraMeals?.ExtraMeal ?? [],
			});
		} else if (parseInt(accommodationId ?? 0) === 0) {
			setOrderInfo({
				...defaultOrderInfo,
				Id: 0 ,
				Hotel,
				Product,
				NumberOfGuests: parseInt(NumberOfGuests),
				NumberOfExtraBedsAdult: parseInt(NumberOfExtraBedAdult),
				NumberOfExtraBedsChild: parseInt(NumberOfExtraBedChild),
				NumberOfExtraBedsInfant: parseInt(NumberOfExtraBedInfant),
				CriticalEarlyCheckIn: (accommodation?.CriticalEarlyCheckIn?.Code ?? 1) === 1,
				CriticalLateCheckOut: (accommodation?.CriticalLateCheckOut?.Code ?? 1) === 1,
				ArrivalDate,
				DepartureDate,
				ArrivalTime: '',
				DepartureTime: '',
				Persons: [defaultPerson]
			});
		}
	}, [accommodation]);

	return {
		accommodation,
		order,
		orderInfo,
		setOrderInfo,
	};
};