import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Container = styled.div`
	z-index: 100;
	background-color: white;
	position: fixed;
	padding: 40px 20px;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;
	border: 1px solid ${props => props.theme.inputBorderColor};
	box-shadow: 5px 5px 5px rgba(33, 33, 33, .3);

	@media (min-width: 1280px) {
		padding: 20px;
		width: ${props => isNaN(Number(props.$width)) ? props.$width : `${props.$width}px`};
		height: auto;
		max-width: calc(100vw - 80px);
		max-height: calc(100vh - 80px);
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
`;

const Close = styled.div`
	width: 20px;
	height: 20px;
	background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.50499 0.504989C1.17831 -0.168328 2.26997 -0.168328 2.94329 0.504989L19.495 17.0567C20.1683 17.73 20.1683 18.8217 19.495 19.495C18.8217 20.1683 17.73 20.1683 17.0567 19.495L0.50499 2.94329C-0.168328 2.26997 -0.168328 1.17831 0.50499 0.504989Z' fill='%23004685'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.495 0.504988C20.1683 1.17831 20.1683 2.26997 19.495 2.94329L2.94329 19.495C2.26997 20.1683 1.17831 20.1683 0.504988 19.495C-0.168329 18.8217 -0.168329 17.73 0.504988 17.0567L17.0567 0.504988C17.73 -0.168329 18.8217 -0.168329 19.495 0.504988Z' fill='%23004685'/%3E%3C/svg%3E%0A");
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1000;
`;

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #004685CC;
	z-index: 100;
	display: none;

	@media (min-width: 1280px) {
		display: block;
	}
`;

const Portal = ({ isOpen = false, onClose = () => false, width = 560, className, style = {}, portalStyle = {}, children = null }) => {
	const el = useRef(document.createElement('div'));
	const root = document.getElementById('root');

	useEffect(() => {
		document.querySelector('html').style.overflow = isOpen ? 'hidden' : 'hidden scroll';
		// document.querySelector('body').style.overflow = isOpen ? 'hidden' : 'hidden scroll';
	}, [isOpen]);

	useEffect(() => {
		root.appendChild(el.current);
		return () => root.removeChild(el.current);
	}, []);

	const handleClose = () => {
		document.querySelector('html').style.overflow = 'hidden scroll';
		// document.querySelector('body').style.overflowY = 'hidden scroll';
		onClose();
	};

	return isOpen
		? ReactDOM.createPortal(
			<>
				<Overlay onClick={handleClose} style={portalStyle} />

				<Container $width={width} className={className} style={style}>
					<Close onClick={handleClose} />

					{children}
				</Container>
			</>, el.current
		)
		: null;
};

export default Portal;