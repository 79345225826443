import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Sprite } from 'components';
import { parseDate, toMoney } from 'utils';

const OrderButton = styled.a`
	cursor: pointer;
	width: 200px;
	height: 30px !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center !important;
	border-radius: 5px;
	background: linear-gradient(180deg, #22AFFE 0%, #0270CB 100%);
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 17px !important;
	color: white !important;
	text-transform: uppercase;
	font-family: 'Rubik', sans-serif !important;
	margin-top: 5px !important;

	@media (max-width: 659px) {
		width: 100%;
	}

	&.orange {
		background: linear-gradient(180deg, #FEB446 0%, #FF6B00 100%);
	}

	svg {
		margin-left: 10px;
	}
`;

const RateContainer2 = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	padding: 10px 0;
	
	&:nth-of-type(n + 2) { border-top: 1px solid ${props => props.theme.lightGray}; }
`;

const Column1 = styled.div`
	margin-right: 10px;
	flex-grow: 1;
	
	> *:nth-child(n + 2) { margin-top: 10px; }
`;

const Column2 = styled.div`
	margin-right: 10px;
	width: calc((100% - 200px - 20px) / 2);
	> *:nth-child(n + 2) { margin-top: 10px; }
`;

const Column3 = styled.div`
	width: 200px;

	@media (max-width: 659px) {
		width: 100%;

		.button {
			width: 100%;
		}
	}
`;

const Bit = styled.div`
	font-size: 16px;
	line-height: 21px;
	color: #333;
	font-family: ${props => props.theme.pt};
	display: flex;
	flex-direction: row;
	align-items: center;

	&.orange {
		color: ${props => props.theme.orange};
	}

	&.bold {
		font-weight: bold;
	}

	&.pointer {
		cursor: pointer;
	}
	
	svg {
		margin-right: 5px;
	}
	
	* { fill: #333 !important; }

	&.mobilephone {
		.buttonAsLink:not(:nth-of-type(1)) {
			margin-left: 20px;
		}		
	}
`;

const BitLink = styled.a`
	font-size: 16px;
	line-height: 21px;
	color: ${props => props.theme.darkBlue};
	transition: color .2s ease-in-out;
	font-family: ${props => props.theme.pt};
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	cursor: pointer;
	
	&:hover {
		color: ${props => props.theme.lightBlue};
	}

	svg {
		margin-right: 5px;
	}
`;

const BitSum = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: ${props => props.theme.darkBlue};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	white-space: nowrap;
	cursor: pointer;

	&.nds { color: #00C0FF; }
	
	&:nth-of-type(n + 2) { margin-top: 10px; }	

	svg {
		margin-right: 5px;
	}

	&.price {
		@media (max-width: 659px) {
			flex-direction: column;
			align-items: flex-end;
		}
	}
`;

const RackPriceDiv = styled.div`
	font-weight: inherit;
	color: #4F7EA7;
	margin-right: 10px;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	font-size: 14px;

	@media (max-width: 659px) {
		margin-right: 0;
		margin-bottom: 10px;
	}
`;

const DescrAndAddContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

export const RateNew = ({
	rate = {}, 
	// hotelName, 
	// hotelStars, 
	hotelCode = 0, 
	onShowDetails = () => null, 
	orderUrl,
	// filters
}) => {
	const { t } = useTranslation();

	const isNewOrder = (parseInt(sessionStorage?.getItem('orderId')) ?? 0) === 0;

	// const offer = {...rate, HotelName: hotelName, Stars: hotelStars, filters: {...filters}};

	const {
		DeadlineTimeLoc,
		RackPrice,
		TotalPrice,
		MealIncludedInPrice: {
			Code: MealIncludedInPrice,
			Name: MealIncludedInPriceName,
		},
		WhereToPay: {
			Code: WhereToPay,
			Name: WhereToPayName
		},
		Availability: {
			Code: Availability,
			Name: AvailabilityName,
			Allotment: {
				Rooms
			}
		},
		DiscountOffer: {
			Code: DiscountOffer = 2
		} = {},
		RateName,
		IsCancellationPolicyCode = 1
	} = rate;

	return (
		<RateContainer2>
			<Column1 className='notmobilephone'>
				<Bit className='desktop'>
					<Sprite icon={`${MealIncludedInPrice === 1 ? 'without' : 'with'}_meal`} />
					{MealIncludedInPrice === 1 ? t('Без питания') : MealIncludedInPriceName}
				</Bit>

				<Bit className='mobile'>
					<Sprite icon={`${MealIncludedInPrice === 1 ? 'without' : 'with'}_meal`} />
					<Sprite icon={`payment_${WhereToPay === 2 ? 'at_counter' : 'cashless'}`} />
				</Bit>

				{parseInt(IsCancellationPolicyCode) === 1
					? <Bit className={classnames({orange: parseDate(DeadlineTimeLoc) < new Date(), bold: parseDate(DeadlineTimeLoc) < new Date()})}>
						<Sprite icon={parseDate(DeadlineTimeLoc) < new Date() ? 'clock_orange' : 'clock'} />
						{parseDate(DeadlineTimeLoc) < new Date()
							? t('Штраф наступил')
							: `${t('Штраф с')} ${DeadlineTimeLoc}`
						}					
					</Bit>
					: <Bit className={classnames('orange', 'bold', 'pointer')} onClick={onShowDetails.bind(this, rate, hotelCode)}>
						<Sprite icon='clock_orange' />
						{t('Информация о штрафе')}
					</Bit>
				}

				<DescrAndAddContainer>
					<BitLink className={classnames('buttonAsLink', {orange: DiscountOffer === 1})} onClick={onShowDetails.bind(this, rate, hotelCode)}>
						<Sprite icon={DiscountOffer === 1 ? 'orange_info' : 'info'} />
						{DiscountOffer === 1
							? t('Специальное предложение')
							: `${RateName.slice(0, 29)}${RateName.length > 30 ? '...' : ''}`
						}
					</BitLink>&nbsp;&nbsp;&nbsp;
				</DescrAndAddContainer>
			</Column1>

			<Column2 className='desktop'>
				<Bit>
					<Sprite icon={`payment_${WhereToPay === 2 ? 'at_counter' : 'cashless'}`} />
					{WhereToPayName}
				</Bit>
			</Column2>

			<Column3>
				<Bit className='mobilephone'>
					<Sprite icon={`${MealIncludedInPrice === 1 ? 'without' : 'with'}_meal`} />
					<Sprite icon={`payment_${WhereToPay === 2 ? 'at_counter' : 'cashless'}`} />
					<div className='filler' />

					<BitLink className='buttonAsLink' onClick={e => parseInt(IsCancellationPolicyCode) === 1 ? false : onShowDetails(rate, hotelCode, e)}>
						<Sprite icon={parseInt(IsCancellationPolicyCode) === 2 ? 'clock_orange' : 'clock'} />
					</BitLink>

					<BitLink className='buttonAsLink' onClick={onShowDetails.bind(this, rate, hotelCode)}>
						<Sprite icon={DiscountOffer === 1 ? 'orange_info' : 'info'} />
					</BitLink>
				</Bit>

				<BitSum onClick={onShowDetails.bind(this, rate, hotelCode)} className='price'>
					{(RackPrice ?? false) && 
						<RackPriceDiv title={t('Стоимость в отеле')}>
							Rack {toMoney(RackPrice)} RUB
						</RackPriceDiv>
					}
					{DiscountOffer === 1 && <Sprite icon='orange_info' />}
					{toMoney(TotalPrice)} RUB
				</BitSum>

				<OrderButton href={orderUrl(rate)} target={isNewOrder ? '_blank' : '_self'} className={classnames({orange: Availability === 4})}>
					{Availability === 4 ?
						t('Под запрос') :
						`${t('В наличии')}: ${Rooms !== '' ? Rooms : (AvailabilityName.toLowerCase().indexOf('свободная продажа') === -1 ? AvailabilityName : t('много'))}`
					}
					<Sprite icon='arrow_right_adaptive' />
				</OrderButton>
			</Column3>
		</RateContainer2>
	);
};