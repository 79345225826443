import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import cardMir from 'images/cashback_mir_logo.png';
import { Link } from 'react-router-dom';

const Container = styled(Link)`
	color: #0F754E !important;
	display: flex !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	white-space: nowrap !important;
	flex-grow: 1;
	width: 100%;
	align-items: center;

	img {
		margin-top: -1px;
	}
`;

export const CashBackMir = () => {
	const { t } = useTranslation();

	return (
		<Container to='/cashback' className='cashback_mir'>
			{t('КЕШБЭК')}
			&nbsp;
			<img src={cardMir} />
		</Container>
	);
};