export const defaultPerson = {
	FirstName: '',
	LastName: '',
	Category: {
		Code: 0,
		Name: ''
	},
	Citizenship: {
		Code: 0,
		Name: ''
	}
};
