import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ScrollToTop } from 'utils/ScrollToTop';
import { useUserStore } from 'stores';

const About = lazy(() => import('pages/About'));
const City = lazy(() => import('pages/City'));
const Contacts = lazy(() => import('pages/Contacts'));
const Countries = lazy(() => import('pages/Countries'));
const Country = lazy(() => import('pages/Country'));
const Hotel = lazy(() => import('pages/Hotel'));
const Main = lazy(() => import('pages/Main'));
const NewsItem = lazy(() => import('pages/NewsItem'));
const NewsItems = lazy(() => import('pages/NewsItems'));
const HotelBookingOld = lazy(() => import('pages/HotelBookingOld'));
const HotelBooking = lazy(() => import('pages/HotelBooking'));
const Order = lazy(() => import('pages/Order'));
const Orders = lazy(() => import('pages/Orders'));
const Personal = lazy(() => import('pages/Personal'));
const HotelSearch = lazy(() => import('pages/HotelSearch'));
const TransfersSearch = lazy(() => import('pages/TransfersSearch'));
const TransfersBooking = lazy(() => import('pages/TransfersBooking'));
const Cashback = lazy(() => import('pages/Cashback'));
const LegalDocs = lazy(() => import('pages/LegalDocs'));
const Rules = lazy(() => import('pages/Rules'));
const PersonalDataPolicy = lazy(() => import('pages/PersonalDataPolicy'));

export const Router = () => {
	const { isLogged } = useUserStore();
	const { allowTransfers } = useUserStore();

	return (
		<>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Main />} />
				<Route path="/login" element={<Main />} />
				<Route path="/about" element={<About />} />
				<Route path="/personal_data_policy" element={<PersonalDataPolicy />} />
				{allowTransfers &&
					<>
						<Route path="/transfers" element={<Main />} />
						<Route path="/transfers/search" element={<TransfersSearch />} />
						<Route path="/transfers/booking/:ServiceDate/:ServiceCode" element={<TransfersBooking />} />
						<Route path="/transfers/booking/:fromType/:fromCode/:toType/:toCode/:date/:code" element={<TransfersBooking />} />
						{isLogged && <Route path="/transfers/booking/:ServiceDate/:ServiceCode" element={<TransfersBooking />} />}
						{isLogged && <Route path="/transfers/booking/:transferId" element={<TransfersBooking />} />}
					</>
				}
				<Route path="/news/:id" element={<NewsItem />}/>
				<Route path="/news" element={<NewsItems />}/>
				<Route path="/rules" element={<Rules />}/>
				<Route path="/search" element={<HotelSearch />}/>
				<Route path='/contacts' element={<Contacts />}/>
				<Route path='/countries' element={<Countries />}/>
				<Route path='/country/:id' element={<Country />}/>
				<Route path='/city/:countryCode/:cityCode' element={<City />}/>
				<Route path="/hotel/:id/:startDate/:endDate/:adults/:childs/:ages" element={<Hotel />} />
				<Route path="/hotel/:id" element={<Hotel />}/>
				<Route path="/cashback" element={<Cashback />}/>
				<Route path="/legaldocs" element={<LegalDocs />}/>
				{isLogged && <Route path="/profile" element={<Personal />} />}
				{isLogged && <Route path='/order/:id' element={<Order />}/>}
				{isLogged && <Route path='/orders' element={<Orders />}/>}
				
				{isLogged && <Route path='/order_edit/:AccommodationId' element={<HotelBookingOld />} />}
				<Route path='/order/:Hotel/:Product/:ArrivalDate/:DepartureDate/:NumberOfGuests/:NumberOfExtraBedAdult/:NumberOfExtraBedChild/:NumberOfExtraBedInfant' element={<HotelBookingOld />} />
				
				{isLogged && <Route path='/order_new/:accommodationId' element={<HotelBooking />} />}
				<Route path='/order_new/:Hotel/:Product/:ArrivalDate/:DepartureDate/:NumberOfGuests/:NumberOfExtraBedAdult/:NumberOfExtraBedChild/:NumberOfExtraBedInfant' element={<HotelBooking />} />
				
				<Route path="*" element={<Navigate replace to="/" />} />
			</Routes>
		</>
	);
};