import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { RoomGallery, Sprite } from './';
import { Modal } from '../modals';
import pictoSquare from '../images/picto_square.svg';
import pictoBed from '../images/picto_bed.svg';
import pictoBathroom from '../images/picto_bathroom.svg';
import noPhoto from '../images/no_photo.svg';
import starEmpty from '../images/star_empty.svg';
import starFill from '../images/star_fill.svg';
import { useDevice } from 'hooks';

const ImgCircle = styled.img`
	margin-right: 5px;
`;

const ImgCircleEmpty = () => <ImgCircle src={starEmpty} alt='' />;
const ImgCircleFill = () => <ImgCircle src={starFill} alt='' />;

const Main = styled.div`
	border-bottom: 1px solid ${props => props.theme.lightGray};
	padding-bottom: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	overflow: visible;
	margin-bottom: 10px;

	@media (max-width: 659px) {
		flex-direction: column;
	}
	
	&.noServices {
		border-bottom: none;
		margin-bottom: 0;
		padding-bottom: 0;
	}

`;

const Info = styled.div`
	flex: 1 0 10px;
`;

const Hotel = styled.div`
	color: ${props => props.theme.darkBlue};
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 10px;
	//display: flex;
	//flex-direction: row;
	
	div {
		display: inline-block;
		white-space: nowrap;

		@media (min-width: 1280px) {
			margin-left: 10px;
		}
	}
`;

const RoomName = styled.div`
	margin-bottom: 20px;
	color: ${props => props.theme.darkBlue};
	font-weight: 500;
	font-size: 34px;
	line-height: 40px;
`;

const RoomAttrs = styled.div`
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;

	&.orange {
		font-weight: bold;
		color: ${props => props.theme.orange}; 
		font-family: ${props => props.theme.pt};
	}

	svg {
		margin-right: 5px;
	}

	> div {
		margin-right: 10px;
		color: #333;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		display: flex;
		flex-direction: row;
		align-items: center;

		&:before {
			content: '';
			display: inline-block;
			width: 20px;
			height: 20px;
			background-repeat: no-repeat;
			background-size: cover;
			margin-right: 5px;
		}

		&.square:before { background-image: url(${pictoSquare}); }
		&.bed:before { background-image: url(${pictoBed}); }
		&.bathroom:before { background-image: url(${pictoBathroom}); }
	}
`;

const Places = styled.div`
	/* min-width: 200px; */
	
	> div {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		white-space: nowrap;
		margin-bottom: 5px;

		div, b {
			font-family: ${props => props.theme.pt};
			font-size: 16px;
			line-height: 21px;
			color: #333;
		}
		
		b {
			font-weight: bold;
			margin-right: 15px;
		}
	}	
`;

const Line = styled.div`
	width: 100%;
	height: 1px;
	background: ${props => props.theme.lightGray};
	margin-top: 20px;
	margin-bottom: 20px;
`;

const Description = styled.div`
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: #333;
`;

const Services = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	@media (max-width: 659px) {
		flex-direction: column;
		align-items: stretch;
	}
`;

const Service = styled.div`
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 50%;
	font-family: ${props => props.theme.pt};
	color: ${props => props.theme.darkBlue};
	font-size: 16px;
	line-height: 21px;
	margin-bottom: 10px;

	@media (max-width: 659px) {
		width: 100%;
	}

	&:before {
		content: '';
		display: flex;
		height: 30px;
		width: 30px;
		margin-right: 10px;
		background-image: url(${props => props.$Url});
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}
`;

const NoPhoto = styled.div`
	width: 420px;
	height: 420px;
	background-color: #4F7EA7;
	background-image: url(${noPhoto});
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 20px 20px;
	margin-right: 10px;

	@media (max-width: 659px) {
		width: calc(100vw - 40px);
		padding-top: 100%;
		height: 0;
		margin-bottom: 20px;
	}
`;

const Close = styled.div`
	color: ${props => props.theme.darkBlue};
	position: absolute;
	top: 10px;
	right: 10px;

	@media (min-width: 1280px) {
		display: none !important;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;

	@media (max-width: 1279px) {
		flex-direction: column;
		gap: 5px;
	}
`;

const Column = styled.div`
	&.border {
		@media (min-width: 1280px) {
			border-right: 1px solid ${props => props.theme.lightGray};
			padding-right: 15px;
			margin-right: 15px;
		}
	}
`;

export const RoomDescription = ({
	room = {}, 
	onClose = () => null
}) => {
	const { t } = useTranslation();
	const { isMobile } = useDevice();
	const language = i18n.language;

	if (!room) {
		return null;
	}

	const {
		BedTypes: {
			Variant: BedTypes = []
		} = {},
		Bathlocation: bathLoc = false,
		// Square: square = false
	} = room;

	const description = language === 'en' ? room?.Header?.DescriptionEnglish : room?.Header?.DescriptionRussian;
	const hotelStarsCode = room?.HotelStars?.Code ?? room?.HotelStars;

	return (
		<Modal isVisible={Boolean(room)} onClose={onClose} fixed>
			<Main className={classnames({'noServices': room.Services.Service.length === 0})}>
				<Close onClick={onClose}>
					<Sprite icon='blue_cross' />
				</Close>

				{isMobile && <>
					<Hotel>
						{room.HotelName}

						<div>
							{hotelStarsCode <= 5 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
							{hotelStarsCode <= 4 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
							{hotelStarsCode <= 3 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
							{hotelStarsCode <= 2 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
							{hotelStarsCode <= 1 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
						</div>
					</Hotel>

					<RoomName>{room.Header.Name}</RoomName>
				</>}

				{room.Images.length > 0 ? <RoomGallery items={room.Images} /> : <NoPhoto />}

				<Info>
					{!isMobile && <>
						<Hotel>
							{room.HotelName}

							<div>
								{hotelStarsCode <= 5 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
								{hotelStarsCode <= 4 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
								{hotelStarsCode <= 3 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
								{hotelStarsCode <= 2 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
								{hotelStarsCode <= 1 ? <ImgCircleFill/> : <ImgCircleEmpty/>}
							</div>
						</Hotel>

						<RoomName>{room.Header.Name}</RoomName>
					</>}

					<Row>
						<Column className='border'>
							{room?.Header?.SquareSizeArea > 0 && <RoomAttrs>
								<div className="square">{t('Площадь')} {room.Header.SquareSizeArea} {t('кв. м.')}</div>
								{/* <div className="bed">???</div> */}
								{/* <div className="bathroom">???</div> */}
							</RoomAttrs>}

							<Places>
								<div>
									<b>{t('Основных мест')}:</b>
									<div>{room.Header.CountOfGuests}</div>
								</div>

								<div>
									<b>{t('Дополнительных мест')}:</b>
									<div>{room.Header.ExtraBeds}</div>
								</div>

								<div>
									<div>— {t('взрослых')}:</div>
									<div>{room.Header.ExtraBedsAdult}</div>
								</div>

								<div>
									<div>— {t('детей')}:</div>
									<div>{room.Header.ExtraBedsChild}</div>
								</div>

								<div>
									<div>— {t('младенцев')}:</div>
									<div>{room.Header.ExtraBedsInfant}</div>
								</div>
							</Places>
						</Column>

						<Column>
							{bathLoc && 
								<RoomAttrs className={classnames({orange: parseInt(bathLoc?.Code ?? 2) !== 2})}>
									<Sprite icon='bath' />
									{t('Санузел')} {bathLoc.Name.toLowerCase()}
								</RoomAttrs>
							}

							<Places>
								{BedTypes.map((i, key) =>
									<React.Fragment key={key}>
										<div>
											<b><Sprite icon='bed' /> {t('Кровати')}{BedTypes.length > 1 && ` - ${t('вариант')} ${key + 1}`}</b>
										</div>

										{i.Bed.map((j, jKey) =>
											<div key={jKey}>
												{j.Name} - {j.Count} {t('шт.')}
											</div>
										)}
									</React.Fragment>
								)}
							</Places>
						</Column>
					</Row>

					{description.length > 0 && 
						<>
							<Line />
							<Description>
								{description}
							</Description>
						</>
					}
				</Info>
			</Main>

			{room?.Services?.Service?.length > 0 && <Services>
				{room?.Services?.Service?.filter(i => i?.Available?.Code === 1)?.map((i, key) =>
					<Service key={key} $Url={`${i?.Url}`}>{i?.Name}</Service>
				)}
			</Services>}
		</Modal>
	);
};