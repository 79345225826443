import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorStore } from 'stores';
import { useInstance } from 'hooks';

export const usePricelist = init => {
	const { t } = useTranslation();
	const { setUserError } = useErrorStore();
	const { post } = useInstance();
	const [ countries, setCountries ] = useState([]);
	const [ country, setCountry ] = useState(null);
	const [ cities, setCities ] = useState([]);
	const [ city, setCity ] = useState(null);
	const [ hotels, setHotels ] = useState([]);
	const [ hotel, setHotel ] = useState(null);
	const [ email, setEmail ] = useState(() => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));
			return user.Email;
		} catch (e) {
			return '';
		}
	});
	const [ isOpen, setOpen ] = useState(false);

	const fetchCountries = async () => {
		const request = {
			CountryRequest: {
				ActionPubliclist: {
					Parameters: {}
				}
			}
		};

		const data = await post(request);
		setCountries(data?.CountryList?.Country ?? []);
	};

	const fetchCities = async () => {
		const request = {
			CityRequest: {
				ActionPubliclist: {
					Parameters: {
						CountryCode: country
					}
				}
			}
		};

		const data = await post(request);
		setCities(data?.Country?.[0]?.City ?? []);
	};

	const fetchHotels = async () => {
		const request = {
			HotelRequest: {
				ActionPubliclist: {
					Parameters: {
						CountryCode: country,
						CityCode: city
					}
				}
			}
		};

		const data = await post(request);
		setHotels(data?.Country?.CityList?.City?.find(i => parseInt(i.Code) === parseInt(city))?.HotelList?.Hotel ?? []);
	};

	useEffect(() => {
		if (!isNaN(parseInt(init?.Country))) {
			setCountry(init?.Country);
		}

		if (!isNaN(parseInt(init?.City))) {
			setCity(init?.City);
		}

		if (!isNaN(parseInt(init?.Hotel))) {
			setHotel(init?.Hotel);
		}
	}, [init]);

	useEffect(() => {
		if (init?.open) {
			fetchCountries();
		}
	}, []);

	useEffect(() => {
		if (isNaN(parseInt(country))) {
			setCities([]);
			setCity(null);
			setHotels([]);
			setHotel(null);
		} else {
			fetchCities();
		}
	}, [country]);

	useEffect(() => {
		if (isNaN(parseInt(country))) {
			setHotels([]);
			setHotel(null);
		} else {
			fetchHotels();
		}
	}, [city]);

	const onChangeEmail = e => setEmail(e.target.value);

	const onSend = async () => {
		if (country) {
			const request = {
				PriceListRequest: {
					byEmail: '1',
					Email: email,
					FileType: 'XLS',
					PriceObjs: hotel ? 4 : (city ? 3 : 2),
					PriceObjv: hotel ? hotel : (city ? city : country)
				}
			};

			const data = await post(request);
			if (data) {
				setOpen(false);
			}
		} else {
			setUserError(t('Пожалуйста, выберите страну!'));
		}
	};

	const onOpen = () => {
		setOpen(true);
		
		if (countries.length === 0) {
			fetchCountries();
		}

		if (country > 0) {
			// fetchCities();
		}

		if (city > 0) {
			// fetchHotels()
		}
	};

	const onClose = () => setOpen(false);

	return {
		countries,
		country,
		setCountry,
		cities,
		city,
		setCity,
		hotels,
		hotel,
		setHotel,
		email,
		onChangeEmail,
		onSend,
		isOpen,
		onOpen,
		onClose,
	};
};