import { compareDates } from 'utils';

export const useCheckInOutProps = ({ pricing, setOrderInfo, orderInfo }) => {
	const checkInOutProps = {
		AllowEarlierCheckIn: pricing?.HotelPricingDetail?.AllowEarlierCheckIn?.Code === 1,
		AllowLateCheckOut: pricing?.HotelPricingDetail?.AllowLateCheckOut?.Code === 1,
		CheckInTime: pricing?.HotelPricingDetail?.CheckInTime,
		CheckOutTime: pricing?.HotelPricingDetail?.CheckOutTime,
		DefaultCheckInTime: pricing?.HotelPricingDetail?.DefaultCheckInTime,
		DefaultCheckOutTime: pricing?.HotelPricingDetail?.DefaultCheckOutTime,
		EarlyCheckInList: pricing?.EarlyCheckInList?.EarlyCheckIn,
		LateCheckOutList: pricing?.LateCheckOutList?.LateCheckOut,
		onChangeCheckInTime: time => setOrderInfo(prev => ({...prev, ArrivalTime: (time?.target?.value ?? time)})),
		onChangeCheckOutTime: time => setOrderInfo(prev => ({...prev, DepartureTime: (time?.target?.value ?? time)})),
		CriticalEarlyCheckIn: orderInfo?.CriticalEarlyCheckIn,
		CriticalLateCheckOut: orderInfo?.CriticalLateCheckOut,
		onChangeCriticalEarlyCheckIn: value => setOrderInfo(prev => ({
			...prev, 
			CriticalEarlyCheckIn: value !== 'CriticalEarlyCheckIn'
		})),
		onChangeCriticalLateCheckOut: value => setOrderInfo(prev => ({
			...prev, 
			CriticalLateCheckOut: value !== 'CriticalLateCheckOut'
		})),
		readOnlyIn: compareDates(orderInfo?.ArrivalDate, 'today') < 1,
		readOnlyOut: compareDates(orderInfo?.DepartureDate, 'today') < 1
	};

	return {
		checkInOutProps
	};
};