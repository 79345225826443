export const dateToString = date => {
	try {
		if (typeof date === 'string') {
			return date;
		}
	
		if (typeof date !== 'object') {
			throw new Error('Date not object');
		}
	
		return `${date.getDate() < 10 ? '0' : ''}${date.getDate()}.${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}.${date.getFullYear()}`;
	} catch (e) {
		return '';
	}
};