import React, {useState} from 'react';
import styled from 'styled-components';
import circleArrow from 'images/circle_arrow.svg';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	width: calc(100vw - 40px);
	flex-shrink: 0;
	margin-right: 0;
	margin-bottom: 20px;

	@media (min-width: 660px) {
		width: 280px;
		margin-right: 20px;
	}

	@media (min-width: 1280px) {
		width: 420px;
	}
`;

const Full = styled.div`
	height: calc(100vw - 40px);
	flex-shrink: 0;
	flex-grow: 0;
	margin-bottom: 10px;
	position: relative;
	background: ${props => props.theme.darkBlue};

	@media (min-width: 660px) {
		height: 280px;
	}

	@media (min-width: 1280px) {
		height: 420px;
		margin-bottom: 20px;
	}
	
	img {
		width: 100%;
		height: 100%;
		flex-shrink: 0;
		flex-grow: 0;
		object-fit: cover;
		object-position: center;
		z-index: 9;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		transition: opacity .5s ease-in-out, visibility .5s ease-in-out;

		@media (min-width: 660px) {
			width: 280px;
			height: 280px;
		}

		@media (min-width: 1280px) {
			width: 420px;
			height: 420px;
		}

		&.visible {
			opacity: 1;
			visibility: visible;
		}
	}
`;

const Thumbs = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	gap: 10px;

	@media (min-width: 1280px) {
		gap: 20px;
	}
`;

const Thumb = styled.div`
	width: calc((100vw - 70px) / 4);
	height: calc((100vw - 70px) / 4);
	flex-grow: 0;
	flex-shrink: 0;
	cursor: pointer;
	position: relative;

	@media (min-width: 660px) {
		width: calc((280px - 30px) / 4);
		height: calc((280px - 30px) / 4);
	}

	@media (min-width: 1280px) {
		width: calc((420px - 60px) / 4);
		height: calc((420px - 60px) / 4);
	}

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 11;
		background: rgba(3, 71, 129, 0.5);
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	}
	
	&:hover:before {
		opacity: 1;
		visibility: visible;
	}
	
	div {
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: white;
		background: rgba(3, 71, 129, 0.5);
		font-weight: bold;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		text-align: center;
	}
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		z-index: 9;
		position: absolute;
		top: 0;
		left: 0;
	}
`;

const Prev = styled.div`
	width: 40px;
	height: 40px;
	background: url(${circleArrow}) center/cover no-repeat;
	position: absolute;
	z-index: 10;
	cursor: pointer;
	left: 15px;
	top: calc(100% / 2 - 20px);
	opacity: 1;
	visibility: visible;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;

	@media (max-width: 659px) {
		width: 30px;
		height: 30px;
	}
	
	&.hidden {
		opacity: 0;
		visibility: hidden;
	}
`;

const Next = styled.div`
	width: 40px;
	height: 40px;
	background: url(${circleArrow}) center/cover no-repeat;
	position: absolute;
	z-index: 10;
	cursor: pointer;
	right: 15px;
	top: calc(100% / 2 - 20px);
	transform: rotate(180deg);
	opacity: 1;
	visibility: visible;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	
	@media (max-width: 659px) {
		width: 30px;
		height: 30px;
	}

	&.hidden {
		opacity: 0;
		visibility: hidden;
	}
`;

export const RoomGallery = ({ items = [] }) => {
	const { t } = useTranslation();
	const [ index, setIndex ] = useState(0);

	const handleClickThumb = i => setIndex(i);

	const handleClickPrev = () => index > 0 && setIndex(index - 1);
	const handleClickNext = () => index < (items.length - 1) && setIndex(index + 1);

	return (
		<Container>
			<Full>
				<Prev className={classnames({'hidden': index === 0})} onClick={handleClickPrev} />
				{items.map((i, key) => <img src={items[index]} key={key} alt='' className={classnames({'visible': index === key})} />)}
				<Next className={classnames({'hidden': index === (items.length - 1)})} onClick={handleClickNext} />
			</Full>

			<Thumbs>
				{items.slice(0, 4).map((i, key) => <Thumb key={key} onClick={handleClickThumb.bind(this, key)}>
					<img src={i} alt='' />

					{key === 3 && items.length > 4 && <div>{t('ещё')} {items.length - 4} {t('фото')}</div>}
				</Thumb>)}
			</Thumbs>
		</Container>
	);
};