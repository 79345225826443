import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toMoney } from 'utils';
import { dayjs } from 'utils/dayjs';

const isPenalty = datetime => {
	return dayjs.utc(datetime, 'DD.MM.YYYY HH:mm').isSameOrBefore(dayjs());
};

export const useCancelText = ({ pricing, accommodation, CurrencyName }) => {
	const { t } = useTranslation();
	const hasChanges = useMemo(() => {
		if (!accommodation?.Id) return true;
		if (!accommodation?.Price || !pricing?.HotelPricingDetail?.Price) return false;

		return parseFloat(accommodation?.Price) !== parseFloat(pricing?.HotelPricingDetail?.Price);
	}, [accommodation?.Id, accommodation?.Price, pricing?.HotelPricingDetail?.Price]);

	const cancelText = useMemo(() => {
		const rules = (!hasChanges && accommodation?.Id > 0)
			? (accommodation?.PenaltyRuleList?.PenaltyRule ?? []).map(i => ({
				...i,
				DeadlineTimeSys: i?.DeadlineTimeSys ?? accommodation?.DeadlineTimeSys,
				DeadlineTimeUTC: i?.DeadlineTimeUTC ?? accommodation?.DeadlineTimeUTC,
			}))
			: (pricing?.HotelPricingDetail?.PenaltyRuleList?.PenaltyRule ?? []);

		if (rules.length > 1) {
			const bits = rules.map(i => {
				if (isPenalty(i.DeadlineTimeUTC)) {
					return t(
						'Изменения и аннуляция до {{DeadlineTimeLoc}} по местному времени отеля ({{DeadlineTimeSys}} по Московскому времени, {{DeadlineTimeUTC}} по UTC) платно — {{Value}}% от полной стоимости в размере {{PenaltySize}} {{CurrencyName}}',
						{
							...i,
							PenaltySize: toMoney(i.PenaltySize),
							CurrencyName,
						}
					);
				}

				return t(
					'с {{DeadlineTimeLoc}} — {{Value}}% от полной стоимости в размере {{PenaltySize}} {{CurrencyName}}.',
					{
						...i,
						PenaltySize: toMoney(i.PenaltySize),
						CurrencyName,
					}
				);
			});

			return bits.join('; ') + '.';
		} else if (rules.length === 1) {
			const i = rules[0];

			if (isPenalty(i.DeadlineTimeUTC)) {
				return t(
					'Изменения и аннуляция платно - {{Value}}% от полной стоимости в размере {{PenaltySize}} {{CurrencyName}}.',
					{
						...i,
						PenaltySize: toMoney(i.PenaltySize),
						CurrencyName,
					}
				);
			}

			return t(
				'Изменения и аннуляция бесплатно до {{DeadlineTimeLoc}} по местному времени отеля ({{DeadlineTimeSys}} по Московскому времени, {{DeadlineTimeUTC}} по UTC), далее - за {{PenaltySize}} {{CurrencyName}}.',
				{
					...i,
					PenaltySize: toMoney(i.PenaltySize),
					CurrencyName,
				}
			);
		} else {
			return '';
		}

		// const node = !hasChanges && accommodation?.Id > 0
		// 	? {
		// 		PenaltySize: toMoney(accommodation?.PossiblePenaltySize ?? 0),
		// 		PenaltySize1: accommodation?.PossiblePenaltySize ?? 0,
		// 		DeadlineTimeLoc: accommodation?.DeadlineTimeLoc,
		// 		DeadlineTimeSys: accommodation?.DeadlineTimeSys,
		// 		DeadlineTimeUTC: accommodation?.DeadlineTimeUTC,
		// 		IsDirect: accommodation?.Country?.IsDirect ?? 1,
		// 		PenaltyRule: accommodation?.PenaltyRuleList?.PenaltyRule ?? []
		// 	}
		// 	: {
		// 		PenaltySize: toMoney(pricing?.HotelPricingDetail?.PenaltySize ?? 0),
		// 		PenaltySize1: pricing?.HotelPricingDetail?.PenaltySize ?? 0,
		// 		DeadlineTimeLoc: pricing?.HotelPricingDetail?.DeadlineTimeLoc,
		// 		DeadlineTimeSys: pricing?.HotelPricingDetail?.DeadlineTimeSys,
		// 		DeadlineTimeUTC: pricing?.HotelPricingDetail?.DeadlineTimeUTC,
		// 		IsDirect: pricing?.Country?.IsDirect ?? 1,
		// 		PenaltyRule: pricing?.PenaltyRuleList?.PenaltyRule ?? []
		// 	};

		// const {
		// 	PenaltySize,
		// 	PenaltySize1,
		// 	DeadlineTimeLoc,
		// 	DeadlineTimeSys,
		// 	DeadlineTimeUTC,
		// 	IsDirect,
		// 	PenaltyRule
		// } = node;

		// if (parseInt(IsDirect) === 2) {
		// 	return (
		// 		<div>
		// 			{t('БЕСПЛАТНАЯ отмена заявки возможна до {{DeadlineTimeLoc}} по местному времени гостиницы ({{DeadlineTimeSys}} по Московскому времени, {{DeadlineTimeUTC}} по UTС).', { DeadlineTimeLoc, DeadlineTimeSys, DeadlineTimeUTC })}
		// 			<br/>
		// 			{t('Начиная с указанного времени за аннулирование или изменение заявки, а также за незаезд взимается штраф в размере {{PenaltySize}} {{currency}}.', { PenaltySize, currency })}
		// 		</div>
		// 	);
		// } else {
		// 	if (PenaltyRule.length > 0) {
		// 		return [
		// 			<div key={0}>{t('БЕСПЛАТНАЯ отмена заявки возможна до {{DeadlineTimeLoc}} по местному времени гостиницы ({{DeadlineTimeSys}} по Московскому времени, {{DeadlineTimeUTC}} по UTС).', { DeadlineTimeLoc, DeadlineTimeSys, DeadlineTimeUTC })}</div>,
		// 			<div key={1}>{t('Штрафные санкции наступают (по местному времени гостиницы):')}</div>,
		// 			...(PenaltyRule.map((i, key) => <div key={key + 2}>{t('с')} {i?.DeadlineTimeLoc} {i?.ValueCurrencyCode === 1 ? `${i?.Value}${i?.CalculationRuleName}` : ''} {t('в размере')} {PenaltySize} {currency}</div>))
		// 		];
		// 	}

		// 	if (PenaltySize1 > 0) {
		// 		return (
		// 			<div>
		// 				{t('БЕСПЛАТНАЯ отмена заявки возможна до {{DeadlineTimeLoc}} по местному времени гостиницы ({{DeadlineTimeSys}} по Московскому времени, {{DeadlineTimeUTC}} по UTС).', { DeadlineTimeLoc, DeadlineTimeSys, DeadlineTimeUTC })}
		// 				<br/>
		// 				{t('Начиная с указанного времени за аннулирование или изменение заявки, а также за незаезд взимается штраф в размере {{PenaltySize}} {{currency}}.', { PenaltySize, currency })}
		// 			</div>
		// 		);
		// 	}

		// 	return <div>{t('БЕСПЛАТНАЯ отмена заявки возможна до {{DeadlineTimeLoc}} по местному времени гостиницы ({{DeadlineTimeSys}} по Московскому времени, {{DeadlineTimeUTC}} по UTС).', { DeadlineTimeLoc, DeadlineTimeSys, DeadlineTimeUTC })}</div>;
		// }
	}, [hasChanges, accommodation, pricing]);

	return cancelText;
};