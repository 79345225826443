import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import arrow from 'images/arrow_down.svg';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	//margin-top: 40px;
    //position: fixed;
    //bottom: 20px;
    //background-color: white;
    //margin-left: calc(23%);
    //border: 1px solid #D5E3F0;
    //padding: 10px;
    //z-index: 30;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: ${props => props.$topMargin ? 40 : 0}px;
	white-space: nowrap !important;

	* {
		white-space: nowrap !important;
	}
	
	span {
		color: #333;
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
		font-weight: bold;
		//display: flex;
		display: none;
		flex-direction: column;
		align-items: center;
		margin-bottom: 15px;
	}
`;

const Pages = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	//margin-bottom: 15px;
	margin-right: 15px;
	gap: 10px;
`;

const Page = styled.div`
	font-family: ${props => props.theme.pt};
	color: ${props => props.theme.darkBlue};
	font-size: 16px;
	line-height: 21px;
	transition: color .2s ease-in-out, font-weight .2s ease-in-out;
	cursor: pointer;
	/* margin-left: 15px; */
	font-weight: 400;
	//padding: 5px;
	
	&.current {
		color: #0270CB;
		font-weight: 600;
	}
	
	&:hover {
		color: #0270CB;
	}
`;

const Prev = styled.div`
	width: 12px;
	height: 7px;
	background: url(${arrow}) center/cover no-repeat;
	transform: rotate(90deg);
	margin-right: 5px;
	opacity: 1;
	transition: opacity .2s ease-in-out;
	cursor: pointer;
	
	&.disabled {
		opacity: .5;
	}
`;

const Next = styled.div`
	width: 12px;
	height: 7px;
	background: url(${arrow}) center/cover no-repeat;
	transform: rotate(-90deg);
	margin-left: 5px;
	opacity: 1;
	transition: opacity .2s ease-in-out;
	cursor: pointer;
	
	&.disabled {
		opacity: .5;
	}
`;

const Show = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #333;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
`;

export const Paginator = ({
	total = 0, 
	limit = 0, 
	page = 0, 
	setPage = () => null, 
	topMargin = false
}) => {
	const { t } = useTranslation();

	if (total < 2) {
		return null;
	}

	const totalPages = Math.ceil(total / limit);

	const items = [];

	let start, end;
	if (page < totalPages - 2) {
		start = page <= 3 ? 1 : page - 2;
		end = start + 4 <= totalPages ? start + 4 : totalPages;
	} else {
		end = page >= totalPages - 2 ? totalPages : totalPages - page;
		start = end - 4 > 1 ? end - 4 : 1;
	}

	for (let i = start; i <= end; i++) {
		items.push(i);
	}

	items[0] !== 1 && (items[0] = 1);
	items[items.length - 1] !== totalPages && (items[items.length - 1] = totalPages);

	const onClick = page => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});

		setPage(page);
	};

	return (
		<Container $topMargin={topMargin}>
			<span>{t('Страница')}</span>
			{totalPages > 1 && <Pages>
				<Prev className={classnames({'disabled': page === 1})} onClick={onClick.bind(this, page > 1 ? page - 1 : 1)} />
				{items.map(i => <Page className={classnames({'current': i === page})} key={i} onClick={onClick.bind(this, i)}>{i}</Page>)}
				<Next className={classnames({'disabled': page === totalPages})} onClick={onClick.bind(this, page < totalPages ? page + 1 : totalPages)} />
			</Pages>}

			<Show>
				{t('Показаны')} {(page - 1) * limit + 1}-{page * limit > total ? total : page * limit}
			</Show>
		</Container>
	);
};