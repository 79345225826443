import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { Button, Paginator, RoomDescription } from 'components';
import { TabletMobileFilters, SelectView, SelectSort } from '../';
import { Link } from 'react-router-dom';
import { useHotelSearchStore } from 'stores';
import { declines } from 'utils';
import { parseDate, shortDayName } from 'utils/dayjs';
import { MapContainer, ModalDetailsV2 } from 'features/Search';
import { Hotel } from './';

const Container = styled.div`
	margin: 20px;
	width: calc(100vw - 40px);

	> h4 {
		margin: 20px 0 !important;
		color: #FF6B00 !important;
		font-size: 20px !important;
		font-weight: 500 !important;
		line-height: normal !important;
	}
`;

const Settings = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: stretch;

	> .row {
		display: flex;
		gap: 10px;

		.button {
			white-space: nowrap;
		}

		.select_container {
			border: 2px solid ${props => props.theme.darkBlue} !important;
			border-radius: 5px;
		}
	}
`;

const Info = styled.div`
	> div {
		text-align: center;
		color: ${props => props.theme.darkBlue};
		font-family: ${props => props.theme.pt};
		font-size: 14px;
		font-weight: 700;
		line-height: normal;

		span {
			font-family: inherit;
			font-size: inherit;
			font-weight: inherit;
			line-height: inherit;
			color: #00C0FF;
		}
	}
`;

const Tablet = () => {
	const { t } = useTranslation();
	const {
		lastDestination,
		filteredCount,
		filtersCount,
		totalCount,
		params: {
			startDate,
			endDate,
			adults,
			childs,
			limit: {
				value: limit = 25
			} = {},
		},
		isMap,
		setMap,
		filtered,
		altFiltered,
		roomDescriptionProps,
		setRoomDescriptionProps,
		modalDetailsProps,
		page,
		setPage,
	} = useHotelSearchStore();

	const foundedTitles = [t('найден'), t('найдено'), t('найдено')];
	const filteredTitles = [t('показан'), t('показано'), t('показано')];
	const hotelTitles = [t('отель'), t('отеля'), t('отелей')];
	const nightsTitles = [t('ночь'), t('ночи'), t('ночей')];
	const adultsTitles = [t('взрослый'), t('взрослых'), t('взрослых')];
	const childsTitles = [t('ребёнок'), t('детей'), t('детей')];

	const nights = Math.abs(parseDate(startDate).diff(parseDate(endDate), 'day', true));

	const toggleMap = () => setMap(prev => !prev);

	const paginatorBind = {
		total: filteredCount,
		limit,
		page,
		setPage
	};

	return (
		<>
			<Container>
				<Settings>
					<Info>
						<div>
							{lastDestination?.Name?.replace(/(<([^>]+)>)/gi, '')}: {declines(filteredCount, filtersCount > 0 ? filteredTitles : foundedTitles)} <span>{filteredCount}</span> {filtersCount > 0 && <>{t('из')} <span>{totalCount}</span></>} {declines(filtersCount > 0 ? totalCount : filteredCount, hotelTitles)}
						</div>

						<div>
							{startDate} {shortDayName(startDate)} — {endDate} {shortDayName(endDate)} • {declines(nights, nightsTitles, true)}
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							{declines(adults, adultsTitles, true)} • {childs < 1 ? t('без детей') : declines(childs, childsTitles, true)}
						</div>
					</Info>

					<Link to='/' className='button grad'>
						{t('Искать снова')}
					</Link>

					<div className='row'>
						<TabletMobileFilters />

						<Button white onClick={toggleMap}>
							{isMap ? t('Отели списком') : t('На карте')}
						</Button>

						<SelectView />
						<SelectSort />

						<div className='filler' />
					</div>


					<Paginator {...paginatorBind} />
				</Settings>

				{isMap && Array.isArray(filtered) && <MapContainer />}

				{!isMap && Array.isArray(filtered) && <>
					{filtered.length === 0
						? <h4>{t('Отели, соответствующие Вашему запросу, не найдены!')}</h4>
						: filtered.map(hotel => <Hotel hotel={hotel} key={hotel.Code} />)
					}

					{altFiltered.length > 0 && <>
						<h4>{t('Следующие отели не полностью соответствуют Вашему запросу!')}</h4>
						{altFiltered.map(hotel => <Hotel hotel={hotel} key={hotel.Code} />)}
					</>}
				</>}

				<br />
				<Paginator {...paginatorBind} />
			</Container>

			<RoomDescription {...roomDescriptionProps} setRoomDescriptionProps={setRoomDescriptionProps} />
			<ModalDetailsV2 {...modalDetailsProps} />
		</>
	);
};

export default Tablet;