import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Calendar from 'react-calendar';
import pictoCalendar from '../images/picto_calendar.svg';
import { declines } from 'utils';
import { useClickOutside, useDevice } from 'hooks';
import { dayjs, format, parseDate, today } from 'utils/dayjs';

const DatesContainer = styled.div`
	${props => props.$fill ? 'flex: 1 0 10px' : 'width: 330px'};
	position: relative;
	z-index: 10;
	color: ${props => props.theme.darkBlue};
	padding: 0 !important;

	.mobile {
		display: none;
	}

	> input {
		cursor: pointer;
		width: 100%;
		height: 100% !important;
	}

	div.label {
		background-color: white;
		border: none;
		outline: none;
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
		width: 100%;
		height: 100%;
		padding: 8px 10px;
		z-index: 10;
		cursor: pointer;

		@media (max-width: 659px) {
			display: flex;
			justify-content: center;
		}
	}
	
`;

const Icon = styled.div`
	display: ${props => props.$fill ? 'none' : 'block'};
	width: 20px;
	height: 20px;
	background-image: url(${pictoCalendar});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 10px;
	right: 8px;
	z-index: 11;
	cursor: pointer;

	@media (max-width: 659px) {
		display: none;
	}
`;

const Container = styled.div`
	position: absolute;
	top: 100%;
	left: -1px;
	border: 1px solid ${props => props.theme.darkBlue};
	padding: 0 10px 10px 10px;
	background-color: white;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	display: flex;
	flex-direction: row;
	justify-content: center;

	@media (max-width: 659px) {
		padding: 0;
	}
	
	&.isOpen {
		opacity: 1;
		visibility: visible;
	}
`;

export const Ranges = ({
	startDate,
	endDate,
	onChange,
	fill = false
}) => {
	const containerRef = useRef(null);
	const [ isOpen, setOpen ] = useState(false);
	const { ref } = useClickOutside(() => setOpen(false));
	const { isNotMobile } = useDevice();
	const { t } = useTranslation();
	const [ activeStartDate, setActiveStartDate ] = useState(parseDate(startDate).toDate());
	const locale = i18n.language;

	typeof startDate === 'string' && (startDate = parseDate(startDate));
	typeof endDate === 'string' && (endDate = parseDate(endDate));

	const nightsTitles = [t('ночь'), t('ночи'), t('ночей')];

	const startDateString = `${startDate.format(`${format} dd`)}`;
	const endDateString = `${endDate.format(`${format} dd`)}`;
	const nights = Math.abs(startDate.diff(endDate, 'day', true));
	const nightsString = declines(nights, nightsTitles, true);

	const title = (startDate && endDate)
		? (fill
			? <>
				{startDateString} - {endDateString}
				<br />
				{nightsString}
			</>
			: `${startDateString} - ${endDateString} • ${nightsString}`
		)
		: '';

	const titleMobile = (
		<>
			{startDateString} - {endDateString} • {nightsString}
		</>
	);

	const toggle = e => {
		if (!containerRef?.current?.contains(e.target)) {
			setOpen(prev => !prev);
		}
	};

	const bind = {
		locale,
		value: [
			startDate.toDate(),
			endDate.toDate()
		],
		onChange: e => {
			onChange([
				dayjs(e[0]).format(format),
				dayjs(e[1]).format(format)
			]);
			setOpen(false);
		},
		minDate: today().toDate(),
		selectRange: true,
		showDoubleView: isNotMobile,
		activeStartDate,
		onActiveStartDateChange: ({ action, activeStartDate }) => action !== 'onChange' && setActiveStartDate(activeStartDate),
	};

	return (
		<DatesContainer $fill={fill} className='dates' onClick={toggle} ref={ref}>
			{!fill && <input className='notmobilephone' value={title} readOnly />}
			<div className='label mobile'>{titleMobile}</div>
			{fill && <div className='label'>{title}</div>}

			{!fill && <Icon />}

			<Container className={classnames({isOpen})} $fill={fill} ref={containerRef}>
				<Calendar {...bind} />
			</Container>
		</DatesContainer>
	);
};