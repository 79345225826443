import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import objectSupport from 'dayjs/plugin/objectSupport';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(objectSupport);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(utc);

export const format = 'DD.MM.YYYY';

export const today = () => dayjs(dayjs().format(format), format);
export const tomorrow = () => dayjs(dayjs().format(format), format).add(1, 'day');

export const todayString = () => today().format(format);
export const tomorrowString = () => tomorrow().format(format);

export const parseDate = date => typeof date === 'string' ? dayjs(date, format) : dayjs(date);

export const shortDayName = date => {
	if (typeof date === 'string' || date instanceof Date) return parseDate(date).format('dd');
	return date?.format('dd') ?? '';
};

export const getNights = (date1, date2) => {
	const startDate = parseDate(date1);
	const endDate = parseDate(date2);

	return Math.abs(endDate.diff(startDate, 'day'));
};

export {
	dayjs,
};