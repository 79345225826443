import React from 'react';
import { Destination } from 'forms2';
import { useHotelSearchStore } from 'stores';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-grow: 1;
`;

export const DestinationInput = () => {
	const store = useHotelSearchStore();

	const {
		params: {
			destinationValue,
			destinationNeedle
		},
		setParams,
	} = store;

	const bind = {
		value: destinationValue?.Code
			? {
				...destinationValue,
				value: destinationValue.Code,
				label: destinationValue.Name
			}
			: null,
		onChange: i => {
			if (i?.item?.label) delete i.item.label;

			setParams(prev => ({
				...prev,
				destinationNeedle: i?.input ?? '',
				destinationValue: i?.item ?? null
			}));
		},
		initialInput: destinationNeedle,
	};

	return (
		<Container>
			<Destination {...bind} />
		</Container>
	);
};