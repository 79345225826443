import { useEffect } from 'react';
import axios from 'axios';
import { useErrorStore, useLoaderStore, useUserStore } from 'stores';
import { useTranslation } from 'react-i18next';

export const Api = () => {
	const { t } = useTranslation();
	const { user, Language } = useUserStore();

	const { 
		setError,
		setFatalError,
	} = useErrorStore();

	const {
		addCounter,
		subCounter,
		startLoading,
		stopLoading
	} = useLoaderStore();

	const parseUserData = async () => {
		const userData = {
			UserType: 'BUYER',
			BuyerId: user?.BuyerId ?? 'RO1BAND',
			UserId: user?.UserId ?? 'WWW',
			Password: user?.Password ?? '84765',
			Language,
			Currency: user?.CurrencyCode ?? 22,
			CurrencyCode: user?.CurrencyCode ?? 22,
			WhereToPay: user?.WhereToPayCode ?? 3,
		};

		return userData;
	};

	useEffect(() => {
		// parseUserData();

		window.onstorage = e => {
			if (['user', 'buyer', 'userType'].includes(e.key)) {
				parseUserData();
			}
		};
	}, []);

	const getInstance = () => (
		axios.create({
			baseURL: '/rapi99',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				'Bora-Alien': 2,
				'Bora-Formatted-Output': 1,
			}
		})
	);

	const addUserData = async request => {
		const ud = await parseUserData();
		const requestName = Object.keys(request)[0];

		return {
			[requestName]: {
				...request[requestName], 
				...ud
			}
		};
	};

	// const handleError = error => setError(error);

	const call = async (request, noChangeCounter = false) => {
		try {
			request = await addUserData(request);
			const instance = getInstance();

			!noChangeCounter && await addCounter();

			return await instance
				.post('', request)
				.then(data => {
					if (data.headers['content-type'].includes('image/')) {
						return {
							data: data.data,
							type: data.headers['content-type']
						};
					} else {
						const response = data.data;
						const root = Object.keys(response)[0];

						if (response.Error) {
							setError(response.Error);
							return false;
						} else if (response[root]?.Error) {
							setError(response[root].Error);
							return false;
						} else {
							return response?.[root] ?? response;
						}
					}
				})
				.catch(error => {
					if (error?.response?.status === 404) {
						this.setFatalError(t('Сервис временно недоступен.'));
					} else {
						const requestStyle = 'color: #00aa00';
						const responseStyle = 'color: #ff0000';
						console.log('%cRequest:', requestStyle, JSON.stringify(request));
						console.log('%cResponse: %s', responseStyle, JSON.stringify(error));
					}
				})
				.finally(async () => {
					!noChangeCounter && await subCounter();
				});
		} catch (e) {
			setFatalError(e.message);
		}
	};

	const callBinary = async (request, noChangeCounter = false) => {
		try {
			request = await addUserData(request);
			const instance = axios.create({
				baseURL: '/rapi99',
				headers: {
					// 'Content-Type': 'application/json; charset=UTF-8',
					'Bora-Alien': 2,
					'Bora-Formatted-Output': 1,
				},
				// responseType: 'arraybuffer'
				responseType: 'blob'
			});
	
			!noChangeCounter && await addCounter();	

			return await instance
				.post('', request, { transformResponse: (r) => r })
			// .then(response => response.blob())
				.then(data => data?.data)
				.catch(error => {
					if (error?.response?.status === 404) {
						this.setFatalError(t('Сервис временно недоступен.'));
					} else {
						const requestStyle = 'color: #00aa00';
						const responseStyle = 'color: #ff0000';
						console.log('%cRequest:', requestStyle, JSON.stringify(request));
						console.log('%cResponse: %s', responseStyle, JSON.stringify(error));
					}
				})
				.finally(async () => {
					!noChangeCounter && await subCounter();
				});
		} catch (e) {
			console.log('ERROR!!!', e);
		}
	};

	return {
		call,
		callBinary,
		startLoading,
		stopLoading
	};
};
