import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import arrowDown from 'images/arrow_down.svg';
import {declines} from 'utils/declines';
import { useTranslation } from 'react-i18next';

import { Counter } from 'forms';

const Container = styled.div`
	border: 1px solid ${props => props.theme.darkBlue};
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-grow: 1;
	position: relative;
`;

const Icon = styled.div`
	display: block;
	width: 12px;
	height: 8px;
	background-image: url(${arrowDown});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 15px;
	right: 10px;
	cursor: pointer;
`;

const Label = styled.div`
	margin-left: 10px;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: ${props => props.theme.darkBlue};
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	//width: 285px;
	flex-grow: 1;
	overflow-x: hidden;
`;

const GuestsContainer = styled.div`
	position: absolute;
	top: 38px;
	left: -1px;
	border: 1px solid ${props => props.theme.darkBlue};
	width: calc(100% + 2px);
	padding: 0 10px;
	background-color: white;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	display: flex;
	flex-direction: column;
	justify-content: center;

	> div:last-child {
		border-bottom: 0;
	}
	
	&.isOpen {
		opacity: 1;
		visibility: visible;
	}
`;

export const Guests = ({ mainBedsProps, adultsBedsProps, childsBedsProps, infantsBedsProps, readOnly = false }) => {
	const { t } = useTranslation();
	const containerRef = useRef(null);
	const [isOpen, setOpen] = useState(false);

	const guestsTitles = [t('гость'), t('гостя'), t('гостей')];

	const listener = e => containerRef && containerRef.current && !containerRef.current.contains(e.target) && setOpen(false);

	useEffect(() => {
		document.body.addEventListener('click', listener, true);
		return () => document.body.removeEventListener('click', listener, true);
	}, []);

	const handleToggleOpen = () => !readOnly && setOpen(prev => !prev);

	const guest = mainBedsProps.value + adultsBedsProps.value + childsBedsProps.value + infantsBedsProps.value;
	
	const title = declines(guest, guestsTitles, true);

	return (
		<Container ref={containerRef}>
			<Label onClick={handleToggleOpen}>
				{title}
			</Label>

			<Icon onClick={handleToggleOpen} />

			<GuestsContainer className={classnames({isOpen})}>
				<Counter {...mainBedsProps} />
				<Counter {...adultsBedsProps} />
				<Counter {...childsBedsProps} />
				<Counter {...infantsBedsProps} />
			</GuestsContainer>
		</Container>
	);
};