import React, { useMemo } from 'react';
import styled from 'styled-components';
import checkboxEmpty from 'images/checkbox_empty.svg';
import checkboxFill from 'images/checkbox_fill.svg';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore } from 'stores';
import { Sprite } from 'components';

const AmenitiesContainer = styled.div`
	width: 100%;
	height: 170px;
	padding-right: 11px;
	overflow-y: auto;
	
	> div {
		display: flex;
		flex-direction: row;
	
		
		label {
			font-family: ${props => props.theme.pt};
			color: #333;
			font-size: 16px;
			line-height: 21px;
			/* margin-top: 10px; */
			margin-top: 0;
			margin-bottom: 10px;
			display: flex;
			padding-left: 30px;
			position: relative;
			align-items: flex-start;
			
			&:before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				background-image: url(${checkboxEmpty});
				position: absolute;
				left: 0;
				top: 0;
			}
		}

		input {
			display: none;
			
			&:checked + label:before {
				background-image: url(${checkboxFill});
			}
		}
	}
`;

export const Amenities = () => {
	const { t } = useTranslation();
	const {
		params: {
			filters: {
				amenities: items
			}
		},
		setParams
	} = useHotelSearchStore();

	const amenities = useMemo(() => [
		{id: 15, title: t('Wi-Fi в бизнес центре')},
		{id: 66, title: t('Wi-Fi в публичных зонах')},
		{id: 18, title: t('Авиа-, ж/д- билеты')},
		{id: 100, title: t('Англоговорящий персонал')},
		{id: 20, title: t('Аптечный пункт')},
		{id: 17, title: t('Аренда автомобиля')},
		{id: 10, title: t('Бар')},
		{id: 27, title: t('Бассейн крытый')},
		{id: 26, title: t('Бассейн открытый')},
		{id: 34, title: t('Бильярд')},
		{id: 35, title: t('Боулинг')},
		{id: 16, title: t('Вызов такси')},
		{id: 37, title: t('Казино')},
		{id: 103, title: t('Камера хранения')},
		{id: 8, title: t('Лифт')},
		{id: 104, title: t('Офисные услуги (факс,ксерокс)')},
		{id: 3, title: t('Парковка неохраняемая')},
		{id: 2, title: t('Парковка охраняемая')},
		{id: 33, title: t('Пляж')},
		{id: 4, title: t('Поднос багажа')},
		{id: 7, title: t('Пункт обмена валюты')},
		{id: 42, title: t('Размещение с домашними животными')},
		{id: 9, title: t('Ресторан')},
		{id: 24, title: t('Салон красоты')},
		{id: 25, title: t('Сауна')},
		{id: 6, title: t('Сейф на стойке регистрации')},
		{id: 21, title: t('Сувенирный магазин')},
		{id: 31, title: t('Теннисный корт крытый')},
		{id: 30, title: t('Теннисный корт открытый')},
		{id: 101, title: t('Трансфер')},
		{id: 23, title: t('Тренажерный зал')},
		{id: 102, title: t('Удобства на этаже')},
		{id: 19, title: t('Услуги няни')},
		{id: 22, title: t('Услуги по предоставлению газет')},
		{id: 11, title: t('Услуги прачечной')},
		{id: 12, title: t('Услуги химчистки')},
	], []);
	
	const setAmenity = id => setParams(prev => {
		const items = [ ...prev.filters.amenities ];
		const index = items.findIndex(i => i === id);
		index === -1 && items.push(id);
		index !== -1 && items.splice(index, 1);

		console.log({index});
		
		return {
			...prev,
			filters: {
				...prev.filters,
				amenities: [...items]
			}
		};
	});

	const isDirty = items.length > 0;
	const clear = () => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			amenities: []
		}
	}));

	return (
		<div>
			<label>
				{t('Услуги')}

				{isDirty && 
					<div onClick={clear}>
						<Sprite icon='small_cross' />
					</div>
				}
			</label>

			<AmenitiesContainer>
				{amenities.map(i =>
					<div key={i.id}>
						<input type='checkbox' id={`am${i.id}`} checked={items.includes(i.id)} onChange={setAmenity.bind(this, i.id)} />
						<label htmlFor={`am${i.id}`}>{i.title}</label>
					</div>
				)}
			</AmenitiesContainer>
		</div>
	);
};