import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import noPhoto from 'images/no_photo.svg';
import { useHotelSearchStore } from 'stores';
import {
	HotelSubways,
	HotelTitleWithStars,
	HotelTransport,
	Sprite,
} from 'components';
// import { GroupCriterions } from 'features/Search';
import { RoomTablet } from './RoomTablet';
import { declines } from 'utils';

const Container = styled.table`
	width: 100%;
	display: table;
	table-layout: fixed;
	position: relative;
	margin-bottom: 20px;

	col {
		&.col1 { width: 60px; }
		&.col2 { width: 60px; }
		&.col4 { width: 50%; }
	}

	.clickable {
		cursor: pointer;
	}

	tr {
		&.first {
			border-top: 1px solid ${props => props.theme.lightGray};

			td {
				padding-top: 10px;
			}
		}

		&.last {
			td {
				padding-bottom: 10px;
			}
		}

		&.other {
			svg {
				margin-left: 5px;
			}

			&.isOpen {
				svg {
					transform: rotate(180deg);
				}
			}
		}

		&.hotelName {
			td {
				padding-top: 20px;
				vertical-align: baseline;
			}
		}

		&.rommName {
			height: 60px;
			background-color: #D3EFFF;

			td {
				height: 60px;
				background-color: #D3EFFF;
				
				img {
					height: 60px;
					width: 60px;
				}
			}
		}

		&.compact {
			.buttons {
				margin-top: 0 !important;
				justify-content: flex-end;
			}
		}
	}

	td {
		/* border: 1px solid red; */
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: top;
		height: 30px;

		&.hotelInfo:not(:nth-child(1)) {
			padding-left: 10px;
		}

		img {
			vertical-align: text-bottom;
			/* margin-right: 5px; */

			display: inline !important;
		}

		&.info {
			padding-left: 20px;

			h6 {
				margin: 0 0 5px 0 !important;
			}
		}

		&.roomPhoto {
			line-height: 0;

			* {
				line-height: 0;
			}

			img {
				width: 60px;
				height: 60px;
				object-position: center;
				object-fit: contain;
			}
		}

		&.roomName {
			background-color: #D3EFFF;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: ${props => props.theme.darkBlue};
			padding-left: 10px;
			vertical-align: middle;
		}

		&.roomParams {
			background-color: #D3EFFF;
			text-align: right;
			vertical-align: middle;
			padding-right: 10px;

			> * {
				flex-grow: 0;
				flex-shrink: 1;
				display: inline;
				color: #333;
				font-size: 16px;
				line-height: 21px;
				font-family: ${props => props.theme.pt};

				svg {
					vertical-align: text-top;
				}
			}
		}

		&.icons {
			vertical-align: middle;

			img {
				margin-right: 5px;
			}
		}

		&.bit {
			/* height: 40px; */
			vertical-align: middle;
			/* padding: 5px 0; */
			font-size: 16px;
			line-height: 21px;
			color: #333;
			font-family: ${props => props.theme.pt};

			&.bold {
				font-weight: bold;
			}

			&.orange {
				color: #FF6B00;
			}

			svg {
				vertical-align: bottom;
				margin-right: 5px;
			}
		}

		&.price {
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: ${props => props.theme.darkBlue};
			vertical-align: middle;
			text-align: right;
			white-space: nowrap;

			span {
				margin-right: 10px;
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				color: #4F7EA7;
			}
		}

		&.commission {
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: #00C0FF;
			vertical-align: middle;
			text-align: right;

			svg {
				vertical-align: bottom;
				margin-right: 5px;
			}
		}

		&.order {
			text-align: right;
			vertical-align: middle;
			
			.button {
				width: 200px;
				margin: 0 0 0 auto;
				position: relative;

				svg {
					position: absolute;
					right: 10px;
					top: 8px;
				}
			}
		}

		&.spacer {
			height: 20px !important;
			vertical-align: middle;

			&:before {
				content: '';
				width: 100%;
				height: 0;
				display: block;
				border-bottom: 1px solid ${props => props.theme.lightGray};
			}
		}

		&.bold {
			font-weight: bold;
		}

		&.blue {
			color: ${props => props.theme.darkBlue};
		}
	}
`;

const Photo = styled.a`
	width: 120px;
	height: 120px;
	position: relative;
	line-height: 0;
	background-color: #A2D0EA;
	display: flex;
	flex: 0 0 120px;
	margin-bottom: 10px;

	@media (max-width: 1279px) {
		width: 120px;
		height: 120px;
		flex: 0 0 120px;
		margin-right: 10px;
	}

	&.new:before {
		content: 'Новый';
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 30px;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		text-transform: uppercase;
		background-color: #5BB500;
		z-index: 10;			
	}
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		z-index: 9;
	}
	
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(3, 71, 129, 0.5);
		color: white;
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		height: 30px;
		user-select: none !important;
	}
	
	.noPhoto {
		width: 100%;
		height: 100%;
		background-image: url(${noPhoto});
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 20px 20px;
		margin-right: 10px;
		z-index: 9;
		user-select: none !important;
	}
`;

const Address = styled.div`
	font-weight: bold;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: #333;
	margin: 0 0 5px 0 !important;
`;

const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	/* justify-content: space-between; */

	* {
		font-weight: bold;
	}
	
	.buttonAsLink {
		&:nth-of-type(n + 2) {
			margin-left: 10px;
		}

		> div {
			opacity: 0;
			visibility: hidden;
			transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
		}
		
		&:hover > div {
			opacity: 1;
			visibility: visible;
		}
	}
`;

/*
const GroupInfo = styled.div`
	font-family: ${props => props.theme.pt};
	color: #004685;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 10px;
	position: relative;
	cursor: pointer;

	> div {
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	}

	&:hover > div {
		opacity: 1;
		visibility: visible;
	}
	
	svg {
		margin-right: 5px;
	}
`;
*/

const MobileTransport = styled.div`
	border: 1px solid ${props => props.theme.darkBlue};
	padding: 10px 30px 10px 10px;
	position: absolute;
	z-index: 100;
	background: white;
	/* width: calc(100% - 40px); */
	width: 100%;
	opacity: 0;
	visibility: hidden;

	&.isShow {
		opacity: 1;
		visibility: visible;
	}

	.close {
		color: ${props => props.theme.darkBlue};
		position: absolute;
		top: 10px;
		right: 10px;
	}
`;

export const HotelTablet = ({ hotel }) => {
	const { t } = useTranslation();
	const [ isOpen, setOpen ] = useState(false);
	const [ isTransport, setTransport ] = useState(false);

	const {
		lastDestination: {
			Code: DestinationCode,
			Name: DestinationName,
			TypeCode: DestinationTypeCode,
			TownType: {
				Name: TownTypeName = ''
			} = {},
			ObjType: {
				Name: ObjTypeName = ''
			} = {},
			HotelType: {
				Name: HotelTypeName = ''
			} = {},
			// IsDirect = 2,
		},
		params: {
			startDate,
			endDate,
			adults = 1,
			childs = 0,
			ages = [],
			view: {
				value: view = 'standart'
			} = {}	
		} = {},
		// getGroupCriterion,
		setHotelOnMap,
		setMap
	} = useHotelSearchStore();

	useEffect(() => {
		setOpen(view === 'extended');
	}, [view]);

	const {
		Code,
		Name,
		Stars,
		IsNewHotel = 2,
		Url,
		ImagesCount,
		DistanceToDestination = false,
		CityCentre,
		City: {
			Code: City,
			Name: CityName = ''
		} = {},
		Address: HotelAddress = '',
		Airport = '',
		RailwayStation = '',
		Objects: {
			Object: subways = []
		} = {},
		Position: {
			Latitude = 0,
			Longitude = 0
		} = {},
		// AvailabilityGroupCriterion = 2,
		// GroupCriterionList,
		HotelOfferList: {
			HotelOfferDetail: offers
		}
	} = hotel;

	const {
		Code: starsCode
	} = Stars;

	const distances = useMemo(() =>[
		(DestinationTypeCode === 2 && parseInt(DestinationCode) !== parseInt(City)) || (DestinationTypeCode !== 2 && parseInt(Code) !== parseInt(DestinationCode)) ? `${DistanceToDestination} км ${parseInt(DestinationTypeCode)  === 2 ? `от центра ${TownTypeName?.toLowerCase()}` : `от ${parseInt(DestinationTypeCode) === 4 ? ObjTypeName?.toLowerCase() : HotelTypeName?.toLowerCase()}`} ${DestinationName?.split(',')?.[0]}` : false,
		CityCentre,
	].filter(i => Boolean(i)), []);

	const rooms = useMemo(() => {
		return offers.slice(0, isOpen ? offers.length : 1).reduce((acc, cur) => {
			if (acc.find(i => parseInt(i.RoomCode) === parseInt(cur.RoomCode))) {
				return [...acc];
			} else {
				return [
					...acc,
					{
						...cur,
						rates: offers.filter(i => parseInt(i.RoomCode) === parseInt(cur.RoomCode))
					}
				];
			}
		}, []);
	}, [isOpen]);

	const toggle = () => setOpen(prev => !prev);

	const hotelBind = {
		Code,
		Name,
		Stars
	};

	const onOpen = () => setTransport(true);
	const onClose = () => setTransport(false);

	const onMap = () => {
		setMap(true);
		setHotelOnMap({Code: Code, lat: Latitude, lng: Longitude});
	};

	return (
		<Container width='500px' border='1'>
			<colgroup>
				<col className='col1' width='60' />
				<col className='col2' width='120' />
				<col className='col3' />
				<col className='col4' width='50%' />
			</colgroup>
			
			<tbody>
				<tr>
					{view !== 'compact' && <td colSpan={2}>
						<Photo href={`/hotel/${Code}`} target='_blank' className={classnames({'new': parseInt(IsNewHotel) === 1})}>
							{Url ?
								<img src={Url && Url.replace('test-images', 'images')} alt='' width='120' height='120' /> :
								<div className='noPhoto'/>
							}
							{Url && ImagesCount > 1 && <span>{t('ещё')} {ImagesCount - 1} {t('фото')}</span>}
						</Photo>
					</td>}
	
					<td colSpan={view === 'compact' ? 4 : 2} className='hotelInfo'>
						<HotelTitleWithStars starsCode={starsCode}>
							<a href={`/hotel/${Code}/${startDate}/${endDate}/${adults}/${childs}/${ages.join(',')}`} target='_blank' rel="noreferrer">
								{Name}
							</a>
						</HotelTitleWithStars>
						<br />

						{distances && 
							<h6>{distances.join(' • ')}</h6>
						}

						<Address className='notmobilephone'>{CityName}, {HotelAddress}</Address>
					</td>
				</tr>

				<tr>
					<td colSpan={4}>
						<Buttons className='notmobilephone buttons'>
							<div className='buttonAsLink' onClick={onOpen}>
								<Sprite icon='transport' />
								{t('Транспорт')}
							</div>

							<div className="buttonAsLink" onClick={onMap}>
								<Sprite icon='map' />
								{t('На карте')}
							</div>

							{/* {parseInt(IsDirect) === 1 && parseInt(AvailabilityGroupCriterion) === 1 && <GroupInfo className='buttonAsLink' onMouseEnter={getGroupCriterion.bind(this, Code)}>
								<Sprite icon='group' />
								{t('Группа')}
								<GroupCriterions items={GroupCriterionList} />
							</GroupInfo>} */}
						</Buttons>

						<MobileTransport className={classnames('mobile', {isShow: isTransport})}>
							<div className='close' onClick={onClose}>
								<Sprite icon='blue_cross' />
							</div>

							<HotelTransport title={t('Аэропорты')} string={Airport} />
							<HotelTransport title={t('Вокзалы')} string={RailwayStation} />
							<HotelSubways objects={subways} />
						</MobileTransport>
					</td>
				</tr>

				{rooms.map(i => <RoomTablet key={i.RoomCode} room={i} hotel={hotelBind} isOpen={isOpen} />)}

				{offers.length > 1 && <tr className={classnames('other', { isOpen })}>
					<td colSpan={4}>
						<div className={classnames('button', 'white', 'height30')} onClick={toggle}>
							{isOpen ? 'Скрыть' : `Показать ещё ${declines(offers.length - 1, [t('вариант'), t('варианта'), t('вариантов')], true)}`}
							<Sprite icon='arrow_down' />
						</div>
					</td>
				</tr>}

			</tbody>
		</Container>
	);
};