import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { Hotel } from './Hotel';
// import { WhereToPay } from './WhereToPay';
import { FreeSale } from './FreeSale';
import { Price } from './Price';
import { Stars } from './Stars';
import { Distance } from './Distance';
import { Amenities } from './Amenities';

const Container = styled.div`
	/* border: 1px solid red; */
	display: flex;
	flex-direction: row;
	align-items: stretch;
	gap: 20px;
	position: relative;
	height: auto;
	overflow-y: hidden;
	transition: .2s max-height ease-in-out, .2s margin-bottom ease-in-out;
	max-height: 0;
	margin-bottom: -10px;

	&.isOpen {
		max-height: 500px;
		margin-bottom: 10px;
	}

	> div {
		/* border: 1px solid blue; */
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: calc(100% / 3);

		> div {
			display: flex;
			flex-direction: column;
			gap: 10px;

			> label {
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				color: ${props => props.theme.darkBlue};
			}

			> label {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				> div {
					cursor: pointer;
				}
			}

			.checkbox {
				margin: 0 !important;
			}
		}
	}
`;

export const ExtFilters = ({ isOpen = false }) => {
	return (
		<Container className={classnames({ isOpen })}>
			<div>
				<Hotel />
				{/* <WhereToPay /> */}
				{/* <div className='filler' /> */}
				<FreeSale />
			</div>

			<div>
				<Price />
				<Stars />
			</div>

			<div>
				<Distance />
				<Amenities />
			</div>
		</Container>
	);
};