import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Sprite } from './Sprite';
import Calendar from 'react-calendar';
import { dayjs, format, parseDate, today } from 'utils/dayjs';
import { useClickOutside } from 'hooks';
import i18n from 'i18next';

const Container = styled.div`
	height: 40px;
	background-color: white;
	padding: 0 10px;
	color: ${props => props.theme.darkBlue};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	position: relative;

	@media (min-width: 1280px) {
		width: 145px;
	}

	@media (max-width: 659px) {
		justify-content: center;

		svg {
			display: none;
		}
	}
`;

const Label = styled.div`
	cursor: default;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
`;

const CalendarContainer = styled.div`
	position: absolute;
	z-index: 10;
	background-color: white;
	border: 1px solid ${props => props.theme.darkBlue};
	top: 100%;
	right: -1px;
	box-shadow: 2px 2px 2px rgba(33, 33, 33, .3);
`;

export const SingleDate = ({ value, onChange, readOnly = false }) => {
	const [ isOpen, setOpen ] = useState(false);
	const calendarRef = useRef(null);
	const { ref } = useClickOutside(() => setOpen(false));
	const locale = i18n.language;

	const calendarBind = {
		locale,
		value: parseDate(value).toDate(),
		minDate: today().toDate(),
		onChange: newValue => {
			onChange(dayjs(newValue).format(format));
			setOpen(false);
		},
	};

	const toggleOpen = e => !(calendarRef.current && calendarRef.current.contains(e.target)) && setOpen(prev => !prev);

	return (
		<Container onClick={toggleOpen} className='single_date' ref={ref}>
			<Label>{value}</Label>

			{!readOnly && <Sprite icon='calendar' />}

			{!readOnly && isOpen &&
				<CalendarContainer ref={calendarRef} className='calendar_container'>
					<Calendar {...calendarBind} />
				</CalendarContainer>
			}
		</Container>
	);
};