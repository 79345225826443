import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useUserStore } from './UserStore';

const sortFunc = (a, b) => {
	if (a.id === 999999) return -1;
	if (b.id === 999999) return 1;

	const bitsA = a.date.split('.');
	const bitsB = b.date.split('.');

	const dateA = new Date(bitsA[2], bitsA[1], bitsA[0]);
	const dateB = new Date(bitsB[2], bitsB[1], bitsB[0]);

	if (dateA === dateB) {
		return a.id > b.id ? -1 : 1;
	}

	return dateA > dateB ? -1 : 1;
};

const useNews = () => {
	const [ items, setItems ] = useState([]);
	const { isLogged, isBuyer } = useUserStore();

	const lastItems = useMemo(() => items.slice(0, 3), [items.length]);

	const itemsLength = items.length;

	const getItem = id => items.find(i => parseInt(i.id) === parseInt(id));

	const getNextItem = id => {
		const index = items.findIndex(i => parseInt(i.id) === parseInt(id));
		return index > 0 ? items[index - 1] : false;
	};

	const getPrevItem = id => {
		const index = items.findIndex(i => parseInt(i.id) === parseInt(id));
		return index < items.length - 1 ? items[index + 1] : false;
	};

	const fetchItems = () => {
		const time = (new Date()).getTime();

		axios.get(`https://academservice.ru/news/news.json?${time}`)
			.then(data => data.data)
			.then(data => {
				if (isBuyer) {
					axios.get(`https://academservice.ru/news/newhotels.json?${time}`)
						.then(data2 => data2.data)
						.then(data2 => {
							let news = data.concat(data2);
							news = news.filter(i => i?.acase === true).sort(sortFunc);

							setItems(news);
						});
				} else {
					const news = data.filter(i => i?.acase === true).sort(sortFunc);
					setItems(news);
				}
			})
			.catch(function (error) {
				console.log(error);
			})
			.then(function () {

			});
	};

	useEffect(() => {
		fetchItems();
	}, [isLogged]);

	return {
		items,
		lastItems,
		itemsLength,
		getItem,
		getNextItem,
		getPrevItem,
		fetchItems,
	};
};

const NewsContext = createContext(null);

const NewsProvider = ({ children }) => {
	const newsStore = useNews();

	return (
		<NewsContext.Provider value={newsStore}>
			{children}
		</NewsContext.Provider>
	);
};

const useNewsStore = () => {
	const context = useContext(NewsContext);

	if (context === undefined) {
		throw new Error('useNewsStore must be used within a NewsProvider');
	}

	return context;
};

export {
	NewsProvider,
	useNewsStore,
};
