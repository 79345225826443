import React from 'react';
import styled from 'styled-components';
import { Sprite } from 'components';
import { RateTablet } from './RateTablet';
import { useHotelSearchStore } from 'stores';

const NoPhoto = styled.div`
	width: 60px;
	height: 60px;
	background-color: #A2D0EA;
	margin-right: 10px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const RoomTablet = ({ room, hotel, isOpen = false }) => {
	const {
		onClickRoom,
	} = useHotelSearchStore();

	const {
		Code,
		Url,
		ThUrl,
		RoomName,
		rates,
		RoomCode
	} = room;

	const image = ThUrl ?? Url ?? false;

	return (
		<>
			<tr onClick={onClickRoom.bind(this, Code, hotel.Code, RoomCode, hotel.Name, hotel.Stars)} className='clickable rommName'>
				<td className='roomPhoto'>
					{image ?
						<img src={room.Url.replace('test-images', 'images')} alt='' width='60' height='60' /> :
						<NoPhoto>
							<Sprite icon='no_photo' />
						</NoPhoto>
					}
				</td>

				<td colSpan={3} className='roomName'>
					{RoomName}
				</td>
			</tr>

			{rates.slice(0, isOpen ? rates.length : 1).map(i => <RateTablet rate={i} key={i.Code} hotel={hotel} />)}
		</>
	);
};