import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useInstance } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from 'stores';
import {
	useCheckInOutProps,
	useCitizenships,
	useCommentProps,
	useDatesProps,
	useExtraMealsProps,
	useGuestsProps,
	useHotel,
	useMealsProps,
	useOrderInfo,
	usePricesProps,
	usePricing,
	useProduct,
	useRatesProps,
	useRoomProps,
} from 'hooks/useOrderLibs';
import { usePersonsProps } from 'hooks/useOrderLibs/usePersonsProps';
import { useRegister } from 'hooks/useRegister';
import { compareDates } from 'utils';
import { useCancelText } from 'hooks';

const useHotelBookingOld = () => {
	const props = useParams();
	const { post } = useInstance();
	const [ guarantee, setGuarantee ] = useState(false);
	const navigate = useNavigate();
	const { user } = useUserStore();

	const { registerBind } = useRegister();

	const { citizenships } = useCitizenships();
	const { accommodation, order, orderInfo, setOrderInfo } = useOrderInfo(props);

	const orderReadOnly = parseInt(order?.ReadOnly?.Code ?? 2) === 1;
	const accommodationAllowModify = parseInt(accommodation?.AllowModify?.Code ?? 1) === 1;
	const canChangeProduct = !orderReadOnly && accommodationAllowModify && ((accommodation?.Id ?? 0) <= 0 || compareDates(orderInfo.ArrivalDate, 'today') >= 0);

	const { products } = useProduct(orderInfo);

	const { pricing } = usePricing(orderInfo, products, setOrderInfo, props?.AccommodationId, order);
	const { hotel } = useHotel(orderInfo?.Hotel ?? 0);
	const [ rate, setRate ] = useState(null);

	const currency = pricing?.Currency?.Name ?? 'RUB';

	useEffect(() => {
		if (props?.accommodationId > 0) return;
		
		if (products.length > 0 && !products.map(i => parseInt(i.Code)).includes(parseInt(orderInfo.Product))) {
			setOrderInfo(prev => ({
				...prev,
				Product: products?.[0]?.Code
			}));
		}
	}, [products.length]);

	const offer = products?.find(i => parseInt(i.Code) === parseInt(orderInfo.Product));
	
	const rooms = products?.slice()
		.map(i => i.RoomCode)
		.filter((v, i, a) => a.indexOf(v) === i)
		.map(roomCode => {
			const room = products.find(i => i.RoomCode === roomCode);
			room.Rates = products.filter(i => i.RoomCode === roomCode);

			return room;
		}) ?? null;

	const other = rooms.map(r => ({
		...r,
		Rates: r.Rates.filter(i => i.Code !== offer?.Code)
	})).filter(i => i?.Rates?.length > 0);

	useEffect(() => {
		const guarantee = pricing?.HotelPricingDetail?.WhereToPayList?.WhereToPay?.[0] ?? false;
		setGuarantee(guarantee);
	}, [pricing?.HotelPricingDetail?.WhereToPayList?.WhereToPay?.[0]?.Code]);

	const hotelCode = hotel?.Code ?? 0;
	const hotelName = hotel?.Name ?? '';
	const hotelStars = hotel?.Stars?.Code;
	const hotelAddress = hotel ? `${hotel?.Country?.Name}, ${hotel?.TypeOfPlace?.Name} ${hotel?.City?.Name}, ${hotel.Address}` : '';

	const { datesProps } = useDatesProps({ orderInfo, setOrderInfo, orderReadOnly, accommodationAllowModify });
	const { guestsProps } = useGuestsProps({ orderInfo, setOrderInfo, offer });
	const { roomProps } = useRoomProps({ orderInfo, setOrderInfo, offer, setRate, currency, canChangeProduct });
	// const { ratesProps } = useRatesProps({ rooms, hotelCode, setOrderInfo, setRate });
	const { ratesProps } = useRatesProps({ rooms: other, hotelCode, setOrderInfo, setRate, currency });
	const { personsProps } = usePersonsProps({ orderInfo, citizenships, setOrderInfo });
	const { checkInOutProps } = useCheckInOutProps({ pricing, setOrderInfo, orderInfo });
	const { commentProps } = useCommentProps({ orderInfo, setOrderInfo });
	const { pricesProps } = usePricesProps({ order, offer, pricing });
	const cancelText = useCancelText({ pricing, accommodation, CurrencyName: currency });

	const { mealsProps } = useMealsProps({ pricing, orderInfo, setOrderInfo });
	const { extraMealsProps } = useExtraMealsProps({ pricing, orderInfo, setOrderInfo });

	const specialProps = {
		pricing
	};

	const isAllowModify = (order?.ReadOnly?.Code ?? 2) === 2 && (accommodation?.AllowModify?.Code ?? 1) === 1;

	const update = async () => {
		const item = {
			...accommodation,
			ArrivalDate: orderInfo.ArrivalDate,
			ArrivalTime: orderInfo.ArrivalTime === '' ? (parseInt(pricing.HotelPricingDetail.IsHourRate) === 1 ? pricing.HotelPricingDetail.StartTime : pricing.HotelPricingDetail.DefaultCheckInTime) : orderInfo.ArrivalTime,
			DepartureDate: orderInfo.DepartureDate,
			DepartureTime: orderInfo.DepartureTime === '' ? (parseInt(pricing.HotelPricingDetail.IsHourRate) === 1 ? pricing.HotelPricingDetail.EndTime : pricing.HotelPricingDetail.DefaultCheckOutTime) : orderInfo.DepartureTime,
			NumberOfRooms: 1,
			NumberOfGuests: pricing.NumberOfGuests,
			NumberOfExtraBedsAdult: pricing.NumberOfExtraBedsAdult,
			NumberOfExtraBedsChild: pricing.NumberOfExtraBedsChild,
			NumberOfExtraBedsInfant: pricing.NumberOfExtraBedsInfant,
			AdditionalInfo: orderInfo.AdditionalInfo,
			ToBeCancelled: {
				Code: 2
			},
			ToBeChanged: {
				Code: 1
			},
			Product: {
				Code: orderInfo.Product,
				Allotment: {
					Code: 'void'
				}
			},
			Meal: {
				// Code: pricing?.HotelPricingDetail?.Meals?.Meal?.find(i => i.IncludedInPrice.Code === 1)?.Code ?? 0
				// Code: pricing?.HotelPricingDetail?.Meals?.Meal?.[0]?.Code ?? 0
				Code: orderInfo?.Meal
			},
			ExtraMeals: {
				ExtraMeal: orderInfo.ExtraMeals
			},
			CriticalEarlyCheckIn: {
				Code: orderInfo?.ArrivalTime === pricing?.HotelPricingDetail?.DefaultCheckInTime ? 3 : (orderInfo.CriticalEarlyCheckIn ? 1 : 2)
			},
			CriticalLateCheckOut: {
				Code: orderInfo?.DepartureTime === pricing?.HotelPricingDetail?.DefaultCheckOutTime ? 3 : (orderInfo.CriticalLateCheckOut ? 1 : 2)
			},
			Persons: {
				Person: orderInfo.Persons.slice(0, orderInfo.NumberOfGuests + orderInfo.NumberOfExtraBedsAdult + orderInfo.NumberOfExtraBedsChild + orderInfo.NumberOfExtraBedsInfant).map(i => ({
					LastName: i.LastName,
					FirstName: i.FirstName,
					Category: i.Category,
					Citizenship: i.Citizenship
				}))
			},
			Price: pricing?.HotelPricingDetail?.Price,
			TravelAgencyCommission: pricing?.HotelPricingDetail?.TravelAgencyCommission,
			VATIncludedInPrice: pricing?.HotelPricingDetail?.VATIncludedInPrice,
			PossiblePenaltySize: pricing?.HotelPricingDetail?.PenaltySize,
		};

		const request = {
			OrderRequest: {
				...order,
				AccommodationList: {
					Accommodation: [
						...order.AccommodationList.Accommodation.map(i => {
							return parseInt(i.Id) === parseInt(item.Id) ? item : i;
						})
					]
				}
			}
		};

		const data = await post(request);

		if (data?.Success?.Id) {
			navigate(`/order/${data.Success.Id}`);
		}
	};

	const create = async () => {
		const item = {
			Id: -99999,
			ArrivalDate: orderInfo.ArrivalDate,
			ArrivalTime: orderInfo.ArrivalTime === '' ? (parseInt(pricing.HotelPricingDetail.IsHourRate) === 1 ? pricing.HotelPricingDetail.StartTime : pricing.HotelPricingDetail.DefaultCheckInTime) : orderInfo.ArrivalTime,
			DepartureDate: orderInfo.DepartureDate,
			DepartureTime: orderInfo.DepartureTime === '' ? (parseInt(pricing.HotelPricingDetail.IsHourRate) === 1 ? pricing.HotelPricingDetail.EndTime : pricing.HotelPricingDetail.DefaultCheckOutTime) : orderInfo.DepartureTime,
			NumberOfRooms: 1,
			NumberOfGuests: pricing.NumberOfGuests,
			NumberOfExtraBedsAdult: pricing.NumberOfExtraBedsAdult,
			NumberOfExtraBedsChild: pricing.NumberOfExtraBedsChild,
			NumberOfExtraBedsInfant: pricing.NumberOfExtraBedsInfant,
			AdditionalInfo: orderInfo.AdditionalInfo,
			ToBeCancelled: {
				Code: 2
			},
			ToBeChanged: {
				Code: 1
			},
			Hotel: {
				Code: orderInfo.Hotel
			},
			Product: {
				Code: orderInfo.Product,
				Allotment: {
					Code: 'void'
				}
			},
			Meal: {
				// Code: pricing?.HotelPricingDetail?.Meals?.Meal?.find(i => i.IncludedInPrice.Code === 1)?.Code ?? 0
				// Code: pricing?.HotelPricingDetail?.Meals?.Meal?.[0]?.Code ?? 0
				Code: orderInfo?.Meal
			},
			ExtraMeals: {
				ExtraMeal: orderInfo.ExtraMeals
			},
			CriticalEarlyCheckIn: {
				Code: orderInfo?.ArrivalTime === pricing?.HotelPricingDetail?.DefaultCheckInTime ? 3 : (orderInfo.CriticalEarlyCheckIn ? 1 : 2)
			},
			CriticalLateCheckOut: {
				Code: orderInfo?.DepartureTime === pricing?.HotelPricingDetail?.DefaultCheckOutTime ? 3 : (orderInfo.CriticalLateCheckOut ? 1 : 2)
			},
			Persons: {
				Person: orderInfo.Persons.slice(0, orderInfo.NumberOfGuests + orderInfo.NumberOfExtraBedsAdult + orderInfo.NumberOfExtraBedsChild + orderInfo.NumberOfExtraBedsInfant).map(i => ({
					LastName: i.LastName,
					FirstName: i.FirstName,
					Category: i.Category,
					Citizenship: i.Citizenship
				}))
			},
			Penalties: [],
			Gain: '0.00',
			Price: pricing?.HotelPricingDetail?.Price,
			TravelAgencyCommission: pricing?.HotelPricingDetail?.TravelAgencyCommission,
			VATIncludedInPrice: pricing?.HotelPricingDetail?.VATIncludedInPrice,
			PossiblePenaltySize: pricing?.HotelPricingDetail?.PenaltySize,
		};

		const request = {
			OrderRequest: {
				Id: 0,
				Options: '',
				Currency: {
					Code: pricing.Currency.Code
				},
				WhereToPay: {
					// Code: offer.WhereToPay.Code
					Code: offer.WhereToPayList.WhereToPay[0].Code
				},
				Customer: {
					Code: user?.CustomerCode
				},
				BuyerImplant: {},
				AccommodationList: {
					Accommodation: [
						item
					]
				}
			}
		};

		const data = await post(request);

		if (data?.Success?.Id) {
			navigate(`/order/${data.Success.Id}`);
		}
	};

	const saveOrder = async () => {
		if (isAllowModify) {
			(orderInfo?.Id ?? 0) < 1 && (orderInfo.AccommodationId ?? -99999) < 1 && create();
			// (orderInfo?.Id ?? 0) > 1 && (orderInfo.AccommodationId ?? -99999) < 1 && add();
			(orderInfo?.Id ?? 0) > 1 && (orderInfo.AccommodationId ?? -99999) > 1 && update();
		}
	};

	const detailsProps = {
		isVisible: rate !== null,
		rate: rate === null ? null : { ...rate, hotelCode: orderInfo?.Hotel },
		store: {
			startDate: orderInfo?.ArrivalDate,
			endDate: orderInfo?.DepartureDate,
			adults: parseInt(orderInfo?.NumberOfGuests),
			childs: 0
		},
		// details: offer?.details,
		details: pricing,
		onClose: () => setRate(null)
	};

	const personsCount = useMemo(() => (orderInfo?.Persons?.filter(i => i.FirstName.trim().length >= 1 && i.LastName.trim().length >= 1) ?? []).length, [JSON.stringify(orderInfo?.Persons)]);

	const saveButtonProps = {
		accId: orderInfo?.AccommodationId, 
		saveOrder, 
		order,
		personsCount,
	};

	const canChangeEarlyCheckIn = !orderReadOnly && accommodationAllowModify && ((accommodation?.Id ?? 0) <= 0 || compareDates(orderInfo.ArrivalDate, 'today') >= 0);
	const canChangeLateCheckOut = !orderReadOnly && accommodationAllowModify && ((accommodation?.Id ?? 0) <= 0 || compareDates(orderInfo.DepartureDate, 'today') >= 0);
	const canAddExtraMeal = !orderReadOnly && accommodationAllowModify && ((accommodation?.Id ?? 0) <= 0 || compareDates(orderInfo.DepartureDate, 'today') >= 0);
	const isHourRate = parseInt(pricing?.HotelPricingDetail?.IsHourRate ?? 2) === 1;

	const isLoaded = Boolean(orderInfo?.ArrivalDate);
	
	return {
		order,
		accommodation,
		pricing,
		offer,
		products,
		hotel,
		orderInfo,

		hotelName,
		hotelStars,
		hotelAddress,
		datesProps,
		extraMealsProps,
		guestsProps,
		roomProps,
		ratesProps,
		personsProps,
		checkInOutProps,
		mealsProps,
		commentProps,
		pricesProps,
		specialProps,
		detailsProps,
		
		currency,
		cancelText,
		saveOrder,
		isAllowModify,
		saveButtonProps,
		canChangeProduct,
		canChangeEarlyCheckIn,
		canChangeLateCheckOut,
		canAddExtraMeal,
		isHourRate,
		setOrderInfo,
		orderReadOnly,
		accommodationAllowModify,
		registerBind,
		isLoaded,
		orderId: order?.Id,
		accommodationId: accommodation?.Id,
		personsCount,
		guarantee,
		setGuarantee,
	};
};

const HotelBookingOldContext = createContext(null);

const HotelBookingOldProvider = ({ children }) => {
	const errorStore = useHotelBookingOld();

	return (
		<HotelBookingOldContext.Provider value={errorStore}>
			{children}
		</HotelBookingOldContext.Provider>
	);
};

const useHotelBookingOldStore = () => {
	const context = useContext(HotelBookingOldContext);

	if (context === undefined) {
		throw new Error('useHotelBookingOldStore must be used within a HotelBookingOldProvider');
	}

	return context;
};

export {
	HotelBookingOldProvider,
	useHotelBookingOldStore,
};