import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toMoney } from 'utils';
import { useHotelSearchStore, useUserStore } from 'stores';
import { Button } from 'components';
import { PenaltyList } from '../';

const Container = styled.table`
	width: 100%;

	& + .offer {
		border-top: 1px solid #D5E3F0;
	}

	td {
		padding-top: 10px;
		padding-bottom: 10px;
		position: relative;

		> div + div {
			margin-top: 5px;
		}

		&:last-child {
			div {
				text-align: right;
			}
		}
	}

	*:not(.button):not(.components__button) {
		color: #333;
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		font-weight: 400;
		line-height: normal;
	}

	b {
		font-weight: 700 !important;
	}

	.orange {
		color: ${props => props.theme.orange};
	}

	.blue {
		color: ${props => props.theme.darkBlue};
	}

	.gray {
		color: ${props => props.theme.placeholderColor};
	}

	.lightBlue {
		color: #00C0FF;
	}

	.green {
		color: #5BB500;
	}

	img {
		vertical-align: text-bottom;
		margin-right: 5px;
	}

	.button, .components__button  {
		display: inline-block !important;
		margin-left: 10px;
		vertical-align: middle;
		line-height: 30px !important;
		width: 160px !important;
	}

	span + span, b + b {
		margin-left: 10px;
	}

	.overflow {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 300px;
		display: inline-block;
	}
`;

const Offer = ({ offer, CurrencyName, hotelCode, datesBind, onShowDetails }) => {
	const { t } = useTranslation();
	const { allowTabs } = useUserStore();

	const {
		params: {
			startDate = datesBind?.startDate,
			endDate = datesBind?.endDate,
		} = {},
		onShowDetails: handleShowDetails = onShowDetails,
	} = useHotelSearchStore() ?? {};

	const {
		Code,
		MealIncludedInPrice: {
			Code: MealIncludedInPrice,
			Name: MealIncludedInPriceName
		},
		WhereToPay: {
			Name: WhereToPayName
		},
		DiscountOffer: {
			Code: DiscountOffer = 2,
			Name: DiscountOfferName
		} = {},
		TotalPrice,
		Availability: {
			Code: Availability,
			Name: AvailabilityName,
			Allotment: {
				Rooms
			}
		},
		RateDescription = '',
		NumberOfGuests,
		NumberOfExtraBedAdult,
		NumberOfExtraBedChild,
		NumberOfExtraBedInfant,
		PenaltyRuleList: {
			PenaltyRule = []
		} = {}
	} = offer;

	const buttonBind = {
		orange: Availability === 4,
		gradient: Availability !== 4,
		to: `/order/${hotelCode}/${Code}/${startDate}/${endDate}/${NumberOfGuests}/${NumberOfExtraBedAdult}/${NumberOfExtraBedChild}/${NumberOfExtraBedInfant}`,
		target: Number(sessionStorage?.getItem('orderId') ?? 0) === 0 && allowTabs ? '_blank' : '_self',
	};

	return (
		<Container className='offer'>
			<colgroup>
				<col width='47%' />
				<col />
			</colgroup>

			<tbody>
				<tr>
					<td>
						{Number(DiscountOffer) === 1 &&
							<div>
								<b className='orange overflow'>{DiscountOfferName}</b>
							</div>
						}

						{Number(DiscountOffer) !== 1 && !!RateDescription &&
							<div>
								<b className='blue overflow'>{RateDescription}</b>
							</div>
						}

						{PenaltyRule.length > 0 && <PenaltyList items={PenaltyRule} CurrencyName={CurrencyName} />}

						<div>
							<b>
								{MealIncludedInPrice > 1 ? MealIncludedInPriceName : t('Без питания')}
							</b>
						</div>
					</td>

					<td>
						<div onClick={handleShowDetails.bind(this, offer, hotelCode)}>
							<img src='/images/icon_warning.png' />
	
							<b className='blue'>
								{toMoney(TotalPrice)} {CurrencyName}
							</b>
						</div>

						<div>
							<span>
								{WhereToPayName}
							</span>
						</div>

						<div>
							<span>
								{Availability === 4 && t('Под запрос')}
								{Availability !== 4 && Rooms !== '' && <>{t('В наличии')}: <b>{Rooms}</b></>}
								{Availability !== 4 && Rooms === '' && AvailabilityName.toLowerCase().indexOf('свободная продажа') === -1 && <>{t('В наличии')}: <b>{AvailabilityName}</b></>}
								{Availability !== 4 && Rooms === '' && AvailabilityName.toLowerCase().indexOf('свободная продажа') !== -1 && <>{t('В наличии')}: <b>{t('много')}</b></>}
							</span>

							<Button {...buttonBind}>{t('Бронировать')}</Button>
						</div>
					</td>
				</tr>
			</tbody>
		</Container>
	);
};

export default Offer;