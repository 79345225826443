import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { IconWhiteCross } from 'images/icons';
import { useClickOutside } from 'hooks';

const Overlay = styled.div`
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(3, 71, 129, 0.8);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out !important;
	margin: 0 !important;
	padding: 0 !important;
	
	&.visible {
		opacity: 1;
		visibility: visible;
	}
`;

const CloseButton = styled.div`
	cursor: pointer;
	position: absolute;
	top: 20px;
	right: 20px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	@media (min-width: 1280px) {
		top: 40px;
		right: 40px;
	}

	&.fixed {
		@media (max-width: 1279px) {
			display: none;
		}
	}
`;

const Container = styled.div`
	padding: 20px;
	background: white;
	width: ${props => isNaN(parseInt(props.$width)) ? props.$width : `${props.$width}px`};
	margin: 100px 0;
	overflow-y: auto;

	@media (min-width: 1280px) {
		max-width: calc(100vw - 80px);
	}

	@media (max-width: 1279px) {
		width: calc(100vw - 40px) !important;
		/* height: 100vh !important; */
		margin: 0;
	}

	&.fixed {
		@media (max-width: 1279px) {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: auto !important;
			margin: 0;
		}
	}

	h1 {
		/* color: ${props => props.theme.darkBlue}; */
		margin-bottom: 20px;
	}
`;

export const Modal = ({
	children = null, 
	isVisible = false, 
	onClose = () => null, 
	width = '1240',
	containerStyle = {},
	fixed = false
}) => {
	const { mainRef, ref } = useClickOutside(onClose);

	return (
		<Overlay className={classnames({visible: isVisible})} ref={mainRef}>
			<CloseButton className={classnames({fixed})}>
				<IconWhiteCross />
			</CloseButton>

			<Container $width={width} className={classnames('modal_container', {fixed})} style={containerStyle} ref={ref}>
				{children}
			</Container>
		</Overlay>
	);
};
