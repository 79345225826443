import React from 'react';
import styled from 'styled-components';
import { declines } from 'utils';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	margin-top: 20px;
`;

const Title = styled.div`
	color: ${props => props.theme.darkBlue};
	font-size: 16px;
	line-height: 19px;
	margin-bottom: 10px;
	
	b { font-weight: 500; }
`,

	Items = styled.div`
	margin-top: 10px;
	border: 1px solid ${props => props.theme.lightGray};
	padding: 10px;
	max-height: 145px;
	overflow-y: auto;
	
	> div {
		font-family: ${props => props.theme.pt};
		font-size: 14px;
		line-height: 18px;
		color: #333;
		margin-bottom: 5px;	
	}
`;

export const Special = ({ pricing }) => {
	const { t } = useTranslation();

	if (!pricing) {
		return null;
	}

	const titles = [t('условие'), t('условия'), t('условий')];

	const {
		SpecialRequirementList: {
			SpecialRequirement = []
		} = {}
	} = pricing ?? {};

	return (
		<Container>
			<Title><b>{t('Особые условия отеля')}:</b> {SpecialRequirement.length} {declines(SpecialRequirement.length, titles)}</Title>

			<Items>
				{SpecialRequirement.map((i, key) => <div key={key}>{key + 1}. {i.Text}</div>)}
			</Items>
		</Container>
	);
};