import React, { useEffect, useState } from 'react';
import { useInstance } from 'hooks';
import { Sprite } from 'components';
import { SelectInput } from './SelectInput';
import { useTranslation } from 'react-i18next';

let timer = null;

export const Destination = ({
	initialItems = [],
	initialInput = '',
	value = null,
	onChange = () => false,
	...props
}) => {
	const { t } = useTranslation();
	const [ input, setInput ] = useState('');
	const [ saveInput, setSaveInput ] = useState(initialInput);
	const [ options, setOptions ] = useState(initialItems);
	const { post } = useInstance();

	const fetch = async input => {
		const request = {
			DestinationRequest: {
				ActionSearch: {
					Parameters: {
						Text: input.trim(),
						HighlightCssClass: 'Highlighter',
						NotSearchableCssClass: 'NotSearchableCss'
					}
				}
			}
		};

		const data = await post(request);

		return data?.DestinationList?.Destination?.map(i => ({
			...i,
			value: i.Code,
			Name: i.Name.replace(/(<([^>]+)>)/gi, ''),
			label: (
				<div className='row'>
					{i.TypeCode === 2 && <Sprite icon='city' />}
					{i.TypeCode === 3 && <Sprite icon='hotel' />}
					{i.TypeCode === 4 && <Sprite icon='airport' />}
					<div>{i.Name.replace(/(<([^>]+)>)/gi, '')}</div>
				</div>),
		})) ?? [];
	};

	/*
	useEffect(() => {
		clearTimeout(timer);

		if (input.trim().length >= 2) {
			timer = setTimeout(async () => {
				const items = await fetch(input);
				setOptions(items);
			}, 500);
		}
	}, [input]);
	*/

	useEffect(() => {
		const func = async () => {
			if (initialInput.trim().length >= 2) {
				const items = await fetch(initialInput);
				setOptions(items);
			}
		};
		
		func();
	}, []);

	const bind = {
		placeholder: t('Город, отель, станция метро, достопримечательность'),
		name: 'destination',
		value,
		options,
		className: 'react-select-container',
		classNamePrefix: 'react-select',
		isSearchable: true,
		noOptionsMessage: () => null,
		// defaultMenuIsOpen: true,
		filterOption: () => true,
		blurInputOnSelect: true,
		inputValue: input,
		openMenuOnFocus: true,
		onInputChange: (input, e) => {
			if (e?.action === 'input-change') {
				setInput(input);

				clearTimeout(timer);

				if (input.trim().length >= 2) {
					timer = setTimeout(async () => {
						const items = await fetch(input);
						setOptions(items);
					}, 500);
				}
			}
		},
		onBlur: () => {
			setSaveInput(input);
			setInput('');
		},
		onFocus: () => {
			setInput(saveInput);
			setSaveInput('');
		},
		onChange: item => {
			onChange({
				input,
				item
			});
		},
		handleKeyDown: evt => {
			const len = evt.target.value.length;
			console.log(evt.key);
			switch(evt.key){
			case "Home": evt.preventDefault();
				if(evt.shiftKey) evt.target.selectionStart = 0;
				else evt.target.setSelectionRange(0,0);
				break;
			case "End": evt.preventDefault();
				if(evt.shiftKey) evt.target.selectionEnd = len;
				else evt.target.setSelectionRange(len,len);
				break;
			}
		}
	};

	return <SelectInput {...bind} {...props} />;
};