import React, { useEffect } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { declines } from 'utils';
import { Sprite } from 'components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	background: white;
	position: fixed;
	top: calc(-100% - 20px);
	left: 0;
	right: 0;
	box-shadow: 0 10px 20px rgb(0 0 0 / .2);
	z-index: 1999999;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 20px 40px;
	transition: top .2s ease-in-out;
	
	&.isVisible {
		top: 0;
	}
`;

const InnerContainer = styled.div`
	width: 1240px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const Photo = styled.a`
	width: 80px;
	height: 80px;
	position: relative;
	margin-right: 20px;
	line-height: 0;
	background-color: #4F7EA7;
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		z-index: 9;
	}
	
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(3, 71, 129, 0.5);
		color: white;
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		height: 30px;
	}
	
	.noPhoto {
		width: 100%;
		height: 100%;
		z-index: 9;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const Titles = styled.div`
	> div {
		color: #333;
		font-family: ${props => props.theme.pt};

		&:first-child {
			font-size: 18px;
			line-height: 21px;
			margin-bottom: 6px;
			font-weight: bold;
		}

		&:last-child {
			font-size: 16px;
			line-height: 21px;
			margin-bottom: 10px;
		}
	}
`;

const CompareButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border: 2px solid ${props => props.theme.darkBlue};
	background-color: white;
	color: ${props => props.theme.darkBlue};
	transition: color .2s ease-in-out, background-color .2s ease-in-out;
	font-size: 14px;
	line-height: 17px;
	text-transform: uppercase;
	border-radius: 5px;
	height: 30px;
	width: 180px;
	cursor: pointer;
	margin-left: 20px;
	padding: 0 10px;
	white-space: nowrap;
		
	&:hover {
		color: white;
		background-color: ${props => props.theme.darkBlue};
	}
`;

const CloseButton = styled.div`
	cursor: pointer;
	width: 20px;
	height: 20px;
	flex-grow: 0;
	flex-shrink: 0;
	margin-left: 20px;
	color:  ${props => props.theme.darkBlue};
`;

export const AddCompareMessage = ({last, isVisible, count, onOpenCompare, onClose}) => {
	const { t } = useTranslation();

	const hotelsTitles = [t('отель'), t('отеля'), t('отелей')];

	useEffect(() => {
		if (isVisible && count === 0) {
			onClose();
		}
	}, [count, isVisible]);

	return (
		<Container className={classnames({isVisible: isVisible})}>
			<InnerContainer>
				<Photo href={`/hotel/${last?.Code}`} target='_blank'>
					{last?.Url ?
						<img src={last.Url && last.Url.replace('test-images', 'images')} alt=''/> :
						<div className='noPhoto'>
							<Sprite icon='no_photo' />
						</div>
					}
				</Photo>

				<Titles>
					<div>
						{t('Отель {{Name}}, {{City}} добавлен к сравнению', {
							Name: last?.Name,
							City: last?.City
						})}
					</div>
					<div>{t('Всего в списке')} {count} {declines(count, hotelsTitles)}</div>
				</Titles>

				<div className='filler' />

				{count > 0 && <CompareButton onClick={onOpenCompare}>{t('Сравнить выбранные')}</CompareButton>}

				<CloseButton onClick={onClose}>
					<Sprite icon='blue_cross' />
				</CloseButton>
			</InnerContainer>
		</Container>
	);
};