import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import { Sprite } from 'components';
import classnames from 'classnames';
import { nanoid } from 'nanoid';

const Container = styled.div`
	height: 40px;
	border: 1px solid ${props => props.theme.darkBlue};
	position: relative;

	& *::selection { background: transparent; }
	& *::-moz-selection { background: transparent; }

	> label {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 100%;
		width: 100%;
		padding: 0 10px;
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
		color: ${props => props.theme.darkBlue};

		b {
			font-weight: 500;
		}

		svg {
			margin-left: 10px;
		}
	}

	> input {
		clip: rect(0 0 0 0);
		position: absolute;
		height: 100% !important;
		width: calc(100% - 30px) !important;
		outline: none;
		z-index: 100;
		padding: 0 10px;
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
		color: ${props => props.theme.darkBlue};
		border: none !important;
		box-shadow: none !important;

		&::selection {
			background-color: ${props => props.theme.lightBlue} !important;
			color: white;
		}

		&:focus {
			clip: auto;

			& + label {
				svg { transform: rotate(180deg); }

				& + ul {
					opacity: 1;
					visibility: visible;
				}
			}			
		}
	}

	> ul {
		position: sticky;
		z-index: 10;
		background-color: white;
		border: 1px solid ${props => props.theme.darkBlue};
		width: calc(100% + 2px);
		margin-left: -1px;
		max-height: 200px;
		height: auto;
		overflow-y: auto;
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
		margin-top: 0 !important;
		padding: 0 !important;

		li {
			height: 40px;
			width: 100%;
			padding: 0 10px !important;
			margin: 0 !important;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			font-family: ${props => props.theme.pt};
			font-size: 16px;
			line-height: 21px;
			color: ${props => props.theme.darkBlue};
			cursor: pointer;

			&:not(:last-child) {
				border-bottom: 1px solid ${props => props.theme.lightGray};
			}

			&.selected {
				background-color: ${props => props.theme.darkBlue};
				color: white;
			}

			&.hover {
				background-color: ${props => props.theme.lightBlue};
				color: white;
			}
		}
	}
`;

export const Select2 = ({ 
	items = [], 
	value = null, 
	valueField = i => i?.Code ?? null, 
	labelField = i => i?.Name ?? '', 
	onChange = i => console.log(i),
	style = {},
	placeholder = '',
	readOnly = false
}) => {
	const [ input, setInput ] = useState('');
	const [ index, setIndex ] = useState(0);
	const ref = useRef(null);
	const itemsRef = useRef(null);
	const id = 'select_is_focus_' + nanoid(10);

	if (readOnly) {
		placeholder = '';
	}

	const children = itemsRef?.current?.children?.[index];

	const filtered = items.filter(i => input.trim().length === 0 || ReactDOMServer.renderToString(labelField(i)).replace(/(<([^>]+)>)/gi, "").toLowerCase().includes(input.trim().toLowerCase()));
	
	// const item = items?.find(i => valueField(i)?.toString() === value?.toString()) ?? items?.[0];
	
	const item = items?.find(i => valueField(i)?.toString() === value?.toString());

	useEffect(() => {
		children !== undefined && children.scrollIntoView({block: 'nearest', behavior: 'smooth'});
	}, [index]);

	useEffect(() => {
		setIndex(0);
	}, [filtered.length]);

	const inputProps = {
		id,
		ref,
		value: input,
		onChange: e => setInput(e.target.value),
		onKeyUp: e => {
			e.keyCode === 13 && onChange(valueField(filtered[index]));
			e.keyCode === 38 && setIndex(prev => prev > 0 ? prev - 1 : filtered.length - 1);
			e.keyCode === 40 && setIndex(prev => index < (filtered.length - 1) ? prev + 1 : 0);
			[13, 27].includes(e.keyCode) && ref.current.blur();
		},
		placeholder
	};

	const itemProps = (i, key) => ({
		className: classnames({selected: valueField(i) === valueField(item), hover: key === index}),
		onClick: () => onChange(valueField(i)),
		onMouseEnter: i => setIndex(i)
	});

	return (
		<Container style={style} className='select2'>
			{!readOnly && <input {...inputProps} />}
			<label htmlFor={id}>
				{item ? labelField(item) : <>{placeholder}<div className='filler' /></>}
				{/* <div className='filler' /> */}
				{!readOnly && <Sprite icon='arrow_down' />}
			</label>

			{filtered.length > 0 && <ul ref={itemsRef}>
				{filtered.map((i, key) => 
					<li {...itemProps(i, key)} key={valueField(i)} >
						{labelField(i)}
					</li>
				)}
			</ul>}
		</Container>
	);
};