import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toMoney } from 'utils';

const Container = styled.div`

	> * {
		text-align: right;
	}
`;

const PriceDiv = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	color: ${props => props.theme.darkBlue};
`;

const TotalPrice = styled.div`
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: ${props => props.theme.darkBlue};
	margin-top: 20px;
`;

export const Prices = ({ currency, pricing }) => {
	const { t } = useTranslation();

	if (!pricing) {
		return null;
	}

	const {
		Price,
		PriceDescription: {
			EarlierCheckInPrice = 0,
			LateCheckOutPrice = 0,
			Days: {
				Day = []
			} = {}
		} = {},
		ExtraMeals: {
			ExtraMeal = []
		} = {}
	} = pricing.HotelPricingDetail;

	const mealPrice = Day.map(i => parseFloat(i?.AdditionalMeal ?? 0)).reduce((acc, curr) => acc + curr, 0);
	const extraMealsPrice = ExtraMeal.reduce((a, b) => a + parseFloat(b.Total), 0);

	const mealsPrice = mealPrice + extraMealsPrice;

	const daysPrice = Day
		.filter(i => i?.Price !== undefined)
		.map(i => parseFloat(i.Price))
		.reduce((a, b) => a + b, 0)
		- mealPrice;

	return (
		<Container>
			{daysPrice > 0 && (
				<PriceDiv>
					{t('Размещение')}: {toMoney(daysPrice)} {currency}
				</PriceDiv>
			)}

			{EarlierCheckInPrice.Price > 0 && (
				<PriceDiv>
					{t('Ранний заезд')}: {toMoney(EarlierCheckInPrice.Price)}{' '}
					{currency}
				</PriceDiv>
			)}

			{LateCheckOutPrice.Price > 0 && (
				<PriceDiv>
					{t('Поздний выезд')}: {toMoney(LateCheckOutPrice.Price)} {currency}
				</PriceDiv>
			)}

			{mealsPrice > 0 && (
				<PriceDiv>
					{t('Питание')}: {toMoney(mealsPrice)} {currency}
				</PriceDiv>
			)}

			<TotalPrice>
				{t('ИТОГО')}: {toMoney(Price)} {currency}
			</TotalPrice>
		</Container>
	);
};
