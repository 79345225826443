import { createPerson } from './createPerson';

export const createTransport = ({
	Id,
	ServiceCode,
	ServiceDate,
	IsGain = 3,
}) => {
	const request = {
		Id,
		ServiceDate,
		ServiceTime: '',
		NumberOfGuests: 1,
		NumberOfServices: '',
		FlightNumber: '',
		FlightDate: '',
		FlightTime: '',
		NameBoard: '',
		Route: '',
		AddressFrom: '',
		AddressTo: '',
		PassengerPhone: '',
		VoucherId: '',
		Awaiting: {
			Code: 2,
			FullName: '',
			Phone: '',
		},
		AdditionalInfo: '',
		InternalInfo: '',
		DeadlineDate: '',
		Price: '0.00',
		VATIncludedInPrice: '0.00',
		FullVATInPrice: 2,
		TravelAgencyCommission: '0.00',
		Penalty: '0.00',
		Penalties: {
			Penalty: []
		},
		AddField1: '',
		AddField2: '',
		AllowAddFieldModify: 1,
		ToBeCancelled: {
			Code: 2,
		},
		ToBeChanged: {
			Code: 1,
		},
		AllowCancel: {
			Code: 1,
		},
		AllowModify: {
			Code: 1,
		},
		IsClosedFinancialPeriod: {
			Code: 2,
		},
		IsExcluded: {
			Code: 2,
		},
		AmendmentRejected: {
			Code: 2,
		},
		/*
		Status: {
			Code: 1,
		},
		*/
		Service: {
			Code: ServiceCode,
			Name: '',
		},
		Persons: {
			Person: [createPerson()],
		},
	};

	if (parseInt(IsGain) === 1) {
		request.Gain = '0.00';
		request.AllowSetGain = {
			Code: 1,
		};
	}

	return request;
};
