import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Combobox } from './Combobox';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'hooks';

const Container = styled.div`
	position: relative;
	margin: 40px;

	@media (max-width: 1279px) {
		margin: 20px;
	}

	.combobox {
		border: 1px solid ${props => props.theme.darkBlue};

		input {
			border: none !important;
		}

		> div {
			> div {
				/* color: ${props => props.theme.darkBlue}; */
			}
		}
	}
`;

let timer = null;

export const CatalogSearch = () => {
	const { t } = useTranslation();
	const [items, setItems] = useState([]);
	const [needle, setNeedle] = useState('');
	const navigate = useNavigate();
	const { post } = useInstance();

	const destSearch = async () => {
		const request = {
			DestinationRequest: {
				ActionSearch: {
					Parameters: {
						Text: needle,
						HighlightCssClass: 'Highlighter',
						NotSearchableCssClass: 'NotSearchableCss'
					}
				}
			}
		};

		const data = await post(request);
		const items = (data?.DestinationList?.Destination ?? [])
			.filter(i => [2, 3].includes(i.TypeCode))
			.map(i => ({
				value: i.Code,
				Name: i.Name.replace(/(<([^>]+)>)/gi, ''),
				TypeCode: i.TypeCode
			}));
		setItems(items);
	};

	const cityDescription = async id => {
		const request = {
			CityRequest: {
				ActionDescription: {
					Parameters: {
						Code: id
					}
				}
			}
		};

		const data = await post(request);

		if (data?.City[0]?.Country?.Code) {
			navigate(`/city/${data?.City[0]?.Country?.Code}/${id}`);
		}
	};

	useEffect(() => {
		clearTimeout(timer);

		if (needle.trim().length >= 2) {
			timer = setTimeout(destSearch, 500);
		}
	}, [needle]);

	const handleChangeNeedle = e => setNeedle(e.target.value);
	const handleSelect = item => {
		if (item.TypeCode === 2) {
			cityDescription(item.value);
		}
		item.TypeCode === 3 && navigate(`/hotel/${item.value}`);
	};

	return (
		<Container className='width980'>
			<Combobox fill
				placeholder={t('Введите город или название отеля')}
				needle={needle}
				onChangeNeedle={handleChangeNeedle}
				// onSearch={store.searchDestination}
				items={items}
				onChange={handleSelect}
				// value={store.destination}
			/>
		</Container>
	);
};