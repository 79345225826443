import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Sprite } from 'components';
import classnames from 'classnames';
import { MainFilters } from './MainFilters';
import { Info } from './Info';
import { ExtFilters } from './ExtFilters';

const Container = styled.div`
	user-select: none !important;
	background-color: #D3EFFF !important;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 10px;
	/* margin-top: -20px; */
	padding: 20px 0;

	.button.transparent:hover svg {
		transform: rotateZ(180deg);
	}
`;

const Filters = () => {
	const { t } = useTranslation();
	const [ isOpen, setOpen ] = useState(false);

	const toggleOpen = () => setOpen(prev => !prev);

	return (
		<>

			<Container className="blueContainer">
				<MainFilters />
				<Info />
				<ExtFilters isOpen={isOpen} />

				<div className={classnames('button', 'transparent', { isOpen })} onClick={toggleOpen}>
					{isOpen ? t('Скрыть фильтры') : t('Показать фильтры')}
					<Sprite icon='arrow_down' />
				</div>
			</Container>
		</>
	);
};

export default Filters;