import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toMoney } from 'utils';

const Container = styled.div`
	color: ${props => props.theme.darkBlue};
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
	white-space: nowrap;

	@media (min-width: 1280px) {
		font-size: 20px;
	}
`;

const HotelMinPrice = ({ offers, CurrencyName }) => {
	const { t } = useTranslation();

	const minPrice = useMemo(() => {
		return toMoney(offers.reduce((acc, cur) => parseFloat(cur.TotalPrice) < acc ? parseFloat(cur.TotalPrice) : acc, parseFloat(offers[0].TotalPrice)));
	}, [offers]);

	return (
		<Container>
			{t('от {{minPrice}} {{CurrencyName}}', { minPrice, CurrencyName })}
		</Container>
	);
};

export default HotelMinPrice;