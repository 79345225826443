import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNewsStore } from 'stores';
import { Button } from './Button';

const Container = styled.div`
	margin-bottom: 40px;

	@media (max-width: 1279px) {
		margin-bottom: 20px;
	}

	.components__button {
		width: calc((100% - 80px) / 3);
		margin: 0 auto;

		@media (max-width: 1279px) {
			width: calc((100% - 20px) / 2);
		}

		@media (max-width: 659px) {
			width: 100%;
		}
	}
`;

const NewsItems = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	flex-wrap: wrap;
	margin-bottom: 40px;
	gap: 40px;

	@media (max-width: 1279px) {
		gap: 20px;
	}

	a {
		width: calc((100% - 80px) / 3);
		position: relative;
		display: flex;
		flex-direction: column;
		color: ${props => props.theme.darkBlue};
		gap: 10px;

		@media (max-width: 1279px) {
			width: calc((100% - 20px) / 2);
		}

		@media (max-width: 659px) {
			width: calc(100%);
		}

		&:hover {
			div:before {
				opacity: 1;
				visibility: visible;
			}

			p {
				color: ${props => props.theme.lightBlue} !important;
			}
		}

		> div {
			position: relative;
			width: 100% !important;
			display: inline-table;
			height: 0;
			padding-top: 75%;
			overflow: hidden;
			line-height: 0 !important;
	
			img {
				position: absolute;
				z-index: 5;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}

			&:before {
				content: '';
				display: block;
				position: absolute;
				z-index: 6;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(3, 71, 129, 0.6);
				opacity: 0;
				visibility: hidden;
				transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
			}

		}

		p {
			color: ${props => props.theme.darkBlue} !important;
			display: block;

			&.date {
				font-size: 16px !important;
				line-height: 19px !important;

				@media (max-width: 1279px) {
					font-size: 14px !important;
					line-height: 17px !important;
				}
			}

			&.title {
				font-weight: bold !important;
				font-size: 18px !important;
				line-height: 21px !important;
			}
		}
	}
`;

const Title = styled.h1`
	margin-bottom: 40px;
	text-align: left;
	font-size: 32px;
	line-height: 38px;
	font-weight: 500;

	@media (min-width: 1280px) {
		font-size: 48px;
		line-height: 57px;
		margin-bottom: 20px;
	}
	
	.date {
		font-size: 14px;
		line-height: 18px;
		@media (min-width: 1280px) {
			font-size: 16px;
			line-height: 21px;
		}
		margin-bottom: 20px;
	}
`;

export const NewsList = ({ all = false }) => {
	const { t } = useTranslation();

	const {
		items,
		lastItems,
		itemsLength,
	} = useNewsStore();

	return (
		<Container className='container'>
			{all && <Title>{t('Новости')}</Title>}

			<NewsItems>
				{(all ? items : lastItems)?.map(i => (
					<Link to={`/news/${i.id}`} key={i.id}>
						<div>
							{i.hotels 
								// ? <NoImage />
								? <img src={`/images/news_bg/news-${i.id % 8 + 1}.jpg`} alt='' />
								: <img src={`${i.smallImg}`} alt='' />
							}
						</div>
						<p className="date">{i.date}</p>
						<p className="title">{i.hotels ? t('Новые отели в системе бронирования') : i.title}</p>
					</Link>
				))}
			</NewsItems>

			{!all && itemsLength > lastItems.length && <Button to='/news' white small>
				{t('Больше новостей')}
			</Button>}
		</Container>
	);
};