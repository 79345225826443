import React from 'react';
import styled from 'styled-components';
import { useHotelSearchStore } from 'stores';
import { DatesInput as Input } from 'forms2';

const Container = styled.div`
	.dates .label {
		text-align: center;
	}

	.dates {
		border: 1px solid ${props => props.theme.inputBorderColor} !important;
		border-radius: 2px;
		
		.isOpen {
			left: 0px !important;
			top: calc(100% - 1px) !important;
		}
	}
`;

const DatesInput = () => {
	const {
		params: {
			startDate,
			endDate
		},
		setParams
	} = useHotelSearchStore();

	const bind = {
		value: [ startDate, endDate ],
		onChange: dates => {
			setParams(prev => ({
				...prev,
				startDate: dates[0],
				endDate: dates[1]
			}));
		},
		big: true,
		showNights: true,
		minDate: 'today',
		minNights: 1,
	};

	return (
		<Container>
			<Input {...bind} />
		</Container>
	);
};

export default DatesInput;