import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { HotelSubways, HotelTransport, Sprite } from 'components';
import { useClickOutside, useDevice } from 'hooks';

const Container = styled.div`
	position: relative;
	margin-top: 10px;
`;

const Label = styled.div`
	position: relative;

	@media (min-width: 1280px) {
		display: none;
	}

	img {
		margin-right: 5px;
	}

	span {
		position: absolute;
		color: ${props => props.theme.darkBlue};
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		font-weight: 700;
		line-height: normal;
	}
`;

const Transports = styled.div`
	padding: 10px 35px 10px 10px;
	position: relative;
	border: 1px solid ${props => props.theme.darkBlue};
	position: absolute;
	left: 0;
	top: 100%;
	background-color: white;
	z-index: 3;

	@media (min-width: 1280px) {
		padding: 0;
		border: none;
		position: relative !important;
	}

	> div:not(:first-child) {
		margin-top: 10px;

		@media (min-width: 1280px) {
			margin-top: 0;
		}
	}
`;

const CloseButton = styled.div`
	color: ${props => props.theme.darkBlue};
	margin: 0;
	position: absolute;
	top: 0;
	right: 10px;

	@media (min-width: 1280px) {
		display: none;
	}
`;

const HotelTransports = ({
	Airport,
	RailwayStation,
	subways,
}) => {
	const { t } = useTranslation();
	const [ isOpen, setOpen ] = useState(false);
	const { ref } = useClickOutside(() => setOpen(false));
	const { isDesktop } = useDevice();

	const toggle = () => setOpen(prev => !prev);

	return (
		<Container ref={ref} className='hotelTransports'>
			<Label onClick={toggle}>
				<img src='/images/icon_transport.png' />
				<span>{t('Транспорт')}</span>
			</Label>

			{(isOpen || isDesktop) &&
				<Transports>
					<HotelTransport title={t('Аэропорты')} string={Airport} />
					<HotelTransport title={t('Вокзалы')} string={RailwayStation} />
					<HotelSubways objects={subways} />

					<CloseButton onClick={toggle}>
						<Sprite icon='small_cross' />
					</CloseButton>
				</Transports>
			}
		</Container>
	);
};

export default HotelTransports;