import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Select } from 'forms';
import { Paginator } from 'components';

const Container = styled.div`
	/* margin-bottom: 20px; */
	//padding-bottom: 10px;
	border-bottom: 1px solid ${props => props.theme.lightGray};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 10px;

	.select {
		border: 1px solid ${props => props.theme.darkBlue};
		height: 30px;
		margin-left: 5px;

		> div {
			> label {
				height: 30px;
				flex: 0 0 30px;
				min-height: 30px;

				label {
					height: 30px;
					line-height: 29px;
				}
			}
		}
	}
`;

const Label = styled.label`
	color: #333;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
`;

const limits = [
	{label: '25', value: 25},
	{label: '50', value: 50},
	{label: '100', value: 100},
];

export const SortPaginator = ({
	isMap = false, 
	limit, 
	setLimit,
	sort,
	setSort,
	paginatorProps
}) => {
	const { t } = useTranslation();

	const sorts = [
		{label: t('Рекомендуемая'), value: 'DEFAULT'},
		{label: t('По стоимости'), value: 'COST'},
		{label: t('По расстоянию'), value: 'DISTANCE'},
	];
	
	return (
		<Container className='desktop'>
			{setSort && <Label htmlFor="sortBy">{t('Сортировка')}:</Label>}
			{setSort && <Select items={sorts} onChange={setSort} value={sort} name='sortBy' />}

			{isMap && <div className='filler' />}

			{!isMap &&
				<>
					<Label htmlFor="limitPerPage">{t('Показывать по')}:</Label>
					<Select items={limits} onChange={setLimit} value={limit} name='limit' />

					<div className='filler' />

					<Paginator {...paginatorProps} />
				</>
			}
		</Container>
	);
};