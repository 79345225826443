/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'forms';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 10px;

	> label {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: ${props => props.theme.darkBlue};
	}

	.checkbox {
		width: 270px !important;

		label {
			display: inline;

			&:before {
				display: inline-block;
				vertical-align: sub;
			}
		}
	}
`;

const Field = styled.div`
	/* width: 600px; */
	margin-bottom: 10px;

	&.info {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: ${props => props.theme.darkBlue};
		margin-bottom: 10px;
	}

	&.center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	> label {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: ${props => props.theme.darkBlue};
		margin-bottom: 10px;
		display: flex;
	}
`;

export const Register = ({ userNameBind, emailBind, personalBind, intPhoneBind }) => {
	const { t } = useTranslation();

	return (
		<Container>
			<label>{t('Ваши ФИО')}</label>
			<input {...userNameBind} autoComplete="new-password" />
			
			<label>E-mail</label>
			<input {...emailBind} autoComplete="new-password" />

			<label>{t('Телефон')}</label>
			{/* <input {...phoneBind} autoComplete="new-password" /> */}
			<PhoneInput {...intPhoneBind} />

			<Field>
				<Checkbox {...personalBind} />
			</Field>
		</Container>
	);
};