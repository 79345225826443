/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Checkbox, Select } from 'forms';
import { toMoney } from 'utils';
import { useDevice } from 'hooks';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	
	> div {
		//border: 1px solid red;
		margin-bottom: 10px;
		width: calc((100% - 20px) / 2);
	}

	.select {
		border: 1px solid ${props => props.theme.darkBlue};
		margin-right: 0;
		height: 40px;

		label {
			font-weight: bold;
		}
	}

	.checkbox {
		color: ${props => props.theme.darkBlue};
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
	}
`;

const Label = styled.div`
	color: ${props => props.theme.darkBlue};
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;

	@media (max-width: 659px) {
		margin-bottom: 10px;
		margin-top: 10px;
	}
`;

const Additional = styled.div`
	border-left: 1px solid ${props => props.theme.lightGray};
	padding-left: 10px;
`;

const AdditionalCheckboxes = styled.div`
	display: flex;
	flex-direction: row;

	@media (max-width: 659px) {
		margin: 10px 0;
		flex-direction: column;
	}
	
	> div:first-child {
		margin-right: 20px;
	}
`;

const AdditionalText = styled.div`
	color: #333;
	font-size: 14px;
	line-height: 18px;
	font-family: ${props => props.theme.pt};	
`;

const NotAllowed = styled.div`
	height: 40px;
	border-top: 1px solid ${props => props.theme.lightGray};
	border-bottom: 1px solid ${props => props.theme.lightGray};
	display: flex;
	flex-direction: row;
	align-items: center;
	font-family: ${props => props.theme.pt};
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	color: #333;
`;

const MobileContainer = styled.div`
	.select {
		margin-top: 10px;
		border: 1px solid ${props => props.theme.darkBlue};
		margin-right: 0;
		height: 40px,
	}

`;

export const CheckInOut = ({
	AllowEarlierCheckIn = false,
	AllowLateCheckOut = false,
	CheckInTime = '',
	CheckOutTime = '',
	DefaultCheckInTime = '',
	DefaultCheckOutTime = '',
	EarlyCheckInList = [],
	LateCheckOutList = [],
	onChangeCheckInTime = () => false,
	onChangeCheckOutTime = () => false,
	onChangeCriticalEarlyCheckIn = () => false,
	onChangeCriticalLateCheckOut = () => false,
	CriticalEarlyCheckIn = true,
	CriticalLateCheckOut = true,
	readOnly = false,
	canChangeEarlyCheckIn = true,
	canChangeLateCheckOut = true,
}) => {
	const { t } = useTranslation();
	const { isMobile } = useDevice();
	const insItems = EarlyCheckInList.map(i => ({label: `${t('Ранний заезд в')} ${i.Time}`, value: i.Time, price: i.Price}));
	insItems.splice(0, 0, {label: `${t('Стандартный заезд в')} ${DefaultCheckInTime}`, value: DefaultCheckInTime, price: false});

	const outsItems = LateCheckOutList.map(i => ({label: `${t('Поздний выезд в')} ${i.Time}`, value: i.Time, price: i.Price}));
	outsItems.splice(0, 0, {label: `${t('Стандартный выезд в')} ${DefaultCheckOutTime}`, value: DefaultCheckOutTime, price: false});

	const selectProps = name => ({
		name: `Check${name}Time`,
		items: name === 'In' ? insItems : outsItems,
		rightLabel: item => item.price ? `${toMoney(parseFloat(item.price))} RUB` : '',
		value: name === 'In' ? CheckInTime : CheckOutTime,
		readOnly: readOnly || (name === 'In' ? !canChangeEarlyCheckIn : !canChangeLateCheckOut),
		onChange: name === 'In' ? onChangeCheckInTime : onChangeCheckOutTime
	});

	const checkboxProps = (name, value) => ({
		checked: (name === 'In' ? CriticalEarlyCheckIn : CriticalLateCheckOut) === value,
		label: `${name === 'In' ? t('Ранний заезд') : t('Поздний выезд')} ${value ? t('строго обязателен') : t('не обязателен')}`,
		onChange: readOnly || (name === 'In' ? !canChangeEarlyCheckIn : !canChangeLateCheckOut) ? () => false : (name === 'In' ? onChangeCriticalEarlyCheckIn : onChangeCriticalLateCheckOut),
		value: `${value ? '!' : ''}Critical${name === 'In' ? 'Early' : 'Late'}Check${name}`
	});

	if (isMobile) {
		return (
			<MobileContainer>
				<Label>{t('Ранний заезд')}</Label>
				
				{AllowEarlierCheckIn
					? <Select {...selectProps('In')} />
					: <NotAllowed>{t('Не предоставляется')}{DefaultCheckInTime && `. ${t('Заезд в')} ${DefaultCheckInTime}`}</NotAllowed>
				}

				{CheckInTime === DefaultCheckInTime ? <div /> : <Additional>
					<AdditionalCheckboxes>
						<Checkbox {...checkboxProps('In', false)} />
						<Checkbox {...checkboxProps('In', true)} />
					</AdditionalCheckboxes>

					<AdditionalText>
						{t('Если отель не может предоставить ранний заезд, то будет забронировано размещение со стандартным временем заезда и перевыставлен счет.')}
					</AdditionalText>
				</Additional>}

				<Label>{t('Поздний выезд')}</Label>

				{AllowLateCheckOut ?
					<Select {...selectProps('Out')} /> :
					<NotAllowed>{t('Не предоставляется')}{DefaultCheckOutTime && `. ${t('Выезд в')} ${DefaultCheckOutTime}`}</NotAllowed>
				}

				{CheckOutTime === DefaultCheckOutTime ? <div /> : <Additional>
					<AdditionalCheckboxes>
						<Checkbox {...checkboxProps('Out', false)} />
						<Checkbox {...checkboxProps('Out', true)} />
					</AdditionalCheckboxes>

					<AdditionalText>
						{t('Если отель не может предоставить поздний выезд, то будет забронировано размещение со стандартным временем выезда и перевыставлен счет.')}
					</AdditionalText>
				</Additional>}

			</MobileContainer>
		);
	}

	return (
		<Container>
			{AllowEarlierCheckIn
				? <Select {...selectProps('In')} />
				: <NotAllowed>{t('Ранний заезд не предоставляется')}{DefaultCheckInTime && `. ${t('Заезд в')} ${DefaultCheckInTime}`}</NotAllowed>
			}

			{AllowLateCheckOut ?
				<Select {...selectProps('Out')} /> :
				<NotAllowed>{t('Поздний выезд не предоставляется')}{DefaultCheckOutTime && `. ${t('Выезд в')} ${DefaultCheckOutTime}`}</NotAllowed>
			}

			{CheckInTime === DefaultCheckInTime ? <div /> : <Additional>
				<AdditionalCheckboxes>
					<Checkbox {...checkboxProps('In', false)} />
					<Checkbox {...checkboxProps('In', true)} />
				</AdditionalCheckboxes>

				<AdditionalText>
					{t('Если отель не может предоставить ранний заезд, то будет забронировано размещение со стандартным временем заезда и перевыставлен счет.')}
				</AdditionalText>
			</Additional>}

			{CheckOutTime === DefaultCheckOutTime ? <div /> : <Additional>
				<AdditionalCheckboxes>
					<Checkbox {...checkboxProps('Out', false)} />
					<Checkbox {...checkboxProps('Out', true)} />
				</AdditionalCheckboxes>

				<AdditionalText>
					{t('Если отель не может предоставить поздний выезд, то будет забронировано размещение со стандартным временем выезда и перевыставлен счет.')}
				</AdditionalText>
			</Additional>}
		</Container>
	);
};