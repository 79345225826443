import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHotelSearchStore, useUserStore } from 'stores';

const Container = styled(Link)`
	display: block;
	position: relative;
	width: 120px;
	height: 120px;

	@media (min-width: 1280px) {
		width: 160px;
		height: 160px;
	}

	img {
		width: 100%;
		height: 100%;
		line-height: 0;
		position: relative;
		object-fit: cover;
		z-index: 1;
	}
`;

const NoPhoto = styled.div`
	width: 120px;
	height: 120px;
	background-color: #A2D0EA;
	position: relative;

	@media (min-width: 1280px) {
		width: 160px;
		height: 160px;
	}

	img {
		position: absolute;
		top: 50%;
		left: 50%;

		transform: translateX(-50%) translateY(-50%);
	}
`;

const NewHotel = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background-color: #5BB500;
	padding: 5px 15px;
	color: #FFF;
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	text-transform: uppercase;
`;

const Count = styled.div`
	position: absolute;
	z-index: 2;
	background: rgba(0, 70, 133, 0.50);
	bottom: 0;
	left: 0;
	right: 0;
	padding: 5px 0;
	text-align: center;
	color: #FFF;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	font-weight: 700;
	line-height: normal;
`;

const HotelPhoto = ({
	Code,
	Url,
	BigUrl,
	ThUrl,
	IsNewHotel,
	ImagesCount,
}) => {
	const { t } = useTranslation();
	const { allowTabs } = useUserStore();
	const {
		params: {
			startDate,
			endDate,
			adults = 1,
			childs = 0,
			ages = [],
		} = {},
	} = useHotelSearchStore();

	const url = useMemo(() => {
		if (Url) return Url.replace('test-images', 'images');
		if (BigUrl) return BigUrl.replace('test-images', 'images');
		if (ThUrl) return ThUrl.replace('test-images', 'images');
		return false;
	}, []);

	if (!url) {
		return (
			<NoPhoto>
				<img src='/images/no_photo.png' />
			</NoPhoto>
		);
	}

	return (
		<Container to={`/hotel/${Code}/${startDate}/${endDate}/${adults}/${childs}/${ages.join(',')}`} target={allowTabs ? '_blank' : '_self'} rel="noreferrer">
			<img src={url} />

			{Number(IsNewHotel) === 1 &&
				<NewHotel>
					{t('Новый')}
				</NewHotel>
			}

			{ImagesCount > 1 &&
				<Count>
					{t('ещё {{ImagesCount}} фото', { ImagesCount })}
				</Count>
			}
		</Container>
	);
};

export default HotelPhoto;