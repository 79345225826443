import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'forms';
import { useHotelSearchStore } from 'stores';
import { Sprite } from 'components';
import { useRequest } from 'hooks';

const request = {
	WhereToPayRequest: {
		ActionList: {}
	}
};

export const WhereToPay = () => {
	const { t } = useTranslation();
	const {
		params,
		setParams
	} = useHotelSearchStore();
	const { data } = useRequest(
		request,
		{
			parser: data => {
				return data?.WhereToPay ?? [];
			}
		}
	);

	const onChange = (val) => setParams(prev => {
		return {
			...prev,
			whereToPay: parseInt(val)
		};
	});

	const value = parseInt(params?.whereToPay ?? 1);

	const isDirty = parseInt(params?.whereToPay ?? 1) !== 1;
	const clear = () => onChange(1);

	if (!data) return null;

	return (
		<div>
			<label>
				{t('Способ оплаты')}

				{isDirty && 
					<div onClick={clear}>
						<Sprite icon='small_cross' />
					</div>
				}
			</label>
			{data.map(i => <Checkbox key={i.Code} label={i.Name} checked={i.Code === value || value === 1} onChange={onChange.bind(this, i.Code)} />)}
		</div>
	);
};