import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Sprite } from 'components';
import { declines } from 'utils';

import iconMinus from 'images/icon_minus.svg';
import iconPlus from 'images/icon_plus.svg';

const GuestsContainer = styled.div`
	${props => props.$fill ? 'flex: 1 0 220px' : 'width: 220px'};
	position: relative;
	z-index: 9;
	color: ${props => props.theme.darkBlue};
	background: white;

	input {
		background-color: white;
		border: none;
		outline: none;
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
		width: calc(100% - 20px) !important	;
		height: 100% !important;
		padding: 0 10px;
		//z-index: 10;
		cursor: pointer;

		& + div {
			cursor: pointer;
			display: inline-block;
		}
	}
`;

const Container = styled.div`
	position: absolute;
	top: 40px;
	left: -1px;
	border: 1px solid ${props => props.theme.darkBlue};
	width: calc(100% + 2px);
	padding: 0 10px 10px 10px;
	background-color: white;
	z-index: 11;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	
	&.isOpen {
		opacity: 1;
		visibility: visible;
	}
`;

const Line = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 16px;
	line-height: 21px;
	font-family: ${props => props.theme.pt};
	height: 40px;
	border-bottom: 1px solid ${props => props.theme.lightGray};
`;

const Count = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Inc = styled.div`
	width: 20px;
	height: 20px;
	background: url(${iconPlus}) center/cover no-repeat;
	cursor: pointer;
	opacity: 1;
	transition: opacity .2s ease-in-out;
	user-select: none !important;

	&.disabled { opacity: .6; }
`;

const Dec = styled.div`
	width: 20px;
	height: 20px;
	background: url(${iconMinus}) center/cover no-repeat;
	cursor: pointer;
	opacity: 1;
	transition: opacity .2s ease-in-out;
	user-select: none !important;

	&.disabled { opacity: .6; }
`;

const Label = styled.div`
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
`;

const Age = styled.select`
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	font-size: 16px;
	line-height: 21px;
	font-family: ${props => props.theme.pt};
	color: inherit;
	
	option {
		padding: 10px;
	}
`;

export const Guests = ({
	adults = 1, 
	childs = 0, 
	ages = [0, 0, 0, 0],
	isOpen = false,
	close,
	toggle,
	fill = false,
	incAdults,
	decAdults,
	incChilds,
	decChilds,
	setAge,
}) => {
	const containerRef = useRef(null);
	const { t } = useTranslation();

	const ageTitles = [t('год'), t('года'), t('лет')];
	const adultTitles = [t('взрослый'), t('взрослых'), t('взрослых')];
	const childTitles = [t('ребёнок'), t('детей'), t('детей')];

	const listener = e => {
		if (containerRef && containerRef.current && !containerRef.current.contains(e.target)) {
			close();
		}
	};

	useEffect(() => {
		document.body.addEventListener('click', listener, { capture: true });
		return () => document.body.removeEventListener('click', listener, { capture: true });
	}, []);

	const title = adults + ' ' +
		declines(adults, adultTitles) +
		' • ' +
		(childs === 0 ? t('без детей') : childs + ' ' + declines(childs, childTitles));

	return (
		<GuestsContainer ref={containerRef} $fill={fill} className='guests'>
			<input value={title} onChange={() => null} onClick={toggle} readOnly />
			<div onClick={toggle}>
				<Sprite icon='arrow_down' />
			</div>
			

			<Container className={classnames({isOpen})}>
				<Line>
					{t('Взрослых')}
					<div className='filler' />
					<Dec className={classnames({'disabled': adults === 1})} onClick={decAdults} />
					<Count>{adults}</Count>
					<Inc className={classnames({'disabled': adults === 9})} onClick={incAdults} />
				</Line>

				<Line>
					{t('Детей')}
					<div className='filler' />
					<Dec className={classnames({'disabled': childs === 0})} onClick={decChilds} />
					<Count>{childs}</Count>
					<Inc className={classnames({'disabled': childs === 4})} onClick={incChilds} />
				</Line>

				{childs > 0 && <Label>{childs === 1 ? t('Возраст ребёнка') : t('Возраст детей')}:</Label>}

				{ages.slice(0, childs).map((i, key) => 
					<Line key={key}>
						<Age value={ages[key]} onChange={setAge.bind(this, key)}>
							{new Array(18).fill(0).map((j, jKey) => 
								<option key={jKey} value={jKey}>
									{jKey === 0 ? t('Меньше года') : declines(jKey, ageTitles, true)}
								</option>
							)}
						</Age>
					</Line>
				)}
			</Container>
		</GuestsContainer>
	);
};