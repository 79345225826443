import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { declines } from 'utils/declines';
import { useHotelSearchStore } from 'stores';
import { Sprite } from 'components';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;

	* {
		white-space: nowrap !important;
	}
`;

const Founded = styled.div`
	color: ${props => props.theme.darkBlue};
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;

	span {
		font-weight: inherit !important;
		font-size: inherit !important;
		line-height: inherit !important;
		color: #00C0FF;
	}
`;

const FiltersCount = styled.div`
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: ${props => props.theme.darkBlue};
`;

const ClearFiltersButton = styled.div`
	color: ${props => props.theme.darkBlue};
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	text-transform: uppercase;
	font-weight: 400;
	font-size: 14px;
	line-height: 15px;
	cursor: pointer;

	svg {
		margin-top: -1px;
	}
`;

export const Info = () => {
	const { t } = useTranslation();

	const {
		filteredCount,
		filtersCount,
		setParams,
		totalCount,
	} = useHotelSearchStore();

	const foundedTitles = [t('Найден'), t('Найдено'), t('Найдено')];
	const hotelTitles = [t('отель'), t('отеля'), t('отелей')];

	const onClear = () => setParams(prev => ({
		...prev,
		filters: {
			hotel: '',
			freesale: false,
			priceFrom: 0,
			priceTo: 0,
			stars: [1, 2, 3, 4, 5],
			amenities: [],
			distance: 0
		},
		whereToPay: 1
	}));

	return (
		<Container>
			<Founded>
				{filtersCount === 0 && <>{declines(filteredCount, foundedTitles)} <span>{filteredCount}</span> {declines(filteredCount, hotelTitles)}</>}
				{filtersCount > 0 && <>{t('Показано')} <span>{filteredCount}</span> {t('из')} <span>{totalCount}</span> {declines(filteredCount, hotelTitles)}</>}
			</Founded>

			<FiltersCount>
				{filtersCount > 0 && `${t('Выбрано фильтров')}: ${filtersCount}`}
				{filtersCount === 0 && t('Фильтры не выбраны')}
			</FiltersCount>

			<div className='filler' />

			{filtersCount > 0 &&
				<ClearFiltersButton onClick={onClear}>
					{t('Очистить фильтры')}
					<Sprite icon='small_cross' />
				</ClearFiltersButton>
			}
		</Container>
	);
};