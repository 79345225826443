import React from 'react';

export const IconHidePass = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
	<g id="Layer_2" data-name="Layer 2">
		<g id="Layer_1-2" data-name="Layer 1">
			<rect className="cls-1" width="20" height="20" fill="transparent"/>
			<path className="cls-2" fill="#004685"
			      d="M19.73,9.55a11.1,11.1,0,0,0-4.08-4l1.1-1.11L15.34,3,13.69,4.65A11.14,11.14,0,0,0,10,4,11.3,11.3,0,0,0,.27,9.55L0,10l.27.45A11.2,11.2,0,0,0,4.05,14.3L2.76,15.59,4.18,17l1.75-1.76A11.45,11.45,0,0,0,10,16a11.3,11.3,0,0,0,9.73-5.55L20,10ZM10.1,6.19a3.62,3.62,0,0,1,1.64.41L7,11.37a3.42,3.42,0,0,1-.44-1.66A3.55,3.55,0,0,1,10.1,6.19ZM2.15,10A9.31,9.31,0,0,1,5.46,7a5.19,5.19,0,0,0-.77,2.72,5.27,5.27,0,0,0,.95,3L5.38,13A9.41,9.41,0,0,1,2.15,10Zm8,3.23a3.55,3.55,0,0,1-1.71-.46L13.19,8a3.49,3.49,0,0,1-3.09,5.26Zm4.07,0a5.26,5.26,0,0,0,.68-6.06,9.38,9.38,0,0,1,3,2.85A9.35,9.35,0,0,1,14.17,13.22Z"/>
		</g>
	</g>
</svg>;
