import React from 'react';
import styled from 'styled-components';
import RoomPhoto from '../RoomPhoto';
import { Offer } from './';
import { useHotelSearchStore } from 'stores';
import { BathLocation, BedType, Square } from '../../Search';

const Container = styled.table`
	width: 100%;
	position: relative;

	td {
		height: 40px;
		background-color: #D3EFFF;
		vertical-align: middle;

		&.roomName {
			padding-left: 10px;
			overflow: hidden;

			div {
				display: block;
				color: ${props => props.theme.darkBlue};
				font-size: 16px;
				font-weight: 500;
				line-height: normal;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 750px;
			}
		}

		&.icons {
			text-align: right;
			vertical-align: middle;
			padding-right: 5px;

			> * {
				display: inline-block !important;
				margin-left: 10px;
			}

			img, svg {
				vertical-align: middle;
			}
		}
	}
`;

const Bit = styled.div`
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: #333;
	white-space: nowrap;

	&.orange {
		color: ${props => props.theme.orange};
	}
	
	svg {
		margin-right: 5px;
		vertical-align: sub;
	}	
`;


const Room = ({ room, hotelCode, hotelName, hotelStars, CurrencyName, onClickRoom = () => false, onShowDetails = () => false, datesBind = {} }) => {
	const { onClickRoom: handleClickRoom = onClickRoom } = useHotelSearchStore() ?? {};

	const {
		Url = false,
		BigUrl = false,
		ThUrl = false,
		RoomName = '',
		rates,
		Code,
		RoomCode,
		BedTypes: {
			Variant: BedTypes = []
		} = {},
		Bathlocation: bathLoc = false,
		Square: square = 20,
	} = room;

	const roomPhotoBind = {
		Url,
		BigUrl,
		ThUrl,
	};

	const handleClick = () => {
		handleClickRoom(Code, hotelCode, RoomCode, hotelName, hotelStars);
	};

	return (
		<>
			<Container>
				<colgroup>
					<col width='40' />
					<col width='800' />
					<col />
				</colgroup>

				<tbody>
					<tr>
						<td onClick={handleClick} className='clickable'>
							<RoomPhoto {...roomPhotoBind} />
						</td>

						<td className='roomName' onClick={handleClick}>
							<div className='clickable'>
								{RoomName}
							</div>
						</td>

						<td className='icons'>
							<BathLocation loc={bathLoc} Element={Bit} />
							<BedType BedTypes={BedTypes} Element={Bit} />
							<Square square={square} Element={Bit} />
						</td>
					</tr>
				</tbody>
			</Container>

			{rates.map((rate, key) => <Offer key={key} offer={rate} CurrencyName={CurrencyName} hotelCode={hotelCode} datesBind={datesBind} onShowDetails={onShowDetails} />)}
		</>
	);
};

export default Room;