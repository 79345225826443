import React from 'react';
import sprites from 'images/sprites.svg';
import { useDevice } from 'hooks';

export const Sprite = ({ icon, className = '', title = false }) => {
	const { isDesktop } = useDevice();

	if (['logo'].includes(icon) && !isDesktop) icon = 'logo_mobile';
	const id = `${sprites}#icon__${icon}`;
	let width = 20;
	let height = 20;

	if (['button_gear', 'button_cart', 'logout', 'message', 'burger', 'close', 'login'].includes(icon)) {
		width = 30;
		height = 30;
	}

	if (['prev', 'next', 'arrow_right_adaptive'].includes(icon)) {
		width = 8;
		height = 12;
	}

	if (['arrow_down', 'arrow_top_white', 'arrow_up', 'arrow_down_white'].includes(icon)) {
		width = 12;
		height = 8;
	}

	if (['mobile'].includes(icon)) {
		width = 30;
		height = 22;
	}

	if (['user'].includes(icon)) {
		width = 22;
		height = 30;
	}

	if (['cross'].includes(icon)) {
		width = 24;
		height = 24;
	}

	if (['small_cross'].includes(icon)) {
		width = 14;
		height = 15;
	}

	if (['c_marker', 'c_phone', 'c_mail'].includes(icon)) {
		width = 80;
		height = 80;
	}

	if (['arrow_left', 'arrow_right'].includes(icon)) {
		width = 10;
		height = 16;
	}

	if (['bed'].includes(icon)) {
		height = 16;
	}

	if (['small_arrow_right'].includes(icon)) {
		width = 8;
		height = 12;
	}

	if (['ruflag', 'enflag'].includes(icon)) {
		width = 15;
		height = 10;
	}

	if (icon === 'logo') {
		width = 48;
		height = 20;
	}

	if (icon === 'logo_mobile') {
		width = 30;
		height = 12;
	}

	if (icon === 'marker') {
		width = 12;
		height = 20;
	}

	if (icon === 'arrow_right_top') {
		width = 8;
		height = 8;
	}

	if (icon === 'small_light_blue_cross') {
		width = 12;
		height = 12;
	}

	return (
		<svg width={width} height={height} className={className}>
			{title && <title>{title}</title>}
			<use xlinkHref={id} className="sprite" />
		</svg>
	);
};
