import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useHotelSearchStore } from 'stores';
import { useTranslation } from 'react-i18next';
import { useDevice } from 'hooks';
import { SelectInput } from 'forms2';

const Container = styled.div`
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	font-family: 'PT Sans';
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: #333;

	div {
		all: inherit;
		margin: 0;
		color: ${props => props.theme.darkBlue};
		cursor: pointer;

		&.selected {
			color: ${props => props.theme.lightBlue};
		}
	}
`;

const SelectView = () => {
	const { t } = useTranslation();
	const { isDesktop } = useDevice();
	const options = [
		{ value: 'standart', label: t('Стандартный') },
		{ value: 'extended', label: t('Расширенный') },
		{ value: 'compact', label: t('Компактный') },
	];
	const {
		params: {
			view: value = options[0]
		},
		setParams
	} = useHotelSearchStore();
	
	const onChange = view => setParams(prev => ({
		...prev,
		view
	}));

	if (!isDesktop) {
		const items = options.map(i => ({
			label: `${t('Вид')}: ${i.label}`,
			value: i.value
		}));

		const bind = {
			isSearchable: false,
			name: 'view',
			options: items,
			value: items.find(i => i.value === value.value),
			onChange: view => setParams(prev => ({
				...prev,
				view
			})),
		};

		return (
			<SelectInput {...bind} />
		);
	}

	return (
		<Container>
			{t('Вид')}:
			{options.map((i, key) => 
				<div key={key} className={classnames({ selected: value?.value === i.value })} onClick={onChange.bind(this, i)}>
					{i.label}
				</div>
			)}
		</Container>
	);
};

export default SelectView;