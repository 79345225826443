import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { format, parseDate } from 'utils/dayjs';
import { declines } from 'utils';
import { Sprite } from 'components';
import { Calendar } from './Calendar';
import { useClickOutside } from 'hooks';
import { PropTypes } from 'prop-types';

const Container = styled.div`
	min-height: 30px;
	/* height: 30px; */
	/* height: auto; */
	position: relative;
	user-select: none !important;
	background-color: white;
	border-radius: 2px;
	display: flex;
	flex-direction: row;
	align-items: stretch;

	&.big {
		min-height: 40px;
		height: 40px;
		/* height: auto; */
	}

	&.border {
		border: 1px solid ${props => props.theme.inputBorderColor};
	}
`;

const Label = styled.div`
	/* height: 100%; */
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	padding: 0 10px;
	font-family: ${props => props.theme.pt};
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: ${props => props.theme.darkBlue};
	cursor: pointer;
	white-space: nowrap;

	&.readOnly {
		cursor: not-allowed;
	}

	&.labelColumn {
		flex-direction: column;
		justify-content: center;
		text-align: center;

		&.padding {
			padding: 10px 0;
		}
	}

	> input {
		border: none !important;
		padding: 0 !important;
	}
`;

const CalendarContainer = styled.div`
	position: absolute;
	top: 100%;
	z-index: 999998;
	opacity: 0;
	visibility: hidden;
	box-shadow: 3px 3px 5px rgba(33, 33, 33, .3);

	&.isOpen {
		opacity: 1;
		visibility: visible;
	}

	&.left {
		left: -1px;
	}

	&.right {
		right: -1px;
	}

	&.center {
		left: -50%;
	}

	@media (max-width: 659px) {
		left: -1px !important;
		right: -1px !important;
	}
`;

const Reset = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const DatesInput = ({
	border = true,
	readOnly = false,
	align = 'left',
	value,
	minDate = false,
	maxDate = false,
	onChange = () => false,
	brief = false,
	big = false,
	showNights = false,
	labelColumn = false,
	onReset = false,
	readOnlyStartDate = false,
	minNights = 0,
	hideDayName = false,
	// readOnlyEndDate = false,
}) => {
	const { t } = useTranslation();
	const [ isOpen, setOpen ] = useState(false);
	const { ref } = useClickOutside(() => setOpen(false));
	// const iconRef = useRef(null);
	const resetRef = useRef(null);

	const nightsTitles = useMemo(() => ([
		t('ночь'), 
		t('ночи'), 
		t('ночей')
	]), []);

	const title = useMemo(() => {
		if (Array.isArray(value) && (!value[0] || !value[1])) return t('Период не задан');

		if (Array.isArray(value) && value.length === 2) {
			const date1 = parseDate(value[0]);
			const date2 = parseDate(value[1]);
			const nights = Math.abs(date1.diff(date2, 'day', true));

			if (hideDayName) return `${date1.format(format)} — ${date2.format(format)}`;
			
			if (!showNights) return `${date1.format(`${format} dd`)} — ${date2.format(`${format} dd`)}`;

			if (labelColumn) {
				return <>{date1.format(`${format} dd`)} — {date2.format(`${format} dd`)}<br />{declines(nights, nightsTitles, true)}</>;
			}

			return `${date1.format(`${format} dd`)} — ${date2.format(`${format} dd`)} • ${declines(nights, nightsTitles, true)}`;
		}

		if (typeof value === 'string') {
			const date = parseDate(value);
			return date.format(`${format} dd`);
		}

		return '';
	}, [value, labelColumn]);

	const handleChange = newValue => {
		onChange(newValue);
		setOpen(false);
	};

	const bind = readOnly
		? {}
		: {
			value,
			minDate,
			maxDate,
			onChange: handleChange,
			brief,
			showNights,
			readOnlyStartDate,
			minNights
		};

	const toggle = e => {
		if (resetRef.current && (e.target === resetRef.current || resetRef.current.contains(e.target))) return;

		setOpen(prev => !prev);
	};

	return (
		<Container className={classnames('dates_input__container', { border, big })} ref={ref}>
			<Label className={classnames({ readOnly, labelColumn, padding: labelColumn && Array.isArray(value) })} onClick={toggle}>
				{title}

				{(!labelColumn || onReset) && <div className='filler' />}

				{!labelColumn && !hideDayName && <Sprite icon='calendar' />}

				{onReset &&
					<Reset onClick={onReset} ref={resetRef}>
						<Sprite icon='small_cross' />
					</Reset>
				}
			</Label>

			{!readOnly &&
				<CalendarContainer className={classnames(align, { isOpen })}>
					{isOpen && <Calendar {...bind} />}
				</CalendarContainer>
			}
		</Container>
	);
};

DatesInput.propTypes = {
	border: PropTypes.bool,
	readOnly: PropTypes.bool,
	align: PropTypes.oneOf(['left', 'right', 'center']),
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array
	]).isRequired,
	minDate: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	brief: PropTypes.bool,
	big: PropTypes.bool,
	showNights: PropTypes.bool,
	labelColumn: PropTypes.bool,
	onReset: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.bool
	]),
	readOnlyStartDate: PropTypes.bool,
};

export { DatesInput };