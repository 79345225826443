import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { HotelMinPrice, HotelPhoto, HotelTitle, HotelTransports } from '../';
import { useHotelSearchStore } from 'stores';
import { Room } from './';
import { Button, Sprite } from 'components';
import { declines } from 'utils';
import { useEffectExceptOnMount } from 'hooks';

const Container = styled.table`
	margin-top: 20px;
	width: 100%;

	td {
		/* border: 1px solid red; */
		vertical-align: top;

		&.hotelTitle:not(.compact) {
			padding-left: 10px;
		}

		&.info {
			padding-top: 10px;
		}
	}
`;

const OnMapLink = styled.div`
	position: relative;
	margin-bottom: 5px;

	img {
		margin-right: 5px;
	}

	span {
		color: ${props => props.theme.darkBlue};
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
		position: absolute;
	}
`;

const AddressDiv = styled.div`
	color: #8D8D8D;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 10px;
`;

const Hotel = ({ hotel }) => {
	const { t } = useTranslation();
	const {
		lastDestination: {
			Code: DestinationCode,
			Name: DestinationName,
			TypeCode: DestinationTypeCode,
			TownType: {
				Name: TownTypeName = ''
			} = {},
			ObjType: {
				Name: ObjTypeName = ''
			} = {},
			HotelType: {
				Name: HotelTypeName = ''
			} = {},
		},
		params: {
			view: {
				value: view = 'standart'
			} = {}	
		} = {},
		setHotelOnMap,
		setMap,
	} = useHotelSearchStore();
	const [ isOpen, setOpen ] = useState(view === 'extended');

	useEffectExceptOnMount(() => {
		setOpen(view === 'extended');
	}, [view]);

	const {
		Code,
		Url = false,
		BigUrl = false,
		ThUrl = false,
		IsNewHotel = 2,
		ImagesCount = 0,
		Name = '',
		Stars: {
			Code: StarsCode = 0
		} = {},
		City: {
			Code: City,
			Name: CityName = ''
		} = {},
		TypeOfPlace: {
			Name: TypeOfPlaceName
		},
		DistanceToDestination = false,
		CityCentre,
		Address,
		HotelOfferList: {
			HotelOfferDetail: offers
		},
		Currency: {
			Name: CurrencyName,
		} = {},
		Airport = '',
		RailwayStation = '',
		Objects: {
			Object: subways = []
		} = {},
	} = hotel;

	const hotelPhotoBind = {
		Code,
		Url,
		BigUrl,
		ThUrl,
		IsNewHotel,
		ImagesCount,
	};

	const hotelTitleBind = {
		Code,
		Name,
		StarsCode,
	};

	const hotelTransportBind = {
		Airport,
		RailwayStation,
		subways,
	};

	const distances = [
		(DestinationTypeCode === 2 && Number(DestinationCode) !== Number(City)) || (DestinationTypeCode !== 2 && Number(Code) !== Number(DestinationCode)) ? `${DistanceToDestination} км ${Number(DestinationTypeCode)  === 2 ? `от центра ${TownTypeName?.toLowerCase()}` : `от ${Number(DestinationTypeCode) === 4 ? ObjTypeName?.toLowerCase() : HotelTypeName?.toLowerCase()}`} ${DestinationName?.split(',')?.[0]}` : false,
		CityCentre,
	].filter(i => Boolean(i));

	const offersCodes = useMemo(() => {
		return offers.map(i => Number(i.Code));
	}, [offers]);

	const rooms = useMemo(() => {
		const tempOffers = offers.slice(0, isOpen ? offers.length : 1);

		return tempOffers
			.reduce((acc, cur) => {
				if (acc.find(i => Number(i.RoomCode) === Number(cur.RoomCode))) {
					return [...acc];
				} else {
					return [
						...acc,
						{
							...cur,
							rates: tempOffers.filter(i => Number(i.RoomCode) === Number(cur.RoomCode))
						}
					];
				}
			}, []);
	}, [isOpen, offersCodes]);

	const onMap = () => {
		setHotelOnMap(hotel);
		setMap(true);
	};

	const toggle = () => setOpen(prev => !prev);

	return (
		<>
			<Container>
				<colgroup>
					<col className='col1' width='120' />
					<col className='col2' />
					<col className='col3' width='20' />
				</colgroup>

				<tbody>
					<tr>
						{view !== 'compact' && <td>
							<HotelPhoto {...hotelPhotoBind} />
						</td>}

						<td colSpan={view === 'compact' ? '3' : '2'} className={classnames('hotelTitle', { compact: view === 'compact' })}>
							<HotelTitle {...hotelTitleBind} />
						</td>
					</tr>

					<tr>
						<td colSpan='3' className='info'>
							{distances.length > 0 && 
								<OnMapLink onClick={onMap}>
									<img src='/images/icon_marker.png' />
									<span>{t('На карте')}</span>
								</OnMapLink>
							}

							<AddressDiv>
								{distances.join(' • ')} {TypeOfPlaceName} {CityName}, {Address}
							</AddressDiv>

							<HotelMinPrice offers={offers} CurrencyName={CurrencyName} />
						</td>
					</tr>

					<tr>
						<td colSpan='2'>
							<HotelTransports {...hotelTransportBind} />
						</td>

						<td />
					</tr>
				</tbody>
			</Container>

			{rooms.map((room, key) => <Room key={key} room={room} hotelCode={Code} hotelName={Name} hotelStars={StarsCode} CurrencyName={CurrencyName} />)}

			{offers.length > 1 && 
				<Button white onClick={toggle} style={{ marginTop: '20px' }}>
					{isOpen ? t('Скрыть') : `${t('Показать еще')} ${declines(offers.length - 1, [t('вариант'), t('варианта'), t('вариантов')], true)}`}
					<Sprite icon={isOpen ? 'arrow_up' : 'arrow_down'} />
				</Button>
			}
		</>
	);
};

export default Hotel;