import React from 'react';
import styled from 'styled-components';
import logoImg from 'images/loader/logo_white.svg';
import roundImg from 'images/loader/round_1.png';
import { useLoaderStore } from 'stores';

const Container = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999999;
	background: rgba(3, 71, 129, 0.8) url(${logoImg}) center 49.5% no-repeat;
	background-size: 100px 53px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	
	&.active {
		opacity: 1;
		visibility: visible;
	}
`;

const Round = styled.div`
	width: 160px;
	height: 160px;
	background: url(${roundImg}) no-repeat center 100%;
	animation: loader1 10s infinite linear;
	transform: translateZ(0);
	
	@-webkit-keyframes loader1 {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}

	@keyframes loader1 {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
`;

export const Loader = () => {
	const { isLoading } = useLoaderStore();

	return (
		<Container className={isLoading ? 'active' : ''}>
			<Round/>
		</Container>
	);
};