/* eslint-disable no-unreachable */
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
	useErrorStore,
	useLoaderStore,
} from 'stores';

export const useInstance = () => {
	const { t } = useTranslation();
	const { setFatalError, setError } = useErrorStore();
	const { addCounter, subCounter } = useLoaderStore();

	const getMixins = () => {
		const user = JSON.parse(localStorage.getItem('user'));
		const lsLang = localStorage.getItem('lang');

		if (user?.UserType?.toLowerCase() === 'buyer') {
			return {
				UserType: 'BUYER',
				BuyerId: user?.BuyerId,
				UserId: user?.UserId,
				Password: user?.Password,
				Language: lsLang ?? user?.Language,
				Currency: user?.CurrencyCode ?? 2,
				CurrencyCode: user?.CurrencyCode ?? 2,
				WhereToPay: user?.WhereToPayCode ?? 3,
				// WhereToPay: 1,
			};
		}

		return {
			UserType: 'BUYER',
			BuyerId: 'RO1BAND',
			UserId: 'WWW',
			Password: '84765',
			Language: lsLang ?? 'ru',
			Currency: 2,
			CurrencyCode: 2,
			WhereToPay: 3,
			// WhereToPay: 1,
		};
	};

	const instance = axios.create({
		baseURL: '/rapi99',
		headers: {
			'Content-Type': 'application/json; charset=UTF-8',
			'Bora-Alien': 2,
			'Bora-Formatted-Output': 1,
		},
	});

	instance.interceptors.request.use(
		config => {
			addCounter();

			const request = config.data;
			const mixins = getMixins();
			const root = Object.keys(request)[0];

			config.data = {
				[root]: {
					...mixins,
					...request[root]
				}
			};

			return config;
		},
		error => {
			subCounter();

			return Promise.reject(error);
		},
	);

	instance.interceptors.response.use(
		response => {
			subCounter();

			return response;
		},
		error => {
			subCounter();

			return Promise.reject(error);
		},
	);

	const post = async (request, onError = false) => {
		try {
			return await instance
				.post('', request)
				.then(data => {
					if (data.headers['content-type'].includes('image/')) {
						return {
							data: data.data,
							type: data.headers['content-type']
						};
					} else {
						const response = data.data;
						const root = Object.keys(response)[0];

						if (response.Error) {
							typeof onError === 'function' ? onError(response.Error) : setError(response.Error);
							setError(response.Error);
							return false;
						} else if (response[root]?.Error) {
							typeof onError === 'function' ? onError(response[root]?.Error) : setError(response[root].Error);
							return false;
						} else {
							return response?.[root] ?? response;
						}
					}
				})
				.catch(error => {
					if (error?.response?.status === 404) {
						setFatalError(t('Сервис временно недоступен.'));
					} else if (error?.response?.status === 504) {
						setFatalError(t('Превышено время ожидания ответа сервиса.'));
					} else if (error?.response?.status === 500) {
						setFatalError(t('Фатальная ошибка сервиса.'));
					} else if (error?.response?.status) {
						setFatalError(`${t('Ошибка')} ${error?.response?.status}: ${error?.message}`);
					} else {
						const requestStyle = 'color: #00aa00';
						const responseStyle = 'color: #ff0000';
						console.log('%cRequest:', requestStyle, JSON.stringify(request));
						console.log('%cResponse: %s', responseStyle, JSON.stringify(error));
						console.log(error);
					}
				})
				.finally(async () => {

				});
		} catch (e) {
			setFatalError(e.message);
		}
	};

	const silencePost = async (request, onError = false) => {
		const instance = axios.create({
			baseURL: '/rapi99',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				'Bora-Alien': 2,
				'Bora-Formatted-Output': 1,
			},
		});
	
		instance.interceptors.request.use(
			config => {
				const request = config.data;
				const mixins = getMixins();
				const root = Object.keys(request)[0];
	
				config.data = {
					[root]: {
						...mixins,
						...request[root]
					}
				};
	
				return config;
			},
			error => Promise.reject(error),
		);
	
		instance.interceptors.response.use(
			response => response,
			error => Promise.reject(error),
		);
	
		try {
			return await instance
				.post('', request)
				.then(data => {
					if (data.headers['content-type'].includes('image/')) {
						return {
							data: data.data,
							type: data.headers['content-type']
						};
					} else {
						const response = data.data;
						const root = Object.keys(response)[0];

						if (response.Error) {
							typeof onError === 'function' ? onError(response.Error) : setError(response.Error);
							setError(response.Error);
							return false;
						} else if (response[root]?.Error) {
							typeof onError === 'function' ? onError(response[root]?.Error) : setError(response[root].Error);
							return false;
						} else {
							return response?.[root] ?? response;
						}
					}
				})
				.catch(error => {
					if (error?.response?.status === 404) {
						setFatalError(t('Сервис временно недоступен.'));
					} else if (error?.response?.status === 504) {
						setFatalError(t('Превышено время ожидания ответа сервиса.'));
					} else if (error?.response?.status === 500) {
						setFatalError(t('Фатальная ошибка сервиса.'));
					} else if (error?.response?.status) {
						setFatalError(`${t('Ошибка')} ${error?.response?.status}: ${error?.message}`);
					} else {
						const requestStyle = 'color: #00aa00';
						const responseStyle = 'color: #ff0000';
						console.log('%cRequest:', requestStyle, JSON.stringify(request));
						console.log('%cResponse: %s', responseStyle, JSON.stringify(error));
						console.log(error);
					}
				})
				.finally(async () => {

				});
		} catch (e) {
			setFatalError(e.message);
		}
	};

	const prod = async request => {
		const instance = axios.create({
			baseURL: '/prod',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				'Bora-Alien': 2,
				'Bora-Formatted-Output': 1,
			},
		});
	
		instance.interceptors.request.use(
			config => {
				addCounter();
	
				const request = config.data;
				const mixins = {
					UserType: 'buyer',
					BuyerId: 'RO1BAND',
					UserId: 'WWW',
					Password: '84765',
					Language: 'ru',
				};
				const root = Object.keys(request)[0];
	
				config.data = {
					[root]: {
						...mixins,
						...request[root]
					}
				};
	
				return config;
			},
			error => {
				subCounter();
	
				return Promise.reject(error);
			},
		);
	
		instance.interceptors.response.use(
			response => {
				subCounter();
	
				return response;
			},
			error => {
				subCounter();
	
				return Promise.reject(error);
			},
		);
	
		try {
			return await instance
				.post('', request)
				.then(data => {
					if (data.headers['content-type'].includes('image/')) {
						return {
							data: data.data,
							type: data.headers['content-type']
						};
					} else {
						const response = data.data;
						const root = Object.keys(response)[0];

						if (response.Error) {
							setError(response.Error);
							return false;
						} else if (response[root]?.Error) {
							setError(response[root].Error);
							return false;
						} else {
							return response?.[root] ?? response;
						}
					}
				})
				.catch(error => {
					if (error?.response?.status === 404) {
						setFatalError(t('Сервис временно недоступен.'));
					} else if (error?.response?.status === 504) {
						setFatalError(t('Превышено время ожидания ответа сервиса.'));
					} else if (error?.response?.status === 500) {
						setFatalError(t('Фатальная ошибка сервиса.'));
					} else if (error?.response?.status) {
						setFatalError(`${t('Ошибка')} ${error?.response?.status}: ${error?.message}`);
					} else {
						const requestStyle = 'color: #00aa00';
						const responseStyle = 'color: #ff0000';
						console.log('%cRequest:', requestStyle, JSON.stringify(request));
						console.log('%cResponse: %s', responseStyle, JSON.stringify(error));
					}
				})
				.finally(async () => {

				});
		} catch (e) {
			setFatalError(e.message);
		}
	};

	const getBlob = async (request, filename, errors = false) => {
		const instance = axios.create({
			baseURL: '/rapi99',
			headers: {
				'Bora-Alien': 2,
				'Bora-Formatted-Output': 1,
			},
			responseType: 'blob'
		});
	
		instance.interceptors.request.use(
			config => {
				addCounter();
	
				const request = config.data;
				const mixins = getMixins();
				const root = Object.keys(request)[0];
	
				config.data = {
					[root]: { 
						...mixins,
						...request[root]
					}
				};
	
				return config;
			},
			error => {
				subCounter();
				return Promise.reject(error);
			},
		);
	
		instance.interceptors.response.use(
			response => {
				subCounter();
				return response;
			},
			error => {
				subCounter();
				return Promise.reject(error);
			},
		);
	
		try {
			return await instance
				.post('', request, { transformResponse: (r) => r })
				.then(data => {
					return {
						filename: data?.headers?.['content-disposition']?.replace(/^.*filename="([^"]+)".*$/gi, '$1'),
						data: data?.data,
					};
				})
				.then(response => {
					const data = response?.data;
					const name = decodeURI(response?.filename) ?? filename;

					if (data && data?.size > 0) {
						if (data && data?.size > 0) {
							const r = new FileReader();
							r.onloadend = function() {
								const linkSource = r.result;
								const downloadLink = document.createElement("a");
								downloadLink.href = linkSource;
								downloadLink.download = name;
								downloadLink.click();
							};
							r.readAsDataURL(data);
						}
					} else {
						setFatalError(t('Данные не получены.'));
					}
				})
				.catch(error => {
					if (error?.response?.status === 404) {
						setFatalError(errors?.error404 ?? t('Сервис временно недоступен.'));
					} else if (error?.response?.status === 504) {
						setFatalError(errors?.error504 ?? t('Превышено время ожидания ответа сервиса.'));
					} else if (error?.response?.status === 500) {
						setFatalError(errors?.error500 ?? t('Фатальная ошибка сервиса.'));
					} else if (error?.response?.status) {
						setFatalError(`${t('Ошибка')} ${error?.response?.status}: ${error?.message}`);
					} else {
						const requestStyle = 'color: #00aa00';
						const responseStyle = 'color: #ff0000';
						console.log('%cRequest:', requestStyle, JSON.stringify(request));
						console.log('%cResponse: %s', responseStyle, JSON.stringify(error));
					}
				})
				.finally(async () => {

				});
		} catch (e) {
			setFatalError(e.message);
		}
	};

	return {
		instance,
		post,
		prod,
		getBlob,
		silencePost,
	};	
};