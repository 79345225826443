import { defaultPerson } from './';
import { useTranslation } from 'react-i18next';

export const usePersonsProps = ({ orderInfo, citizenships, setOrderInfo }) => {
	const { t } = useTranslation();
	
	const categories = [
		{Code: 0, Name: ''},
		{Code: 1, Name: t('господин')},
		{Code: 2, Name: t('госпожа')},
		{Code: 3, Name: t('ребёнок')},
		{Code: 4, Name: t('младенец')},
	];

	const personsProps = {
		items: orderInfo.Persons.slice(0, orderInfo.NumberOfGuests + orderInfo.NumberOfExtraBedsAdult + orderInfo.NumberOfExtraBedsChild + orderInfo.NumberOfExtraBedsInfant),
		categories: categories,
		citizenships: citizenships,
		// adults: orderInfo.NumberOfGuests + orderInfo.NumberOfExtraBedsAdult,
		// childs: orderInfo.NumberOfExtraBedsChild + orderInfo.NumberOfExtraBedsInfant,
		guests: orderInfo.NumberOfGuests + orderInfo.NumberOfExtraBedsAdult + orderInfo.NumberOfExtraBedsChild + orderInfo.NumberOfExtraBedsInfant,
		// ages: [],
		onChange: (index, item) => setOrderInfo(prev => {
			const temp = prev.Persons;
			temp[index] = item;
			return {
				...prev,
				Persons: temp
			};
		}),
		onAdd: () => setOrderInfo(prev => ({
			...prev,
			Persons: [...prev.Persons, defaultPerson]
		})),
		onDelete: index => setOrderInfo(prev => {
			const items = prev.Persons;
			items.length > 1 && items.splice(index, 1);
			return {
				...prev,
				Persons: [...items]
			};
		})
	};

	return {
		personsProps
	};
};