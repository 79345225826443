export const useCommentProps = ({ orderInfo, setOrderInfo }) => {
	const commentProps = {
		comment: orderInfo.AdditionalInfo,
		onChange: e => setOrderInfo(prev => ({
			...prev,
			AdditionalInfo: e.target.value
		}))
	};

	return {
		commentProps
	};
};