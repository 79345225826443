import React, { createContext, useContext, useEffect } from 'react';
import { useInstance, useLocalStorage } from 'hooks';
import { useMsgStore } from './MsgStore';
import { useTranslation } from 'react-i18next';
import { generatePassword } from 'utils';
import { useNavigate } from 'react-router-dom';

const timeout = 12 * 60 * 60 * 1000;

const useUser = () => {
	const { t } = useTranslation();
	const { post } = useInstance();
	const [ user, setUser, clearUser ] = useLocalStorage('user', null, true);
	const [ Language ] = useLocalStorage('lang', 'ru', true);
	const [ lastTime, setLastTime, clearLastTime ] = useLocalStorage('lastTime', new Date().getTime(), true);
	const { show } = useMsgStore();
	const navigate = useNavigate();

	const isLogged = Boolean(user);

	const login = async ({ UserType = 'BUYER', BuyerId = 'RO1BAND', UserId = '', Password = '' }) => {
		const request = {
			LoginRequest: {
				UserType,
				BuyerId,
				UserId,
				Password: Password.trim(),
				Language
			}
		};

		const data = await post(request);

		if (data) {
			const user = {
				...data,
				UserType,
				BuyerId,
				UserId,
				Password: Password.trim(),
				Language,
				Menu: false,
				PayScheduleOversize: 0
			};

			setUser(user);
			setLastTime(new Date().getTime());
			// navigate('/');
			return true;
		}

		return false;
	};

	const updateUserData = () => {
		isLogged && user?.UserId !== undefined && user?.Password !== undefined && login({ UserId: user?.UserId ?? '', Password: user?.Password ?? '' });
	};

	const logout = async () => {
		localStorage.removeItem('searchparams');
		localStorage.removeItem('orderId');
		localStorage.removeItem('transfers');
		localStorage.removeItem('accommodations');
		sessionStorage.clear();
		clearUser();
		clearLastTime();
		navigate('/');
	};

	const register = async ({ UserName, Email, Phone, Password = false, onError = false }) => {
		if (!Password) {
			Password = generatePassword();
		}

		const request = {
			UserRequest: {
				Data: {
					Action: 'Create',
					UserId: Email,
					UserName,
					Password,
					Email,
					Phone
				}
			}
		};

		const data = await post(request, onError);

		if (data) {
			show(t('Пользователь зарегистрирован, пароль будет выслан на почту.'));

			const data2 = await login({
				UserId: Email,
				Password,
				toMain: false
			});

			if (data2) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	const privileges = user?.Privileges?.PrivilegeItem?.reduce((acc, cur) => ({
		...acc,
		[cur.Name]: true
	}), {}) ?? [];

	const allowTransfers = isLogged && privileges?.NOTRANSFERSINXML !== true;

	
	const PaymentSystems = (user?.PaymentSystems?.PaymentSystem ?? []).filter(i => [7, 8].includes(parseInt(i.Code)));
	// const paymentBind = {
	// 	allowTinkoff: PaymentSystem !== undefined,
	// 	product_id: PaymentSystem?.ProductCode ?? '',
	// 	product_name: PaymentSystem?.ProductName ?? '',
	// 	product_price: '0.00',
	// 	cs2: '0',
	// 	language: Language,
	// 	cb_type: 'P',
	// 	cb_url: `https://${window.location.hostname === 'acase.ru' ? '' : 'test-www.acase.ru'}/pay/chronopay`,
	// 	sign: PaymentSystem?.Secret ?? '',
	// };
	const paymentBinds = PaymentSystems.map(i => ({
		code: i.Code,
		product_id: i?.ProductCode,
		product_name: i?.ProductName,
		product_price: '0.00',
		cs2: '0',
		language: Language,
		cb_type: 'P',
		cb_url: `https://${window.location.hostname === 'acase.ru' ? '' : 'test-www.acase.ru'}/pay/chronopay`,
		sign: i?.Secret,
	}));

	useEffect(() => {
		if (location.pathname.includes('login')) return;
		if (location.pathname.includes('logout')) return;
		
		if (user && lastTime && new Date().getTime() - parseInt(lastTime) > timeout) {
			logout();
			return;
		}
		
		setLastTime(new Date().getTime());
	}, [location.pathname]);

	return {
		user,
		login,
		logout,
		privileges,
		isLogged,
		paymentBinds,
		register,
		Language,
		updateUserData,
		allowTransfers,
	};
};

const UserContext = createContext(null);

const UserProvider = ({ children }) => {
	const userStore = useUser();

	return (
		<UserContext.Provider value={userStore}>
			{children}
		</UserContext.Provider>
	);
};

const useUserStore = () => {
	const context = useContext(UserContext);

	if (context === undefined) {
		throw new Error('useUserStore must be used within a UserProvider');
	}

	return context;
};

export {
	UserProvider,
	useUserStore,
};