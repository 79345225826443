/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import { useEffectExceptOnMount } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserStore } from 'stores';
import { todayString, tomorrowString } from 'utils/dayjs';
import { useInstance } from 'hooks';

export const useTransfers = () => {
	const { post } = useInstance();
	const [ needle, setNeedle ] = useState(JSON.parse(localStorage.getItem('transfers_params'))?.needle ?? '');
	const [ from, setFrom ] = useState(JSON.parse(localStorage.getItem('transfers_params'))?.from ?? false);
	const [ to, setTo ] = useState(JSON.parse(localStorage.getItem('transfers_params'))?.to ?? false);
	const [ date, setDate ] = useState(JSON.parse(localStorage.getItem('transfers_params'))?.date ?? todayString());
	const [ fromOptions, setFromOptions ] = useState([]);
	const [ toOptions, setToOptions ] = useState();
	const [ items, setItems ] = useState([]);
	const [ currency, setCurrency ] = useState('');
	const [ whereToPay, setWhereToPay ] = useState('');
	const [ limit, setLimit ] = useState(25);
	const [ page, setPage ] = useState(1);
	const [ freesale, setFreesale ] = useState(false);
	const [ minPrice, setMinPrice ] = useState(0);
	const [ maxPrice, setMaxPrice ] = useState(0);
	const [ places, setPlaces ] = useState(1);
	const loc = useLocation();

	const {
		isInner,
		buyer
	} = useUserStore();

	const parseData = data => {
		return (data?.TransferPointList?.TransferPoint ?? []).map(i => {
			const {
				Code,
				TransferPointType: {
					Code: TransferPointType,
					Name: TransferPointTypeName,
				} = {},
				TypeOfPlace: {
					Name: TypeOfPlaceName
				} = {},
				City: {
					Name: CityName
				} = {},
				AdmUnit1: {
					Code: AdmUnit1,
					Name: AdmUnit1Name,
				} = {},
				AdmUnit2: {
					Code: AdmUnit2,
					Name: AdmUnit2Name,
				} = {},
				Country: {
					Name: CountryName
				} = {},
				Hotel: {
					Name: HotelName
				} = {},
				ObjectType: {
					Name: ObjectTypeName
				} = {},
				Object: {
					Name: ObjectName
				} = {}
			} = i;

			const point = {
				Code,
				value: Code,
				Type: TransferPointType,
			};

			if (parseInt(TransferPointType) === 1) {
				const place = [];

				parseInt(AdmUnit1) !== 1 && place.push(AdmUnit1Name);
				parseInt(AdmUnit1) !== 1 && parseInt(AdmUnit2) !== 1 && place.push(AdmUnit2Name);
				place.push(CountryName);

				point.label = `${TypeOfPlaceName} ${CityName} (${place.join(', ')})`;
			} else if (parseInt(TransferPointType) === 2) {
				const place = [];

				place.push(`${TypeOfPlaceName} ${CityName}`);
				parseInt(AdmUnit1) !== 1 && place.push(AdmUnit1Name);
				parseInt(AdmUnit1) !== 1 && parseInt(AdmUnit2) !== 1 && place.push(AdmUnit2Name);
				place.push(CountryName);

				point.label = `${TransferPointTypeName} ${HotelName} (${place.join(', ')})`;
			} else {
				const place = [];

				place.push(`${TypeOfPlaceName} ${CityName}`);
				parseInt(AdmUnit1) !== 1 && place.push(AdmUnit1Name);
				parseInt(AdmUnit1) !== 1 && parseInt(AdmUnit2) !== 1 && place.push(AdmUnit2Name);
				place.push(CountryName);

				point.label = `${ObjectTypeName} ${ObjectName} (${place.join(', ')})`;
			}

			return point;
		});
	};

	const fetch = async ({ startCode: Code = '', startType: Type = '', startName: Name = '' }) => {
		if (isInner && !buyer) return;

		const request = {
			RouteRequest: {
				Action: {
					Name: 'LIST',
					Parameters: {
						StartPoint: {
							Code,
							Type,
							Name
						},
						EndPoint: {
							Code: '',
							Type: '',
							Name: ''
						}
					}
				}
			}
		};

		const data = await post(request);

		Code ? setToOptions(parseData(data)) : setFromOptions(parseData(data));
	};

	const search = async () => {
		if (loc.pathname !== '/transfers/search') return;
		if (isInner && !buyer) return;

		const user = JSON.parse(localStorage.getItem('user'));
		const {
			CurrencyCode: Currency = 2,
			WhereToPayCode: WhereToPay = 3
		} = user ?? {};

		const request = {
			TransferSearchRequest: {
				Action: {
					Name: 'List',
					Parameters: {
						ArrivalDate: date,
						Currency,
						WhereToPay,
						StartPoint: {
							Code: from.Code,
							Type: from.Type
						},
						EndPoint: {
							Code: to.Code,
							Type: to.Type
						},
					}
				}
			}
		};

		const data = await post(request);
		setCurrency(data?.Currency?.Name ?? '');
		setWhereToPay(data?.WhereToPay ?? { Code: 0, Name: '' });
		setItems(data?.AvailableTransfersList?.Transfer ?? []);

		// maxPrice === 0 && setMaxPrice(data?.AvailableTransfersList?.Transfer?.reduce((acc, curr) => curr.TotalPrice > acc ? parseFloat(curr.TotalPrice) : acc, 0));
	};

	useEffect(() => {
		search();
	}, [date, from?.Code, from?.Type, to?.Code, to?.Type, isInner, JSON.stringify(buyer)]);

	useEffect(() => {
		if (from?.Code) {
			fetch({
				startCode: from.Code,
				startType: from.Type
			});
		} else if (to?.Code) {
			setTo(false);
		}
	}, [from?.Code]);

	useEffectExceptOnMount(() => {
		const data = {
			from,
			to,
			date,
			needle
		};

		localStorage.setItem('transfers_params', JSON.stringify(data));
	}, [from?.Code, to?.Code, date, needle]);

	useEffect(() => {
		needle.trim().length >= 2 && fetch({ startName: needle });
	}, [needle]);

	const onChangeFromInput = text => {
		// fetch({ startName: text });
		setNeedle(text);
	};

	const filtered = useMemo(() => {
		return items
			.filter(i => !minPrice || i.TotalPrice >= minPrice)
			.filter(i => !maxPrice || i.TotalPrice <= maxPrice)
			.filter(i => !places || places <= i.Capacity);
	}, [
		JSON.stringify(items),
		minPrice,
		maxPrice,
		places,
	]);

	const total = filtered.length;

	const paginatorBind = {
		total,
		limit,
		page,
		setPage
	};

	const sortPaginatorBind = {
		limit,
		setLimit: e => setLimit(parseInt(e.target.value)),
	};

	const pagedItems = filtered.slice((page - 1) * limit, page * limit);

	const freesaleBind = {
		checked: freesale,
		onChange: () => setFreesale(prev => !prev)
	};

	const minPriceBind = {
		value: minPrice,
		onValueChange: value => setMinPrice(parseInt(value.floatValue))
	};

	const maxPriceBind = {
		value: maxPrice,
		onValueChange: value => setMaxPrice(parseInt(value.floatValue))
	};

	const placesBind = {
		value: places,
		onChange: e => setPlaces(isNaN(parseInt(e.target.value)) ? 1 : parseInt(e.target.value)),
		onDec: () => setPlaces(prev => prev > 0 ? prev - 1 : prev),
		onAdd: () => setPlaces(prev => prev + 1)
	};

	const baseUrl = `/transfers/booking/${date}/`;

	return {
		date,
		setDate,
		from,
		setFrom,
		fromOptions,
		to,
		setTo,
		toOptions,
		onChangeFromInput,
		needle,
		total,
		search,
		items: pagedItems,
		currency,
		whereToPay,
		paginatorBind,
		sortPaginatorBind,
		freesaleBind,
		minPriceBind,
		maxPriceBind,
		placesBind,
		baseUrl,
		filtered,
	};
};