export const parseDate = date => {
	if (typeof date === 'string' && /^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}/.test(date)) {
		date = new Date(date.replace(/^(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2}).*$/, '$3-$2-$1 $4:$5'));
	}

	if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}/.test(date)) {
		date = new Date(date.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, '$1-$2-$3'));
	}

	if (typeof date === 'string' && /^\d{2}\.\d{2}\.\d{4}$/.test(date)) {
		date = new Date(date.replace(/^(\d{2})\.(\d{2})\.(\d{4})$/, '$3-$2-$1'));
	}

	if (date instanceof Date) {
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		date.setMilliseconds(0);
		return date;
	} 

	return '';
};