import React from 'react';
import styled from 'styled-components';
import RoomPhoto from '../RoomPhoto';
import { Offer } from './';
import { useHotelSearchStore } from 'stores';

const Container = styled.table`
	width: 100%;
	margin-top: 10px;
	position: relative;

	td {
		height: 40px;
		background-color: #D3EFFF;
		vertical-align: middle;

		&.roomName {
			padding-left: 10px;
			overflow: hidden;

			div {
				display: block;
				color: ${props => props.theme.darkBlue};
				font-size: 16px;
				font-weight: 500;
				line-height: normal;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 400px;
			}
		}

		&.icons {
			text-align: right;
			img {
				margin-right: 10px;
				vertical-align: middle;
			}
		}
	}
`;

const Room = ({ room, hotelCode, hotelName, hotelStars, CurrencyName, onClickRoom = () => false, onShowDetails = () => false, datesBind = {} }) => {
	const { onClickRoom: handleClickRoom = onClickRoom } = useHotelSearchStore() ?? {};

	const {
		Url = false,
		BigUrl = false,
		ThUrl = false,
		RoomName = '',
		Bathlocation = false,
		rates,
		Code,
		RoomCode,
	} = room;

	const roomPhotoBind = {
		Url,
		BigUrl,
		ThUrl,
	};

	const onShowRoomDescription = () => {
		handleClickRoom(Code, hotelCode, RoomCode, hotelName, hotelStars);
	};

	return (
		<>
			<Container>
				<colgroup>
					<col width='40' />
					<col />
					<col />
				</colgroup>

				<tbody>
					<tr>
						<td onClick={onShowRoomDescription}>
							<RoomPhoto {...roomPhotoBind} />
						</td>

						<td className='roomName' onClick={onShowRoomDescription}>
							<div>
								{RoomName} 
							</div>
						</td>

						<td className='icons'>
							{[3, 4].includes(Number(Bathlocation?.Code)) && <img src='/images/icon_bath_orange.png' />}
						</td>
					</tr>
				</tbody>
			</Container>

			{rates.map((rate, key) => <Offer key={key} offer={rate} CurrencyName={CurrencyName} hotelCode={hotelCode} datesBind={datesBind} onShowDetails={onShowDetails} />)}
		</>
	);
};

export default Room;