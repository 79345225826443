import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	margin-top: 20px;
`;

const Title = styled.div`
	color: ${props => props.theme.darkBlue};
	font-size: 16px;
	line-height: 19px;
	margin-bottom: 10px;
	font-weight: 500;
`;

const CommentField = styled.textarea`
	outline: none;
	border: 1px solid ${props => props.theme.darkBlue};
	width: 100%;
	min-height: 120px;
	padding: 10px;
	color: ${props => props.theme.darkBlue};
	font-size: 16px;
	line-height: 21px;
`;

export const Comment = ({comment = '', onChange = () => null, readOnly = false}) => {
	const { t } = useTranslation();

	return (
		<Container>
			<Title>{t('Ваш комментарий')}</Title>

			<CommentField onChange={onChange} value={comment} readOnly={readOnly} />
		</Container>
	);
};