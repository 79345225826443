import React from 'react';
import styled from 'styled-components';
import { Modal2 } from 'modals';
import { useTranslation } from 'react-i18next';
import { toMoney } from 'utils';
import { Button } from 'components';

const Line = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: #333;
	padding: 10px 0;
	border-bottom: 1px solid ${props => props.theme.lightGray};

	@media (max-width: 659px) {
		flex-direction: column;
	}

	* {
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
	}

	b {
		font-weight: bold;
		margin-right: 10px;
	}
`;

const Penalty = styled.div`
	font-weight: bold;
	color: #A2001D;

	@media (max-width: 659px) {
		text-align: right;
		width: 100%;
		margin-top: 10px;
	}
`;

const NoPenalty = styled.div`
	font-weight: bold;
	color: #5BB500;

	@media (max-width: 659px) {
		text-align: right;
		width: 100%;
		margin-top: 10px;
	}
`;

const Summary = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;

	@media (max-width: 659px) {
		flex-direction: column;
		align-items: stretch;
	}	

	label {
		font-size: 16px;
		line-height: 21px;
		color: #A2001D !important;
		font-weight: bold;
		font-family: ${props => props.theme.pt};
		margin-right: 20px;

		> div {
			font-family: inherit;
			font-size: inherit;
			font-weight: inherit;
			color: inherit;
		}

		.checked { display: none; }

		@media (max-width: 659px) {
			margin-right: 0;
			margin-bottom: 20px;
		}
	}

	input[type="checkbox"] {
		display: none;

		& + label {
			display: flex;
			flex-direction: row;
			align-items: center;

			&:before {
				content: '';
				display: flex;
				width: 20px;
				height: 20px;
				margin-right: 5px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 0.5H19.5V19.5H0.5V0.5Z' fill='white' stroke='%23A2001D'/%3E%3C/svg%3E%0A");
				flex: 0 0 20px;
			}
		}

		&:checked + label:before {
			background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 0.5H19.5V19.5H0.5V0.5Z' fill='white' stroke='%230270CB'/%3E%3Cpath d='M6.00037 9L9.00037 12L14.0004 7' stroke='%230270CB' stroke-width='2'/%3E%3C/svg%3E%0A");
		}

		&:checked + label {
			color: ${props => props.theme.darkBlue} !important;
			font-weight: normal;

			.unchecked { display: none; }
			.checked { display: inline; }
		}
	}
`;

export const ModalCancel = ({
	isVisible = true,
	onClose = () => false,
	penalties = false,
	confirmRef = null,
	onCancel = () => false
}) => {
	const { t } = useTranslation();

	if (!penalties) {
		return null;
	}

	const ids = penalties.map(i => i.id);

	return (
		<Modal2 isVisible={isVisible} onClose={onClose}>
			<h1>{t('Аннулирование услуг')}</h1>

			{penalties.filter(i => i?.title !== undefined).map(i => 
				<Line key={i.id}>
					<div>
						<b>{t('Услуга')} {i.id}</b>
						{i.title}
					</div>
					<div className='filler' />
					{i.penalty > 0
						? <Penalty>{t('Штраф')}: {toMoney(i.penalty)} {i.currency}</Penalty>
						: <NoPenalty>{t('Без штрафа')}</NoPenalty>
					}
				</Line>
			)}

			<Summary>
				{penalties.filter(i => i?.title !== undefined && i.penalty > 0).length > 0 &&
						<>
							<input type='checkbox' id="checkbox_confirm" ref={confirmRef} />
							<label htmlFor='checkbox_confirm'>
								<div className='checked'>{t('Со штрафными санкциями согласен')}</div>
								<div className='unchecked'>{t('Необходимо согласиться с оплатой штрафа')}</div>
							</label>
						</>
				}

				<Button red onClick={onCancel.bind(this, ids)}>
					{t('Аннулировать')} {penalties.length === 1 ? t('услугу') : t('услуги')}
				</Button>
			</Summary>
		</Modal2>
	);
};