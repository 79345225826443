import React from 'react';
import styled from 'styled-components';
import { declines, toMoney } from 'utils';
import { Meal } from './';

const Container = styled.div`
	position: relative;

	@media (max-width: 1279px) {
		margin-top: 20px;
	}
`;

const Summary = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	color: ${props => props.theme.darkBlue};
	font-size: 18px;
	line-height: 21px;
	padding-bottom: 10px;

	b {
		font-weight: 500;
		margin-right: 5px;
	}
`;

const typesTitles = ['тип', 'типа', 'типов'];

export const Meals = ({
	meal,
	extraMeals,
	setOrderInfo,
	currency = 'RUB', 
	readOnly = true,
	availableExtraMeals
}) => {
	const typesCount = (meal ? 1 : 0) + extraMeals.length;
	const sum = (meal?.Total ?? 0) + extraMeals.reduce((acc, curr) => acc + parseInt(curr.Total), 0);

	return (
		<Container>
			<Summary>
				<b>Питание:</b> {declines(typesCount, typesTitles, true)}
				<div className='filler' />
				<b>{toMoney(sum)} {currency}</b>
			</Summary>

			{meal && <Meal {...meal} setOrderInfo={setOrderInfo} currency={currency} readOnly={readOnly} extraMeals={availableExtraMeals} />}
			{extraMeals.map(i => <Meal key={i.Id} {...i} setOrderInfo={setOrderInfo} currency={currency} readOnly={readOnly} extraMeals={availableExtraMeals} />)}
		</Container>
	);
};