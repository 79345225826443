/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import pictoNds from 'images/picto_nds.svg';
import pictoPayment from 'images/picto_payment.svg';
import pictoInfo from 'images/picto_info.svg';
import pictoNds2 from 'images/picto_nds_2.svg';
import { toMoney } from 'utils';
import { Sprite } from 'components';
import { useDevice } from 'hooks';

const OrderButton = styled.div`
	cursor: pointer;
	width: 200px;
	height: 30px !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center !important;
	border-radius: 5px;
	background: linear-gradient(180deg, #22AFFE 0%, #0270CB 100%);
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 17px !important;
	color: white !important;
	text-transform: uppercase;
	font-family: 'Rubik', sans-serif !important;
	margin-top: 5px !important;

	&.orange {
		background: linear-gradient(180deg, #FEB446 0%, #FF6B00 100%);
	}

	svg {
		margin-left: 10px;
	}
`;

const RateContainer2 = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	padding: 10px 0;
	
	&:nth-of-type(n + 2) { border-top: 1px solid ${props => props.theme.lightGray}; }
`;

const Column1 = styled.div`
	margin-right: 10px;
	flex-grow: 1;
	
	> *:nth-child(n + 2) { margin-top: 10px; }
`;

const Column2 = styled.div`
	margin-right: 10px;
	width: calc((100% - 200px - 20px) / 2);
	> *:nth-child(n + 2) { margin-top: 10px; }
`;

const Column3 = styled.div`
	width: 200px;
`;

const Bit = styled.div`
	font-size: 16px;
	line-height: 21px;
	color: #333;
	font-family: ${props => props.theme.pt};
	display: flex;
	flex-direction: row;
	align-items: center;

	&.orange {
		color: ${props => props.theme.orange};
	}
	
	&.bold {
		font-weight: bold;
	}

	&.pointer {
		cursor: pointer;
	}

	svg {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		object-fit: cover;
		object-position: center;
		margin-right: 5px;
	}
	
	img, svg {
		margin-right: 5px;
	}
	
	* { fill: #333 !important; }
`;

const BitAvail = styled.div`
	font-family: ${props => props.theme.Rubik};
	justify-content: flex-end;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	color: ${props => props.theme.darkBlue};
	text-transform: uppercase;
	text-align: right;
`;

const BitLink = styled.a`
	font-size: 16px;
	line-height: 21px;
	color: ${props => props.theme.darkBlue};
	transition: color .2s ease-in-out;
	font-family: ${props => props.theme.pt};
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	
	&:hover {
		color: ${props => props.theme.lightBlue};
	}

	img {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		object-fit: cover;
		object-position: center;
		margin-right: 5px;
	}

	svg {
		margin-right: 5px;
	}
`;

const BitSum = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: ${props => props.theme.darkBlue};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	white-space: nowrap;
	cursor: pointer;

	&.nds { color: #00C0FF; }
	
	&:nth-of-type(n + 2) { margin-top: 10px; }	

	img {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		object-fit: cover;
		object-position: center;
		margin-right: 5px;
	}

	svg {
		margin-right: 5px;
	}
`;

const RackPriceDiv = styled.div`
	font-size: 14px;
	line-height: inherit;
	font-weight: inherit;
	color: #4F7EA7;
	margin-right: 10px;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;

	img {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		object-fit: cover;
		object-position: center;
		margin-right: 5px;
	}
`;

const MobileContainer = styled.div`
	position: relative;
	border-bottom: 1px solid ${props => props.theme.lightGray};

	> * {
		margin-bottom: 10px;
	}

	.icons {
		margin-top: 10px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		> *:not(:first-child) {
			margin-left: 10px;
		}
	}

	.price, .comm, .bonus {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
	}

	.price {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: ${props => props.theme.darkBlue};
	}

	.comm {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;		
		color: #00C0FF;
	}

	.bonus {
		font-weight: bold;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		color: #5BB500;
	}

	.buttonAsLink:not(:last-child) {
		margin-left: 20px;
	}
`;

const PenaltyDiv = styled.div`
	border: 1px solid ${props => props.theme.darkBlue};
	position: absolute;
	left: 0;
	top: 25px;
	background: white;
	padding: 5px;
	width: calc(100vw - 40px);
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	opacity: 0;
	visibility: hidden;

	&.isShowPen {
		opacity: 1;
		visibility: visible;
	}
`;

const PictoMeal = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M19.9934 6.5C20.0562 5.37366 19.671 4.26819 18.9218 3.42504C18.1726 2.58189 17.1203 2.06958 15.9947 2C15.389 2.01179 14.7948 2.16757 14.2611 2.45447C13.7275 2.74137 13.2697 3.15117 12.9257 3.65C12.0078 3.22405 11.0085 3.00228 9.99668 3C9.31962 2.99813 8.64598 3.09584 7.99734 3.29V3C7.99734 2.73478 7.89202 2.48043 7.70454 2.29289C7.51707 2.10536 7.2628 2 6.99767 2C6.73254 2 6.47828 2.10536 6.2908 2.29289C6.10333 2.48043 5.99801 2.73478 5.99801 3V8H4.99834V3C4.99834 2.73478 4.89302 2.48043 4.70554 2.29289C4.51807 2.10536 4.2638 2 3.99867 2C3.73354 2 3.47927 2.10536 3.2918 2.29289C3.10432 2.48043 2.999 2.73478 2.999 3V8H1.99934V3C1.99934 2.73478 1.89401 2.48043 1.70654 2.29289C1.51907 2.10536 1.2648 2 0.999668 2C0.734539 2 0.48027 2.10536 0.292796 2.29289C0.105322 2.48043 0 2.73478 0 3L0 10H2.999V18H4.99834V14.89C5.64988 15.5552 6.42756 16.0837 7.28583 16.4444C8.1441 16.8052 9.06571 16.991 9.99668 16.991C10.9276 16.991 11.8492 16.8052 12.7075 16.4444C13.5658 16.0837 14.3435 15.5552 14.995 14.89V18H16.9943V10.84C17.8933 10.5339 18.6702 9.94676 19.2102 9.16531C19.7501 8.38385 20.0247 7.44946 19.9934 6.5ZM9.99668 15C8.67103 15 7.39969 14.4732 6.46232 13.5355C5.52495 12.5979 4.99834 11.3261 4.99834 10H7.99734V5.42C8.62724 5.14213 9.30825 4.99907 9.99668 5C10.7286 5.00424 11.45 5.17523 12.106 5.5C12.0332 5.82836 11.9963 6.16367 11.996 6.5C11.9655 7.43465 12.2315 8.35498 12.7559 9.12918C13.2802 9.90339 14.036 10.4917 14.915 10.81C14.7234 11.9776 14.124 13.0395 13.2235 13.8067C12.3229 14.5739 11.1796 14.9967 9.99668 15ZM15.9947 9C15.4021 8.92475 14.862 8.62173 14.4888 8.15511C14.1156 7.68848 13.9386 7.09488 13.9953 6.5C13.9386 5.90512 14.1156 5.31152 14.4888 4.84489C14.862 4.37827 15.4021 4.07525 15.9947 4C16.5873 4.07525 17.1274 4.37827 17.5006 4.84489C17.8737 5.31152 18.0507 5.90512 17.994 6.5C18.0507 7.09488 17.8737 7.68848 17.5006 8.15511C17.1274 8.62173 16.5873 8.92475 15.9947 9Z" fill="#004685"/>
</svg>;

const PictoCancel = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M10 2C11.5823 2 13.129 2.46919 14.4446 3.34824C15.7602 4.22729 16.7855 5.47672 17.391 6.93853C17.9965 8.40034 18.155 10.0089 17.8463 11.5607C17.5376 13.1126 16.7757 14.538 15.6569 15.6569C14.538 16.7757 13.1126 17.5376 11.5607 17.8463C10.0089 18.155 8.40035 17.9965 6.93854 17.391C5.47673 16.7855 4.2273 15.7602 3.34825 14.4446C2.4692 13.129 2 11.5823 2 10C2 7.87827 2.84286 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0V0Z" fill="#004685"/>
	<path d="M14 13C13.8431 13.0019 13.6883 12.964 13.55 12.89L9 10.62V5C9 4.73478 9.10536 4.48043 9.29289 4.29289C9.48043 4.10536 9.73478 4 10 4C10.2652 4 10.5196 4.10536 10.7071 4.29289C10.8946 4.48043 11 4.73478 11 5V9.38L14.45 11.11C14.6855 11.2299 14.864 11.4381 14.9465 11.6892C15.0289 11.9403 15.0086 12.2138 14.89 12.45C14.8069 12.6149 14.6798 12.7536 14.5227 12.8507C14.3656 12.9478 14.1847 12.9994 14 13Z" fill="#004685"/>
</svg>;

export const Rate = ({ item = {}, isSelected = false, onChange = () => null, onShowDetails, hotelCode, currency }) => {
	const { t } = useTranslation();
	const [ isShowPen, setShowPen ] = useState(false);
	const { isMobile } = useDevice();
	const {
		DeadlineTimeLoc = '',
		Availability = false,
		RackPrice = false,
		// TotalPrice = false,
		Price = false,
		DiscountOffer: {
			Code: DiscountOffer = 2
		} = {},
		RateName,
	} = item;

	const MealIncludedInPrice = (item?.Meals?.Meal.filter(i => i?.IncludedInPrice?.Code === 1)?.length ?? 0) > 0;
	const MealIncludedInPriceName = item?.Meals?.Meal.filter(i => i?.IncludedInPrice?.Code === 1)?.[0]?.Name ?? '';

	const WhereToPay = item?.WhereToPayList?.WhereToPay?.[0]?.Name ?? '';

	if (isMobile) {
		return (
			<MobileContainer>
				<div className='icons'>
					<Sprite icon={`${MealIncludedInPrice === 1 ? 'without' : 'with'}_meal`} />
					<Sprite icon={`payment_${WhereToPay === 2 ? 'at_counter' : 'cashless'}`} />
					<div className='filler' />

					<div className='buttonAsLink' onClick={() => setShowPen(true)}>
						<PictoCancel />
					</div>

					<BitLink className='buttonAsLink' onClick={onShowDetails.bind(this, item)}>
						<Sprite icon={DiscountOffer === 1 ? 'orange_info' : 'info'} />
					</BitLink>
				</div>

				<PenaltyDiv className={classnames({isShowPen})}>
					{new Date(DeadlineTimeLoc) > new Date()
						? t('Штраф наступил')
						: t('Штраф с {{DeadlineTimeLoc}}', {DeadlineTimeLoc})
					}

					<div onClick={() => setShowPen(false)}>
						<Sprite icon='small_cross' />
					</div>
				</PenaltyDiv>

				<div className='price'>{toMoney(Price)} {currency}</div>
				<div className='comm'>
				</div>
				<div className='bonus'>
					{!isSelected && (
						<OrderButton onClick={onChange.bind(this, item.Code)} className={classnames({orange: Availability === 4})}>
							{Availability?.Code && Availability?.Code === 4 && t('Под запрос')}

							{Availability?.Code && Availability?.Code !== 4 && (
								`${t('В наличии')}: ${Availability?.Allotment?.Rooms !== '' 
									? Availability?.Allotment?.Rooms
									: Availability?.Name?.toLowerCase().indexOf(
										t('свободная продажа'),
									) === -1
										? Availability?.Name
										: t('много')}`
							)}

							<Sprite icon='cart' />

						</OrderButton>
					)}
				</div>
			</MobileContainer>
		);
	}

	return (
		<RateContainer2>
			<Column1>
				<Bit>
					<PictoMeal />
					{MealIncludedInPrice ? MealIncludedInPriceName : t('Без питания')}
				</Bit>

				<BitLink onClick={onShowDetails.bind(this, item)}>
					<img src={pictoInfo} alt="" />
					{parseInt(DiscountOffer) === 1
						? t('Специальное предложение')
						: `${RateName.slice(0, 29)}${RateName.length > 30 ? '...' : ''}`
					}

				</BitLink>
			</Column1>

			<Column2>
				{WhereToPay && (
					<Bit onClick={(e) => e.preventDefault()}>
						<img src={pictoPayment} alt="" />
						{WhereToPay}
					</Bit>
				)}
			</Column2>

			<Column3>
				<BitSum onClick={onShowDetails.bind(this, item, hotelCode)}>
					{RackPrice && (
						<RackPriceDiv title={t('Стоимость в отеле')}>
							Rack {toMoney(RackPrice)} {currency}
						</RackPriceDiv>
					)}
					
					{DiscountOffer === 1 && <><Sprite icon='orange_info' />&nbsp;&nbsp;</>}

					{toMoney(Price)} {currency}
				</BitSum>

				<BitSum title={t('Комиссия Агента')}
					onClick={onShowDetails.bind(this, item, hotelCode)}
					className="nds"
				>
				</BitSum>

				{isSelected &&
					<BitAvail>
						{Availability?.Code && Availability?.Code === 4 && t('Под запрос')}

						{Availability?.Code && Availability?.Code !== 4 && (
							`${t('В наличии')}: ${Availability?.Allotment?.Rooms !== '' 
								? Availability?.Allotment?.Rooms
								: Availability?.Name?.toLowerCase().indexOf(t('свободная продажа')) === -1
									? Availability?.Name
									: t('много')}`
						)}
					</BitAvail>
				}

				{!isSelected && (
					<OrderButton onClick={onChange.bind(this, item.Code)} className={classnames({orange: Availability?.Code === 4})}>
						{Availability?.Code && Availability?.Code === 4 && t('Под запрос')}

						{Availability?.Code && Availability?.Code !== 4 && (
							`${t('В наличии')}: ${Availability?.Allotment?.Rooms !== '' 
								? Availability?.Allotment?.Rooms
								: Availability?.Name?.toLowerCase().indexOf(t('свободная продажа')) === -1
									? Availability?.Name
									: t('много')}`
						)}

						<Sprite icon='cart' />
					</OrderButton>
				)}
			</Column3>
		</RateContainer2>
	);
};