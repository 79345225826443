import { useEffect, useState } from 'react';
import { useInstance } from 'hooks';
import { format, parseDate, today, todayString, tomorrowString } from 'utils/dayjs';
import { useParams } from 'react-router-dom';

export const useHotelBooking = () => {
	const params = useParams();
	const ls = JSON.parse(localStorage.getItem('searchparams'));
	const [ hotelName, setHotelName ] = useState('');
	const [ hotelStars, setHotelStars ] = useState('');

	const [ startDate, setStartDate ] = useState(() => {
		if (params?.startDate !== undefined && parseDate(params?.startDate).isValid()) {
			return parseDate(params?.startDate).isBefore(today(), 'day') ? todayString() : params.startDate;
		}

		const ls = JSON.parse(localStorage.getItem('searchparams'));
		if (ls?.startDate !== undefined && parseDate(ls?.startDate).isValid()) {
			return parseDate(ls?.startDate).isBefore(today(), 'day') ? todayString() : ls.startDate;
		}

		return todayString();
	});

	const [ endDate, setEndDate ] = useState(() => {
		if (params?.startDate !== undefined && params?.endDate !== undefined && parseDate(params?.startDate).isValid() && parseDate(params?.endDate).isValid()) {
			if (parseDate(params?.startDate).isBefore(today(), 'day')) {
				return parseDate(params?.startDate).isBefore(parseDate(params.endDate), 'day') ? params.endDate : parseDate(params.startDate).add(1, 'day').format(format);
			} else {
				return today().isBefore(parseDate(params.endDate), 'day') ? params.endDate : today().add(1, 'day').format(format);
			}
		}

		if (ls?.startDate !== undefined && ls?.endDate !== undefined && parseDate(ls?.startDate).isValid() && parseDate(ls?.endDate).isValid()) {
			if (parseDate(ls?.startDate).isSameOrAfter(today(), 'day')) {
				return parseDate(ls?.startDate).isBefore(parseDate(ls.endDate), 'day') ? ls.endDate : parseDate(ls.startDate).add(1, 'day').format(format);
			} else {
				return today().isBefore(parseDate(ls.endDate), 'day') ? ls.endDate : today().add(1, 'day').format(format);
			}
		}

		return tomorrowString();
	});

	const [ adults, setAdults ] = useState(Number(params?.adults ?? ls?.adults ?? 1));
	const [ childs, setChilds ] = useState(Number(params?.childs ?? ls?.childs ?? 0));
	const [ ages, setAges ] = useState(() => {
		if (params?.ages !== undefined && typeof params?.ages === 'string') {
			const ages = params?.ages.split(',').map(i => Number(i)).filter(i => !isNaN(i));
			if (ages.length === 4) return [...ages];
		}

		if (ls?.ages !== undefined) {
			if (typeof ls?.ages === 'string') {
				const ages = ls?.ages.split(',').map(i => Number(i)).filter(i => !isNaN(i));
				if (ages.length === 4) return [...ages];
			}

			if (Array.isArray(ls.ages) && ls.ages.length === 4) {
				return [...ls.ages];
			}
		}

		return [0, 0, 0, 0];
	});

	const hotel = params.id;

	const [ rooms, setRooms ] = useState(null);

	const { post } = useInstance();

	const datesBind = {
		startDate,
		endDate,
		onChange: dates => {
			let [ startDate, endDate ] = dates;
			if (!parseDate(startDate).isBefore(parseDate(endDate))) endDate = parseDate(startDate).add(1, 'day').format(format);

			setStartDate(startDate);
			setEndDate(endDate);
		},
	};

	const guestsBind = {
		adults,
		setAdults,
		childs,
		setChilds,
		ages,
		setAges,
	};

	const onSearch = async () => {
		const request = {
			HotelSearchRequest: {
				ArrivalDate: startDate,
				DepartureDate: endDate,
				Guests: {
					NumberOfAdults: adults,
					NumberOfMinors: childs,
					MinorAgeList: {
						Minor: ages.slice(0, childs).map(i => ({
							Age: i
						}))
					}
				},
				Destination: {
					Distance: 0,
					TypeCode: 3,
					Code: hotel
				},
				Options: 'ViewUndergroundObjects BestEasternRating',
				Currency: 2,
				WhereToPay: 3,
				FreeSaleOnly: 2,
				SortCode: 'DEFAULT'
			}
		};

		const data = await post(request);

		const h = data?.AvailableHotelsList?.Hotel?.find(i => parseInt(i.Code) === parseInt(hotel));
		const offers = h?.HotelOfferList?.HotelOfferDetail ?? [];

		const rooms = offers.filter((value, index, self) => self.map(i => i.RoomCode).indexOf(value.RoomCode) === index).map(i => ({
			...i,
			image: (i?.ThUrl ?? i?.Url ?? '').replace('test-images', 'images'),
			hotel,
			HotelName: h.Name,
			HotelStars: h.Stars,
			rates: offers.filter(j => j.RoomCode === i.RoomCode).map(r => ({
				...r,
				isPenalty: parseDate(r.DeadlineTimeLoc) < new Date(),
				IsCancellationPolicyCode: parseInt(r.IsCancellationPolicyCode),
				url: `/order/${hotel}/${r.Code}/${data?.ArrivalDate}/${data?.DepartureDate}/${r?.NumberOfGuests}/${r?.NumberOfExtraBedAdult}/${r?.NumberOfExtraBedChild}/${r?.NumberOfExtraBedInfant}`,
				Currency: h?.Currency?.Name ?? 'RUB',
				HotelCode: h.Code,
			}))
		}));

		setRooms(rooms);
		setHotelName(data?.AvailableHotelsList?.Hotel?.[0]?.Name);
		setHotelStars(data?.AvailableHotelsList?.Hotel?.[0]?.Stars);
	};

	useEffect(() => {
		onSearch();
	}, []);

	return {
		datesBind,
		guestsBind,
		onSearch,
		rooms,
		hotel,
		hotelName,
		hotelStars,
		params: {
			startDate,
			endDate,
			adults,
			childs,
			ages,
		},
	};
};