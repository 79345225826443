import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { default as RSelect } from 'react-select';

const Container = styled.div`
	&.isBig {
		.react-select__value-container {
			height: 38px !important;
		}

		.react-select__option {
			min-height: 38px !important	;
		}

		.react-select--is-disabled {
			.react-select__indicators {
				opacity: 0 !important;
			}

			.react-select__control {
				background-color: white !important;
			}
		}
	}

	.react-select-container {
		.react-select__control {
			border: 1px solid ${props => props.theme.darkBlue};
			border-radius: 0;
			box-shadow: none !important;
			min-height: auto;

			.react-select__value-container {
				height: 30px;
				overflow: none !important;

				.react-select__placeholder {
					color: #4F7EA7;
					font-family: ${props => props.theme.pt};
					font-size: 16px;
					line-height: 21px;
				}

				.react-select__single-value {
					font-size: 16px;
					line-height: 21px;
					font-family: ${props => props.theme.pt};
					color: ${props => props.theme.darkBlue};
					width: calc(100% - 10px);

					top: 0 !important;
					transform: none !important;
					height: 100% !important;
					top: 0 !important;
					transform: translateY(0) !important;
					line-height: 36px;
				}

				input {
					height: auto !important;
					color: ${props => props.theme.darkBlue} !important;
					box-shadow: none !important;
				}
			}

			.react-select__indicators {
				.react-select__indicator-separator {
					display: none;
				}

				.react-select__indicator {
					padding: 0 5px;
					color: ${props => props.theme.darkBlue};
				}
			}
		}

		.react-select__menu {
			border: 1px solid ${props => props.theme.darkBlue};
			border-radius: 0;
			margin: -1px 0 0 0;
			padding: 0;

			.react-select__menu-list {
				padding: 0;
			}

			.react-select__option {
				font-size: 16px;
				line-height: 21px;
				font-family: ${props => props.theme.pt};
				color: ${props => props.theme.darkBlue};
				min-height: 30px;

				&:nth-of-type(n + 2) {
					background-image: linear-gradient(${props => props.theme.lightGray}, ${props => props.theme.lightGray});
					background-repeat: no-repeat;
					background-size: calc(100% - 20px) 1px;
					background-position: left 10px top;
				}

				&.react-select__option--is-focused {
					background-color: ${props => props.theme.lightGray};
					background-image: none;

					& + .react-select__option {
						background-image: none;
					}
				}

				&.react-select__option--is-selected {
					color: white;
					background-color: ${props => props.theme.darkBlue} !important;
					background-image: none;

					& + .react-select__option {
						background-image: none;
					}
				}

			}
		}
	}
`;

export const Select = ({
	name,
	options,
	value,
	onChange,
	isBig = false,
	...props
}) => {
	const bind = {
		className: 'react-select-container',
		classNamePrefix: 'react-select',
		isSearchable: false,
		name,
		options,
		value,
		onChange,
		getOptionLabel: option => option?.label ?? option?.Name ?? ' ',
		getOptionValue: option => option?.value ?? option?.Code ?? '',
		getValue: props => console.log(props),
		...props
	};

	return (
		<Container className={classnames('select_container', `select_container__${name}`, {isBig})}>
			<RSelect {...bind} />
		</Container>
	);
};