import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Modal2 } from 'modals';
import { useDevice } from 'hooks';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	text-align: center;

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-top: 10px;
	}

	h1 {
		text-align: left;
	}

	@media (max-width: 1279px) {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
		height: calc(100%);

		h1 {
			font-size: 32px !important;
			line-height: 38px !important;
		}
	}
`;

export const ModalConfirm2 = ({
	children = <div />,
	text = '',
	confirmText = false,
	cancelText = false,
	onConfirm = () => false,
	onCancel = () => false
}) => {
	const { t } = useTranslation();
	const { isNotDesktop } = useDevice();
	const [ isVisible, setVisible ] = useState(false);

	const onOpen = useCallback(() => setVisible(true), []);
	const onClose = useCallback(() => {
		setVisible(false);
		onCancel();
	}, []);

	const onClickConfirm = () => {
		setVisible(false);
		onConfirm();
	};

	return (
		<>
			{React.cloneElement(children, { onClick: onOpen })}

			<Modal2 isVisible={isVisible} onClose={onClose} width={800}>
				<Container className='flex column alignCenter'>
					<h1>{text}</h1>
					
					<div className='row'>

						<Button gradient small={isNotDesktop} width='calc((100% - 10px) / 2)' onClick={onClickConfirm}>
							{confirmText ? confirmText : t('Да')}
						</Button>

						<Button white small={isNotDesktop} width='calc((100% - 10px) / 2)' onClick={onClose}>
							{cancelText ? cancelText : t('Нет')}
						</Button>
					</div>
				</Container>
			</Modal2>
		</>
	);
};