import React from 'react';
import styled from 'styled-components';
import { HotelStars } from './HotelStars';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	gap: 5px;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: ${props => props.theme.darkBlue};

	* {
		display: flex;
		font-family: inherit;
		font-size: inherit;
		font-weight: inherit;
		color: inherit;
		line-height: inherit;
	}
	
	@media (max-width: 1279px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const HotelTitleWithStars = ({ starsCode, children = null }) => (
	<Container>
		{children}

		<HotelStars starsCode={starsCode} />
	</Container>
);
