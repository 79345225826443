import React from 'react';
import styled from 'styled-components';
import iconMinus from 'images/icon_minus.svg';
import iconPlus from 'images/icon_plus.svg';
import classnames from 'classnames';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 16px;
	line-height: 21px;
	font-family: ${props => props.theme.pt};
	color: ${props => props.theme.darkBlue};
	height: 40px;
	/* border-bottom: 1px solid ${props => props.theme.lightGray}; */

	user-select: none !important;

	* {
		user-select: none !important;
	}
`;

const Inc = styled.div`
	width: 20px;
	height: 20px;
	background: url(${iconPlus}) center/cover no-repeat;
	cursor: pointer;
	opacity: 1;
	transition: opacity .2s ease-in-out;

	&.disabled { opacity: .6; }
`;

const Dec = styled.div`
	width: 20px;
	height: 20px;
	background: url(${iconMinus}) center/cover no-repeat;
	cursor: pointer;
	opacity: 1;
	transition: opacity .2s ease-in-out;

	&.disabled { opacity: .6; }
`;

const Count = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Counter = ({ value = 0, onChange = () => null, min = false, max = false, label = '', isHidden = false }) => {
	if (isHidden) {
		return null;
	}

	const handleDec = () => (min === false || value > min) && onChange(value - 1);

	const handleInc = () => (max === false || value < max) && onChange(value + 1);

	const decDisabled = min !== false && value <= min;
	const incDisabled = max !== false && value >= max;

	return (
		<Container>
			{label}
			<div className='filler'/>
			<Dec className={classnames({disabled: decDisabled})} onClick={handleDec} />
			<Count>{value}</Count>
			<Inc className={classnames({disabled: incDisabled})} onClick={handleInc} />
		</Container>
	);
};