import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore } from 'stores';
import { useDevice } from 'hooks';
import { SelectView, SelectSort } from '../';
import { SelectLimit } from './';
import { Paginator } from 'components';

const Container = styled.div`
	/* border: 1px solid red; */
	user-select: none !important;
	padding-bottom: 10px;
	/* margin-bottom: 20px; */
	border-bottom: 1px solid ${props => props.theme.lightGray};

	> div {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		&:first-child {
			margin-bottom: 10px;
		}

		> *:not(:last-child) {
			margin-right: 10px;
		}

		> label {
			color: #333;
			font-family: ${props => props.theme.pt};
			font-size: 16px;
			line-height: 21px;
			display: flex;
		}
	}

	.send_offers {
		background-color: #D3EFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 30px;
		width: 30px;
		flex-shrink: 0;
		border-radius: 5px;
		cursor: pointer;
	}
`;

const Toolbar = () => {
	const { t } = useTranslation();
	const { isDesktop } = useDevice();

	const {
		isMap,
		setMap,
		params: {
			limit: {
				value: limit = 25
			} = {}
		} = {},
		page,
		setPage,
		filteredCount: total,
	} = useHotelSearchStore();

	const toggleMap = () => setMap(prev => !prev);

	const paginatorBind = {
		total,
		limit,
		page,
		setPage
	};

	if (isMap) return (
		<Container>
			<div>
				<div className='button white' onClick={toggleMap}>
					{isDesktop && t('Отели')} {isMap ? t('списком') : t('на карте')}
				</div>

				<SelectSort />

				<div className='filler' />
			</div>
		</Container>
	);

	return (
		<>
			<Container>
				<div>
					<div className='button white' onClick={toggleMap}>
						{isDesktop && t('Отели')} {isMap ? t('списком') : t('На карте')}
					</div>

					<SelectView />
					
					<div className='filler' />
				</div>

				<div>
					<SelectSort />

					<SelectLimit />

					<Paginator {...paginatorBind} />
				</div>
			</Container>
		</>
	);
};

export default Toolbar;