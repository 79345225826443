import React from 'react';
import pictoMoveFilter from 'images/picto_move_filter.svg';
import pictoCloseFilter from 'images/picto_close_filter.svg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore } from 'stores';

const MoveFilter = styled.div`
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-image: url(${pictoMoveFilter});
	cursor: pointer;
	margin-right: 5px;
`;

const ClearFilter = styled.div`
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-image: url(${pictoCloseFilter});
	cursor: pointer;
`;

const NameInput = styled.input`
	color: ${props => props.theme.darkBlue};
	flex-grow: 1;
`;

export const ExtName = ({ provided }) => {
	const { t } = useTranslation();
	const {
		params: {
			filters: {
				hotel = ''
			}
		},
		setParams
	} = useHotelSearchStore();

	const onChange = e => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			hotel: e?.target?.value ?? e
		}
	}));

	const onClear = () => onChange('');

	return (
		<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
			<div className="title">
				<MoveFilter {...provided.dragHandleProps} />
				{t('Название отеля')}
				<div className='filler' />
				{hotel.trim().length > 0 && <ClearFilter onClick={onClear} />}
			</div>

			<div className="fromTo">
				<NameInput type='text' value={hotel} onChange={onChange} />
			</div>
		</div>
	);
};