import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Sprite } from 'components';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore } from 'stores';

const Container = styled.div`
	background-color: ${props => props.theme.darkBlue};
	color: white;
	height: 30px;
	width: 100vw;
	max-width: 100vw;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 20px;
	user-select: none !important;

	* {
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		text-transform: uppercase;
		user-select: none !important;
	}

	span {
		font-weight: inherit !important;
		font-size: inherit !important;
		line-height: inherit !important;
		color: #00C0FF;
	}
`;

const Button = styled.div`
	cursor: pointer;
	text-decoration: underline;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
`;

export const AddCompareMessageDesktop = () => {
	const { t } = useTranslation();

	const {
		addCompareMessageProps: {
			count,
			isVisible,
			onOpenCompare,
			onClear
		} = {},
	} = useHotelSearchStore();

	useEffect(() => {
		if (isVisible && count === 0) {
			onClear();
		}
	}, [count, isVisible]);

	if (count === 0) return null;

	return (
		<Container>
			<div>
				{t('Выбрано отелей')}: <span>{count}</span>
			</div>
			
			<Button onClick={onOpenCompare}>
				{t('Сравнить')}
			</Button>

			<Button onClick={onClear}>
				{t('Очистить')}
				<Sprite icon='small_cross' />
			</Button>
		</Container>
	);
};