import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Modal2 } from './Modal2';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	h5:not(:first-child) {
		margin-top: 20px;
	}

	li {
		margin-left: 30px;
		list-style: disc;
		white-space: normal;
	}

	p {
		white-space: normal;
	}
`;

export const ModalPersonal = ({ children = <div /> }) => {
	const [ isVisible, setVisible ] = useState(false);

	const onOpen = useCallback(() => setVisible(true), []);
	const onClose = useCallback(() => setVisible(false), []);

	return (
		<>
			{React.cloneElement(children, { onClick: onOpen })}

			<Modal2 isVisible={isVisible} onClose={onClose} width={800} containerStyle={{overflow: 'visible'}}>
				<Container>
					<h3>Согласие на обработку персональных данных</h3>

					<p>В соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» я настоящим даю свое согласие ООО «АКАДЕМ-ОНЛАЙН» (143405, Московская обл, Красногорск г, Вокзальная ул, дом 17А, пом. 2/17) (далее - Компания), на осуществление следующих действий:</p>

					<p><b>обработку* моих персональных данных</b> (фамилия, имя, дата и место рождения, гражданство, пол, данные банковской карты, контактная информация, домашний/мобильный телефон, адрес личной электронной почты, файлы cookie);</p>
					
					<p><b>для целей оказания мне следующих услуг Компанией:</b></p>

					<ul>
						<li>участия в бонусных программах и программах лояльности Компании, акциях, а также стимулирующих мероприятиях, проводимых на сайте acase.ru;</li>
						<li>консультирования субъекта персональных данных по услугам, предоставляемым Компанией, а также по иным запросам, направленным субъектом персональных данных в адрес Компании;</li>
						<li>отправки сообщений посредством электронной почты и/или с использованием специального чата и/или через форму обратной связи, размещенной на сайте https://acase.ru/ и/или с использованием телефонной связи;</li>
					</ul>

					<p><b>Субъект персональных данных имеет право:</b></p>
					<ul>
						<li>на получение сведений о Компании, о месте ее нахождения, о наличии у Компании персональных данных, относящихся к соответствующему субъекту персональных данных, а также на ознакомление с такими персональными данными;</li>
						<li>на уточнение своих персональных данных, их блокирование или уничтожение в случае, если персональные данные являются неполными, устаревшими, недостоверными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;</li>
						<li>на получение при обращении (запросе) информации, касающейся обработки его персональных данных;</li>
						<li>на защиту своих прав и законных интересов;</li>
						<li>отозвать согласие на обработку своих персональных данных</li>
						<li>иные права, предусмотренные действующим применимым законодательством.</li>
					</ul>

					<p><b>Компания обязана:</b></p>
					<ul>
						<li>принимать необходимые организационные и технические меры для защиты персональных данных;</li>
						<li>сообщить субъекту персональных данных или его законному представителю информацию о наличии персональных данных, относящихся к соответствующему субъекту персональных данных, а также предоставить возможность ознакомления с ними при обращении субъекта персональных данных или его законного представителя;</li>
						<li>внести в персональные данные необходимые изменения, уничтожить или блокировать соответствующие персональные данные по предоставлении</li>
					</ul>
				</Container>
			</Modal2>
		</>
	);
};