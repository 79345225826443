import React from 'react';

export const IconShowPass = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
	<g id="Layer_2" data-name="Layer 2">
		<g id="Layer_1-2" data-name="Layer 1">
			<rect className="cls-1" width="20" height="20" fill="transparent"/>
			<path className="cls-2" fill="#004685"
			      d="M19.73,9.55a11.3,11.3,0,0,0-19.46,0L0,10l.27.46a11.31,11.31,0,0,0,19.46,0L20,10ZM10.1,13.23a3.52,3.52,0,1,1,3.57-3.52A3.55,3.55,0,0,1,10.1,13.23ZM2.15,10A9.31,9.31,0,0,1,5.46,7a5.23,5.23,0,0,0,.72,6.39A9.35,9.35,0,0,1,2.15,10Zm12,3.21a5.25,5.25,0,0,0,.68-6.05,9.35,9.35,0,0,1,3,2.84A9.27,9.27,0,0,1,14.17,13.21ZM11.5,9.64A1.5,1.5,0,1,1,10,8.15,1.49,1.49,0,0,1,11.5,9.64Z"/>
		</g>
	</g>
</svg>;
