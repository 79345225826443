/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useInstance } from 'hooks';
import { useCompare, useDetails, useRoom } from 'rest/search';
import { format, parseDate, todayString, tomorrowString } from 'utils/dayjs';
import { useEffectExceptOnMount } from 'hooks';
import { useLocation } from 'react-router-dom';
import { useUserStore } from './UserStore';

const defaultParams = {
	destinationValue: null,
	destinationNeedle: '',
	startDate: todayString(),
	endDate: tomorrowString(),
	adults: 1,
	childs: 0,
	ages: [0, 0, 0, 0],
	filters: {
		hotel: '',
		freesale: false,
		priceFrom: 0,
		priceTo: 0,
		stars: [1, 2, 3, 4, 5],
		amenities: [],
		distance: 0,
		exts: [
			'name',
			'freesale',
			'price',
			'stars',
			'amenities',
			'distance'
		]
	}
};

const useHotelSearch = () => {
	const { post, silencePost } = useInstance();
	const loc = useLocation();

	const [ params, setParams ] = useState(() => {
		const storedParams = JSON.parse(localStorage.getItem('searchparams'));

		if (!storedParams) return {...defaultParams};

		const startDate = parseDate(storedParams?.startDate).isBefore(parseDate(defaultParams?.startDate)) ? defaultParams.startDate : storedParams?.startDate;
		// let endDate = parseDate(storedParams?.endDate).isBefore(parseDate(defaultParams?.startDate)) ? defaultParams.endDate : storedParams?.startDate;
		let endDate = parseDate(storedParams?.endDate).isAfter(parseDate(startDate)) ? storedParams?.endDate : parseDate(startDate).add(1, 'day').format(format);

		// if (!parseDate(startDate).isBefore(endDate)) endDate = parseDate(startDate).add(1, 'day').format(format);

		if (!storedParams?.ages || !Array.isArray(storedParams?.ages) || storedParams?.ages?.length !== 4) storedParams.ages = defaultParams.ages;
	
		return {
			...defaultParams,
			...storedParams,
			startDate,
			endDate
		};
	});
	const [ hotels, setHotels ] = useState(null);
	const [ altHotels, setAltHotels ] = useState(null);
	const [ lastDestination, setLastDestination ] = useState('');
	const [ isMap, setMap ] = useState(false);
	const [ page, setPage ] = useState(1);
	const [ hotelOnMap, setHotelOnMap ] = useState(null);

	const {
		isInner,
		buyer
	} = useUserStore();

	const {
		fetch: onClickRoom,
		roomDescriptionProps
	} = useRoom();

	const {
		onShowDetails,
		modalDetailsProps
	} = useDetails({
		startDate: params.startDate,
		endDate: params.endDate
	});

	const {
		compareHotels,
		toggleCompare,
		addCompareMessageProps,
		modalCompareProps,
	} = useCompare(hotels, altHotels);

	const fetch = async () => {
		if (loc?.pathname !== '/search') return;
		if (!params?.destinationValue?.Code || !params?.startDate || !params?.endDate) return;
		if (isInner && !buyer) return;

		setHotels(null);

		const request = {
			HotelSearchRequest: {
				ArrivalDate: params.startDate,
				DepartureDate: params.endDate,
				Guests: {
					NumberOfAdults: params.adults,
					NumberOfMinors: params.childs,
					MinorAgeList: {
						Minor: params.ages.slice(0, params.childs).map(i => ({
							Age: i
						}))
					}
				},
				Destination: {
					Distance: params?.destinationValue?.DefaultDistance ?? 0,
					TypeCode: params?.destinationValue.TypeCode,
					Code: params?.destinationValue.Code
				},
				Options: window.location.hostname === 'localhost' ? 'ViewUndergroundObjects BestEasternRating' : 'ViewUndergroundObjects BestEasternRating SearchAlternative',
				// Options: 'ViewUndergroundObjects BestEasternRating SearchAlternative',
				Currency: 2,
				WhereToPay: params?.whereToPay ?? 1,
				FreeSaleOnly: 2,
				SortCode: params?.sort?.value ?? 'DEFAULT'
			}
		};

		const data = await post(request);
		setLastDestination({
			...params.destinationValue,
			Position: data?.Position ?? {}
		});
		setHotels(data?.AvailableHotelsList?.Hotel ?? []);
		setAltHotels(data?.AlternativeHotelsList?.Hotel ?? []);
	};

	useEffect(() => {
		fetch();
	}, [
		params?.sort?.value,
		params?.destinationValue?.Code, 
		params?.startDate, 
		params?.endDate, 
		params?.adults, 
		params?.childs, 
		JSON.stringify(params.ages.slice(0, params.childs)),
		isInner,
		JSON.stringify(buyer),
		params?.whereToPay ?? 1,
	]);

	useEffectExceptOnMount(() => {
		localStorage.setItem('searchparams', JSON.stringify(params));
	}, [JSON.stringify(params)]);

	const filtersCount = useMemo(() => {
		const {
			filters: {
				distance,
				stars,
				priceFrom,
				priceTo,
				freesale,
				hotel,
				amenities
			},
		} = params;

		return (
			(distance > 0 ? 1 : 0)
			+
			(stars.length !== 5 ? 1 : 0)
			+
			(priceFrom > 0 || priceTo > 0 ? 1 : 0)
			+
			(freesale ? 1 : 0)
			+
			(hotel.trim().length >= 2 ? 1 : 0)
			+
			(amenities.length > 0 ? 1 : 0)
		);
	}, [params?.filters]);

	const getFiltered = hotels => {
		setPage(1);

		if (hotels === null) return null;

		const {
			filters: {
				distance,
				stars,
				priceFrom,
				priceTo,
				freesale,
				hotel,
				amenities
			}
		} = params;

		let filtered = [...hotels];

		if (distance > 0) {
			filtered = filtered.filter(i => parseFloat(i.DistanceToDestination) <= parseFloat(distance));
		}
	
		if (stars.length !== 5) {
			filtered = filtered.filter(i => stars.includes(parseInt(i.Stars.Code)));
		}
	
		if (priceFrom > 0 || priceTo > 0) {
			filtered = filtered.map(i => ({
				...i,
				HotelOfferList: {
					HotelOfferDetail: i.HotelOfferList.HotelOfferDetail.filter(o => {
						const price = parseInt(o.TotalPrice);
						const minPrice = isNaN(parseInt(priceFrom)) ? 0 : parseInt(priceFrom);
						const maxPrice = isNaN(parseInt(priceTo)) ? 0 : parseInt(priceTo);
			
						return (minPrice === 0 || price >= minPrice) && (maxPrice === 0 || maxPrice < minPrice || price <= maxPrice);
					})
				}
			})).filter(i => i.HotelOfferList.HotelOfferDetail.length > 0);
		}
	
		if (freesale) {
			filtered = filtered.map(i => ({
				...i,
				HotelOfferList: {
					HotelOfferDetail: i.HotelOfferList.HotelOfferDetail.filter(o => parseInt(o?.Availability?.Code ?? 0) === 2)
				}
			})).filter(i => i.HotelOfferList.HotelOfferDetail.length > 0);
		}
	
		if (hotel.trim().length >= 2) {
			filtered = filtered.filter(i => i.Name.toLowerCase().includes(hotel.trim().toLowerCase()));
		}
	
		if (amenities.length > 0) {
			filtered = filtered.filter(i => JSON.stringify(i.Amenities.HA.map(a => parseInt(a.Code)).filter(a => amenities.includes(a)).slice().sort()) === JSON.stringify(amenities.slice().sort()));
		}

		return filtered;
	};

	const altFiltered = useMemo(() => getFiltered(altHotels), [params?.filters?.hotel, JSON.stringify(params?.filters?.stars), params?.filters?.priceFrom, params?.filters?.priceTo, params?.filters?.distance, params?.filters?.freesale, JSON.stringify(params?.filters?.amenities), JSON.stringify(altHotels)]);
	const filtered = useMemo(() => getFiltered(hotels), [params?.filters?.hotel, JSON.stringify(params?.filters?.stars), params?.filters?.priceFrom, params?.filters?.priceTo, params?.filters?.distance, params?.filters?.freesale, JSON.stringify(params?.filters?.amenities), JSON.stringify(hotels)]);

	const [ paged, altPaged ] = useMemo(() => {
		if (filtered === null || altFiltered === null) return [ null, null ];

		const allIds = [
			(Array.isArray(filtered) ? filtered : []).map(i => i.Code),
			(Array.isArray(altFiltered) ? altFiltered : []).map(i => i.Code),
		].flat();

		const limit = Number(params?.limit?.value ?? 25);
		const ids = allIds.slice((page - 1) * limit, page * limit);
		const paged = filtered.filter(i => ids.includes(i.Code));
		const altPaged = altFiltered.filter(i => ids.includes(i.Code));

		return [
			paged,
			altPaged
		];
	}, [filtered, altFiltered, page, Number(params?.limit?.value ?? 25)]);

	const getGroupCriterion = async id => {
		const index = hotels.findIndex(i => parseInt(i.Code) === parseInt(id));
		const altIndex = altHotels.findIndex(i => parseInt(i.Code) === parseInt(id));

		if (Boolean(index === -1 || hotels?.[index]?.GroupCriterionList) && Boolean(altIndex === -1 || altHotels?.[altIndex]?.GroupCriterionList)) {
			return;
		}

		const request = {
			HotelRequest: {
				ActionDescription: {
					Parameters: {
						HotelCode: id,
						UseObjects: 1,
						Padezh: 1,
						BestEasternMember: 1,
						PublishHotelPhone: 1
					}
				}
			}
		};

		const data = await post(request, true);

		if (data?.Hotel?.[0]?.GroupCriterionList?.GroupCriterion) {
			const crits = data.Hotel[0].GroupCriterionList.GroupCriterion;

			index !== -1 && setHotels(prev => {
				if (index !== -1) {
					prev[index].GroupCriterionList = crits;
				}

				return [...prev];
			});

			altIndex !== -1 && setAltHotels(prev => {
				if (altIndex !== -1) {
					prev[altIndex].GroupCriterionList = crits;
				}

				return [...prev];
			});
		}
	};

	return {
		params,
		setParams,
		totalCount: (hotels?.length ?? 0) + (altHotels?.length ?? 0),
		filteredCount: (filtered?.length ?? 0) + (altFiltered?.length ?? 0),
		lastDestination,
		isMap,
		setMap,
		isLoaded: hotels !== null,
		filtered: paged,
		altFiltered: altPaged,
		isFounded: (Array.isArray(filtered) ? filtered : []).length > 0,
		onClickRoom,
		roomDescriptionProps,
		onShowDetails,
		modalDetailsProps,
		compareHotels,
		toggleCompare,
		addCompareMessageProps,
		modalCompareProps,
		page,
		setPage,
		fetch,
		getGroupCriterion,
		hotelOnMap,
		setHotelOnMap,
		filtersCount,
		all: (Array.isArray(filtered) ? filtered : []).concat(altFiltered),
	};
};

const HotelSearchContext = createContext(null);

const HotelSearchProvider = ({ children }) => {
	const hotelSearchStore = useHotelSearch();

	return (
		<HotelSearchContext.Provider value={hotelSearchStore}>
			{children}
		</HotelSearchContext.Provider>
	);
};

const useHotelSearchStore = () => {
	const context = useContext(HotelSearchContext);

	if (context === undefined) {
		throw new Error('useHotelSeearchStore must be used within a HotelSearchProvider');
	}

	return context;
};

export {
	HotelSearchProvider,
	useHotelSearchStore
};
