import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal2 } from 'modals';
import Login from './Login';
import Forget from './Forget';
import Register from './Register';

const Container = styled.form`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 10px;

	h4 {
		margin: 0 !important;
		white-space: normal;
	}

	& > input[type="text"] {
		height: 30px !important;
	}

	.button.grad {
		margin-top: 10px;
	}

	&.login {
		.button.grad {
			margin-bottom: 30px;
		}
	}

	.PhoneInputInput {
		height: 30px !important;
	}

	input {
		box-shadow: none !important;
	}

	p {
		white-space: normal;
	}
`;

const Actions = styled.div`
	display: flex;
	gap: 5px;
	font-family: ${props => props.theme.pt};
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;

	div {
		font-family: ${props => props.theme.pt};
		color: ${props => props.theme.darkBlue};
		font-weight: 700;
		cursor: pointer;
	}
`;

const ModalLogin = ({ children = null, initialIsOpen = false }) => {
	const [ section, setSection ] = useState('login');
	const [ isOpen, setOpen ] = useState(initialIsOpen);

	return (
		<>
			{React.cloneElement(children, { onClick: () => setOpen(true) })}

			<Modal2 isVisible={isOpen} onClose={() => setOpen(false)} width={620}>
				{section === 'register' && <Register Container={Container} Actions={Actions} setSection={setSection} />}
				{section === 'forget' && <Forget Container={Container} Actions={Actions} setSection={setSection} />}
				{section === 'login' && <Login Container={Container} Actions={Actions} setSection={setSection} />}
			</Modal2>
		</>
	);
};

export default ModalLogin;