import React from 'react';
import styled from 'styled-components';
import checkboxEmpty from 'images/checkbox_empty.svg';
import checkboxFill from 'images/checkbox_fill.svg';
import classnames from 'classnames';
import { nanoid } from 'nanoid';

const Container = styled.div`
	margin-bottom: 10px;
	user-select: none !important;

	* {
		user-select: none !important;
	}

	label {
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
		color: #333;
		display: flex;
		flex-direction: row;
		align-items: center;

		span {
			font-family: ${props => props.theme.pt};
			font-size: 16px;
			line-height: 21px;
			color: ${props => props.theme.darkBlue};
			font-weight: bold;
			display: inline-block;
			margin-left: 5px;
			cursor: pointer;
		}
		
		&:before {
			content: '';
			display: flex;
			width: 20px;
			height: 20px;
			margin-right: 10px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			//background-image: url(${checkboxEmpty});
			background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 0.5H19.5V19.5H0.5V0.5Z' fill='white' stroke='%23004685'/%3E%3C/svg%3E%0A");
			flex: 0 0 20px;
		}
		
		&.checked:before {
			//background-image: url(${checkboxFill});
			background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 0.5H19.5V19.5H0.5V0.5Z' fill='white' stroke='%23004685'/%3E%3Cpath d='M6 9L9 12L14 7' stroke='%23004685' stroke-width='2'/%3E%3C/svg%3E%0A");
		}
	}

	input[type="checkbox"] {
		display: none;
		
		/*
		&:checked + label:before {
			background-image: url(${checkboxFill});
		}
		 */
	}
`;

export const Checkbox = ({ checked = false, label = '', name = '', onChange = () => {}, value = '', styleLabel = {}, styleContainer = {} }) => {
	const id = 'prefix-' + nanoid(10);

	return (
		<Container style={styleContainer} className='checkbox'>
			<input type='checkbox' value={value} id={id} checked={checked} name={name} onChange={onChange.bind(this, value)} />
			<label htmlFor={id} className={classnames({'checked': checked})} style={styleLabel}>{label}</label>
		</Container>
	);
};