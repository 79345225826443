import React, { useMemo } from 'react';
import styled from 'styled-components';
import { SelectInput } from 'forms2';
import { useHotelSearchStore } from 'stores';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	width: 120px;
`;

const SelectLimit = () => {
	const { t } = useTranslation();

	const options = [
		{ label: `${t('По')} 25`, value: 25 },
		{ label: `${t('По')} 50`, value: 50 },
		{ label: `${t('По')} 100`, value: 100 },
	];

	const {
		params: {
			limit: value = options[0]
		},
		setParams
	} = useHotelSearchStore();
	
	const valueOption = useMemo(() => options.find(i => parseInt(i.value) === parseInt(value?.value)) ?? null, [value?.value]);

	const bind = {
		small: true,
		name: 'view',
		isSearchable: false,
		options,
		value: valueOption,
		onChange: limit => setParams(prev => ({
			...prev,
			limit
		})),
		border: false
	};

	return (
		<Container>
			<SelectInput {...bind} />
		</Container>
	);
};

export default SelectLimit;