/* eslint-disable no-unreachable */
import { Api } from 'hooks';
import { useRequest } from 'hooks';
import { useMsgStore } from 'stores';
import { useTranslation } from 'react-i18next';

export const useOrderDocs = () => {
	const { t } = useTranslation();
	const { show } = useMsgStore();
	const { 
		call,
		startLoading,
		stopLoading
	} = Api();
	const { runRequest } = useRequest(false, {
		responseType: 'blob',
		rawParser: data => {
			if (data?.status !== 200) return;

			if (data?.headers?.['content-disposition'] !== undefined) {
				const filename = decodeURI(data?.headers?.['content-disposition']?.replace(/^.*filename="([^"]+)".*$/gi, '$1'));
				const raw = data?.data;
				console.log(raw);

				if (raw) {
					const r = new FileReader();
					r.onloadend = function() {
						const linkSource = r.result;
						const downloadLink = document.createElement("a");
						downloadLink.href = linkSource;
						downloadLink.download = filename;
						downloadLink.click();
					};
					r.readAsDataURL(raw);
				}
			}
		},
	});

	const { runRequest: sendVoucher } = useRequest(false, {
		parser: data => {
			if (data?.Success !== undefined) {
				show(t('Ваучер будет отправлен на указанный email в ближайшее время'));
			}
		}
	});

	const getTaskAddRequest = (docType, docId, opts = '') => (
		{
			OrderDocsRequest: {
				Action: {
					Name: 'TaskAdd',
					Parameters: {
						DocId: docId,
						DocType: {
							Code: docType,
							Name: '',
							Options: opts
						}
					}
				}
			}
		}
	);

	const getTaskStatusRequest = taskId => (
		{
			OrderDocsRequest: {
				Action: {
					Name: 'TaskStatus',
					Parameters: {
						TaskId: taskId,
					}
				}
			}
		}
	);

	const getTaskResponseRequest = taskId => (
		{
			OrderDocsRequest: {
				Action: {
					Name: 'TaskResponse',
					Parameters: {
						TaskId: taskId,
					}
				}
			}
		}
	);

	const sleep = async ms => new Promise(resolve => setTimeout(resolve, ms));

	const fetch = async (docType, docId, filename, opts = 'VoucherWithMeal VoucherWithoutCancel VoucherUKLogo') => {
		startLoading();

		const taskAddRequest = getTaskAddRequest(docType, docId, opts);

		const data = await call(taskAddRequest, false, true);
		if (data) {
			const taskId = data.TaskInfo.Code;			
			const taskStatusRequest = getTaskStatusRequest(taskId);
			
			let tryCount = 50;
			let status = data.TaskInfo.TaskStatus.Code;

			while ([1, 2].includes(status) && tryCount > 0) {
				await sleep(2000);
				tryCount--;

				const data = await call(taskStatusRequest);

				if (data) {
					status = await data.TaskInfo.TaskStatus.Code;
				} else {
					tryCount = 0;
				}
			}

			if (![1, 2].includes(status)) {
				const taskResponseRequest = getTaskResponseRequest(taskId);
				const data = await call(taskResponseRequest);

				if (data && data?.File?.value) {
					const b64 = await data.File.value;

					const linkSource = `data:application/pdf;base64,${b64}`;
					const downloadLink = document.createElement("a");
					const fileName = `${filename}${docId}.pdf`;
					downloadLink.href = linkSource;
					downloadLink.download = fileName;
					downloadLink.click();
				}
			}
		}

		stopLoading();
	};

	const fetchInvoice = async docId => await fetch(2, docId, 'order');
	const fetchNotification = async docId => await fetch(4, docId, 'notification');
	const fetchVoucher = async (docId, opts) => await fetch(7, docId, 'voucher', opts);

	const fetchHistoryVoucher = async (VoucherId) => {
		const request = {
			VoucherRequest: {
				ActionHistory: {
					Parameters: {
						VoucherId
					}
				}
			}
		};

		await runRequest(request);
	};

	const sendHistoryVoucher = async (VoucherId) => {
		const request = {
			VoucherRequest: {
				ActionHistorySend: {
					Parameters: {
						VoucherId
					}
				}
			}
		};

		await sendVoucher(request);
	};

	return {
		fetch,
		fetchInvoice,
		fetchNotification,
		fetchVoucher,
		fetchHistoryVoucher,
		sendHistoryVoucher,
	};
};