import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDevice } from 'hooks';
import { Select2 } from 'forms';

const Container = styled.div`
	height: 30px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;

	@media (max-width: 659px) {
		flex-wrap: wrap;
		height: auto;

		.select2 {
			margin-top: 10px;
		}
	}

	.select2 {
		@media (min-width: 1280px) {
			display: none;
		}

		flex-grow: 1 !important;
		height: 30px !important;
	}

	.button.white {
		margin-right: 10px;
		padding: 0 10px !important;
	}

	.mobile {
		@media (min-width: 1280px) {
			display: none !important;
		}
	}
`;

const CompareButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border: 2px solid ${props => props.theme.darkBlue};
	background-color: white;
	color: ${props => props.theme.darkBlue};
	transition: color .2s ease-in-out, background-color .2s ease-in-out;
	font-size: 14px;
	line-height: 17px;
	text-transform: uppercase;
	border-radius: 5px;
	height: 30px;
	//width: 180px;
	padding: 0 15px;
	cursor: pointer;
	margin-left: 10px;
		
	&:hover {
		color: white;
		background-color: ${props => props.theme.darkBlue};
	}
`;

export const MapButtons = ({
	toggleMap, 
	isMap, 
	isShowCompareButton, 
	onOpenCompare, 
	sort,
	setSort,
	onMobileExtsOpen
}) => {
	const { isDestop } = useDevice();
	const { t } = useTranslation();

	const sorts = [
		{Name: t('Рекомендуемая'), Code: 'DEFAULT'},
		{Name: t('По стоимости'), Code: 'COST'},
		{Name: t('По расстоянию'), Code: 'DISTANCE'},
	];
	
	// const handleClick = e => e.preventDefault();

	return (
		<Container>
			<div className='mobile button white height30' onClick={onMobileExtsOpen}>
				{t('Фильтры')}
			</div>

			<div className='button grad height30' onClick={toggleMap}>
				{isDestop && t('Отели')} {isMap ? t('списком') : t('На карте')}
			</div>

			<div className='filler desktop' />

			{isShowCompareButton && <CompareButton className='desktop' onClick={onOpenCompare}>{t('Сравнить выбранные')}</CompareButton>}

			<Select2
				value={sort}
				items={sorts}
				onChange={setSort}
			/>
		</Container>
	);
};