/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useHotelSearchStore } from 'stores';
import {
	ExtAmenities,
	ExtDistance,
	ExtFreesale,
	ExtName,
	ExtPrice,
	ExtStars,
	ExtPayment,
} from './';

const Container = styled.div`
	border-top: 1px solid ${props => props.theme.lightGray};
	margin-top: 20px;

	.mainTitle {
		text-transform: uppercase;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: ${props => props.theme.darkBlue};
		padding-bottom: 10px !important;
		border-bottom: 1px solid ${props => props.theme.lightGray};

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		> div {
			width: 20px;
			height: 20px;
			cursor: pointer;
		}
	}

	.title, .smallTitle {
		color: ${props => props.theme.darkBlue};
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 10px;
	}

	.smallTitle { font-weight: normal; margin-bottom: 10px; }
	
	.title {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		
		> div:nth-child(n + 2) { margin-left: 8px; }
	}

	> div > div {
		border-bottom: 1px solid ${props => props.theme.lightGray};
		padding: 20px 0;

		.fromTo {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			color: ${props => props.theme.darkBlue};

			input {
				border: 1px solid ${props => props.theme.darkBlue};
				outline: none;
				background-color: white;
				padding: 0 10px;
				font-family: ${props => props.theme.pt};
				font-size: 16px;
				line-height: 21px;
				height: 40px;
				width: 100px;
			}
		}
	}
`;

export const Exts = () => {
	const {
		params: {
			filters: {
				exts = []
			}
		},
		setParams,
	} = useHotelSearchStore();

	!exts.includes('payment') && exts.push('payment');
	!exts.includes('name') && exts.push('name');
	!exts.includes('freesale') && exts.push('freesale');
	!exts.includes('price') && exts.push('price');
	!exts.includes('stars') && exts.push('stars');
	!exts.includes('amenities') && exts.push('amenities');
	!exts.includes('distance') && exts.push('distance');

	const onChangeSort = e => setParams(prev => {
		const items = [...exts];
		const source = items.splice(e.source.index, 1)[0];
		items.splice(e.destination.index, 0, source);

		return {
			...prev,
			filters: {
				...prev.filters,
				exts: [...items]
			}
		};
	});

	return (
		<Container>
			<DragDropContext onDragEnd={onChangeSort}>
				<Droppable droppableId='exts'>
					{(provided, snapshot) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{exts.filter(i => ['name', 'freesale', 'price', 'stars', 'amenities', 'distance'].includes(i)).map((i, index) => (
								<Draggable draggableId={i} index={index} key={i}>
									{provided => {
										if (i === 'payment') return <ExtPayment provided={provided} />;
										if (i === 'name') return <ExtName provided={provided} />;
										if (i === 'freesale') return <ExtFreesale provided={provided} />;
										if (i === 'price') return <ExtPrice provided={provided} />;
										if (i === 'stars') return <ExtStars provided={provided} />;
										if (i === 'amenities') return <ExtAmenities provided={provided} />;
										if (i === 'distance') return <ExtDistance provided={provided} />;

										return null;
									}}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</Container>
	);
};