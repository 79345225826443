import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import noPhoto from 'images/no_photo.svg';
import { useHotelSearchStore } from 'stores';
import {
	Button,
	HotelSubways,
	HotelTitleWithStars,
	HotelTransport,
	Sprite,
} from 'components';
import { Room } from './Room';
import { declines } from 'utils';
import { Link } from 'react-router-dom';

const Container = styled.table`
	width: 100%;
	display: table;
	table-layout: fixed;
	position: relative;

	col {
		&.col1 { width: 60px; }
		&.col2 { width: 120px; }
		&.col4 { width: 30%; }
		&.col5 { width: 30%; }
		&.col6 { width: 20px; }
	}

	.clickable {
		cursor: pointer;
	}

	tr {
		&.first {
			border-top: 1px solid #E4E4E4;

			td {
				padding-top: 10px;
			}
		}

		&.last {
			td {
				padding-bottom: 10px;
			}
		}

		&.lastLine {
			border-bottom: 1px solid #E4E4E4;
		}

		&.other {
			td {
				padding-top: 5px;
			}

			svg {
				margin-left: 5px;
			}

			&.isOpen {
				svg {
					transform: rotate(180deg);
				}
			}
		}

		&.hotelName {
			
			td {
				padding-bottom: 10px;
				padding-top: 20px;
				vertical-align: baseline;
			}
		}

		&.rommName {
			height: 60px;
			background-color: #E4E4E4;

			td {
				height: 60px;
				background-color: #E4E4E4;
				
				img {
					height: 60px;
					width: 60px;
				}
			}
		}

		&.compact {
			.buttons {
				margin-top: 0 !important;
				justify-content: flex-end;
			}
		}
	}

	td {
		/* border: 1px solid red; */
		/* overflow: hidden; */
		text-overflow: ellipsis;
		vertical-align: top;
		height: 30px;

		img {
			vertical-align: text-bottom;
			/* margin-right: 5px; */

			display: inline !important;
		}

		&.info {
			padding-left: 20px;

			h6 {
				margin: 0 0 5px 0 !important;
				display: flex;
			}
		}

		.buttonAsLink {
			font-weight: 700 !important;
		}

		&.roomPhoto {
			line-height: 0;

			* {
				line-height: 0;
			}

			img {
				width: 60px;
				height: 60px;
				object-position: center;
				object-fit: contain;
			}
		}

		&.roomName {
			background-color: #E4E4E4;
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			color: ${props => props.theme.darkBlue};
			padding-left: 10px;
			vertical-align: middle;
		}

		&.roomParams {
			background-color: #E4E4E4;
			text-align: right;
			vertical-align: middle;
			padding-right: 10px;

			> * {
				flex-grow: 0;
				flex-shrink: 1;
				display: inline;
				color: #333;
				font-size: 16px;
				line-height: 21px;
				font-family: ${props => props.theme.pt};

				svg {
					vertical-align: text-top;
				}
			}
		}

		&.bit {
			/* height: 40px; */
			vertical-align: middle;
			/* padding: 5px 0; */
			font-size: 16px;
			line-height: 21px;
			color: #333;
			font-family: ${props => props.theme.pt};

			&.bold {
				font-weight: bold;
			}

			&.orange {
				color: #FF6B00;
			}

			svg {
				vertical-align: bottom;
				margin-right: 5px;
			}
		}

		&.price {
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: ${props => props.theme.darkBlue};
			vertical-align: middle;
			text-align: right;
			white-space: nowrap;

			span {
				margin-right: 10px;
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				color: #4F7EA7;
			}
		}

		&.commission {
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: #00C0FF;
			vertical-align: middle;
			text-align: right;

			svg {
				vertical-align: bottom;
				margin-right: 5px;
			}
		}

		&.order {
			text-align: right;
			vertical-align: middle;
			/* padding-bottom: 10px; */
			height: 40px;
			
			.components__button {
				width: 200px;
				margin: 0 0 0 auto;
				position: relative;

				svg {
					position: absolute;
					right: 10px;
					top: 8px;
				}
			}
		}

		&.spacer {
			height: 20px !important;
			vertical-align: middle;

			&:before {
				content: '';
				width: 100%;
				height: 0;
				display: block;
				border-bottom: 1px solid ${props => props.theme.lightGray};
			}
		}

		&.bold {
			font-weight: bold;
		}

		&.blue {
			color: ${props => props.theme.darkBlue};
		}
	}
`;

const Photo = styled.a`
	width: 180px;
	height: 180px;
	position: relative;
	line-height: 0;
	background-color: #E4E4E4;
	display: flex;
	flex: 0 0 180px;
	margin-bottom: 10px;

	@media (max-width: 1279px) {
		width: 120px;
		height: 120px;
		flex: 0 0 120px;
		margin-right: 10px;
	}

	&.new:before {
		content: 'Новый';
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 30px;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		text-transform: uppercase;
		background-color: #5BB500;
		z-index: 10;			
	}
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		z-index: 9;
	}
	
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(3, 71, 129, 0.5);
		color: white;
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		height: 30px;
		user-select: none !important;
	}
	
	.noPhoto {
		width: 100%;
		height: 100%;
		background-image: url(${noPhoto});
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 20px 20px;
		margin-right: 10px;
		z-index: 9;
		user-select: none !important;
	}
`;

const Address = styled.div`
	font-weight: bold;
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: #333;
	margin: 0 0 5px 0 !important;
`;

const AddressLine = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 10px;
	margin-bottom: 10px;

	* {
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
	}

	.address {
		color: #8D8D8D;
		display: flex;
	}

	.center {
		color: ${props => props.theme.darkBlue};
		cursor: pointer;
		font-weight: 700;
		display: flex;
		gap: 5px;
		align-items: center;
		white-space: nowrap;
	}
`;

export const Hotel = ({ hotel }) => {
	const { t } = useTranslation();
	const [ isOpen, setOpen ] = useState(false);

	const {
		lastDestination: {
			Code: DestinationCode,
			Name: DestinationName,
			TypeCode: DestinationTypeCode,
			TownType: {
				Name: TownTypeName = ''
			} = {},
			ObjType: {
				Name: ObjTypeName = ''
			} = {},
			HotelType: {
				Name: HotelTypeName = ''
			} = {},
			// IsDirect = 2,
		},
		toggleCompare,
		compareHotels,
		params: {
			startDate,
			endDate,
			adults = 1,
			childs = 0,
			ages = [],
			view: {
				value: view = 'standart'
			} = {}	
		} = {},
		// getGroupCriterion,
		setHotelOnMap,
		setMap
	} = useHotelSearchStore();

	useEffect(() => {
		setOpen(view === 'extended');
	}, [view]);

	const {
		Code,
		Name,
		Stars,
		IsNewHotel = 2,
		Url,
		ImagesCount,
		DistanceToDestination = false,
		CityCentre,
		City: {
			Code: City,
			Name: CityName = ''
		} = {},
		Address: HotelAddress = '',
		Airport = '',
		RailwayStation = '',
		Objects: {
			Object: subways = []
		} = {},
		Position: {
			Latitude = 0,
			Longitude = 0
		} = {},
		// AvailabilityGroupCriterion = 2,
		// GroupCriterionList,
		HotelOfferList: {
			HotelOfferDetail: offers
		},
		TypeOfPlace: {
			Name: TypeOfPlaceName = ''
		} = {}
	} = hotel;

	const {
		Code: starsCode
	} = Stars;

	const distances = useMemo(() =>[
		(DestinationTypeCode === 2 && parseInt(DestinationCode) !== parseInt(City)) || (DestinationTypeCode !== 2 && parseInt(Code) !== parseInt(DestinationCode)) ? `${DistanceToDestination} ${t('км')} ${parseInt(DestinationTypeCode)  === 2 ? `${t('от центра')} ${TownTypeName?.toLowerCase()}` : `${t('от')} ${parseInt(DestinationTypeCode) === 4 ? ObjTypeName?.toLowerCase() : HotelTypeName?.toLowerCase()}`} ${DestinationName?.split(',')?.[0]}` : false,
		CityCentre,
	].filter(i => Boolean(i)), []);

	const rooms = useMemo(() => {
		return offers.slice(0, isOpen ? offers.length : 1).reduce((acc, cur) => {
			if (acc.find(i => parseInt(i.RoomCode) === parseInt(cur.RoomCode))) {
				return [...acc];
			} else {
				return [
					...acc,
					{
						...cur,
						rates: offers.filter(i => parseInt(i.RoomCode) === parseInt(cur.RoomCode))
					}
				];
			}
		}, []);
	}, [isOpen]);

	const toggle = () => setOpen(prev => !prev);

	const hotelBind = {
		Code,
		Name,
		Stars
	};

	const isCompare = compareHotels.includes(Code);

	const onMap = () => {
		setMap(true);
		setHotelOnMap({Code: Code, lat: Latitude, lng: Longitude});
	};

	return (
		<Container width='500px' border='1'>
			<colgroup>
				<col className='col1' width='60' />
				<col className='col2' width='120' />
				<col className='col3' />
				<col className='col4' width='30%' />
				<col className='col5' width='30%' />
				<col className='col6' width='20' />
			</colgroup>
			
			<tbody>
				<tr className='hotelName'>
					<td colSpan={5}>
						<HotelTitleWithStars starsCode={starsCode}>
							<Link to={`/hotel/${Code}/${startDate}/${endDate}/${adults}/${childs}/${ages.join(',')}`} target='_blank' rel="noreferrer">{Name}</Link>
						</HotelTitleWithStars>						
					</td>

					<td className='clickable' onClick={toggleCompare.bind(this, Code)}>
						<Sprite icon={isCompare ? 'ok' : 'compare'} />
					</td>
				</tr>

				{view === 'compact' && 
					<>
						<tr className='compact'>
							<td colSpan={4}>
								<h6 className="buttonAsLink" onClick={onMap} title={t('На карте')}>
									<Sprite icon='to_map' />

									{!!distances && distances.join(' • ')}
								</h6>
							</td>

							<td colSpan={2} />
						</tr>

						<tr>
							<td colSpan={6}>
								<Address className='notmobilephone'>{CityName}, {HotelAddress}</Address>
							</td>
						</tr>
					</>
				}

				{view !== 'compact' && <tr>
					<td colSpan={2} valign='top'>
						<Photo href={`/hotel/${Code}`} target='_blank' className={classnames({'new': parseInt(IsNewHotel) === 1})}>
							{Url ?
								<img src={Url && Url.replace('test-images', 'images')} alt='' width='180' height='180' /> :
								<div className='noPhoto'/>
							}
							{Url && ImagesCount > 1 && <span>{t('ещё')} {ImagesCount - 1} {t('фото')}</span>}
						</Photo>
					</td>

					<td colSpan={4} className='info'>						
						<AddressLine>
							<div className='center' onClick={onMap}>
								<Sprite icon='marker' title={t('На карте')} />
								{t('На карте')}
							</div>

							<div className='address'>
								{distances.join(' • ')} {TypeOfPlaceName} {CityName}, {HotelAddress}
							</div>
						</AddressLine>

						<HotelTransport title={t('Аэропорты')} string={Airport} className='desktop' />
						<HotelTransport title={t('Вокзалы')} string={RailwayStation} className='desktop' />
						<HotelSubways objects={subways} className='desktop' />
					</td>
				</tr>}

				{rooms.map(i => <Room key={i.RoomCode} room={i} hotel={hotelBind} isOpen={isOpen} isLast={offers.length === 1} />)}

				{offers.length > 1 && <tr className={classnames('other', { isOpen })}>
					<td colSpan={6}>
						<Button white small onClick={toggle}>
							{isOpen ? t('Скрыть') : `${t('Показать ещё')} ${declines(offers.length - 1, [t('вариант'), t('варианта'), t('вариантов')], true)}`}
							<Sprite icon='arrow_down' />
						</Button>
					</td>
				</tr>}
			</tbody>
		</Container>
	);
};