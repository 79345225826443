import { useInstance } from 'hooks';
import { useUserStore } from 'stores';
import { useTranslation } from 'react-i18next';

export const useCreateOrder = () => {
	const { t } = useTranslation();
	const { post } = useInstance();
	const { user } = useUserStore();

	const create = async () => {
		const acRequest = {
			BuyerContractRequest: {
				Action: {
					Name: 'GetDefaultInvoiceOptions'
				}
			}
		};

		const data = await post(acRequest);

		const {
			Id,
			UserName,
			Phone,
			Fax,
			Email,
			ContractNumber = '',
			ContractDate = '',
			// IsGain = 3,
			// Auxiliaries: {
			// 	Auxiliary = []
			// } = {},
			// CustomReferenceName = 2,
			// ReferenceNumberName = '',
			// AddField1Provide,
			// AddField1Name,
			// AddField2Provide,
			// AddField2Name,
			CurrencyCode = 2,
			CurrencyName = 'RUB',
			MarketCode,
			MarketName,
			UkCode,
			UkName,
			WhereToPayCode = 3,
			WhereToPayName,
			SourceOfBusinessCode,
			SourceOfBusinessName,
			AllowChangeSourceOfBusiness,
			EventCode,
			EventName,
			AllowChangeEvent,
			TypeContractCode,
			TypeContractName,
			CustomerCode,
			CustomerName,
			AutonomCode,
			AutonomName,
			VatLimitCode,
			VatLimitName,
		} = user ?? {};
	
		// const isGain = [parseInt(IsGain), Auxiliary.map(i => parseInt(i?.IsGain_Aux ?? 3))].flat().reduce((acc, cur) => cur < acc ? cur : acc, 3);
	
		const request = {
			OrderRequest: {
				Id: 0,
				RegistrationDate: '',
				DeadlineDate: '',
				ReferenceNumber: '',
				Price: '0.00',
				TravelAgencyCommission: '0.00',
				Penalty: '0.00',
				TotalAmount: '0.00',
				PaidAmount: '0.00',
				UnpaidAmount: '0.00',
				StartDate: '',
				EndDate: '',
				AdditionalInfo: '',
				IsGroundHandlingCode: 2,
				IsGroundHandlingName: t('Нет'),
				IsGroundHandlingAllowChange: 1,
				// CustomReferenceName,
				// ReferenceNumberName,
				// AddField1Provide,
				// AddField1Name,
				// AddField2Provide,
				// AddField2Name,
				CuratorCode: '',
				CuratorName: '',
				ReadOnly: {
					Code: 2
				},
				FinancialCondition: {
					Code: '',
					Name: '',
				},
				Currency: {
					Code: parseInt(CurrencyCode),
					Name: CurrencyName
				},
				Market: {
					Code: MarketCode,
					Name: MarketName	
				},
				LegalEntity: {
					Code: UkCode,
					Name: UkName
				},
				WhereToPay: {
					Code: parseInt(WhereToPayCode),
					Name: WhereToPayName
				},
				SourceOfBusiness: {
					Code: SourceOfBusinessCode,
					Name: SourceOfBusinessName,
					AllowChangeCode: AllowChangeSourceOfBusiness
				},
				Event: {
					Code: EventCode,
					Name: EventName,
					AllowChangeCode: AllowChangeEvent
				},
				TypeContract: {
					Code: TypeContractCode,
					Name: TypeContractName
				},
				Customer: {
					Code: CustomerCode ? CustomerCode : 9500001,
					Name: CustomerName ? CustomerName : t('Не имеет значения'),
					AllowModify: {
						Code: parseInt(TypeContractCode) === 3 ? 1 : 2
					}
				},
				PaymentControl: {
					Code: 2,
					Name: t('Нет'),
					Date: ''
				},
				TourGroup: {
					Code: 1,
					Name: ''
				},
				PurposeOfVisit: {
					Code: 2,
					Name: t('Туризм')
				},
				Country: {
					Code: 1,
					Name: ''
				},
				Status: {
					Code: '',
					Name: ''
				},
				Autonom: {
					Code: AutonomCode,
					Name: AutonomName
				},
				VatLimit: {
					Code: VatLimitCode,
					Name: VatLimitName
				},
				ContactPerson: {
					Code: Id,
					Name: UserName,
					Phone,
					Fax,
					Email
				},
				BuyerImplant: {},
				AccountDetails: {
					...(data?.AccountDetails ?? {}),
					// IsGain: {
					// 	Code: isGain
					// }
				},
				AccommodationList: {
					Accommodation: []
				},
				PassiveList: {
					Passive: []
				},
				TransportList: {
					Transport: []
				}
			}
		};
	
		request.OrderRequest.ContractNumber = ContractNumber;
		request.OrderRequest.ContractDate = ContractDate;

		return request;
	};

	return {
		create,
	};
};