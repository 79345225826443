import React, { useMemo, useState } from 'react';
import ru from 'react-phone-number-input/locale/ru.json';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const useRegister = () => {
	const { t } = useTranslation();
	const [ Email, setEmail ] = useState('');
	const [ UserName, setUserName ] = useState('');
	const [ Phone, setPhone ] = useState('');
	const [ personal, setPersonal ] = useState(false);

	const emailBind = {
		name: 'email',
		value: Email,
		onChange: e => setEmail(e.target.value),
	};

	const userNameBind = {
		name: 'userName',
		value: UserName,
		onChange: e => setUserName(e.target.value),
	};

	const phoneBind = {
		name: 'phone',
		value: Phone,
		onChange: e => setPhone(e.target.value),
	};

	const intPhoneBind = {
		value: Phone,
		onChange: setPhone,
		defaultCountry: 'RU',
		labels: ru,
		withCountryCallingCode: true,
		smartCaret: true,
		international: true,
		countryCallingCodeEditable: false,

	};

	const personalBind = {
		checked: personal,
		onChange: () => setPersonal(prev => !prev),
		label: <>{t('Я даю согласие на')} <Link to='/personal_data_policy' target='_blank'><span>{t('обработку персональных данных')}</span></Link></>,
	};

	const { isValid, invalidFields } = useMemo(() => {
		const invalidFields = [];

		if (UserName.trim().length < 2) {
			invalidFields.push(t('ФИО'));
		}

		if (!Email.trim().toLowerCase().match(re)) {
			invalidFields.push('E-mail');
		}

		if (!Phone || !isValidPhoneNumber(Phone)) {
			invalidFields.push(t('телефон'));
		}

		if (!personal) {
			invalidFields.push(t('согласие на обработку персональных данных'));
		}

		return {
			isValid: invalidFields.length === 0,
			invalidFields
		};
	}, [UserName, Email, Phone, personal]);

	const registerBind = {
		userNameBind,
		emailBind,
		phoneBind,
		intPhoneBind,
		personalBind,
		isValid,
		invalidFields,
		re,
	};

	return {
		registerBind,
	};
};