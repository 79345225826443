import React from 'react';
import styled from 'styled-components';
import { declines } from 'utils';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	
`;

const Title = styled.div`
	color: ${props => props.theme.darkBlue};
	font-size: 16px;
	line-height: 19px;
	margin-bottom: 10px;
	
	b {
		font-weight: 500;
	}
`;

const Items = styled.div`
	padding: 10px;
	max-height: 130px;
	overflow-y: auto;
	border: 1px solid ${props => props.theme.lightGray};
`;

const Item = styled.div`
	font-size: 14px;
	line-height: 18px;
	color: #333;
	font-family: ${props => props.theme.pt};
`;

export const Conditions = ({ items = [] }) => {
	const { t } = useTranslation();

	const conditionTitles = [t('условие'), t('условия'), t('условий')];

	return (
		<Container>
			{items.length > 0 && <Title>
				<b>{t('Особые условия отеля')}:</b> {items.length} {declines(items.length, conditionTitles)}
			</Title>}

			{items.length > 0 &&
				<Items>
					{items.map((i, key) => <Item key={key}>{key + 1}. {i.Text}</Item>)}
				</Items>
			}
		</Container>
	);
};