import React from 'react';
import styled from 'styled-components';

import checkboxEmpty from 'images/checkbox_empty.svg';
import checkboxFill from 'images/checkbox_fill.svg';
import starEmpty from 'images/star_empty.svg';
import starFill from 'images/star_fill.svg';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore } from 'stores';
import { Sprite } from 'components';

const ImgCircle = styled.img`
	margin-right: 5px;
`;

const ImgCircleEmpty = () => <ImgCircle src={starEmpty} alt='' />;
const ImgCircleFill = () => <ImgCircle src={starFill} alt='' />;


const Checkbox = styled.div`
	padding: 0 !important;
	height: 20px;
	position: relative;

	input { display: none; }

	label {
		position: absolute;
		margin: 0 !important;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		padding-left: 30px;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
		color: #333;

		&:before {
			content: '';
			display: inline-block;
			width: 20px;
			height: 20px;
			background-image: url(${checkboxEmpty});
			margin-right: 10px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	input:checked + label:before {
		background-image: url(${checkboxFill});
	}
`;


export const Stars = () => {
	const { t } = useTranslation();
	const {
		params: {
			filters: {
				stars = [1, 2, 3, 4, 5]
			}
		},
		setParams
	} = useHotelSearchStore();

	const changeStars = star => setParams(prev => {
		const items = [ ...prev.filters.stars ];
		const index = items.findIndex(i => i === star);
		index === -1 && items.push(star);
		index !== -1 && items.splice(index, 1);
		
		return {
			...prev,
			filters: {
				...prev.filters,
				stars: [...items]
			}
		};
	});
	
	const setAllStars = () => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			stars: prev.filters.stars.length === 5 ? [] : [1, 2, 3, 4, 5]
		}
	}));
	
	const isDirty = stars.length !== 5;
	const clear = () => setParams(prev => ({
		...prev,
		filters: {
			...prev.filters,
			stars: [1, 2, 3, 4, 5],
		}
	}));

	return (
		<div>
			<label>
				{t('Уровень')}
				{isDirty && 
					<div onClick={clear}>
						<Sprite icon='small_cross' />
					</div>
				}
			</label>

			<Checkbox>
				<input type="checkbox" checked={stars.length === 5} id="stars_any" onChange={setAllStars} />
				<label htmlFor="stars_any">{t('Любой')}</label>
			</Checkbox>

			{new Array(5).fill(null).map((i, key) => 
				<Checkbox key={key}>
					<input type="checkbox" checked={stars.includes(key + 1)} id={`stars_${key + 1}`} onChange={changeStars.bind(this, key + 1)} />
					<label htmlFor={`stars_${key + 1}`}>
						<ImgCircleFill />
						{key < 4 ? <ImgCircleFill /> : <ImgCircleEmpty/>}
						{key < 3 ? <ImgCircleFill /> : <ImgCircleEmpty/>}
						{key < 2 ? <ImgCircleFill /> : <ImgCircleEmpty/>}
						{key < 1 ? <ImgCircleFill /> : <ImgCircleEmpty/>}
					</label>
				</Checkbox>
			)}
		</div>
	);
};