import { useEffect, useMemo, useState } from 'react';
import { useInstance } from 'hooks';

export const useProduct = orderInfo => {
	const [ products, setProducts ] = useState([]);
	const { post } = useInstance();

	const requestProductProps = useMemo(() => {
		if (orderInfo.Id < 1 || orderInfo.AccommodationId < 1) {
			return {
				Hotel: orderInfo.Hotel,
				NumberOfGuests: orderInfo.NumberOfGuests,
				ArrivalDate: orderInfo.ArrivalDate,
				DepartureDate: orderInfo.DepartureDate,
				NumberOfExtraBedsAdult: orderInfo.NumberOfExtraBedsAdult,
				NumberOfExtraBedsChild: orderInfo.NumberOfExtraBedsChild,
				NumberOfExtraBedsInfant: orderInfo.NumberOfExtraBedsInfant,
				// Options: 'exceedcapacity',
				// Currency: orderInfo.Currency,
				WhereToPay: orderInfo.WhereToPay,
			};
		} else {
			return {
				Id: orderInfo.Id,
				AccommodationId: orderInfo.AccommodationId,
				Hotel: orderInfo.Hotel,
				NumberOfGuests: orderInfo.NumberOfGuests,
				ArrivalDate: orderInfo.ArrivalDate,
				DepartureDate: orderInfo.DepartureDate,
				NumberOfExtraBedsAdult: orderInfo.NumberOfExtraBedsAdult,
				NumberOfExtraBedsChild: orderInfo.NumberOfExtraBedsChild,
				NumberOfExtraBedsInfant: orderInfo.NumberOfExtraBedsInfant,
				// Options: 'exceedcapacity',
				// Currency: orderInfo.Currency,
				WhereToPay: orderInfo.WhereToPay,
			};
		}
	}, [
		orderInfo.Id,
		orderInfo.AccommodationId,
		orderInfo.Hotel,
		orderInfo.NumberOfGuests,
		orderInfo.ArrivalDate,
		orderInfo.DepartureDate,
		orderInfo.NumberOfExtraBedsAdult,
		orderInfo.NumberOfExtraBedsChild,
		orderInfo.NumberOfExtraBedsInfant,
		orderInfo.Currency,
		orderInfo.WhereToPay,
	]);

	// console.log(orderInfo.Currency, orderInfo, requestProductProps)

	const fetch = async () => {
		const request = {
			HotelProductRequest: requestProductProps
		};

		const data = await post(request);

		const details = {
			Currency: data?.Currency,
			Name: data?.Name,
			TypeOfPlace: data?.TypeOfPlace,
			City: data?.City,
			Country: data?.Country,		
		};

		const items = data?.HotelProductList?.HotelProductDetail?.map(i => ({
			...i,
			details: {
				...details,
				HotelPricingDetail: {
					Price: i?.Price,
					RackPrice: i?.RackPrice,
					TravelAgencyCommission: i?.TravelAgencyCommission,
					RateDescription: i?.RateDescription,
					PriceDescription: {
						Days: {
							Day: []
						}
					},
					DiscountOffer: {
						Code: 2
					}
				},
			}
		})) ?? [];

		/*
		if (items.length === 0) {
			setOrderInfo(prev => ({
				...prev,
				Product: 0
			}));
		} else if (items.find(i => i.Code === orderInfo.Product) === undefined) {
			setOrderInfo(prev => ({
				...prev,
				Product: items[0].Code
			}));
		}
		*/

		setProducts(items);
	};

	useEffect(() => {
		orderInfo.Hotel > 0 && fetch();
	}, [
		requestProductProps
	]);

	return {
		products
	};
};