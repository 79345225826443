import React, { createContext, useContext, useState } from 'react';

const useCart = () => {
	const [ id, setId ] = useState(parseInt(sessionStorage.getItem('orderId') ?? 0));

	const setOrder = id => {
		sessionStorage.setItem('orderId', parseInt(id));
		setId(parseInt(id));
	};

	const clear = () => {
		sessionStorage.removeItem('orderId');
		setId(0);
	};

	return {
		id,
		setOrder,
		clear
	};
};

const CartContext = createContext(null);

const CartProvider = ({ children }) => {
	const cartStore = useCart();

	return (
		<CartContext.Provider value={cartStore}>
			{children}
		</CartContext.Provider>
	);
};

const useCartStore = () => {
	const context = useContext(CartContext);

	if (context === undefined) {
		throw new Error('useCartStore must be used within a CartProvider');
	}

	return context;
};

export {
	CartProvider,
	useCartStore,
};