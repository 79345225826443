import { useState } from 'react';
import { useInstance } from 'hooks';

export const useCompare = (hotels, altHotels) => {
	const { post } = useInstance();
	const [ compareHotels, setCompareHotels ] = useState([]);
	const [ lastCompare, setLastCompare ] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [ isShowCompare, setShowCompare ] = useState(false);
	const [ hotelsInfo, setHotelsInfo ] = useState([]);

	const toggleCompare = id => setCompareHotels(prev => {
		const ids = [...prev];
		const index = ids.findIndex(i => i === parseInt(id));

		if (index === -1) {
			ids.push(id);

			const hotel = hotels.find(i => parseInt(i.Code) === parseInt(id));
			const altHotel = altHotels.find(i => parseInt(i.Code) === parseInt(id));

			setLastCompare({
				Code: altHotel?.Code ?? hotel.Code,
				Name: altHotel?.Name ?? hotel.Name,
				Url: altHotel?.Url ?? hotel.Url,
				City: `${altHotel?.TypeOfPlace?.Name ?? hotel.TypeOfPlace.Name} ${altHotel?.City?.Name ?? hotel.City.Name}`
			});
		} else {
			ids.splice(index, 1);
		}

		return [...ids];
	});

	const toggleShowCompare = () => setShowCompare(prev => !prev);

	const fetchHotel = async id => {
		const request = {
			HotelRequest: {
				ActionDescription: {
					Parameters: {
						HotelCode: id,
						UseObjects: 1,
						Padezh: 1,
						BestEasternMember: 1,
						PublishHotelPhone: 1
					}
				}
			}
		};

		const data = await post(request);

		if (data?.Hotel?.[0]) {
			const h = data?.Hotel?.[0];

			setHotelsInfo(prev => {
				return [
					...prev,
					{
						Code: h.Code,
						Name: h.Name,
						Description: h.Description,
						Airport: h.Airport,
						RailwayStation: h.RailwayStation,
						RiverPort: h.RiverPort,
						SeaPort: h.SeaPort,
						Underground: h.Underground,
						Images: {
							Image: h.Images.Image.slice(0, 1)
						},
						Stars: h.Stars,
						TypeOfPlace: h.TypeOfPlace,
						City: h.City,
						Address: h.Address,
						CityCentre: h.CityCentre,
						GroupCriterionList: h.GroupCriterionList,
						Amenities: h.Amenities.Amenity.map(i => i.Code)
					}
				];
			});
		}
	};

	const fetchHotels = async () => {
		await Promise.all(compareHotels.map(async id => fetchHotel(id)));
	};

	const onClear = () => setCompareHotels([]);

	const addCompareMessageProps = {
		last: lastCompare,
		isVisible: lastCompare !== false,
		onClose: () => setLastCompare(false),
		count: compareHotels.length,
		onOpenCompare: () => {
			setLastCompare(false);
			fetchHotels();
		},
		onClear,
	};

	const modalCompareProps = {
		hotels: hotelsInfo,
		onClose: () => setHotelsInfo([]),
		onRemove: id => {
			setCompareHotels(prev => {
				const index = prev.findIndex(i => i === id);
				if (index !== -1) {
					prev.splice(index, 1);
				}
				return [...prev];
			});

			setHotelsInfo(prev => {
				const index = prev.findIndex(i => i.Code === id);
				if (index !== -1) {
					prev.splice(index, 1);
				}
				return [...prev];
			});
		}
	};

	return {
		compareHotels,
		toggleCompare,
		lastCompare,
		toggleShowCompare,
		addCompareMessageProps,
		modalCompareProps,
		fetchHotels
	};
};