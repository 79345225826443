import React from 'react';
import styled from 'styled-components';
import { Modal2 } from 'modals';
import { Select2 } from 'forms';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';

const Container = styled.div`
	h1, h5 {
		color: ${props => props.theme.darkBlue};
		margin-bottom: 10px;

		&.disable {
			color: #8D8D8D;
		}
	}

	> .textInput, > .select2 {
		margin-bottom: 10px;
	}

	p {
		margin: 10px 0 20px 0;
		color: #333;
		font-size: 16px;
		line-height: 21px;
		font-family: ${props => props.theme.pt};
	}
`;

export const ModalPricelist = ({ priceListProps }) => {
	const { t } = useTranslation();
	const {
		countries,
		country,
		setCountry,
		cities,
		city,
		setCity,
		hotels,
		hotel,
		setHotel,
		email,
		onChangeEmail,
		onSend,
		isOpen,
		onClose,
	} = priceListProps;

	const countriesProps = {
		items: countries,
		value: country,
		onChange: setCountry,
		name: 'country',
		placeholder: t('Введите название или выберите его из предложенных')
	};

	const citiesProps = {
		items: cities,
		value: city,
		onChange: setCity,
		name: 'city',
		placeholder: t('Введите название или выберите его из предложенных'),
		readOnly: !country,
		style: !country ? {
			border: '1px solid #8D8D8D'
		} : {}
	};

	const hotelsProps = {
		items: hotels,
		value: hotel,
		onChange: setHotel,
		name: 'hotel',
		placeholder: t('Введите название или выберите его из предложенных'),
		readOnly: !city,
		style: !city ? {
			border: '1px solid #8D8D8D'
		} : {}
	};

	const emailProps = {
		className: 'textInput',
		value: email,
		onChange: onChangeEmail,
		placeholder: ''
	};

	return (
		<Modal2 isVisible={isOpen} onClose={onClose} width={560} fixed>
			<Container>
				<h1>{t('Ценовое предложение')}</h1>

				<h5>{t('Страна')}</h5>
				<Select2 {...countriesProps} />

				<h5 className={classnames({disable: !country})}>{t('Город')}</h5>
				<Select2 {...citiesProps} />

				<h5 className={classnames({disable: !city})}>{t('Отель')}</h5>
				<Select2 {...hotelsProps} />

				<h5>E-mail</h5>
				<input {...emailProps} />
				
				<p>{t('Ценовое приложение будет сформировано в формате электронной таблицы Excel.')}</p>

				<Button gradient onClick={onSend}>
					{t('Получить по E-mail')}
				</Button>
			</Container>
		</Modal2>
	);
};