import i18n from 'i18next';
import Backend from 'i18next-http-backend';
// import Backend from 'i18next-fs-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'ru',
		react: {
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i', 'span', 'p', 'img', 'a'],
			transWrapTextNodes: ''
		},
		debug: false,
		detection: {
			order: ['localStorage'],
			lookupLocalStorage: 'lang',
			// order: ['queryString', 'cookie'],
			// cache: ['cookie'],
		},
		interpolation: {
			escapeValue: false,
		},
		nsSeparator: ':::',
		keySeparator: '::',
		// saveMissing: true,
		// backend: {
		// 	loadPath: '/locales/{{lng}}/translation.json',
		// 	addPath: '/locales/{{lng}}/missing.json'
		// }
	});

export default i18n;
