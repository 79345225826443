import React from 'react';
import { useTranslation } from 'react-i18next';
import { Sprite } from 'components';
import { declines } from 'utils';
import { useDevice } from 'hooks';

export const BedType = ({
	BedTypes,
	Element
}) => {
	const { t } = useTranslation();
	const { isDesktop } = useDevice();

	if (!isDesktop) return null;

	if (BedTypes.length === 1 && BedTypes[0].Bed.length > 1) {
		return (
			<Element>
				<Sprite icon='bed' />
				{t('несколько кроватей')}
			</Element>
		);
	}

	if (BedTypes.length === 1 && BedTypes[0].Bed.length === 1) {
		return (
			<Element>
				<Sprite icon='bed' />
				{BedTypes[0].Bed[0].Name.replace(/^(.+?)(\s\d.+)/, '$1')}
			</Element>
		);
	}

	if (BedTypes.length > 1) {
		return (
			<Element>
				<Sprite icon='bed' />
				{declines(BedTypes.length, [t('вариант'), t('варианта'), t('вариантов')], true)}
			</Element>
		);
	}

	return null;
};
