import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores';
import { InputPassword } from 'forms2';
import { Sprite } from 'components';

const ForgetButton = styled.div`
	color: ${props => props.theme.darkBlue};
	font-family: ${props => props.theme.pt};
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	cursor: pointer;
`;

const Login = ({ Container, Actions, setSection }) => {
	const { t } = useTranslation();
	const [ UserId, setUserId ] = useState('');
	const [ Password, setPassword ] = useState('');
	const { login } = useUserStore();

	const userIdBind = {
		type: 'text',
		value: UserId,
		onChange: e => setUserId(e.target.value),
		placeholder: 'E-mail',
	};

	const passwordBind = {
		value: Password,
		onChange: e => setPassword(e.target.value),
		placeholder: t('Пароль'),
	};

	const onLogin = e => {
		e.preventDefault();
		e.stopPropagation();

		login({ BuyerId: 'RO1BAND', UserId, Password });
	};

	const agentUrl = /^\d{3}-/.test(window.location.hostname) ? window.location.hostname.replace(/^(\d{3})-.+$/, 'https://$1-b2b.acase.ru') : 'https://b2b.acase.ru';
	const supplierUrl = /^\d{3}-/.test(window.location.hostname) ? window.location.hostname.replace(/^(\d{3})-.+$/, 'https://$1.acase.ru/online/ru-login.jsp?IsCompany=4') : 'https://academservice.ru/online/ru-login.jsp?IsCompany=4';
	const innerUrl = /^\d{3}-/.test(window.location.hostname) ? window.location.hostname.replace(/^(\d{3})-.+$/, 'https://$1.acase.ru/online/ru-login.jsp?IsCompany=3') : 'https://academservice.ru/online/ru-login.jsp?IsCompany=3';

	return (
		<Container onSubmit={onLogin} className='login'>
			<h4>{t('Вход в личный кабинет для частных лиц')}</h4>

			<Actions>
				{t('Войти')} {t('или')}
				<div onClick={() => setSection('register')}>{t('Зарегистрироваться')}</div>
			</Actions>

			<input {...userIdBind} />

			<InputPassword {...passwordBind} />

			<ForgetButton onClick={() => setSection('forget')}>{t('Забыли пароль?')}</ForgetButton>

			<button type='submit' className='button grad height30'>{t('Войти')}</button>

			<h4>{t('Вход в личный кабинет для других типов пользователей')}</h4>

			<a className='button white height30 bold' href={agentUrl} target='_blank' rel="noreferrer">
				{t('Для агентов')} — b2b.acase.ru
				<Sprite icon='arrow_right_top' />
			</a>

			<a className='button white height30 bold' href={supplierUrl} target='_blank' rel="noreferrer">
				{t('Для отелей')} — academservice.ru
				<Sprite icon='arrow_right_top' />
			</a>

			<a className='button white height30 bold' href={innerUrl} target='_blank' rel="noreferrer">
				{t('Для сотрудников')} — academservice.ru
				<Sprite icon='arrow_right_top' />
			</a>
		</Container>
	);
};

export default Login;