import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Modal } from './';
import iconUser from 'images/icon_error_user.svg';
import { Button } from 'components';

const Container = styled.div`
	text-align: center;

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 10px;

		& > *:not(:last-child) {
			margin-right: 10px;
		}
	}
`;

const Icon = styled.img`
	height: 30px;
	width: 30px;
	object-fit: contain;
	object-position: center;
	margin-bottom: 15px;
`;

const Description = styled.div`
	font-family: ${props => props.theme.pt};
	color: #333;
	font-size: 18px;
	line-height: 23px;
	margin-bottom: 10px;
`;

export const ModalConfirm = ({ isVisible = false, text = '', confirmText = false, cancelText = false, onConfirm = () => false, onCancel = () => false }) => {
	const { t } = useTranslation();

	return (
		<Modal isVisible={isVisible} onClose={onCancel}>
			<Container className='flex column alignCenter'>
				<Icon src={iconUser} alt='' />
				
				<Description>
					{text}
				</Description>
				
				<div className='row'>
					<Button gradient width='240' onClick={onConfirm}>
						{confirmText ? confirmText : t('Да')}
					</Button>

					<Button gradient width='240' onClick={onCancel}>
						{cancelText ? cancelText : t('Нет')}
					</Button>
				</div>
			</Container>
		</Modal>
	);
};