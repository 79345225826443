import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorStore, useMsgStore } from 'stores';
import { useInstance } from 'hooks';

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Forget = ({ Container, Actions, setSection }) => {
	const { t } = useTranslation();
	const [ UserId, setUserId ] = useState('');
	const { setUserError } = useErrorStore();
	const { show } = useMsgStore();
	const { post } = useInstance();

	const userIdBind = {
		type: 'text',
		value: UserId,
		onChange: e => setUserId(e.target.value),
		placeholder: 'E-mail',
	};

	const onForget = async e => {
		e.preventDefault();
		e.stopPropagation();

		if (!re.test(UserId.trim())) {
			setUserError('Введите корректный E-mail');
			return;
		}

		const request = {
			UserRequest: {
				Data: {
					Action: 'SendPassword',
					Email: UserId.trim(),
				}
			}
		};

		const data = await post(request);
		if (data) {
			show(t('Пароль отправлен Вам на электронную почту.'));
			setSection('login');
		}
	};

	return (
		<Container onSubmit={onForget}>
			<h4>{t('Восстановление пароля')}</h4>
			<p>{t('Пожалуйста, укажите адрес электронной почты, мы отправим на него ссылку для восстановления пароля')}:</p>
			<input {...userIdBind} />

			<Actions>
				<div onClick={() => setSection('login')}>{t('Войти')}</div>
				{t('или')}
				<div onClick={() => setSection('register')}>{t('Зарегистрироваться')}</div>
			</Actions>

			<button type='submit' className='button grad height30'>{t('Отправить')}</button>
		</Container>
	);
};

export default Forget;