import React from 'react';
import styled from 'styled-components';
import { useCartStore } from 'stores';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	background-color: #00C0FF;
	margin-bottom: 10px;
	color: white;
	font-size: 16px;
	line-height: 19px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	text-align: center;
`;

export const OrderInfo = () => {
	const { t } = useTranslation();
	const { id } = useCartStore();

	if (id === 0) return null;

	return (
		<Container>
			{t('Добавить услугу')}<br/>
			{t('в заказ')} {id}
		</Container>
	);
};