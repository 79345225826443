/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useInstance } from 'hooks';
import { useParams } from 'react-router-dom';
import { useCreateAccommodation, useCreateOrder } from 'utils';
import { parseDate, today } from 'utils/dayjs';
import { useCitizenships } from 'hooks/useOrderLibs';

const useHotelBooking = () => {
	const [ order, setOrder ] = useState(false);
	const [ accommodation, setAccommodation ] = useState(false);
	const [ products, setProducts ] = useState(false);
	const [ hotel, setHotel ] = useState(false);
	const [ pricing, setPricing ] = useState(false);

	const { post } = useInstance();
	const params = useParams();
	const { create } = useCreateOrder();
	const { createAccommodation } = useCreateAccommodation();
	const { citizenships } = useCitizenships();

	const fetchProducts = async params => {
		const {
			Hotel,
			ArrivalDate,
			DepartureDate,
			NumberOfGuests,
			NumberOfExtraBedAdult: NumberOfExtraBedsAdult,
			NumberOfExtraBedChild: NumberOfExtraBedsChild,
			NumberOfExtraBedInfant: NumberOfExtraBedsInfant
		} = params;

		const request = {
			HotelProductRequest: {
				Hotel,
				NumberOfGuests,
				ArrivalDate,
				DepartureDate,
				NumberOfExtraBedsAdult,
				NumberOfExtraBedsChild,
				NumberOfExtraBedsInfant
			}
		};

		const data = await post(request);
		return data?.HotelProductList?.HotelProductDetail ?? [];
	};

	const fetchHotel = async () => {
		if (!accommodation?.Hotel?.Code) return;

		const request = {
			HotelRequest: {
				ActionDescription: {
					Parameters: {
						HotelCode: accommodation?.Hotel?.Code,
						UseObjects: 1,
						Padezh: 1,
						BestEasternMember: 1,
						PublishHotelPhone: 1
					}
				}
			}
		};

		const data = await post(request);
		setHotel(data?.Hotel?.[0] ?? false);
	};

	useEffect(() => {
		const func = async () => {
			if (params?.accommodationId === undefined && params?.Product !== undefined) {
				const order = (await create())?.OrderRequest;
				setOrder(order);

				const products = await fetchProducts(params);
				setProducts(products);
				const offer = products.find(i => parseInt(i.Code) === parseInt(params?.Product));

				const accommodation = await createAccommodation({...params, offer});
				setAccommodation(accommodation);

			}
		};
		func();
	}, []);

	useEffect(() => {
		fetchHotel();
	}, [accommodation?.Hotel?.Code]);

	useEffect(() => {
		const func = async () => {
			if (order?.Id === undefined || accommodation?.Id === undefined) return;

			const request = {
				HotelPricingRequest2: {
					ExtraMeals: {
						ExtraMeal: accommodation?.ExtraMeals?.ExtraMeal ?? []
					},
					Hotel: accommodation?.Hotel?.Code,
					ProductCode: accommodation?.Product?.Code,
					ArrivalDate: accommodation?.ArrivalDate,
					DepartureDate: accommodation?.DepartureDate,
					ArrivalTime: accommodation?.ArrivalTime,
					DepartureTime: accommodation?.DepartureTime,
					Meal: accommodation?.Meal,
					AccommodationId: accommodation?.Id,
					Id: order?.Id,
					NumberOfGuests: accommodation?.NumberOfGuests ?? 1,
					NumberOfExtraBedsAdult: accommodation?.NumberOfExtraBedsAdult ?? 0,
					NumberOfExtraBedsChild: accommodation?.NumberOfExtraBedsChild ?? 0,
					NumberOfExtraBedsInfant: accommodation?.NumberOfExtraBedsInfant ?? 0,
				}
			};

			const data = await post(request);
			if (data) setPricing(data);
		};
		func();
	}, [
		order?.Id,
		accommodation?.Id, 
		accommodation?.Hotel?.Code,
		accommodation?.ArrivalDate, 
		accommodation?.ArrivalTime, 
		accommodation?.DepartureDate, 
		accommodation?.DepartureTime, 
		accommodation?.Product?.Code,
		accommodation?.NumberOfGuests,
		accommodation?.NumberOfExtraBedsAdult,
		accommodation?.NumberOfExtraBedsChild,
		accommodation?.NumberOfExtraBedsInfant,
		accommodation?.Meal,
		JSON.stringify(accommodation?.ExtraMeals?.ExtraMeal)
	]);

	const orderId = order?.Id ?? 0;
	const accommodationId = accommodation?.Id ?? -99999;

	const isLoaded = order?.Id !== undefined && accommodationId !== undefined && hotel !== false;
	
	const offer = useMemo(() => {
		if (!Array.isArray(products)) return false;
		if (accommodation?.Product?.Code === undefined) return false;
		return products.find(i => parseInt(i.Code) === parseInt(accommodation?.Product?.Code));
	}, [accommodation?.Product?.Code]);
	
	const allowModify = offer !== false && parseInt(order?.ReadOnly?.Code) !== 1 && parseInt(accommodation?.AllowModify?.Code ?? 1) === 1;

	const currency = order?.Currency?.Name;
	
	const isHourRate = useMemo(() => parseInt(offer?.IsHourRate ?? 2) === 1, [offer?.IsHourRate]);

	return {
		order,
		accommodation,
		setAccommodation,
		products,
		hotel,
		offer,
		pricing,

		orderId,
		accommodationId,
		isLoaded,
		allowModify,
		currency,
		citizenships,
		isHourRate,
	};
};

const HotelBookingContext = createContext(null);

const HotelBookingProvider = ({ children }) => {
	const hotelBookingStore = useHotelBooking();

	return (
		<HotelBookingContext.Provider value={hotelBookingStore}>
			{children}
		</HotelBookingContext.Provider>
	);
};

const useHotelBookingStore = () => {
	const context = useContext(HotelBookingContext);

	if (context === undefined) {
		throw new Error('useHotelBookingStore must be used within a HotelBookingProvider');
	}

	return context;
};

export {
	HotelBookingProvider,
	useHotelBookingStore,
};