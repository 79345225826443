import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toMoney } from 'utils';
import { useHotelSearchStore, useUserStore } from 'stores';
import { Button } from 'components';
import { PenaltyList } from '../';

const Container = styled.table`
	border-bottom: 1px solid ${props => props.theme.lightGray};
	width: 100%;
	margin-top: 10px;

	*:not(.button) {
		&.orange, &.green {
			color: #FF6B00;
			font-family: ${props => props.theme.pt};
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
		}

		&.orange { color: #FF6B00 !important; }
		&.blue { color: #004685 !important; }
		&.gray { color: #80A2C2 !important; }
		&.lightBlue { color: #00C0FF !important; }
		&.green { color: #5BB500 !important; }
	}

	td {
		padding-bottom: 5px;
	}

	td > div {
		width: calc(100vw - 40px);
		overflow: hidden;
		text-overflow: ellipsis;
	}

	td.specialOffer > div {
		color: ${props => props.theme.orange};
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		white-space: nowrap;
		max-width: calc(100vw - 60px);
	}

	td.rateDescription > div {
		color: ${props => props.theme.darkBlue};
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		font-weight: 700;
		line-height: normal;
		white-space: nowrap;
		max-width: calc(100vw - 60px);
	}

	td.penalty {
		color: #5BB500;
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		font-weight: 400;
		line-height: 24px; /* 150% */

		&.isPenalty {
			color: ${props => props.theme.orange};
		}
	}

	td.meal {
		color: #333;
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		font-weight: 700;
		line-height: normal;
		padding-bottom: 20px;
	}

	td.totalPrice {
		position: relative;
		text-align: right;
		vertical-align: middle;

		img {
			margin-right: 5px;
		}
		
		span {
			color: ${props => props.theme.darkBlue};
			font-family: ${props => props.theme.pt};
			font-size: 16px;
			font-weight: 700;
			line-height: normal;
			display: inline-block;
			vertical-align: top;
		}
	}

	td.rackPrice {
		color: ${props => props.theme.placeholderColor};
		text-align: right;
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		font-weight: 400;
		line-height: normal;
	}

	td.commission {
		position: relative;
		text-align: right;
		vertical-align: middle;

		img {
			margin-right: 5px;
		}
		
		span {
			color: #00C0FF;
			font-family: ${props => props.theme.pt};
			font-size: 16px;
			font-weight: 700;
			line-height: normal;
			display: inline-block;
			vertical-align: top;
		}
	}

	td.vat, td.whereToPay, td.availability {
		color: #333;
		text-align: right;
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		font-weight: 400;
		line-height: normal;

		b {
			color: inherit;
			font-family: inherit;
			font-size: inherit;
			line-height: inherit;
			font-weight: 700;
		}
	}
	
	td.button {
		padding: 5px 0 0 0 !important;
		

		.button {
			width: 100%;
		}
	}
`;

const Offer = ({ offer, CurrencyName, hotelCode, datesBind, onShowDetails }) => {
	const { t } = useTranslation();
	const { allowTabs } = useUserStore();

	const {
		params: {
			startDate = datesBind?.startDate,
			endDate = datesBind?.endDate,
		} = {},
		onShowDetails: handleShowDetails = onShowDetails,
	} = useHotelSearchStore() ?? {};

	const {
		Code,
		MealIncludedInPrice: {
			Code: MealIncludedInPrice,
			Name: MealIncludedInPriceName
		},
		WhereToPay: {
			Name: WhereToPayName
		},
		DiscountOffer: {
			Code: DiscountOffer = 2,
			Name: DiscountOfferName
		} = {},
		TotalPrice,
		Availability: {
			Code: Availability,
			Name: AvailabilityName,
			Allotment: {
				Rooms
			}
		},
		RateDescription = '',
		NumberOfGuests,
		NumberOfExtraBedAdult,
		NumberOfExtraBedChild,
		NumberOfExtraBedInfant,
		PenaltyRuleList: {
			PenaltyRule = []
		} = {}
	} = offer;

	const buttonBind = {
		orange: Availability === 4,
		gradient: Availability !== 4,
		to: `/order/${hotelCode}/${Code}/${startDate}/${endDate}/${NumberOfGuests}/${NumberOfExtraBedAdult}/${NumberOfExtraBedChild}/${NumberOfExtraBedInfant}`,
		target: Number(sessionStorage?.getItem('orderId') ?? 0) === 0 && allowTabs ? '_blank' : '_self',
	};

	return (
		<Container>
			<tbody>
				{Number(DiscountOffer) === 1 && <tr>
					<td className='specialOffer'>
						<div>
							{DiscountOfferName}
						</div>
					</td>
				</tr>}

				{Number(DiscountOffer) !== 1 && !!RateDescription && <tr>
					<td className='rateDescription'>
						<div>
							{RateDescription}
						</div>
					</td>
				</tr>}

				<tr>
					<td>
						{PenaltyRule.length > 0 && <PenaltyList items={PenaltyRule} CurrencyName={CurrencyName} />}
					</td>
				</tr>

				<tr>
					<td className='meal'>
						{MealIncludedInPrice > 1
							? MealIncludedInPriceName
							: t('Без питания')
						}
					</td>
				</tr>

				<tr>
					<td className='totalPrice' onClick={handleShowDetails.bind(this, offer, hotelCode)}>
						<img src='/images/icon_warning.png' />
						<span>{toMoney(TotalPrice)} {CurrencyName}</span>
					</td>
				</tr>

				<tr>
					<td className='whereToPay'>
						{WhereToPayName}
					</td>
				</tr>

				<tr>
					<td className='availability'>
						{Availability === 4 && t('Под запрос')}
						{Availability !== 4 && Rooms !== '' && <>{t('В наличии')}: <b>{Rooms}</b></>}
						{Availability !== 4 && Rooms === '' && AvailabilityName.toLowerCase().indexOf('свободная продажа') === -1 && <>{t('В наличии')}: <b>{AvailabilityName}</b></>}
						{Availability !== 4 && Rooms === '' && AvailabilityName.toLowerCase().indexOf('свободная продажа') !== -1 && <>{t('В наличии')}: <b>{t('много')}</b></>}
					</td>
				</tr>

				<tr>
					<td className='button'>
						<Button {...buttonBind}>{t('Бронировать')}</Button>
					</td>
				</tr>
			</tbody>
		</Container>
	);
};

export default Offer;