import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import arrowIcon from 'images/arrow_down.svg';

const Container = styled.div`
	position: relative;
	//border: 1px solid red;
	//z-index: 30;
	display: flex;
	flex-direction: row;
	align-items: center;
	
	&:not(.readOnly):after {
		content: '';
		display: block;
		width: 12px;
		flex-shrink: 0;
		height: 7px;
		background: url(${arrowIcon}) center/cover no-repeat;
		position: absolute;
		right: 10px;
		top: 18px;
		cursor: pointer;
	}
`;

const Label = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: ${props => props.theme.darkBlue};
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 10px;
`;

const FieldContainer = styled.div`
	height: 40px;
	//min-width: 360px;
	position: relative;
`;

const FieldInput = styled.input`
	height: 100%;
	width: 100%;
	border: none;
	outline: none;
	padding: 0 10px;
	font-size: 16px;
	line-height: 21px;
	color: ${props => props.theme.darkBlue};
	font-family: ${props => props.theme.pt};
	
	&::placeholder {
		color: #4F7EA7;
	}
`;

const Items = styled.ul`
	background-color: white;
	color: ${props => props.theme.darkBlue};
	position: absolute;
	top: 38px;
	left: -1px;
	right: -1px;
	border: 1px solid ${props => props.theme.darkBlue};
	max-height: 200px;
	overflow-y: auto;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	z-index: 30;
	
	&.active {
		opacity: 1;
		visibility: visible;
	}
`;

const Item = styled.ul`
	height: 40px;
	padding: 0 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	color: ${props => props.theme.darkBlue};
	background-color: white;
	transition: color .2s ease-in-out, background-color .2s ease-in-out;
	cursor: pointer;
	font-size: 16px;
	line-height: 21px;
	font-family: ${props => props.theme.pt};
		
	&:nth-of-type(n + 2):before {
		content: '';
		display: block;
		height: 1px;
		position: absolute;
		top: 0;
		left: 10px;
		right: 10px;
		background-color: ${props => props.theme.lightGray};
	}

	&:hover, &.active {
		background-color: ${props => props.theme.lightBlue};
		color: white;
		
		&:before {
			display: none;
		}
	}
`;

export const Combobox = (
	{
		style = {},
		labelStyle = {},
		inputStyle = {height: '40px', width: '600px', border: `1px solid ${props => props.theme.darkBlue}`},
		label = false,
		placeholder = '',
		onQueryChange = () => null,
		onChange = () => null,
		items = [],
		value = null,
		fieldValue = 'Code',
		fieldLabel = 'Name',
		filter = item => item,
		readOnly = false
	}) => {
	const resultsDiv = useRef();
	const inputRef = useRef();
	const [ index, setIndex ] = useState(0);
	const [ isFocused, setFocused ] = useState(false);
	const [ query, setQuery ] = useState('');
	// const currentItem = items.find(i => i.value.toString() === value.toString()) ?? items[0];


	const handleQueryChange = e => {
		setQuery(e.target.value);
		onQueryChange(e.target.value);
	};

	// const filteredItems = query.trim().length < 2 ? [] : items.filter(item => filter(item, query));
	const filteredItems = items.filter(item => filter(item, query));

	const handleMouseEnter = key => setIndex(key);

	const handleKeyupNeedle = e => {
		if (e.keyCode === 38) {
			setIndex(index > 0 ? index - 1 : filteredItems.length - 1);
		}

		if (e.keyCode === 40) {
			setIndex(index < filteredItems.length - 1 ? index + 1 : 0);
		}

		if (e.keyCode === 13) {
			if (resultsDiv.current.children[index] !== undefined) {
				resultsDiv.current.children[index].click();
				inputRef.current.blur();
			}
		}
	};

	useEffect(() => {
		if (resultsDiv.current && resultsDiv.current.children[index] !== undefined) {
			resultsDiv.current.children[index].scrollIntoView({block: 'nearest', behavior: 'smooth'});

			const scrollTop = resultsDiv.current.scrollTop;
			const parentHeight = resultsDiv.current.getBoundingClientRect().height;
			const childTop = resultsDiv.current.children[index].offsetTop;
			const childHeight = resultsDiv.current.children[index].getBoundingClientRect().height;

			if (scrollTop + parentHeight < childTop + childHeight) {
				resultsDiv.current.children[index].scrollIntoView({block: 'nearest', behavior: 'smooth'});
			}
		}
	}, [index]);

	const onFocus = () => setFocused(true);
	const onBlur = () => setFocused(false);

	const text = value !== null && !isFocused && items.find(i => i[fieldValue].toString() === value.toString()) !== undefined ? items.find(i => i[fieldValue].toString() === value.toString())[fieldLabel] : query;

	if (readOnly) {
		return (
			<Container style={style} className={classnames({readOnly: readOnly})}>
				<FieldContainer style={inputStyle}>
					<FieldInput 
						defaultValue={text} 
						readOnly
					/>
				</FieldContainer>
			</Container>
		);
	}

	return (
		<Container style={style}>
			{label && <Label style={labelStyle}>{label}</Label>}

			<FieldContainer style={inputStyle}>
				<FieldInput value={text} onChange={handleQueryChange} ref={inputRef} onKeyUp={handleKeyupNeedle} onFocus={onFocus} onBlur={onBlur} placeholder={placeholder} />

				{filteredItems.length > 0 && <Items ref={resultsDiv} className={classnames({'active': isFocused})}>
					{filteredItems.map((i, key) => <Item key={key} onMouseEnter={handleMouseEnter.bind(this, key)} className={classnames({'active': key === index})} onClick={onChange.bind(this, i)}>{i[fieldLabel]}</Item>)}
				</Items>}
			</FieldContainer>

			{/*
			<Items>
				{items.map((i, key) => (
					<React.Fragment key={key}>
						<input type='radio' name={name} id={`${name}__${i.value}`} value={i.value} onChange={onChange} />
						<label htmlFor={`${name}__${i.value}`}>{i.label}</label>
					</React.Fragment>
				))}
			</Items>
			*/}
		</Container>
	);
};