import React from 'react';

export const theme = {
	darkBlue: '#004685',
	lightBlue: '#0270CB',
	lightGray: '#D5E3F0',
	orange: '#FF6B00',
	inputBorderColor: '#004685',
	placeholderColor: '#80A2C2',
	pt: "'PT Sans', sans-serif !important",
	rubik: "'Rubik', sans-serif !important",
};

export const mapKey = 'AIzaSyBoO5bO7h87PFrFiV2mejukKsWNWv71pj0';

export const timeout = 12 * 60 * 60 * 1000;

export const hotelAmenityIcons = {
	'84': 'dvd-some.svg',
	'85': 'dvd-all.svg',
	'92': 'dvd-req.svg',
	'15': 'wifi-bus.svg',
	'59': 'wifi-some.svg',
	'66': 'wifi-pub.svg',
	'60': 'wifi-all.svg',
	'18': 'tickets.svg',
	'100': 'eng-speak.svg',
	'20': 'farm.svg',
	'17': 'car-rent.svg',
	'69': 'atm.svg',
	'10': 'bar.svg',
	'27': 'pool_in.svg',
	'26': 'pool-out.svg',
	'34': 'billiards.svg',
	'35': 'bowling.svg',
	'78': 'bath-some.svg',
	'79': 'bath-all.svg',
	'16': 'taxi.svg',
	'96': 'hypo-some.svg',
	'97': 'hypo-all.svg',
	'88': 'hypo-req.svg',
	'80': 'shower-some.svg',
	'81': 'shower-all.svg',
	'44': 'change-some.svg',
	'43': 'change-all.svg',
	'37': 'casino.svg',
	'103': 'lockers.svg',
	'47': 'aircond-some.svg',
	'48': 'aircond-all.svg',
	'46': 'roomserv-24.svg',
	'14': 'business-24.svg',
	'98': 'kithen-some.svg',
	'99': 'kithen-all.svg',
	'8': 'elevator.svg',
	'93': 'massage.svg',
	'53': 'minibar-some.svg',
	'54': 'minibar-all.svg',
	'40': 'disabled.svg',
	'39': 'nosmoking.svg',
	'41': 'connecting.svg',
	'45': 'roomserv-9-6.svg',
	'13': 'business-9-6.svg',
	'104': 'business.svg',
	'3': 'parking.svg',
	'2': 'parking-pay.svg',
	'63': 'tv-pay-some.svg',
	'64': 'tv-pay-all.svg',
	'33': 'beach-2.svg',
	'4': 'luggage.svg',
	'74': 'floor-some.svg',
	'75': 'floor-all.svg',
	'7': 'exchange.svg',
	'42': 'pets.svg',
	'9': 'restaurant.svg',
	'24': 'beauty-salon.svg',
	'25': 'sauna.svg',
	'49': 'safe-some.svg',
	'50': 'safe-all.svg',
	'6': 'safe-reception.svg',
	'70': 'spa.svg',
	'21': 'gift-shop.svg',
	'94': 'slippers-some.svg',
	'95': 'slippers-all.svg',
	'87': 'slippers-req.svg',
	'51': 'phone-some.svg',
	'52': 'phone-all.svg',
	'31': 'tennis-in.svg',
	'30': 'tennis-out.svg',
	'101': 'transfer.svg',
	'23': 'gym.svg',
	'102': 'facilities.svg',
	'19': 'babysitter.svg',
	'22': 'newspaper.svg',
	'11': 'laundray.svg',
	'12': 'dryclean.svg',
	'57': 'hypo-some.svg',
	'58': 'iron-all.svg',
	'90': 'iron-req.svg',
	'55': 'dryer-some.svg',
	'56': 'dryer-all.svg',
	'89': 'dryer-req.svg',
	'76': 'bathrobe-some.svg',
	'77': 'bathrobe-all.svg',
	'86': 'bathrobe-req.svg',
	'82': 'kettle-some.svg',
	'83': 'kettle-all.svg',
	'91': 'kettle-req.svg',
	'72': 'el-lock-some.svg',
	'73': 'el-lock-all.svg',
};

export const colors = {
	darkBlue: '#004685',
	lightBlue: '#0270CB',
	lightGray: '#D5E3F0',
};

export const IconDownloadDoc = () =>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M18.71 17.6845C18.8873 17.4989 18.9862 17.2523 18.9862 16.9959C18.9862 16.7395 18.8873 16.4929 18.71 16.3073C18.5226 16.1214 18.2692 16.0171 18.005 16.0171C17.7408 16.0171 17.4874 16.1214 17.3 16.3073L16.96 16.6466V13.972C16.96 13.7073 16.8546 13.4534 16.6671 13.2663C16.4796 13.0791 16.2252 12.974 15.96 12.974C15.6948 12.974 15.4404 13.0791 15.2529 13.2663C15.0654 13.4534 14.96 13.7073 14.96 13.972V16.5867L14.71 16.3372C14.5226 16.1513 14.2692 16.047 14.005 16.047C13.7408 16.047 13.4874 16.1513 13.3 16.3372C13.1116 16.5215 13.0038 16.7726 13 17.0358C12.9988 17.1604 13.023 17.2839 13.0711 17.3989C13.1192 17.5138 13.1903 17.6178 13.28 17.7045L15.28 19.7005C15.4646 19.8885 15.7162 19.9961 15.98 19.9999C16.2444 20.0041 16.4997 19.9036 16.69 19.7204L18.71 17.6845Z" fill="#004685"/>
		<path d="M16 0H2C1.73478 0 1.48043 0.105146 1.29289 0.292307C1.10536 0.479468 1 0.733312 1 0.997998V18.962C1 19.2266 1.10536 19.4805 1.29289 19.6676C1.48043 19.8548 1.73478 19.96 2 19.96H11C11.2652 19.96 11.5196 19.8548 11.7071 19.6676C11.8946 19.4805 12 19.2266 12 18.962C12 18.6973 11.8946 18.4434 11.7071 18.2563C11.5196 18.0691 11.2652 17.964 11 17.964H3V1.996H15V9.97998C15 10.2447 15.1054 10.4985 15.2929 10.6857C15.4804 10.8728 15.7348 10.978 16 10.978C16.2652 10.978 16.5196 10.8728 16.7071 10.6857C16.8946 10.4985 17 10.2447 17 9.97998V0.997998C17 0.733312 16.8946 0.479468 16.7071 0.292307C16.5196 0.105146 16.2652 0 16 0Z" fill="#004685"/>
		<path d="M12 14.97H6C5.73478 14.97 5.48043 14.8648 5.29289 14.6777C5.10536 14.4905 5 14.2367 5 13.972C5 13.7073 5.10536 13.4534 5.29289 13.2663C5.48043 13.0791 5.73478 12.974 6 12.974H12C12.2652 12.974 12.5196 13.0791 12.7071 13.2663C12.8946 13.4534 13 13.7073 13 13.972C13 14.2367 12.8946 14.4905 12.7071 14.6777C12.5196 14.8648 12.2652 14.97 12 14.97Z" fill="#004685"/>
		<path d="M12 10.978H6C5.73478 10.978 5.48043 10.8728 5.29289 10.6857C5.10536 10.4985 5 10.2447 5 9.97998C5 9.71529 5.10536 9.46145 5.29289 9.27429C5.48043 9.08713 5.73478 8.98198 6 8.98198H12C12.2652 8.98198 12.5196 9.08713 12.7071 9.27429C12.8946 9.46145 13 9.71529 13 9.97998C13 10.2447 12.8946 10.4985 12.7071 10.6857C12.5196 10.8728 12.2652 10.978 12 10.978Z" fill="#004685"/>
		<path d="M12 6.98598H6C5.73478 6.98598 5.48043 6.88084 5.29289 6.69368C5.10536 6.50652 5 6.25267 5 5.98799C5 5.7233 5.10536 5.46946 5.29289 5.2823C5.48043 5.09513 5.73478 4.98999 6 4.98999H12C12.2652 4.98999 12.5196 5.09513 12.7071 5.2823C12.8946 5.46946 13 5.7233 13 5.98799C13 6.25267 12.8946 6.50652 12.7071 6.69368C12.5196 6.88084 12.2652 6.98598 12 6.98598Z" fill="#004685"/>
	</svg>
;

export const IconHistory = () =>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14 13C13.8431 13.0019 13.6883 12.964 13.55 12.89L9 10.62V5C9 4.73478 9.10536 4.48043 9.29289 4.29289C9.48043 4.10536 9.73478 4 10 4C10.2652 4 10.5196 4.10536 10.7071 4.29289C10.8946 4.48043 11 4.73478 11 5V9.38L14.45 11.11C14.6855 11.2299 14.864 11.4381 14.9465 11.6892C15.0289 11.9403 15.0086 12.2138 14.89 12.45C14.8069 12.6149 14.6798 12.7536 14.5227 12.8507C14.3656 12.9478 14.1847 12.9994 14 13Z" fill="#004685"/>
		<path d="M10 0C7.6247 0.00178672 5.3276 0.84902 3.52 2.39V2C3.52 1.73478 3.41464 1.48043 3.22711 1.29289C3.03957 1.10536 2.78522 1 2.52 1C2.38672 0.997911 2.25436 1.02249 2.13071 1.07229C2.00707 1.12209 1.89463 1.19611 1.8 1.29C1.62375 1.48352 1.53041 1.73843 1.54 2V4.82C1.54 5.08522 1.64536 5.33957 1.83289 5.52711C2.02043 5.71464 2.27478 5.82 2.54 5.82H5.37C5.63522 5.82 5.88957 5.71464 6.07711 5.52711C6.26464 5.33957 6.37 5.08522 6.37 4.82C6.37 4.55478 6.26464 4.30043 6.07711 4.11289C5.88957 3.92536 5.63522 3.82 5.37 3.82H4.89C6.28882 2.65874 8.04146 2.00857 9.85921 1.97658C11.677 1.9446 13.4514 2.53271 14.8902 3.64403C16.329 4.75536 17.3464 6.32361 17.7747 8.09046C18.2031 9.8573 18.0169 11.7174 17.2467 13.3642C16.4766 15.0111 15.1685 16.3465 13.5379 17.1505C11.9073 17.9545 10.0515 18.1792 8.27622 17.7874C6.50089 17.3957 4.91194 16.411 3.77111 14.9954C2.63027 13.5799 2.0056 11.818 2 10C2 9.73478 1.89464 9.48043 1.70711 9.29289C1.51957 9.10536 1.26522 9 1 9C0.734784 9 0.48043 9.10536 0.292893 9.29289C0.105357 9.48043 0 9.73478 0 10C0 11.9778 0.58649 13.9112 1.6853 15.5557C2.78412 17.2002 4.3459 18.4819 6.17317 19.2388C8.00043 19.9957 10.0111 20.1937 11.9509 19.8079C13.8907 19.422 15.6725 18.4696 17.0711 17.0711C18.4696 15.6725 19.422 13.8907 19.8079 11.9509C20.1937 10.0111 19.9957 8.00043 19.2388 6.17317C18.4819 4.3459 17.2002 2.78412 15.5557 1.6853C13.9112 0.58649 11.9778 0 10 0Z" fill="#004685"/>
	</svg>
;

export const IconMoney = () =>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M20 7.98032C19.9939 6.15405 19.3644 4.38475 18.2159 2.96587C17.0674 1.547 15.4689 0.563815 13.6856 0.179401C11.9023 -0.205012 10.0413 0.0324444 8.41131 0.852385C6.78132 1.67233 5.48027 3.02548 4.72398 4.6874C3.5413 5.21863 2.50532 6.02969 1.70524 7.05075C0.905159 8.0718 0.364963 9.27225 0.131166 10.5487C-0.102632 11.8252 -0.0230235 13.1394 0.363135 14.3782C0.749293 15.6171 1.43043 16.7433 2.34789 17.6601C3.26535 18.5769 4.39164 19.2567 5.62977 19.6409C6.8679 20.0252 8.18078 20.1024 9.45533 19.8659C10.7299 19.6295 11.9279 19.0864 12.9463 18.2834C13.9647 17.4805 14.7728 16.4418 15.3012 15.2568C16.6992 14.623 17.8855 13.6 18.7186 12.3098C19.5518 11.0196 19.9966 9.51667 20 7.98032ZM8.00312 17.9892C6.81674 17.9892 5.65701 17.637 4.67057 16.9771C3.68413 16.3172 2.9153 15.3793 2.46129 14.282C2.00728 13.1847 1.88849 11.9772 2.11994 10.8123C2.35139 9.64737 2.92269 8.57732 3.76159 7.73746C4.60048 6.8976 5.6693 6.32565 6.83289 6.09393C7.99647 5.86222 9.20256 5.98114 10.2986 6.43567C11.3947 6.8902 12.3315 7.65992 12.9906 8.64749C13.6498 9.63506 14.0016 10.7961 14.0016 11.9839C14.0016 13.5766 13.3696 15.1041 12.2447 16.2303C11.1197 17.3565 9.59401 17.9892 8.00312 17.9892ZM16.001 12.4543C16.001 12.2941 16.001 12.144 16.001 11.9839C16.001 9.86025 15.1584 7.82362 13.6585 6.32199C12.1586 4.82037 10.1243 3.97677 8.00312 3.97677H7.54324C8.08372 3.35588 8.74599 2.85292 9.48888 2.49916C10.2318 2.14541 11.0394 1.94842 11.8616 1.92043C12.6837 1.89245 13.5029 2.03407 14.268 2.33649C15.0332 2.63892 15.7281 3.09568 16.3094 3.67839C16.8908 4.2611 17.3462 4.9573 17.6474 5.72371C17.9486 6.49012 18.089 7.31035 18.0601 8.13343C18.0312 8.95651 17.8335 9.76483 17.4792 10.5082C17.125 11.2515 16.6219 11.9139 16.001 12.4543Z" fill="currentColor"/>
		<path d="M6.32501 8.9812H5.51501C5.48116 8.98974 5.45013 9.00699 5.42501 9.03125L3.62501 10.4125C3.58593 10.4469 3.55807 10.4922 3.54501 10.5426C3.53337 10.5677 3.52734 10.595 3.52734 10.6227C3.52734 10.6503 3.53337 10.6776 3.54501 10.7027L3.87501 11.1331C3.90936 11.1722 3.95465 11.2001 4.00501 11.2132C4.02682 11.2238 4.05075 11.2293 4.07501 11.2293C4.09926 11.2293 4.1232 11.2238 4.14501 11.2132L5.36501 10.2824V14.426C5.34976 14.4715 5.34976 14.5207 5.36501 14.5662C5.387 14.5761 5.41087 14.5812 5.43501 14.5812C5.45915 14.5812 5.48301 14.5761 5.50501 14.5662H6.23501C6.27997 14.584 6.33004 14.584 6.37501 14.5662C6.39025 14.5207 6.39025 14.4715 6.37501 14.426V9.2014C6.39025 9.15594 6.39025 9.10673 6.37501 9.06127C6.36533 9.0308 6.34813 9.00326 6.32501 8.9812Z" fill="currentColor"/>
		<path d="M11.7219 10.2323C11.6387 9.97941 11.5024 9.74732 11.322 9.55169C11.14 9.34982 10.9143 9.19233 10.6622 9.09128C10.3526 8.99168 10.0264 8.95427 9.70241 8.98118C9.37843 8.97216 9.05581 9.02656 8.75265 9.14133C8.5005 9.24237 8.27479 9.39986 8.09283 9.60173C7.91929 9.80063 7.78678 10.0319 7.70293 10.2823C7.60287 10.5447 7.54876 10.8223 7.54297 11.1031C7.54297 11.2532 7.54297 11.4133 7.54297 11.5935V12.114C7.54297 12.2941 7.54297 12.4543 7.54297 12.5944C7.55948 12.8771 7.61326 13.1565 7.70293 13.4251C7.77901 13.6768 7.90862 13.909 8.08283 14.1057C8.26247 14.3082 8.48429 14.4688 8.73266 14.5761C9.05116 14.6993 9.39113 14.7572 9.7324 14.7463C10.0736 14.7556 10.4132 14.6978 10.7321 14.5761C10.9698 14.4648 11.1815 14.3048 11.3537 14.1066C11.5259 13.9084 11.6547 13.6762 11.7319 13.4251C11.8149 13.1556 11.8619 12.8763 11.8718 12.5944C11.8718 12.4543 11.8718 12.2941 11.8718 12.114V11.5935C11.8718 11.4133 11.8718 11.2532 11.8718 11.1031C11.8642 10.8071 11.8136 10.5138 11.7219 10.2323ZM10.7221 12.0339C10.7221 12.194 10.7221 12.3542 10.7221 12.5043C10.7313 12.8324 10.6445 13.1559 10.4722 13.4351C10.3807 13.5497 10.263 13.6405 10.129 13.6997C9.99494 13.759 9.84861 13.785 9.70241 13.7754C9.56776 13.7748 9.43498 13.7438 9.31386 13.6849C9.19274 13.626 9.08637 13.5407 9.00259 13.4351C8.82682 13.157 8.73644 12.8334 8.74266 12.5043C8.74266 12.3542 8.74266 12.194 8.74266 12.0339V11.5635C8.74266 11.4033 8.74266 11.2432 8.74266 11.1031C8.733 10.7798 8.82383 10.4616 9.00259 10.1923C9.08603 10.0733 9.19919 9.97833 9.33077 9.91686C9.46234 9.85539 9.60773 9.82956 9.75239 9.84195C9.89988 9.82996 10.048 9.85584 10.1827 9.91714C10.3174 9.97844 10.4342 10.0731 10.5222 10.1923C10.6934 10.4645 10.7803 10.7814 10.7721 11.1031C10.7721 11.2432 10.7721 11.4033 10.7721 11.5635L10.7221 12.0339Z" fill="currentColor"/>
	</svg>
;

export const IconCopy = () =>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M17 0H7C6.20435 0 5.44129 0.31607 4.87868 0.87868C4.31607 1.44129 4 2.20435 4 3V4H3C2.20435 4 1.44129 4.31607 0.87868 4.87868C0.31607 5.44129 0 6.20435 0 7L0 17C0 17.7956 0.31607 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.31607 17.7956 0 17 0V0ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V7C2 6.73478 2.10536 6.48043 2.29289 6.29289C2.48043 6.10536 2.73478 6 3 6H4V13C4 13.7956 4.31607 14.5587 4.87868 15.1213C5.44129 15.6839 6.20435 16 7 16H14V17ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H7C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13V3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V13Z" fill="#004685"/>
	</svg>
;

export const IconDownload = () =>
	<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M11.7235 6.72C11.901 6.53402 12 6.28694 12 6.03C12 5.77306 11.901 5.52598 11.7235 5.34C11.5359 5.15375 11.2822 5.04921 11.0177 5.04921C10.7532 5.04921 10.4995 5.15375 10.3119 5.34L7.00808 8.68V3.34V1C7.00808 0.734784 6.9026 0.48043 6.71485 0.292893C6.5271 0.105357 6.27246 0 6.00693 0C5.74141 0 5.48677 0.105357 5.29902 0.292893C5.11126 0.48043 5.00579 0.734784 5.00579 1V8.61L1.71201 5.36C1.52443 5.17375 1.27069 5.06921 1.0062 5.06921C0.74171 5.06921 0.487967 5.17375 0.300389 5.36C0.125614 5.5297 0.0188236 5.75726 4.45531e-05 6C-0.00118468 6.12483 0.0230341 6.24861 0.0712263 6.36379C0.119418 6.47898 0.190576 6.58316 0.280366 6.67L5.28611 11.67C5.37743 11.7711 5.48847 11.8526 5.61242 11.9093C5.73637 11.9661 5.87062 11.9969 6.00693 12C6.27162 12.0042 6.52719 11.9035 6.71775 11.72L11.7235 6.72Z" fill="#004685"/>
	</svg>
;

export const IconDropdown = () =>
	<svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1.30273 1.33325L6.30273 6.33325L11.3027 1.33325" stroke="#004685" strokeWidth="2" strokeLinecap="round"/>
	</svg>
;
