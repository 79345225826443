import { useState } from 'react';
import useRequest from './useRequest';
import i18n from 'i18next';

const stars = [
	'',
	'https://images.acase.ru/signs_images/Level 5 grey.svg',
	'https://images.acase.ru/signs_images/Level 4 grey.svg',
	'https://images.acase.ru/signs_images/Level 3 grey.svg',
	'https://images.acase.ru/signs_images/Level 2 grey.svg',
	'https://images.acase.ru/signs_images/Level 1 grey.svg',
];

export const useFetchMarkers = hotelCode => {
	const [ map, setMap ] = useState(null);
	const Language = i18n?.language ?? 'ru';

	const {
		bounds: {
			ne: {
				lat: neLat = false,
				lng: neLng = false,
			} = {},
			sw: {
				lat: swLat = false,
				lng: swLng = false,
			} = {},
		} = {},
		zoom = false
	} = map ?? {};

	const {
		data
	} = useRequest(
		{
			MapRequest: {
				Language,
				ActionDestinationSearch: {
					Parameters: {
						Zoom: zoom,
						Bounds: {
							NorthEast: {
								Lat: neLat,
								Lng: neLng
							},
							SouthWest: {
								Lat: swLat,
								Lng: swLng
							}
						},
						// Context: "003"
						Context: '004',
					}
				}
			}
		},
		{
			manual: !(map?.bounds?.ne?.lat > 0),
			refreshDeps: [
				zoom,
				neLat,
				neLng,
				swLat,
				swLng,
			],
			parser: data => {
				if (!data) return [];
				let markers = [];
				data.DestinationGroupList.DestinationGroup.forEach(g => {
					g.DestinationList.Destination.forEach(i => {
						markers.push({
							...i,
							icon: getIconUri(g.DestinationTypeCode, g.ObjectTypeCode, i.Code),
							type: Number(i.Code) === Number(hotelCode) ? 'selected' : g.ObjectTypeCode,
							stars: g.DestinationTypeCode === 3 && g.ObjectTypeCode === 9500001 ? stars[i.StarCode] : false,
							id: `${g.DestinationTypeCode}-${g.ObjectTypeCode}`,
						});
					});
				});
				return markers;
			},
		}
	);

	const getIconUri = (destinationTypeCode, objectTypeCode, code) => {
		const icon = destinationTypeCode + "-" + objectTypeCode + (destinationTypeCode === 3 && objectTypeCode === 9500001 ? (Number(code) === Number(hotelCode) ? '-p' : '-b') : '');

		return "https://images.acase.ru/gmapIcons/" + icon + ".png";
	};

	const onMapLoaded = () => null;

	const onMapChange = map => setMap(map);

	return {
		markers: Array.isArray(data) ? data : [],
		onMapChange,
		onMapLoaded,
	};
};