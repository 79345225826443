import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { declines } from 'utils';
import { Combobox } from 'forms2';

const Container = styled.div`
	margin: 20px 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	
	> div {
		//border: 1px solid red;
		margin-bottom: 10px;
		width: calc((100% - 20px) / 2);
	}

	.select {
		border: 1px solid ${props => props.theme.darkBlue};
		margin-right: 0;
		height: 40px,
	}

	.checkbox {
		color: ${props => props.theme.darkBlue};
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
	}
`;

const Label = styled.div`
	color: ${props => props.theme.darkBlue};
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;

	@media (max-width: 659px) {
		margin-bottom: 10px;
		margin-top: 10px;
	}
`;

const NotAllowed = styled.div`
	height: 40px;
	border-top: 1px solid ${props => props.theme.lightGray};
	border-bottom: 1px solid ${props => props.theme.lightGray};
	display: flex;
	flex-direction: row;
	align-items: center;
	font-family: ${props => props.theme.pt};
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	color: #333;
`;

export const Hours = ({ pricing, orderInfo, setOrderInfo }) => {
	const { t } = useTranslation();

	const {
		HotelPricingDetail: {
			MaxHours = '',
			StartTime = '',
		} = {},
		EarlyCheckInList: {
			EarlyCheckIn = []
		} = {}
	} = pricing;

	const {
		ArrivalTime = ''
	} = orderInfo;

	const options = EarlyCheckIn.map(i => i.Time).filter((value, index, self) => self.indexOf(value) === index).map(i => ({
		value: i,
		label: i
	}));

	const value = ArrivalTime ? {value: ArrivalTime, label: ArrivalTime} : {value: StartTime, label: StartTime};

	const onChange = e => setOrderInfo(prev => {
		let [ hours, minutes ] = e.value.split(':');
		hours = parseInt(hours) + parseInt(MaxHours);
		if (hours > 23) hours = hours - 24;

		return {
			...prev,
			ArrivalTime: e.value,
			DepartureTime: `${hours < 10 ? `0${parseInt(hours)}` : hours}:${minutes}`
		};
	});

	const bind = {
		options,
		value,
		onChange
	};

	return (
		<Container>
			<Label>{t('Заезд')}</Label>
			<Label />

			<Combobox {...bind} />
			<NotAllowed>{t('не более')} {declines(MaxHours, [t('часа'), t('часов'), t('часов')], true)}</NotAllowed>
		</Container>
	);
};