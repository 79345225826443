import React from 'react';
import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';
import { Ranges } from 'components';
import { useHotelSearchStore } from 'stores';
import { parseDate, format } from 'utils/dayjs';

const Container = styled.div`

	.dates .label {
		text-align: center;
	}

	.dates {
		border: 1px solid ${props => props.theme.darkBlue} !important;
		.isOpen {
			left: 0px !important;
			top: calc(100% - 1px) !important;
		}
	}
`;

export const DatesInput = () => {
	// const { t } = useTranslation();
	const {
		params: {
			startDate,
			endDate
		},
		setParams
	} = useHotelSearchStore();

	const bind = {
		startDate,
		endDate,
		onChange: dates => {
			let [ startDate, endDate ] = dates;

			if (!parseDate(startDate).isBefore(parseDate(endDate))) endDate = parseDate(startDate).add(1, 'day').format(format);

			setParams(prev => ({
				...prev,
				startDate,
				endDate
			}));
		},
	};

	return (
		<Container className='dates_input_container'>
			{/* {label && <label>{t('Даты')}</label>} */}
			<Ranges {...bind} />
		</Container>
	);
};