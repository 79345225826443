import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Month from './Month';
import MonthSelect from './MonthSelect';
import { parseDate, format, today } from 'utils/dayjs';
import { declines } from 'utils';
import { useDevice } from 'hooks';
import YearSelect from './YearSelect';

const Container = styled.div`
	border: 1px solid ${props => props.theme.inputBorderColor};
	padding: 0 10px;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: stretch;
`;

const Months = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
	/* margin-bottom: 10px; */

	@media (max-width: 659px) {
		justify-content: center;
	}
`;

const Title = styled.div`
	height: 40px;
	border-top: 1px solid ${props => props.theme.lightGray};
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: #333;
	font-family: ${props => props.theme.pt};
	white-space: nowrap;
	width: calc(100%);
`;

const Delimiter = styled.div`
	border-left: 1px solid ${props => props.theme.lightGray};
`;

export const Calendar = ({
	value,
	minDate = false,
	maxDate = false,
	onChange = () => false,
	minNights = 0,
	brief = false,
	showNights = false,
	readOnlyStartDate = false,
}) => {
	const { t } = useTranslation();
	const { isMobile } = useDevice();
	const [ isMonthSelect, setMonthSelect ] = useState(false);
	const [ isYearSelect, setYearSelect ] = useState(false);
	const [ months, setMonths ] = useState(() => {
		// parseDate(Array.isArray(value) ? value[0] : value).date(1).format(format),
		// parseDate(Array.isArray(value) ? value[0] : value).date(1).add(1, 'month').format(format)

		let date = Array.isArray(value) && value.length === 2 ? parseDate(value[0]) : parseDate(value);

		date = date.isValid()
			? date.date(1)
			: today().date(1);

		return [
			date.format(format),
			date.add(1, 'month').format(format)
		];
	});

	const [ dates, setDates ] = useState(false);

	const nightsTitles = useMemo(() => ([
		t('ночь'), 
		t('ночи'), 
		t('ночей')
	]), []);

	const title = useMemo(() => {
		if (Array.isArray(value)) {
			const date1 = parseDate(dates === false ? value[0] : dates[0]);
			const date2 = parseDate(dates === false ? value[1] : dates[1]);
			const nights = Math.abs(date1.diff(date2, 'day', true));
			
			return `${date1.format(`${format} dd`)} — ${date2.format(`${format} dd`)} • ${declines(nights, nightsTitles, true)}`;
		}

		if (typeof value === 'string') {
			const date = parseDate(dates === false ? value : dates);
			return date.format(`${format} dd`);
		}

		return '';
	}, [value, dates]);

	const toPrevMonth = () => setMonths(prev => {
		const date1 = parseDate(prev[0]);
		const date2 = parseDate(prev[1]);

		return [
			date1.subtract(1, 'month'),
			date2.subtract(1, 'month')
		];
	});

	const toNextMonth = () => setMonths(prev => {
		const date1 = parseDate(prev[0]);
		const date2 = parseDate(prev[1]);

		return [
			date1.add(1, 'month'),
			date2.add(1, 'month')
		];
	});

	const onClick = newValue => {
		if (Array.isArray(dates)) {
			let date1 = parseDate(dates[0]);
			let date2 = parseDate(newValue);
			if (date1.isAfter(date2)) {
				const temp = date2;
				date2 = date1;
				date1 = temp;
			}
			const nights = Math.abs(date1.diff(date2, 'day', true));

			if (nights < minNights) {
				date2 = date2.add(1, 'day');

				onChange([
					date1.format(format),
					date2.format(format)
				]);
			} else {
				onChange([
					date1.format(format),
					date2.format(format)
				]);
			}

			setDates(false);
			return;
		}

		if (typeof value === 'string') {
			onChange(newValue);
			return;
		}

		if (readOnlyStartDate && Array.isArray(value) && value.length === 2 && dates === false) {
			setDates(value);
			return;
		}

		if (dates === false) setDates([newValue, newValue]);
	};

	const onHover = value => {
		if (Array.isArray(dates)) setDates(prev => ([
			prev[0],
			value
		]));
	};

	const bind = {
		value: dates === false ? value: dates,	
		minDate,
		maxDate,
		onClick,
		onHover,
		selectMonth: () => setMonthSelect(true),
	};

	if (isYearSelect) {
		const bind = {
			minDate,
			maxDate,
			current: months[0],
			onChange: months => {
				setMonths(months);
				setYearSelect(false);
				setMonthSelect(true);
			}
		};

		return (
			<Container>
				<YearSelect {...bind} />
			</Container>
		);
	}

	if (isMonthSelect) {
		const bind = {
			minDate,
			maxDate,
			current: months[0],
			onChange: months => {
				setMonths(months);
				setMonthSelect(false);
			},
			onClickYear: () => setYearSelect(true),
		};

		return (
			<Container>
				<MonthSelect {...bind} />
			</Container>
		);
	}

	return (
		<Container>
			<Months>
				<Month
					stringDate={months[0]}
					{...bind}
					toPrevMonth={toPrevMonth}
					toNextMonth={isMobile || brief ? toNextMonth : false}
				/>

				{!isMobile && !brief && <Delimiter />}
				
				{!isMobile && !brief &&
					<Month
						stringDate={months[1]}
						{...bind}
						toPrevMonth={false}
						toNextMonth={toNextMonth}
					/>
				}
			</Months>

			{Array.isArray(value) && showNights && title && 
				<Title>
					{title}
				</Title>
			}
		</Container>
	);
};