import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useCartStore } from 'stores';
import { useTranslation } from 'react-i18next';

const Container = styled.h3`
	display: flex;
	flex-direction: row;
	align-items: center;

	span, a {
		margin: 0 5px;
	}

	span {
		color: #00C0FF;
	}
`;

export const ToOrder = () => {
	const { t } = useTranslation();
	const { id } = useCartStore();

	if (id === 0) return null;

	return (
		<Container>
			{t('Заказ')} <span>{id}</span>
			<Link to={`/order/${id}`} className='button white height30'>{t('Назад к заказу')}</Link>
		</Container>
	);
};