import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	position: relative;

	&:hover div.tooltipBody {
		opacity: 1;
		visibility: visible;
	}

	div.tooltipBody {
		border: 1px solid ${props => props.theme.darkBlue};
		background-color: white;
		padding: 5px 10px;
		position: absolute;
		left: 50%;
		bottom: calc(100% + 5px);
		box-shadow: 2px 2px 5px rgba(51, 51, 51, .5);
		z-index: 999999;
		opacity: 0;
		visibility: hidden;
		white-space: nowrap;
		transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
		color: #333;
		font-size: 16px;
		line-height: 21px;
		font-weight: normal;
	}
`;

export const Tooltip = ({ children, text = '' }) => 
	<Container>
		{children}
		<div className='tooltipBody'>{text}</div>
	</Container>
;
