import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHotelSearchStore } from 'stores';
import { Button, Sprite } from 'components';

const Container = styled.div`
	position: sticky;
	background-color: white;
	width: 270px;
	padding: 10px 0 20px 0;
	/* bottom: 20px; */
	bottom: 0;
	z-index: 999999;
	align-self: flex-start;
	margin-bottom: -20px;
`;

const FilteredCount = styled.div`
	margin: 10px 0;
	text-align: center;
	font-size: 14px;
	line-height: 17px;
	color: ${props => props.theme.darkBlue};

	span {
		font-size: inherit;
		line-height: inherit;
		color: #00C0FF;
	}

	& + .button {
		position: relative;

		svg {
			position: absolute;
			right: 5px;
			top: 5px;
		}
	}
`;

export const ClearFilters = () => {
	const { t } = useTranslation();
	const {
		params: {
			filters: {
				hotel,
				freesale,
				priceFrom,
				priceTo,
				stars,
				amenities,
				distance
			}
		},
		setParams,
		totalCount,
		filteredCount
	} = useHotelSearchStore();

	const isFiltered = useMemo(() => {
		if (hotel.trim().length >= 2) return true;
		if (freesale) return true;
		if (priceFrom > 0) return true;
		if (priceTo) return true;
		if (stars.length !== 5) return true;
		if (amenities.length > 0) return true;
		if (distance > 0) return true;

		return false;
	}, [hotel, freesale, priceFrom, priceTo, stars, amenities, distance]);

	if (!isFiltered) return null;

	const onClear = () => setParams(prev => ({
		...prev,
		filters: {
			hotel: '',
			freesale: false,
			priceFrom: 0,
			priceTo: 0,
			stars: [1, 2, 3, 4, 5],
			amenities: [],
			distance: 0
		}
	}));

	return (
		<Container className='sticky-element'>
			<FilteredCount>
				{t('Показаны')} <span>{filteredCount}</span> {t('из')} <span>{totalCount}</span>
			</FilteredCount>

			<Button gradient small onClick={onClear}>
				{t('Очистить все фильтры')}
				<Sprite icon='small_white_cross' />
			</Button>
		</Container>
	);
};